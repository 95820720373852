import { saveAs } from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { API_SERVER } from '../../EnvConfig/env_config';
import { FetchAutoCompleteData } from "../Common/CommanServices";
import { FileUploadChild, setFocus } from '../Common/Common';
import { AlertBox, AutoCompleteBox } from '../Common/Input';
import { post } from '../Common/service';
import Loader from '../Layout/Loader';

const BulkProduct = ({ open, setOpen }) => {
    const [ImportData, setImportData] = useState([]);

    const fileType = 'xlsx'
    const cookies = new Cookies();
    const LoginType = cookies.get('loginType')
    const LoginUserCode = cookies.get('loginUserCode');
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const EXCEL_FILE_URL = 'BulkProduct.xlsx';
    const [description, setDescription] = useState([])
    const [errorData, seterrorData] = useState([])
    const [errorCount, seterrorCount] = useState(0)
    const [sucessCount, setSucessCount] = useState(0)
    const [formData, setFormData] = useState({
      //  Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : String(LoginUserCode),
        Brand: "",
        Category: "",
        SubCategory: "",
        newFile: "",
        fileExt: "",
        fileName: ""
    })
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const Vendor = cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : String(LoginUserCode)

    const handleClear = () => {
        setFormData({
            Brand: "",
            Category: "",
            SubCategory: "",
            newFile: "",
            fileExt: "",
            fileName: ""
        });
        setSucessCount(0)
        seterrorCount(0)
    }


    const exportToErrorData = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(blob, "ErrorData.xlsx");
    }

    const handleSubmit = () => {
        let SuccessCount = 0;
        let ErrorData = [];
        let ErrorCount = 0;
        if (!formData.Brand) {
            AlertBox('ERROR', 'Please enter Brand', 'txtBrand');
            return;
        }
        else if (!formData.Category) {
            AlertBox('ERROR', 'Please enter Category', 'txtCategory');
            return;
        }
        else if (!formData.SubCategory) {
            AlertBox('ERROR', 'Please enter SubCategory', 'txtSubCategory');
            return;
        }
        else if (!formData.fileName) {
            AlertBox('ERROR', 'Please Upload file', 'txtfileName');
            return;
        } else if (ImportData.length > 0) {
            ImportData.forEach(async (item, i) => {
                setIsLoading(true)
                let requestData = { ...item, ...formData, ...data };
                if (LoginType === 'Vendor') {
                    requestData.Vendor = Vendor 
                }
                post(`${API_SERVER}/api/Master/InsertUpdateProductMaster`, requestData, (res) => {
                    if (res.status === "SUCCESS") {
                        SuccessCount = SuccessCount + 1;
                        AlertBox(res.status, `Success Count : ${SuccessCount}`, res.focus)
                        setSucessCount(SuccessCount)
                        handleClear()
                    } else if (res.status === 'ERROR') {
                        ErrorData.push({ ...item, ERROR: res.message })
                        seterrorData(ErrorData)

                        ErrorCount = ErrorCount + 1;
                        seterrorCount(ErrorCount)
                        AlertBox(res.status, `Error Count : ${ErrorCount}`, res.focus)
                    }
                    setIsLoading(false)
                })
            })
        }
        else {
            AlertBox()
        }
    };
    const autoCompleteTimeoutRef = useRef(null);

    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };

    const handleFileUploader = async (e) => {
        let data = await readFile(e)
        setImportData(data.exceljson)
        setFormData({ ...formData, fileName: data.FileName })
    };

    const getBulkProductData = async () => {
        post(`${API_SERVER}/api/Master/GetBulkProductFields`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setDescription(res.value)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setDescription({ ...res })
            }
        });
    }
   
    const handleExport = async () => {
        if (!formData.Brand) {
            AlertBox('ERROR', 'Please enter Brand');
            setTimeout(() => {
                setFocus("txtBrand");
            }, 100);
            return;
        }
        if (!formData.Category) {
            AlertBox('ERROR', 'Please enter Category');
            setTimeout(() => {
                setFocus("txtCategory");
            }, 100);
            return;
        }
        if (!formData.SubCategory) {
            AlertBox('ERROR', 'Please enter SubCategory');
            setTimeout(() => {
                setFocus("txtSubCategory");
            }, 100);
            return;
        }
        let workbook;
        try {
            const response = await fetch('BulkProduct.xlsx');
            const arrayBuffer = await response.arrayBuffer();
            workbook = XLSX.read(arrayBuffer, { type: 'array' });
        } catch (error) {
            AlertBox('ERROR', 'Failed to load the Excel file');
            return;
        }

        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

        if (LoginType === 'Vendor') {
            const range = XLSX.utils.decode_range(firstSheet['!ref']);
            for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C < range.e.c; ++C) {
                    const cell = firstSheet[XLSX.utils.encode_cell({ r: R, c: C + 1 })];
                    if (cell) {
                        firstSheet[XLSX.utils.encode_cell({ r: R, c: C })] = cell;
                    } else {
                        delete firstSheet[XLSX.utils.encode_cell({ r: R, c: C })];
                    }
                }
            }
            range.e.c--;
            firstSheet['!ref'] = XLSX.utils.encode_range(range);
        }

        const fileName = `${formData.Category}.xlsx`;
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
        URL.revokeObjectURL(url);
    };

    useEffect(() => {
        getBulkProductData();
        document.title = 'Bulk Load'
        setFocus("txtBrand")

    }, [])

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                {
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                            <h6 className="font-weight-bold text-primary mb-4">Bulk Product</h6>
                                            <div className="col-12 col-lg-4">
                                                <AutoCompleteBox
                                                    id="txtBrand"
                                                    label='Brand'
                                                    divclassname='col-12 fields'
                                                    placeholder='Brand'
                                                    inputValue={formData.Brand ? formData.Brand : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'Brand', 'GetBrand', '');
                                                        }}
                                                    maxLength='50'
                                                    options={autoCompleteOptions}
                                                    setOptions={setAutoCompleteOptions}
                                                    required={true}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtCategory") };
                                                    }}
                                                />
                                                <AutoCompleteBox
                                                    id="txtCategory"
                                                    divclassname='col-12 fields'
                                                    label='Category'
                                                    placeholder='Category'
                                                    inputValue={formData.Category ? formData.Category : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'Category', 'GetCategory', '');
                                                        }}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, SubCategory: '' })
                                                    }}
                                                    maxLength='50'
                                                    options={autoCompleteOptions}
                                                    setOptions={setAutoCompleteOptions}
                                                    required={true}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtSubCategory") };
                                                    }}
                                                />
                                                <AutoCompleteBox
                                                    id="txtSubCategory"
                                                    divclassname='col-12 fields'
                                                    label='SubCategory'
                                                    placeholder='SubCategory'
                                                    inputValue={formData.SubCategory ? formData.SubCategory : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'SubCategory', 'GetSubCategoryCategoryWise', formData.Category);
                                                        }}
                                                    maxLength='50'
                                                    options={autoCompleteOptions}
                                                    setOptions={setAutoCompleteOptions}
                                                    required={true}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtVendor") };
                                                    }}
                                                />
                                                <div className="col-12 fields">
                                                    <label>Choose File<span className="text-danger">*</span></label>
                                                    <FileUploader
                                                        id="txtfileName"
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(e) => handleFileUploader(e)}
                                                        children={<FileUploadChild label="" message={formData.fileName + '.' + formData.fileExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-8 mt-4">
                                                <div>
                                                    <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                        Note:
                                                        <div>
                                                            1. Please Upload Only File of Format .xlsx <br />
                                                            2. Below Given Fields and Header of .xlsx Should Be Same
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <textarea
                                                        id="txtDescription"
                                                        className="col-12 mt-4"
                                                        name='Description'
                                                        rows='6'
                                                        defaultValue={description}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("submit") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-4 z-0">
                                                <div className="lms-new-button text-center">
                                                    {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="submit" onClick={handleSubmit}>
                                                        <i className="fa-solid fa-upload"></i> Upload
                                                    </button>)}
                                                    {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button export-btn" onClick={() => handleExport(EXCEL_FILE_URL)}>
                                                        <i className="fa-solid fa-download"></i> Download Excel Format
                                                    </button>)}
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                    {sucessCount > 0 ?
                                                        <div className="d-inline-flex gap-3">
                                                            <span className="ms-4 text-info">SUCESS COUNT : {sucessCount} </span>
                                                        </div>
                                                        :
                                                        <span></span>
                                                    }
                                                    {errorCount > 0 ?
                                                        <div className="d-inline-flex gap-3">

                                                            <span className="ms-4 text-danger">ERROR COUNT : {errorCount} </span>
                                                            <button className="btn export-btn btn-sm" onClick={() => exportToErrorData(errorData)}> <i className="fa-solid fa-download"></i></button>
                                                        </div>
                                                        :
                                                        <span></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default BulkProduct

const readFile = async (e) => {
    const file = e;
    let FileName = e.name;
    const data = await file.arrayBuffer(file);
    const excelFile = XLSX.read(data);
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
    const exceljson = XLSX.utils.sheet_to_json(excelSheet, {
        defval: "",
        raw: false,
        header: 0 
    });
    const textToBoolean = (text) => {
        const lowerText = text.toLowerCase();
        if (lowerText === "true") {
            return true;
        } else if (lowerText === "false") {
            return false;
        }
      
        return null; 
    };
   
    for (let i = 0; i < exceljson.length; i++) {
        const row = exceljson[i];
        for (const key in row) {
            if (row.hasOwnProperty(key)) {
                const value = row[key];
                if (typeof value === "string") {
                    const booleanValue = textToBoolean(value);
                    if (booleanValue !== null) {
                        row[key] = booleanValue;
                    }
                }
            }
        }
    }

    return { exceljson, FileName };
};