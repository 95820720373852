import { Dialog, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "chart.js/auto";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { Pie } from "react-chartjs-2";
import { Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import { API_SERVER } from "../../EnvConfig/env_config";
import { FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";
import { setFocus } from "../Common/Common";
import { AlertBox, AutoCompleteBox } from "../Common/Input";
import { ModalTable } from "../Common/Table";
import { post } from "../Common/service";

const Dashboard = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const LoginUserCode = cookies.get('loginUserCode');
    const Token = cookies.get('token')
    const LoginType = cookies.get('loginType')
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode),
    }
    const [currentPageNo, setCurrentPageNo] = useState(1);

    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [formData, setFormData] = useState({
        Fdate: Todate,
        Tdate: Todate,
        VendorCode: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
    })
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [dashboard, setDashboard] = useState({})
    const [BarChartData, setBarChartData] = useState({})


    const [seriesData, setSeriesData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [result, setResult] = useState({})
    const [tabType, setTabType] = useState('')
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const [msg, setMsg] = useState('')
    const [PaiChartData, setPaiChartData] = useState({
        labels: [],
        data: []
    });
    const [statusData, setStatusData] = useState({
        type: "pie",
        animationEnabled: true,
        exportEnabled: true,
        theme: "dark2",
        labels: [],
        datasets: [
            {
                label: "",
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF8C00",
                    "#7FFF00"
                ],
                data: []
            }
        ]
    });
    const getFormData = async () => {
        setLoading(true)
        const loginCodeString = String(LoginUserCode)
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: ""
        }

        await post(`${API_SERVER}/api/Dashboard/GetDashboardData`, { ...data, ...formData }, (res) => {
            if (res.status === "SUCCESS") {
                setDashboard({ ...res })
                setLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setLoading(false)
                setDashboard({ ...res })
            }
        });
    }

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const getchart1 = async () => {
        setLoading(true)
        const loginCodeString = String(LoginUserCode)
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: "1Chart"
        }
        await post(`${API_SERVER}/api/Dashboard/GetDashboardData`, { ...data, ...formData }, (res) => {
            if (res.status === "SUCCESS") {
                setBarChartData({ ...res })
                setLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setBarChartData({})
                setLoading(false)
            }
        });
    }
    const getPieChart = async () => {
        setLoading(true);
        const loginCodeString = String(LoginUserCode);
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
        };
        await post(`${API_SERVER}/api/Dashboard/GetDashboardDataPaymentwise`, { ...data, ...formData }, (res) => {

            if (res.status === "SUCCESS") {
                setPaiChartData({
                    labels: ["COD", "Paid", "All"],
                    data: [res.cod, res.paid, res.all]
                });
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login');
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setPaiChartData({
                    labels: [],
                    data: []
                });
            }



        }
        )
    }

    const GetModelData = (type, CurrentPage) => {
        setLoading(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const loginCodeString = String(LoginUserCode)
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: "s",
            PageIndex: `${PageIndex}`
        }
        setResult({})
        setTabType(type)
        post(`${API_SERVER}/api/Dashboard/ShowDashboardOrderDetails`, { ...data, ...formData, tabType: type }, (res) => {
            if (res.status === "SUCCESS") {
                setResult({ ...res })
                setLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setResult({ ...res })
            }

        });
    }
    const handleChange1 = (event, value) => {
        GetModelData(tabType, value);
    };

    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1',
        ...formData,
        tabType
    }

    useEffect(() => {
        if (BarChartData.totalStatusChart) {
            const series = BarChartData.totalStatusChart.map(item => parseInt(item.value));
            const categories = BarChartData.totalStatusChart.map(item => item.type);
            setSeriesData([{ data: series }]);
            setCategories(categories);

        }
        if (PaiChartData.labels.length > 0 && PaiChartData.data.length > 0) {
            setStatusData(prevStatusData => ({
                ...prevStatusData,
                labels: PaiChartData.labels,
                datasets: prevStatusData.datasets.map(dataset => ({
                    ...dataset,
                    data: PaiChartData.data
                }))
            }));
        }
    }, [BarChartData.totalStatusChart, PaiChartData]);

    const HandleClear = () => {
        setFormData({ ...formData, Fdate: Todate, Tdate: Todate, VendorCode: "" })
        getFormData()
    }
    
    const handleVendorVerification = async () => {
        let LoginUserCode = await cookies.get('loginUserCode');
        const requestData = {
            Token: cookies.get('token'),
            LoginUserCode: LoginUserCode,
            Source: 'web',
            VendorCode: LoginUserCode
        }
        await post(`${API_SERVER}/api/Master/CheckVerifyVendor`, { ...requestData }, (res) => {
            if (res.status === "ERROR") {
                setMsg(res.message)
                setIsOpen(true)
            } else {
                setMsg('')
                setIsOpen(false)
            }
        });
    }

    useEffect(() => {
        getFormData()
        getchart1("1Chart")
        getPieChart()
        handleVendorVerification()
    }, [formData.VendorCode])
    useEffect(() => {
        document.title = 'Indo CraftWorld: Dashboard'
        AOS.init(
            {
                duration: 1000,
            }
        );
    }, [])
    const series = [
        {
            name: 'Delivered(%)',
            data: [20, 30, 40, 50, 60]
        },
        {
            name: 'RTO(%)',
            data: [10, 25, 35, 45, 55]
        },
        {
            name: 'Intransit(%)',
            data: [15, 20, 30, 40, 50]
        },
        {
            name: 'Undelivered(%)',
            data: [5, 15, 25, 35, 45]
        }
    ];

    const staticCategories = ['BLUEDART', 'DELIVEHRY', 'FEDEX', 'TRACK ON', 'ON POINT'];
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            }
        },
        xaxis: {
            categories: staticCategories
        },
        colors: ['#11d04b', '#f5e029', '#33d1fe', '#b50000'],
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            offsetX: 50
        }
    };
    return (
        <>
            <>
                <main id="main" className={`main ${open ? '' : 'margin_left'}`} style={open ? {} : { marginLeft: "60px" }}>
                    <div className='container-fluid dashboard'>
                        <div className='mb-3'>
                            <h4 className="m-0">Dashboard</h4>
                        </div>
                        <div className='main-card1 card1 border-0 mb-2'>
                            <div className='row p-3'>
                                <div className="col-lg-3 row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="fields">
                                            <label className="form-label"> From Date </label>
                                            <DatePicker
                                                preventOpenOnFocus={true}
                                                id='txtFromDate'
                                                className="form-control"
                                                placeholderText='From Date'
                                                dateFormat={'dd-MMM-yyyy'}
                                                autoComplete='off'
                                                maxDate={new Date()}
                                                //showMonthDropdown
                                                selected={formData.Fdate === '' ? '' : new Date(formData.Fdate)}
                                                onChange={(e) => {
                                                    let dateArr = e?.toString()?.split(' ')
                                                    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                    setFormData({ ...formData, Fdate: dateArr })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtToDate") };
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="fields">
                                            <label className="form-label">To Date</label>
                                            <DatePicker
                                                preventOpenOnFocus={true}
                                                id='txtToDate'
                                                className="form-control"
                                                minDate={formData.Fdate}
                                                placeholderText='To Date'
                                                dateFormat={'dd-MMM-yyyy'}
                                                autoComplete='off'
                                                selected={formData.Tdate === '' ? '' : new Date(formData.Tdate)}
                                                onChange={(e) => {
                                                    let dateArr = e?.toString()?.split(' ')
                                                    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                    setFormData({ ...formData, Tdate: dateArr })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtVendor") };
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <AutoCompleteBox
                                    id="txtVendor"
                                    label='Vendor'
                                    divclassname='col-lg-3 col-md-3 col-sm-12 fields'
                                    placeholder='Vendor'
                                    inputValue={formData.VendorCode ? formData.VendorCode : ''}
                                    onInputChange={
                                        (event, newInputValue) => {
                                            handleAutoCompleteChange(event, newInputValue, 'VendorCode', 'GetVendor', LoginUserCode.toString());
                                        }}
                                    disabled={VendorName}
                                    maxLength='50'
                                    autoComplete="off"
                                    options={autoCompleteOptions}
                                    setOptions={setAutoCompleteOptions}
                                    onKeyDown={(e) => {

                                        if (e.key === 'Enter') {
                                        };
                                    }}
                                />
                                <div className="col-lg-4 m-4  z-0">
                                    <div className="lms-new-button ">

                                        <button className="button search-btn" id='submit' onClick={() => { getFormData(); getchart1("1Chart"); getPieChart(); }}>
                                            <i className="fa-solid fa-magnifying-glass"> </i>
                                        </button>

                                        <button className="button reset-btn" onClick={HandleClear}>
                                            <i className="bi bi-arrow-clockwise" /> Reset
                                        </button>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='row p-2'>

                            {/*----------  New Order Start -----------*/}

                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card1 card1 border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('AllOrders')} >
                                    <div className='card1-header mb-1'>
                                        <h6 className="m-0">All Orders</h6>
                                    </div>
                                    <div className='card1-body'>
                                        <div className='d-flex align-items-center ' style={{ justifyContent: "space-between", padding: "16px" }}>
                                            <div className='dash-icon4' data-aos="fade-left">
                                                <i className='fa-solid fa-truck' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.orders}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- New Order End -----------*/}

                            {/*---------- Orders Start -----------*/}

                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card1 card1 border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('NewOrders')}>
                                    <div className='card1-header mb-1'>
                                        <h6 className="m-0">New Orders</h6>
                                    </div>
                                    <div className='card1-body'>
                                        <div className='d-flex align-items-center' style={{ justifyContent: "space-between", padding: "16px" }}>
                                            <div className='dash-icon3' data-aos="fade-right">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.newOrders}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- Orders End -----------*/}

                            {LoginType !== 'Vendor' && (
                                <>
                                    {/*---------- Customers Start -----------*/}
                                    <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                        <div className='main-card1 card1 border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('Customers')}>
                                            <div className='card1-header mb-1'>
                                                <h6 className="m-0">Customers</h6>
                                            </div>
                                            <div className='card1-body'>
                                                <div className='d-flex align-items-center' style={{ justifyContent: "space-between", padding: "16px" }}>
                                                    <div className='dash-icon2' data-aos="fade-right">
                                                        <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                                    </div>
                                                    <div>
                                                        <h5 className="m-0">{dashboard.customers}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*---------- Customers End-----------*/}

                                    {/*---------- Pending Vendor -----------*/}
                                    <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                        <div className='main-card1 card1 border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('PendingVendor')}>
                                            <div className='card1-header mb-1'>
                                                <h6 className="m-0">Pending Vendor</h6>
                                            </div>
                                            <div className='card1-body'>
                                                <div className='d-flex align-items-center' style={{ justifyContent: "space-between", padding: "16px" }}>
                                                    <div className='dash-icon2' data-aos="fade-right">
                                                        <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                                    </div>
                                                    <div>
                                                        <h5 className="m-0">{dashboard.pendingVendor}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*---------- Pending Vendor End-----------*/}
                                </>
                            )}

                            {/*---------- Pending Products -----------*/}
                            <div className='col-sm-6 col-lg-3' style={{ cursor: "pointer" }}>
                                <div className='main-card1 card1 border-0' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => GetModelData('PendingProduct')}>
                                    <div className='card1-header mb-1'>
                                        <h6 className="m-0">Pending Products</h6>
                                    </div>
                                    <div className='card1-body'>
                                        <div className='d-flex align-items-center' style={{ justifyContent: "space-between", padding: "16px" }}>
                                            <div className='dash-icon2' data-aos="fade-right">
                                                <i className='fa-solid fa-money-bills' style={{ color: "black" }}></i>
                                            </div>
                                            <div>
                                                <h5 className="m-0">{dashboard.pendingProduct}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*---------- Pending Products End-----------*/}
                        </div>

                        <div className='row'>
                            <div className='col-md-8 col-lg-8'>
                                <div className='main-card1 card1 border-0'>
                                    <div className='card1-header mb-1'>
                                        <h5 className="m-0">Total Order Status</h5>
                                    </div>
                                    <div className='card1-body' >

                                        <Chart
                                            type="bar"
                                            height={390}
                                            series={seriesData}
                                            options={{
                                                chart: {
                                                    height: "390",
                                                    type: "bar",
                                                },
                                                plotOptions: {
                                                    bar: {
                                                        horizontal: false,
                                                        columnWidth: "50%",
                                                        endingShape: "rounded",
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                stroke: {
                                                    show: true,
                                                    width: 2,
                                                    colors: ['transparent'],
                                                },
                                                xaxis: {
                                                    categories: categories,
                                                },
                                                yaxis: {
                                                    title: {
                                                        text: "",
                                                    },
                                                },
                                                fill: {
                                                    opacity: 1,
                                                },
                                                tooltip: {
                                                    y: {
                                                        formatter: '',
                                                    },
                                                },
                                                colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF8C00', '#7FFF00']
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4'>
                                <div className="main-card1 card1 border-0 p-2">
                                    <div className="card1">
                                        <h6 className="m-0 font-weight-bold pt-2  text-center  "><b>Status - Total Shipment</b></h6>
                                        <Pie className='py-2 mt-2' data={statusData} />
                                    </div></div>
                            </div>
                            {/*<div className='col-md-12 col-lg-12 '>*/}
                            {/*    <div className="main-card1 card1 border-0 p-2">*/}
                            {/*        <div className="card1-header1 py-3 d-flex justify-content-between align-items-center bg-transparent border-bottom-0">*/}
                            {/*            <h6 className="m-0 fw-bold">Top Selling Product</h6>*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <div className="card-body" style={{ "overflowX": "auto" }}>*/}
                            {/*                <ReactApexChart options={options} series={series} type="bar" height={350} />*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </main>
            </>
            {/* Loder start*/}

            {/* Loder End*/}
            {isOpen === true ?
                <Dialog
                    open
                    //   onClose={() => setOpenReview(false)}
                    fullWidth='md'
                    aria-labelledby="responsive-dialog-title">
                    <div className="review d-flex justify-content-end" style={{ minHeight: '150px' }}>
                        <div className="col-sm-11 ps-4" style={{ fontSize: '18px', fontWeight: '700', marginTop: '50px', textAlign: 'center', color: 'red' }}>
                            {msg ? msg :'Please update your profile and wait for administrator approval for further activity.'}
                        </div>
                        <div className="col-sm-1 close_btn">
                            <IconButton onClick={() => setIsOpen(false)}>
                                <Close fontSize='small' style={{ color: '#000'}} />
                            </IconButton>
                        </div>
                    </div>

                </Dialog>
                : ''}


            <div className="modal fade"
                id="exampleModal" data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="aboutUsLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ background: "mintcream" }}>
                        <div className="modal-header d-flex justify-content-between w-100" style={{ padding: '7px 15px' }}>
                            <div>
                                <h5 className="modal-title d-flex" id="exampleModalLabel">
                                    {(tabType === 'NewOrders' && 'New Orders') ||
                                        (tabType === 'AllOrders' && 'All Orders') ||
                                        (tabType === 'Customers' && 'Customers') ||
                                        (tabType === 'PendingVendor' && 'Pending Vendors') ||
                                        (tabType === 'PendingProduct' && 'Pending Product')}
                                </h5>
                            </div>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            <div >
                                <button type="button"
                                    onClick={() => handleDownloadExcel(API_SERVER + '/api/Dashboard/ShowDashboardOrderDetails', ExportRequest, tabType)}
                                    className="btn btn-rounded btn-warning"
                                >
                                    <span className=" text-warning me-2">
                                        <i className="fa fa-download text-white color-success">
                                        </i>
                                    </span>Export
                                </button>
                                <button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"> <i className='fa fa-close' /> Close</button>
                            </div>
                        </div>
                        <div className="modal-body table-responsive" style={{ maxHeight: '550px', padding: '8px' }}>
                            <div className="form-box p-0  me-3 " >
                                <div className="row  details-tableAwb" >
                                    <ModalTable
                                        Token={Token}
                                        PageName='Dashboard'
                                        handleFilter={() => {
                                            setFilterData({
                                                ...filterData,
                                            });
                                        }}
                                        Filter={false}
                                        tabType={tabType}
                                        tableData={result}
                                        filterData={{ tabType }}
                                        setFilterData={setFilterData}
                                        currentPageNo={currentPageNo}
                                        handleChange1={handleChange1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ padding: '3px' }}>
                            {/*<button type="button"*/}
                            {/*    onClick={() => handleDownloadExcel(API_SERVER + '/api/Dashboard/ShowDashboardOrderDetails', ExportRequest, tabType)}*/}
                            {/*    className="btn btn-rounded btn-warning"*/}
                            {/*>*/}
                            {/*    <span className=" text-warning me-2">*/}
                            {/*        <i className="fa fa-download text-white color-success">*/}
                            {/*        </i>*/}
                            {/*    </span>Export*/}
                            {/*</button>*/}
                            {/*<button type="button" className="btn btn-secondary" style={{ margin: '5px', minWidth: '30px' }} data-bs-dismiss="modal"> <i className='fa fa-close' /> Close</button>*/}
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}
export default Dashboard;
