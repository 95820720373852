import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ value, setValue, className = '', style }) => {

    const modules = {
        toolbar: [
            [{ 'font': [] }, { 'header': [1, 2, 3, 4, 5, 6, false] }, 'bold', 'italic', 'underline', 'strike', 'blockquote', { 'color': [] }, { 'background': [] }, 'code', { 'script': 'sub' }, { 'script': 'super' }, { 'direction': 'rtl' }, { 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, 'link', 'image', 'clean'],
        ],
    }

    return (
        <div className={className} style={style} >
    
            <ReactQuill
                theme="snow"
                
                value={value}
                onChange={setValue}
                modules={modules}
                style={{ backgroundColor:"white" }}
              //  style={{ height: '300px' }}
            />
        </div>
    );
};

export default TextEditor;


export function AdvancedTextEditor({ value, setValue, className = '', style }) {

    const handleEditorChange = (content, editor) => {
        setValue(content);
    }
    return (
        <>
            <div className={className} style={style} >
                <Editor
                    apiKey='1bb82jlh7a51ipqt52c2j7qyao9ujpxzjvmdg11cnf6kjrt0'
                    value={value}
                    onEditorChange={handleEditorChange}
                    init={{
                        height: 500,
                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker fullscreen', 
                        fullscreen_native: true,
                        toolbar: 'undo redo | fullscreen | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    }}
                />
            </div>
</>
    );
}