
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, handleFileUploader, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { Table } from "../Common/Table";
import { DropDownData, FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";
import { useRef } from "react";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';

const SubCategoryMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [isExpanded, setIsExpanded] = useState(false);
    const [formData, setFormData] = useState({
        SubCategoryID: "",
        CategoryCode: '',
        SubCategoryName: '',
        SequenceNo: '',
        subCategoryImage: "",
        ImageExt: "",
        ImageName: "",
        SubCategoryBannerImage: "",
        BannerImageExt: "",
        BannerImageName: "",
        ProcessType: "",
        Description: '',
        IsActive: true,
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const fileType = 'jpg,png'

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    let GetOrderOptions = DropDownData('GetOPSProcessType', showForm);

    const getSubCategoryMasterData = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowSubCategoryMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
               // AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleClear = () => {
        setFormData({
            SubCategoryID: "",
            CategoryCode: '',
            SubCategoryName: '',
            SequenceNo: '',
            ProcessType: "",
            subCategoryImage: "",
            ImageExt: "",
            ImageName: "",
            SubCategoryBannerImage: "",
            BannerImageExt: "",
            BannerImageName: "",
            Description: '',
            IsActive: true,
        });
        filterData.SearchText = '';
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getSubCategoryMasterData(value);
    };

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };

    const handleSubmit = () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateSubCategoryMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false)
                Swal.fire({
                    title: "success",
                    text: "Uploaded",
                    icon: "success"
                });
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.status === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditSubCategoryMaster`, { ...data, SubCategoryID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    SubCategoryID: res.editRecord.subCategoryID,
                    CategoryCode: res.editRecord.categoryCode,
                    SubCategoryName: res.editRecord.subCategoryName,
                    SequenceNo: res.editRecord.sequenceNo,
                    ProcessType: res.editRecord.processType,
                    Description: res.editRecord.description,
                    subCategoryImage: res.editRecord.subCategoryImage,
                    ImageName: res.editRecord.imageName,
                    ImageExt: res.editRecord.imageExt,
                    SubCategoryBannerImage: res.editRecord.subCategoryBannerImage,
                    BannerImageExt: res.editRecord.bannerImageExt,
                    BannerImageName: res.editRecord.bannerImageName,
                    IsActive: res.editRecord.isActive
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteSubCategoryMaster`, { ...data, subCategoryID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }

    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }

    useEffect(() => {
        document.title = 'Sub Category Master'
        if (showForm) {
            setFocus("txtCategory")
        }
        setFocus("txtCategory")
        
    }, [showForm, setFocus])
    useEffect(() => {
        getSubCategoryMasterData()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">

                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowSubCategoryMaster', ExportRequest, 'SubCatgory Master')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Sub Category Master'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area mt-2">
                                        <div className="row">
                                            <AutoCompleteBox
                                                placeholder="Category"
                                                label='Category'
                                                inputValue={formData.CategoryCode ? formData.CategoryCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'CategoryCode', 'GetCategory', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtCategory'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSubCategoryName") };
                                                }}
                                            />


                                            <InputBox
                                                label="SubCategory Name"
                                                placeholder="SubCategory Name"
                                                id="txtSubCategoryName"
                                                type="text"
                                                maxLength='30'
                                                value={formData.SubCategoryName}
                                                onChange={(e) => setFormData({ ...formData, SubCategoryName: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSequenceNo") };
                                                }}
                                                required
                                            />

                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Sequence No <span className="Required">*</span></label>
                                                    <input
                                                        id="txtSequenceNo"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Sequence No"
                                                        autoComplete="off"
                                                        maxLength='30'
                                                        name='Sequence No'
                                                        value={formData.SequenceNo}
                                                        onChange={(e) => setFormData({ ...formData, SequenceNo: e.target.value })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtDescription") };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <SelectInputBox
                                                id="txtProcessType"
                                                divclassname="col col-lg-3 fields"
                                                label="Process Type"
                                                required={true}
                                                value={formData.ProcessType}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, ProcessType: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtSteps")
                                                    }
                                                }}
                                            ><option value=''>--Select Process--</option>
                                                {
                                                    GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                        <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                    )
                                                }
                                            </SelectInputBox>
                                            <div className="col-lg-3 col-md-4 col-sm-6 fields "
                                                id="txtSubCategoryImage">
                                                <label className="form-label text-nowrap">SubCategory Image<span className="Required">* </span>
                                                </label>
                                                <div className="mt-1">
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'subCategoryImage', 'ImageExt', 'ImageName')}
                                                        children={<FileUploadChild label='Sub Category Image' message={formData.ImageName + '.' + formData.ImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note: For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (190-210)Pixels.</div>
                                                                <div>* Height should be between (190-210)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.subCategoryImage ?
                                                <div className='col-lg-3  ' style={{ position: "relative" }}>
                                                    <div className="m-3">
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.ImageExt};base64,${formData.subCategoryImage}`)}
                                                            className='border border-success img-thumbnail m-1'
                                                            src={`data:image/png;base64,${formData.subCategoryImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                    </div>
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6 fields" id="txtSubCategoryBannerImage">

                                                <label className="form-label text-nowrap">SubCategory Banner Image <span className="Required" >*</span>
                                                </label>
                                                <div className="mt-1">
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'SubCategoryBannerImage', 'BannerImageExt', 'BannerImageName')}
                                                        children={<FileUploadChild label='SubCategory Banner Image' message={formData.BannerImageName + '.' + formData.BannerImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note:For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (1500-1530)Pixels.</div>
                                                                <div>* Height should be between (275-300)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {formData.SubCategoryBannerImage ?
                                                <div className='col-md-4 col-lg-3' style={{ position: "relative" }}>
                                                    <div className="m-4">
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.BannerImageExt};base64,${formData.SubCategoryBannerImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.SubCategoryBannerImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative" }}
                                                        />
                                                    </div>
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Description</label>
                                                    <textarea
                                                        id="txtDescription"
                                                        className="col-12"
                                                        placeholder="Description"
                                                        rows="2"
                                                        value={formData.Description}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Description: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch mt-4">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsActive}
                                                        onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {/*//<div className="col-lg"></div>*/}
                                            <div className={"col-lg-12 mt-4"}>
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='SubCategoryMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getSubCategoryMasterData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>SubCategory Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='SubCategoryImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default SubCategoryMaster
