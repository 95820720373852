
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, FileUploadChild, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, handleFileUploader } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

const BlogMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate();
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode').toString();
    const fileType = 'jpg,png'

    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        blogID: "",
        description: "",
        descriptionDetails:"",
        image: "",
        imageName: "",
        imageExt: "",
        blogUrl: "",
        active: true,
    });

    const handleClear = () => {
        setFormData({
            blogID: "",
            description: "",
            descriptionDetails: "",
            image: "",
            imageName: "",
            imageExt: "",
            blogUrl:"",
            active: true,
        });
        filterData.SearchText = '';
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    //-------------Start Show insert update delete and export-------------//

    const getBlogMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowBlogMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            setIsLoading(false)
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }

    const handleChange = (value) => {
        setFormData({ ...formData, descriptionDetails: value }); // Directly set the value
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateBlogMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditBlogMaster`, { ...data, blogID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    blogID: res.blogID,
                    description: res.description,
                    descriptionDetails: res.descriptionDetails,
                    image: res.image,
                    imageExt: res.imageExt,
                    imageName: res.imageName,
                    active: res.active,
                    blogUrl: res.blogUrl
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteBlogMaster`, { ...data, blogID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    const handleChange1 = (event, value) => {
        getBlogMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    //-------------End Show insert update delete and export-------------//

    useEffect(() => {
        document.title = 'Blog Master'
        if (showForm) {
            setFocus("txtDescription")
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getBlogMaster()
    }, [sortConfig, refreshGrid])


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">

                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()

                                    }}

                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowBlogMaster', ExportRequest, 'BlogMaster')
                                    }}

                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Blog Master'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">Description<span className="Required">*</span></label></div>
                                                    <textarea
                                                        className="col-12"
                                                        id="txtDescription"
                                                        name='description'
                                                        rows={2}
                                                        maxLength={500}
                                                        value={formData.description}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, description: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtShareUrl") };
                                                        }}
                                                    />
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">Url</label></div>
                                                    <input
                                                        className="col-12"
                                                        id="txtShareUrl"
                                                        name='blogUrl'
                                                        maxLength={500}
                                                        value={formData.blogUrl}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, blogUrl: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtDescriptionDetails") };
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12"
                                                    id="txtImage">
                                                    <div className="fields">
                                                        <label className="form-label ">Image<span className="Required">*</span></label>
                                                        <FileUploader
                                                            classes='file-uploader emp-photo'
                                                            types={fileType.split(",")}
                                                            handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'image', 'imageExt', 'imageName')}
                                                            children={<FileUploadChild label='Image' message={formData.imageName + '.' + formData.imageExt} types={fileType.split(",")} />}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <div className="my-2">
                                                            <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                                <div style={{ fontSize: '13px' }}>Note:For Best View</div>
                                                                <div className="notefont">
                                                                    <div>* Width should be between (180-500) Pixels.</div>
                                                                    <div>* Height should be between (130-500) Pixels.</div>
                                                                    <div>* Maximum Image Size 100 kb.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-check form-switch">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="activeCheckbox"
                                                        >
                                                            Active
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="activeCheckbox"
                                                            name='Active'
                                                            checked={formData.active}
                                                            onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleSubmit();
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">Description Details<span className="Required">*</span></label></div>
                                                    {/*<textarea*/}
                                                    {/*    className="col-12"*/}
                                                    {/*    id="txtDescriptionDetails"*/}
                                                    {/*    name='descriptionDetails'*/}
                                                    {/*    rows={2}*/}
                                                    {/*    maxLength={2000}*/}
                                                    {/*    style={{ border: '1px solid #ccc', padding: '10px', minHeight: '200px' }}*/}
                                                    {/*    value={formData.descriptionDetails}*/}
                                                    {/*    onChange={(e) => {*/}
                                                    {/*        setFormData({ ...formData, descriptionDetails: e.target.value })*/}
                                                    {/*    }}*/}
                                                    {/*    onKeyDown={(e) => {*/}
                                                    {/*        if (e.key === 'Enter') { setFocus("txtImage") };*/}
                                                    {/*    }}*/}
                                                    {/*/>*/}
                                                    <ReactQuill
                                                        className="col-12"
                                                        value={formData.descriptionDetails}
                                                        onChange={handleChange} 
                                                        style={{ border: '1px solid #ccc' }}
                                                    />
                                                </div>
                                            </div>
                                 
                                    

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='BlogMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getBlogMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default BlogMaster

