
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { DropDownData, FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";

const PinCodeMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [MenuText, setMenuText] = useState("Section Master");
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [formData, setFormData] = useState({
        PinCodeId: "",
        Area: '',
        countryCode: "",
        StateCode:"",
        PinCode: "",
        IsNotServiceable:false,
        IsActive: true,
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getPinCodeMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowPinCodeMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status==="ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            PinCodeId: "",
            countryCode: "",
            StateCode: "",
            Area: '',
            PinCode: "",
            IsNotServiceable:false,
            IsActive: true,
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setColoredRow(null)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getPinCodeMaster(value);
    };
    
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdatePinCodeMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setColoredRow(null)
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    let GetCountry = DropDownData('GetCountry', showForm);
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditPinCodeMaster`, { ...data, PinCodeId: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    PinCodeId: res.pinCodeID,
                    countryCode: res.countryCode,
                    StateCode: res.stateCode,
                    Area: res.area,
                    PinCode: res.pinCode,
                    IsNotServiceable: res.isNotServiceable,
                    IsActive: res.isActive
                });
                setShowForm(true)
                setColoredRow(id)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeletePinCodeMaster`, { ...data, PinCodeId: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    useEffect(() => {
        document.title = 'Pin Code Master'
        if (showForm) {
            setFocus("txtArea")
        }
        setFocus("txtArea")
    }, [showForm, setFocus])

    useEffect(() => {
        getPinCodeMaster()
    }, [sortConfig, refreshGrid])
    console.log(formData.countryCode,"dfdsfsd")
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        handleClear()
                                        toggleModal(showForm ? 'Back' : 'add');
                                        if (!showForm) {
                                            setFocus("txtArea");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowPinCodeMaster', ExportRequest, 'PinCodeMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Pin Code Master'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                            <InputBox
                                                label="Area/City"
                                                placeholder="Area/City"
                                                id="txtArea"
                                                type="text"
                                                maxLength='30'
                                                value={formData.Area}
                                                onChange={(e) => setFormData({ ...formData, Area: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCountry") };
                                                }}
                                                required
                                            />
                                            <SelectInputBox
                                                id='txtCountry'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtState")
                                                    };

                                                }}
                                                label='Country'
                                                value={formData.countryCode}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, countryCode: e.target.value,
                                                    })
                                                }}
                                                required={true}
                                                inputclassname='p-1'
                                            >
                                                <option value="">Select</option>
                                                {GetCountry?.data?.dataList?.map((item, index) =>
                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>

                                                )}
                                            </SelectInputBox>

                                            <AutoCompleteBox
                                                label='State'
                                                inputValue={formData.StateCode ? formData.StateCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'StateCode', 'GetState', '');
                                                    }}
                                                maxLength='50'
                                                placeholder="State"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtState'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtPinCode") };
                                                }}
                                            />
                                            <InputBox
                                                label="Pin Code"
                                                placeholder="Pin Code"
                                                id="txtPinCode"
                                                type="text"
                                                maxLength='6'
                                                value={formData.PinCode}
                                                onChange={(e) => setFormData({ ...formData, PinCode: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("NotDeliveredCheckbox") };
                                                }}
                                                required
                                            />
                                            <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="NotDeliveredCheckbox"
                                                    >
                                                        Not Delivered
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="NotDeliveredCheckbox"
                                                        name='Not Delivered'
                                                        checked={formData.IsNotServiceable}
                                                        onChange={(e) => setFormData({ ...formData, IsNotServiceable: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                setFocus("activeCheckbox")
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6 mt-2">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsActive}
                                                        onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                   
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='PinCodeMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getPinCodeMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default PinCodeMaster



