import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from '../Common/Input';
import {  Table } from "../Common/Table";
import { post } from '../Common/service';
import Loader from '../Layout/Loader';
import * as React from 'react';

const VendorVerification = ({ open }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    // Show Data on Table
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: loginCodeString,
        Source: 'web',
    }
    const getVendorVerify = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        post(`${API_SERVER}/api/Master/ShowVendorVerification`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
        });
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, value) => {
        getVendorVerify(value);
    };
    const handleCheckbox = (itemValue, checkValue, item, index) => {
        const data = { ...tableData }
        data.Data[index].Checkbox = checkValue
        setTableData(data)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const requestData = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            VendorID: item.Vendor,
            
            isActive: checkValue,
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/VerifyVendor`, { ...requestData }, async (res) => {
            if (res.status === "SUCCESS") {
                await AlertBox(res.status, res.message, res.focus);
                await getVendorVerify()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        })
    }
    useEffect(() => {
        document.title = 'Vendor Verify'
        getVendorVerify()
    }, [sortConfig, refreshGrid])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">Vendor Verification</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Table
                Token={Token}
                PageName='VendorVerification'
                handleCheckbox={handleCheckbox}
                refreshGrid={refreshGrid}
                setRefreshGrid={setRefreshGrid}
                handleFilter={() => {
                    getVendorVerify();
                    setFilterData({
                        ...filterData,
                    });
                }}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                tableData={tableData}
               
               
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {isLoading && <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' />}
           
        </main>
    )
}
export default VendorVerification;