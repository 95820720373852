import * as React from "react";
import { Cookies } from 'react-cookie';
import { useEffect, useState, useRef } from "react";
import { post } from "../Common/service";
import { API_SERVER } from "../../EnvConfig/env_config";
import { setFocus } from "../Common/Common";
import Loader from "../Layout/Loader";
import { AlertBox, AutoCompleteBox } from "../Common/Input";
import { useNavigate } from "react-router-dom";
import { FetchAutoCompleteData } from "../Common/CommanServices";

const cookies = new Cookies();

const UserMenuAccess = ({ open, setOpen }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [CheckView, setCheckView] = useState(false);
    const [CheckEdit, setCheckEdit] = useState(false);
    const [CheckDel, setCheckDel] = useState(false);
    const [CheckAdd, setCheckAdd] = useState(false);
    const [CheckExp, setCheckExp] = useState(false);
    const [CheckAll, setCheckAll] = useState(false);
    const [checkByFolderName, setCheckByFolderName] = useState(false);
    const [MenuText, setMenuText] = useState("User Menu Access");
    const navigate = useNavigate();
    let MPageID = '';
    const [tableData, setTableData] = useState([])
    const [UserNameValue, setUserNameValue] = useState('');
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode').toString()
    const Source = 'web';
    const [formData, setFormData] = useState([]);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const handleClear = () => {
        setFormData('')
        setUserNameValue('');
        setIsLoading(false);
        setCheckView(false);
        setCheckEdit(false);
        setCheckDel(false);
        setCheckAdd(false);
        setCheckExp(false);
        setCheckAll(false);
        setCheckByFolderName(false);
        setTableData('');
        getUserMenuData();
    };
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: loginCodeString,

    }

    const ViewCheckAll = () => {
        if (CheckView === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkView')[i].checked = true

            }
            setCheckView(true)
        } else if (CheckView === true) {
            setCheckView(false)
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkView')[i].checked = false
            }
        }
    };

    const AddCheckAll = () => {
        if (CheckAdd === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkAdd')[i].checked = true

            }
            setCheckAdd(true)
        } else if (CheckAdd === true) {
            setCheckAdd(false)
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkAdd')[i].checked = false
            }
        }
    };

    const EditCheckAll = () => {
        if (CheckEdit === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkEdit')[i].checked = true

            }
            setCheckEdit(true)
        } else if (CheckEdit === true) {
            setCheckEdit(false)
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkEdit')[i].checked = false
            }
        }
    };

    const DelCheckAll = () => {
        if (CheckDel === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkDel')[i].checked = true

            }
            setCheckDel(true)
        } else if (CheckDel === true) {
            setCheckDel(false)
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkDel')[i].checked = false
            }
        }
    };

    const ExpCheckAll = () => {
        if (CheckExp === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkExp')[i].checked = true

            }
            setCheckExp(true)
        } else if (CheckExp === true) {
            setCheckExp(false)
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkExp')[i].checked = false
            }
        }
    };

    const AllCheckAll = () => {
        if (CheckAll === false) {
            for (let i = 0; i < tableData.data.length; i++) {
                document.getElementsByClassName('checkExp')[i].checked = true
                document.getElementsByClassName('checkDel')[i].checked = true
                document.getElementsByClassName('checkEdit')[i].checked = true
                document.getElementsByClassName('checkAdd')[i].checked = true
                document.getElementsByClassName('checkView')[i].checked = true
                document.getElementsByClassName('checkAll')[i].checked = true

            }
            document.getElementsByClassName('a')[0].checked = true
            document.getElementsByClassName('a')[1].checked = true
            document.getElementsByClassName('a')[2].checked = true
            document.getElementsByClassName('a')[3].checked = true
            document.getElementsByClassName('a')[4].checked = true
            setCheckAll(true)
            setCheckAdd(true)
            setCheckEdit(true)
            setCheckView(true)
            setCheckDel(true)
            setCheckExp(true)

        } else if (CheckAll === true) {
            setCheckAll(false)
            setCheckAdd(false)
            setCheckEdit(false)
            setCheckDel(false)
            setCheckExp(false)
            setCheckView(false)
            document.getElementsByClassName('a')[0].checked = false
            document.getElementsByClassName('a')[1].checked = false
            document.getElementsByClassName('a')[2].checked = false
            document.getElementsByClassName('a')[3].checked = false
            document.getElementsByClassName('a')[4].checked = false
            for (let i = 0; i < tableData.getGroupUserRightsMenu.length; i++) {
                document.getElementsByClassName('checkExp')[i].checked = false
                document.getElementsByClassName('checkDel')[i].checked = false
                document.getElementsByClassName('checkEdit')[i].checked = false
                document.getElementsByClassName('checkAdd')[i].checked = false
                document.getElementsByClassName('checkView')[i].checked = false
                document.getElementsByClassName('checkAll')[i].checked = false
            }
        }
    }

    const CheckByFolderNameCheckBox = (e, r) => {
        let a = document.getElementsByClassName("Row" + e)
        if (r.target.checked === true) {
            for (let i = 0; i < a.length; i++) {
                document.getElementsByClassName("Row" + e)[i].checked = true
            }
            setCheckByFolderName(true)
        } else {
            for (let i = 0; i < a.length; i++) {
                document.getElementsByClassName("Row" + e)[i].checked = false
            }
            setCheckByFolderName(false)
        }
    }

    const RightToLeftCheck = (e, r) => {
        let a = document.getElementsByClassName("RightToLeft" + r)
        if (e.target.checked === true) {
            for (let i = 0; i < a.length; i++) {
                document.getElementsByClassName("RightToLeft" + r)[i].checked = true
            }
        } else {
            for (let i = 0; i < a.length; i++) {
                document.getElementsByClassName("RightToLeft" + r)[i].checked = false
            }
        }
    }

    const getUserMenuData = async (id) => {
        setIsLoading(false);
        setCheckView(false);
        setCheckEdit(false);
        setCheckDel(false);
        setCheckAdd(false);
        setCheckExp(false);
        setCheckAll(false);
        setCheckByFolderName(false);
        setTableData('');
        let UserID = '0'
        if (id !== undefined) {
            UserID = id;
        }
        else {
            UserID = '0'
        }
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            UserID: UserID.split(':')[0].trim()
        }
        post(`${API_SERVER}/api/User/GetUserMenuAccess`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setTableData({...res})
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

            }

        })
    }



    const handleSubmit = async () => {
        var View = "";
        var Add = "";
        var Edit = "";
        var Del = "";
        var Exp = "";

        var elementsView = document.querySelectorAll('.checkView');
        for (var i = 0; i < elementsView.length; i++) {
            if (elementsView[i].checked) {
                if (View === "") {
                    View = elementsView[i].id.replace('chk_View', '');
                }
                else {
                    View = View + ',' + elementsView[i].id.replace('chk_View', '')
                }
            }
        }

        var elementsAdd = document.querySelectorAll('.checkAdd');
        for (var j = 0; j < elementsAdd.length; j++) {
            if (elementsAdd[j].checked) {
                if (Add === "") {
                    Add = elementsAdd[j].id.replace('chk_Add', '');
                }
                else {
                    Add = Add + ',' + elementsAdd[j].id.replace('chk_Add', '');
                }
            }
        }

        var elementsEdit = document.querySelectorAll('.checkEdit');
        for (var k = 0; k < elementsEdit.length; k++) {
            if (elementsEdit[k].checked) {
                if (Edit === "") {
                    Edit = elementsEdit[k].id.replace('chk_Edit', '');
                }
                else {
                    Edit = Edit + ',' + elementsEdit[k].id.replace('chk_Edit', '');
                }
            }
        }


        var elementsDel = document.querySelectorAll('.checkDel');
        for (var l = 0; l < elementsDel.length; l++) {
            if (elementsDel[l].checked) {
                if (Del === "") {
                    Del = elementsDel[l].id.replace('chk_Del', '');
                }
                else {
                    Del = Del + ',' + elementsDel[l].id.replace('chk_Del', '');
                }
            }
        }


        var elementsExp = document.querySelectorAll('.checkExp');
        for (var m = 0; m < elementsExp.length; m++) {
            if (elementsExp[m].checked) {
                if (Exp === "") {
                    Exp = elementsExp[m].id.replace('chk_Exp', '');
                }
                else {
                    Exp = Exp + ',' + elementsExp[m].id.replace('chk_Exp', '');
                }
            }
        }

        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            Token,
            LoginUserCode: loginCodeString,
            Source,
            UserID: UserNameValue,
            View: View,
            Add: Add,
            Edit: Edit,
            Del: Del,
            Exp: Exp,
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/User/InsertupdateUserMenuAccess`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setIsLoading(false);
                AlertBox(res.status, res.message, res.focus);
                handleClear();
                setIsLoading(false)
            } else {
                setIsLoading(false)
                AlertBox(res.status, res.message, res.focus);

            }
            if (res.focus) {
                setFocus(res.focus)
            }
        })
    };

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, type) => {
        setUserNameValue(newValue)
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };

    useEffect(() => {
        getUserMenuData();
        document.title = 'User Menu Access'
        setFocus('txtUserNameValue')
        setTimeout(() => {
            setMenuText(document.title);
        }, 400)
    }, []);

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">{MenuText}</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">
                                    <div className="row align-items-center">

                                        <AutoCompleteBox
                                            placeholder="User"
                                            label='User'
                                            inputValue={UserNameValue}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'GetUserCode');
                                                }}
                                            maxLength='50'
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}
                                            required={true}
                                            id='txtCategory'
                                            onChange={(e, newInputValue) => {
                                                if (newInputValue !== "") {
                                                    getUserMenuData(newInputValue)
                                                }
                                            } }
                                        />
                                        {/*<div className="col-lg-3 col-md-6 col-sm-12">*/}
                                        {/*    <div className="fields">*/}
                                        {/*        <label className="form-label">User Name <span className="Required">*</span></label>*/}
                                        {/*        <Autocomplete*/}
                                        {/*            id='txtUserNameValue'*/}
                                        {/*            inputValue={UserNameValue}*/}
                                        {/*            onInputChange={(e, newInputValue) => {*/}
                                        {/*                setUserNameValue(newInputValue)*/}
                                        {/*                getUserMenuData(newInputValue)*/}
                                        {/*                BindAutoComplete({ setOnState: setUserAutoComplete, Prefix: '', Type: 'GetUserCode' })*/}
                                        {/*            }}*/}
                                        {/*            options={UserAutoComplete}*/}
                                        {/*            disablePortal*/}
                                        {/*            freeSolo*/}
                                        {/*            autoComplete*/}
                                        {/*            onFocus={() => {*/}
                                        {/*                BindAutoCompleteOnLoad({ setOnState: setUserAutoComplete, Prefix: '', Type: 'GetUserCode' })*/}
                                        {/*            }}*/}
                                        {/*            renderInput={(params) => <TextField {...params}*/}
                                        {/*                className="form-control"*/}
                                        {/*                maxLength='10'*/}
                                        {/*                placeholder='User Name'*/}
                                        {/*                onKeyDown={(e) => {*/}
                                        {/*                    if (e.key === 'Enter') {*/}
                                        {/*                        handleSubmit(e);*/}
                                        {/*                    };*/}
                                        {/*                }}*/}
                                        {/*            />}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="lms-new-button text-center">
                                                <button className="button save-btn" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Save
                                                </button>
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <>
                    <section className="recoard-table mt-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-box">
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            {tableData.length !== 0 ?
                                                (
                                                    tableData.totalRecord !== null ? (
                                                        <table className="table " id="dataTable" width="100%" cellSpacing="0">
                                                            <thead className="sticky-top" >
                                                                <tr style={{ background: '#cbe2ff' }}>
                                                                    <th>Folder Name</th>
                                                                    <th></th>
                                                                    <th>Page</th>
                                                                    <th><input onClick={ViewCheckAll} className='a' type="checkbox" defaultChecked={CheckView} /> View</th>
                                                                    <th><input onClick={AddCheckAll} className='a' type="checkbox" defaultChecked={CheckAdd} />   Add</th>
                                                                    <th><input onClick={EditCheckAll} className='a' type="checkbox" defaultChecked={CheckEdit} /> Edit</th>
                                                                    <th><input onClick={DelCheckAll} className='a' type="checkbox" defaultChecked={CheckDel} />  Delete</th>
                                                                    <th><input onClick={ExpCheckAll} className='a' type="checkbox" defaultChecked={CheckExp} />  Export</th>
                                                                    <th><input onClick={AllCheckAll} className='a' type="checkbox" defaultChecked={CheckAll} />  CheckAll</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData.data !== null ?
                                                                    (
                                                                        (tableData.data).map((item, index) => (
                                                                            <>
                                                                                {MPageID !== item.mPageID && index > 0 ?
                                                                                    <tr style={{ background: '#c4e1c5' }}>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                        <td><br /></td>
                                                                                    </tr> : ''}
                                                                                <tr key={item.mPageID}>
                                                                                    {MPageID !== item.mPageID ?
                                                                                        <> <td rowSpan={item.rowSpan}
                                                                                            className="fw-bold">{MPageID = item.mPageID, item.folderName}
                                                                                        </td><td rowSpan={item.rowSpan}>
                                                                                                <span className="MM"><input
                                                                                                    type="checkbox"
                                                                                                    onClick={(e) => CheckByFolderNameCheckBox(item.mPageID, e)}
                                                                                                    defaultChecked={checkByFolderName}
                                                                                                /></span></td></>

                                                                                        : ('')}
                                                                                    <td>{item.page}</td>
                                                                                    <td> <span className="MM">
                                                                                        {item.view ?
                                                                                            <input defaultChecked={item.view}
                                                                                                className={`checkView Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_View${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, View: e.target.checked })}
                                                                                            /> :
                                                                                            <input
                                                                                                className={`checkView Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_View${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, View: e.target.checked })}
                                                                                            />
                                                                                        }
                                                                                    </span></td>
                                                                                    <td> <span className="MM">
                                                                                        {item.add ?
                                                                                            <input
                                                                                                defaultChecked={item.add}
                                                                                                className={`checkAdd Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Add${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Add: e.target.checked })}
                                                                                            /> :
                                                                                            <input className={`checkAdd Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Add${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Add: e.target.checked })}
                                                                                            />
                                                                                        }
                                                                                    </span></td>
                                                                                    <td> <span className="MM">
                                                                                        {item.edit ?
                                                                                            <input
                                                                                                defaultChecked={item.edit}
                                                                                                className={`checkEdit Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Edit${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Edit: e.target.checked })}
                                                                                            /> :
                                                                                            <input className={`checkEdit Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Edit${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Edit: e.target.checked })}
                                                                                            />
                                                                                        }

                                                                                    </span></td>
                                                                                    <td> <span className="MM">
                                                                                        {item.del ?
                                                                                            <input
                                                                                                defaultChecked={item.del}
                                                                                                className={`checkDel Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Del${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Delete: e.target.checked })}
                                                                                            /> :
                                                                                            <input className={`checkDel Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                type="checkbox" id={`chk_Del${item.pageID}`}
                                                                                                onChange={(e) => setFormData({ ...formData, Delete: e.target.checked })}
                                                                                            />
                                                                                        }

                                                                                    </span></td>
                                                                                    <td> <span className="MM">
                                                                                        {item.exp ?
                                                                                            <input
                                                                                                defaultChecked={item.exp}
                                                                                                className={`checkExp Row${item.mPageID} RightToLeft${item.pageID}`} id={`chk_Exp${item.pageID}`}
                                                                                                type="checkbox"
                                                                                                onChange={(e) => setFormData({ ...formData, Export: e.target.checked })}
                                                                                            /> :
                                                                                            <input
                                                                                                className={`checkExp Row${item.mPageID} RightToLeft${item.pageID}`} id={`chk_Exp${item.pageID}`}
                                                                                                type="checkbox"
                                                                                                onChange={(e) => setFormData({ ...formData, Export: e.target.checked })}
                                                                                            />
                                                                                        }
                                                                                    </span></td>
                                                                                    <td> <span className="MM">
                                                                                        {item.all ?
                                                                                            <input
                                                                                                defaultChecked={item.all}
                                                                                                type="checkbox"
                                                                                                className={`checkAll Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                onClick={(e) => RightToLeftCheck(e, item.pageID)}
                                                                                                onChange={(e) => {
                                                                                                    setCheckAll(e.target.checked)
                                                                                                }
                                                                                                } /> :
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className={`checkAll Row${item.mPageID} RightToLeft${item.pageID}`}
                                                                                                onClick={(e) => RightToLeftCheck(e, item.pageID)}
                                                                                                onChange={(e) => {
                                                                                                    setCheckAll(e.target.checked)
                                                                                                }
                                                                                                } />
                                                                                        }
                                                                                    </span></td>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    ) : (<></>)}
                                                            </tbody>
                                                        </table>
                                                    ) : (<></>)

                                                ) : (<></>)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </>

                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}

            </main>
        </>
    )
}
export default UserMenuAccess;