import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus, formatDate } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import Badge from '@mui/material/Badge';
import { AlertBox, AutoCompleteBox, DateBox, InputBox, SelectInputBox, } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { ModalTable, Table } from "../Common/Table";
import Modal from 'react-bootstrap/Modal';
import { DropDownData1, FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import DragableModal from "../Common/DragableModal";
const OrdersManagment = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const navigate = useNavigate();
    const [ModalData, setModalData] = useState([])
    const [historyData, setHistoryData] = useState([])
    const [productPreviewData, setProductPreviewData] = useState([])
    const [shippingFree, setShippingFree] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHistoryModal, setIsHistoryModal] = useState(false)
    const [isProductModal, setIsProductModal] = useState(false)
    const [isModalOpenDispatch2, setIsModalOpenDispatch2] = useState(false)
    const [CheckAll, setCheckAll] = useState(false)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [Count, setCount] = useState([])
    const [refreshGrid, setRefreshGrid] = useState(false);
    const VendorName = cookies.get('userName');
    const LoginUserCode = cookies.get('loginUserCode')
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode').toString() : '';
    const Token = cookies.get('token')
    const LoginType =cookies.get('loginType')
    const [OrderNo, setOrderNo] = useState('')
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    let tody = new Date()
    const [showButton, setShowButton] = useState(false);
    const [shippingCharge, setShippingCharge] = useState('');
    const [isLabelPrinted, setIsLabelPrinted] = useState(false);
    const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false);
    const [AWBNo, setAWBNo] = useState("")
    const [DeliveryPartner, setDeliveryPartner] = useState("")
    const [CourierCode, setCourierCode] = useState("");
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [model, setModel] = useState({
        "StatusDate": Todate,
        "StatusTime": getCurrentTime(),
        "Remarks": "",
        Courier: "",
        TrackingNo: AWBNo ? AWBNo : "",
    })
    const [Ratedata, setRateData] = useState({})
    function getCurrentTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const [DispatchModal, setDispatchModal] = useState({
        OrderNo: "",
        OrderStatus: "",
        StatusDate: Todate,
        StatusTime: getCurrentTime(),
        Remark: "",
    })
    useEffect(() => {
        const interval = setInterval(() => {
            setModel(prevModel => ({
                ...prevModel,
                StatusTime: getCurrentTime()
            }));
            setDispatchModal(prevModalDis => ({
                ...prevModalDis,
                StatusTime: getCurrentTime()
            }))
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const [Steps, setSteps] = useState([])
    const [Ptype, setPtype] = useState('')
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        ProcessType: "",
        "AdvanceFilter": [],
    })
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [filterTable, setFilterTable] = useState({
        ProcessType: ""
    })
    const [globalorder, setGlobelOrder] = useState("")
    const [formData, setFormData] = useState({
        OrderNo: "",
        OrderId: [],
    });

    const [tabType, setTabType] = useState('NEW ORDERS');
    //-----------get orders----------//
    const getOrders = async (CurrentPage, tabType,) => {
        setTabType(tabType)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...sortConfig,
            ...filterTable,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: 'S',
            PageIndex: `${PageIndex}`,
            tabType: tabType
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/ShowOrderMaster`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {

                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }


    const handleTabChange = (event, newValue,) => {
        setTabType(newValue);
        getOrders(currentPageNo, newValue);
        setFormData({ ...formData, OrderId: "" });
        setShowButton(false)
    };

    //------------get Order tab count----------//
    const getOrdersTabCounts = async () => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            ...filterData,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
        }
        post(`${API_SERVER}/api/OrderManagement/GetTabType`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setCount(res.tabType)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setCount([])

            }
        });
    }

    //-----------get steps api-------------//
    const getSteps = async (OrderNo) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...filterTable,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo
        }
        post(`${API_SERVER}/api/OrderManagement/GetSteps`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setSteps(res.steps)
                setAWBNo(res.awbNO)
                setShippingFree(res.shippingFree)
                setDeliveryPartner(res.deliveryPartner)
                //console.log(shippingFree, "shippingFree")
                setModel({ ...model, TrackingNo: res.awbNO })
                setPtype(res.pType)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setCount([])

            }
        });
    }
    //-----------handle clear--------------//
    const handleClear = () => {
        setModel({
            "StatusDate": Todate,
            "StatusTime": "",
            "Remarks": ""
        });
        setDispatchModal({
            OrderNo: "",
            OrderStatus: "",
            StatusDate: "",
            Remark: "",
        });
        setShippingCharge("")
        filterData.SearchText = '';
        getOrders(currentPageNo, tabType);
        setSortConfig({ SortColumn: null, SortOrder: null })
    }
    //------------handle GetOrderProduct-----------//
    const handleDetails = async (OrderNo, OrderStatus, tabType) => {
        setGlobelOrder(OrderNo)
        setOrderNo(OrderNo)
        setIsModalOpen(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
            OrderStatus: OrderStatus,
            TabType: tabType
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/GetOrderProduct`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setModalData({ ...res })
                setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.Status, res.message, res.focus);
                setModalData({ ...res })
            }
            setIsLoading(false)
        });
    };

    //-------------------handle History-------------------//
    const handleHistory = async (OrderNo, OrderStatus, tabType) => {
        setIsHistoryModal(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        setOrderNo(OrderNo)
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
            OrderStatus: OrderStatus,
            TabType: tabType
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/GetOrderHistory`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setHistoryData({ ...res })

                setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.Status, res.message, res.focus);
                setHistoryData({ ...res })
            }
            setIsLoading(false)
        });
    };
    //-------------------handle ProductPreview --------------------------//
    const handleProductPreviewModal = async (OrderNo, OrderStatus, tabType) => {
        setIsProductModal(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            ProductCode: OrderNo,
            OrderStatus: OrderStatus,
            TabType: tabType
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/ShowProductDetails`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setProductPreviewData({ ...res })
                setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.Status, res.message, res.focus);
                setProductPreviewData({ ...res })
            }
            setIsLoading(false)
        });
    };
    //----------------handle Accept---------------------------------//
    const handleAccept = async (OrderNo, OrderStatus) => {
        const confirmAcceptance = async (reason) => {
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

            const requestData = {
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                OrderNo: OrderNo,
                OrderStatus: OrderStatus,
            }
            setIsLoading(true);
            await post(`${API_SERVER}/api/OrderManagement/UpdateOrdersStatus`, { ...requestData, ...formData, OrderNo: OrderNo, TabType: tabType, reason }, (res) => {
                if (res.status === "SUCCESS") {
                    AlertBox(res.status, res.message, res.focus);
                    getOrders("", tabType);
                    getOrdersTabCounts();
                    
                } else if (res.message === "EXPIRED") {
                    navigate('/Login');
                } else {
                    AlertBox(res.status, res.message, res.focus);
                }
                setIsLoading(false);
            });
        };

        let alertMessage = "";
        let title = "";
        let confirmButtonText = "";

        switch (OrderStatus) {
            case "Accepted":
                alertMessage = "Do you want to accept?";
                title = "Accepted";
                confirmButtonText = "Yes Accept It!"
                break;
            case "Rejected":
                alertMessage = "Do you want to Reject?";
                title = "Rejected";
                confirmButtonText = "Yes Reject It!";
                break;
            case "Removed":
                alertMessage = "Do you want to remove?";
                title = "Removed";
                confirmButtonText = "Yes Removed It!";
                break;
            default:
                alertMessage = "Do you want to proceed?";
                break;
        }
        if (OrderStatus === "Accepted") {
            await Swal.fire({
                text: alertMessage,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmAcceptance('');
                    handleSendWhatsAppMsg(OrderNo);
                }
            });
          
        }
        if (OrderStatus === "Rejected") {
            const { value: text, dismiss } = await Swal.fire({
                text: alertMessage,
                icon: "warning",
                input: "textarea",
                inputPlaceholder: "Type your Reason here...",
                inputAttributes: {
                    "aria-label": "Type your Reason here"
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Please enter a reason.");
                    }
                    return value;
                }
            });

            if (text && !dismiss) {
                confirmAcceptance(text);
            }
        }
    };
    //-----------handle Accept Modal---------------------//
    const handleAcceptModal = async (ProductCode, OrderStatus) => {
        const confirmAcceptance = async (reason) => {
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

            const requestData = {
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                ProductCode: ProductCode,
                OrderStatus: OrderStatus,
            };
            setIsLoading(true);
            post(`${API_SERVER}/api/OrderManagement/UpdateAcceptStatus`, { ...requestData, ...formData, ProductCode: ProductCode, reason, OrderNo: OrderNo }, (res) => {
                if (res.status === "SUCCESS") {
                    if (OrderStatus === "Accepted" || OrderStatus === "Rejected" || OrderStatus === "Removed") {
                        AlertBox(res.status, res.message, res.focus);
                    }
                    getOrders("", tabType);
                    getOrdersTabCounts()
                } else if (res.message === "EXPIRED") {
                    navigate('/Login');
                } else {
                    AlertBox(res.status, res.message, res.focus);
                }
                setIsLoading(false);
            });
        };

        let alertMessage = "";
        let title = "";
        let confirmButtonText = "";
        switch (OrderStatus) {
            case "Accepted":
                alertMessage = "Do you want to accept?";
                title = "Accepted";
                confirmButtonText = "Yes Accept It!";
                break;
            case "Rejected":
                alertMessage = "Do you want to reject?";
                title = "Rejected";
                confirmButtonText = "Yes Reject It!";
                break;
            case "Removed":
                alertMessage = "Do you want to remove?";
                title = "Removed";
                confirmButtonText = "Yes Removed It!";
                break;
            default:
                alertMessage = "Do you want to proceed?";
                break;
        }
        setIsModalOpen(false)
        if (OrderStatus === "Accepted") {
            await Swal.fire({
                text: alertMessage,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmAcceptance('');
                }
            });
        }
        if (OrderStatus === "Rejected") {
            const { value: text, dismiss } = await Swal.fire({
                text: alertMessage,
                icon: "warning",
                input: "textarea",
                inputPlaceholder: "Type your Reason here...",
                inputAttributes: {
                    "aria-label": "Type your Reason here"
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Please enter a reason.");
                    }
                    return value;
                }
            });

            if (text && !dismiss) {
                confirmAcceptance(text);
                handleDetails()
            }
        }

        setIsModalOpen(true);
    };
    //---------------Autocomplete function------------//
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setModel({ ...model, [field]: newValue })
        setDispatchModal({ ...DispatchModal, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    let GetOrderOptions = DropDownData1('GetOrderStatus');
    let GetCourierOptions = DropDownData1('GetCourier');

    //----------handle sorting-----------------//
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    //------------handle AcceptALL modal------------------//
    const handleAcceptAllModal = async (ProductCode, OrderStatus) => {
        const confirmAcceptance = async (reason) => {
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

            const requestData = {
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                ProductCode: ProductCode,
                OrderStatus: OrderStatus,

            }
            setIsLoading(true)
            post(`${API_SERVER}/api/OrderManagement/UpdateAcceptStatus`, { ...requestData, ...formData, ProductCode: ProductCode, reason }, (res) => {
                if (res.status === "SUCCESS") {
                    if (OrderStatus === "Accepted") {
                        AlertBox(res.status, res.message, res.focus);
                        getOrdersTabCounts()
                    } else if (OrderStatus === "Rejected") {
                        AlertBox(res.status, res.message, res.focus);
                    }
                    else if (OrderStatus === "Removed") {
                        AlertBox(res.status, res.message, res.focus);
                    }
                    getOrders("", tabType);

                }
                else if (res.message === "EXPIRED") {
                    navigate('/Login')
                }
                else {
                    AlertBox(res.status, res.message, res.focus);
                }
                setIsLoading(false)
            })
        };
        let alertMessage = "";
        let title = "";
        let confirmButtonText = "";
        switch (OrderStatus) {
            case "Accepted":
                alertMessage = "Do you want to accept?";
                title = "Accepted";
                confirmButtonText = "Yes Accept It!"
                break;
            case "Rejected":
                alertMessage = "Do you want to reject?";
                title = "Rejected";
                confirmButtonText = "Yes Reject It!"
                break;
            case "Removed":
                alertMessage = "Do you want to remove?";
                title = "Removed";
                confirmButtonText = "Yes Removed It!"
                break;
            default:
                alertMessage = "Do you want to proceed?";
                break;
        }
        setIsModalOpen(false)
        if (OrderStatus === "Accepted") {
            await Swal.fire({
                text: alertMessage,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmAcceptance('');
                }
            });
        }
        confirmAcceptance('');
        if (OrderStatus === "Rejected") {
            const { value: text, dismiss } = await Swal.fire({
                text: alertMessage,
                icon: "warning",
                input: "textarea",
                inputPlaceholder: "Type your Reason here...",
                inputAttributes: {
                    "aria-label": "Type your Reason here"
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Please enter a reason.");
                    }
                    return value;
                }
            });

            if (text && !dismiss) {
                confirmAcceptance(text);
            }
        }

        setIsModalOpen(true);
    };

    const handleChange1 = (event, value) => {
        getOrders(value, tabType);
    };
    //-----------------ECOM Label Print----------//
    //const handleLabelPrint = async () => {
    //    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    //    const data = {
    //        Token: await cookies.get('token'),
    //        LoginUserCode: loginCodeString,
    //        Source: 'web',
    //    }
    //    post(`${API_SERVER}/api/ECOMAPI/Shippinglabel`, { ...data, ...formData, awb: AWBNo }, (res) => {
    //        if (res && res.status) {
    //            if (res.status === "ERROR") {
    //            } else {
    //                window.open(`/PdfView/#data:application/pdf;base64,${res.base64bit}`)
    //                setIsLabelPrinted(true);
    //            }
    //        }
    //    });
    //};
    const handleSendWhatsAppMsg = async (OrderNo) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
        }
        post(`${API_SERVER}/api/WhatsAppAPI/SendTextMessageORImage`, { OrderNo }, (res) => {
            if (res && res.status) {
                if (res.status === "SUCCESS") {
                    //console.log(res.status, "handleSendWhatsAppMsghandleSendWhatsAppMsg");
                } else {
                   
                }
            }
        });
    };

   
    const handleLabelPrint = async (OrderNo) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
        }
        post(`${API_SERVER}/api/Print/GetShipmentLebelPrint`, { ...data, ...formData, OrderNo }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                } else {
                    const baseURL = process.env.REACT_APP_BASE_URL || "";
                    const pdfURL = `${baseURL}/PdfView/#data:application/pdf;base64,${res.documentPdfBase64}`;
                    window.open(pdfURL);
                    setIsLabelPrinted(true)
                }
            }
        });
    };
    //--------------Genrate Invoice-----------------//
    const handleGenrateInvoice = async (OrderNo) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
        }
        post(`${API_SERVER}/api/Print/GetInvoicePrint`, { ...data, ...formData, awb: AWBNo, OrderNo }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                } else {
                    const baseURL = process.env.REACT_APP_BASE_URL || ""; 
                    const pdfURL = `${baseURL}/PdfView/#data:application/pdf;base64,${res.documentPdfBase64}`;
                    window.open(pdfURL);
                    setIsInvoiceGenerated(true)
                }
            }
        });
    };
    //---------------------------HANDLE VERIFICATION SAVE------------------------//

    const handleVerficationSave = async (OrderNo, tabType, processType) => {
        setIsModalOpen2(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        if (Steps === 'Packing' && (!isLabelPrinted || !isInvoiceGenerated)) {
            AlertBox("ERROR", 'Please generate the invoice and print the label before saving.');
            return;
        }
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
            TabType: tabType,
            ShippingCharge: String(shippingCharge),
            LoginType: LoginType,
            shippingFree: shippingFree === "True" ? true : false
        }
        post(`${API_SERVER}/api/OrderManagement/UpdateOrderHistory`, { ...model, ...filterTable, Steps, ...data, ...formData, OrderNo: OrderNo }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                setIsModalOpen2(false)
                AlertBox(res.status, res.message, res.focus);
                handleClear()
                getOrdersTabCounts()
                // if (Steps !== "Packing") { handlefetchAwb(OrderNo) }
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    //--------------HANDLE VERIFICATION DISPATCH SAVE API-----------//
    const handleVerficationDispatchSave = async (tabType, processType) => {
        setIsModalOpenDispatch2(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            TabType: tabType,
        }
        post(`${API_SERVER}/api/Master/SaveShipmentMovementMaster`, { ...DispatchModal, ...filterTable, ...data, }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                setIsModalOpenDispatch2(false)
                AlertBox(res.status, res.message, res.focus);
                handleClear()
                getOrdersTabCounts()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    //-------------------------FETCH AWBNO API----------------------//
    const handlefetchAwb = async (OrderNo, tabType, processType) => {
        setIsModalOpen2(true);
        setIsLoading(true);
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const additionalData = {
            LoginUserCode: loginCodeString,
            Source: 'Admin',
            type: Ptype,
            count: '1',
            ORDER_NUMBER: OrderNo,
            deliveryPartner: DeliveryPartner ||'',
        };
        post(`${API_SERVER}/api/ECOMAPI/FetchAwbNo`, { ...additionalData }, (res) => {
            if (res.status === 'SUCCESS') {
                const awbNumber = res.awb
                const manifestData = {
                    ORDER_NUMBER: OrderNo,
                    LoginUserCode: loginCodeString,
                    Source: 'Admin',
                    AWB_NUMBER: (awbNumber).toString(),
                    deliveryPartner: DeliveryPartner || ''
                };
                innerApi(manifestData)

            }
            else if (res.status === 'ERROR') {
                AlertBox(res.status, res.message, res.focus);
                setIsLoading(false);
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
        })
    }


    const handleVerficationDispatchOrder = async (OrderNo, tabType, processType) => {
        setIsModalOpen2(true)
        const loginCodeString = String(LoginUserCode)
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
            TabType: tabType,

        }
        post(`${API_SERVER}/api/OrderManagement/DispatchOrder`, { ...model, ...filterTable, Steps, ...data, ...formData, OrderNo: OrderNo }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                setIsModalOpen2(false)
                AlertBox(res.status, res.message, res.focus);
                handleClear()
                getOrdersTabCounts()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    //----------------------ECOM Manifest API & DispatchOrder API-----------------//
    const innerApi = (manifestData) => {
        post(`${API_SERVER}/api/ECOMAPI/ManifestAwb`, { ...manifestData }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox('SUCCESS', res.message, res.focus);
                setIsLoading(false);
                handleVerficationSave(OrderNo, tabType)
                //  setIsModalOpen2(false)
                // AlertBox(res.status, res.message, res.focus);
                handleClear()
            } else {
                AlertBox('ERROR', res.message, res.focus);
                setIsLoading(false);
            }
        });
    };
    //---------------DTDC Booking API-----------------------------//
    const DTDCHandleBooking = async (OrderNo, tabType) => {
        setIsModalOpen2(true);
        setIsLoading(true);
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
            TabType: tabType,

        }
        post(`${API_SERVER}/api/ECOMAPI/Booking`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox('SUCCESS', res.message, res.focus);
                setIsLoading(false);
                handleVerficationSave(OrderNo, tabType)
                handleClear()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            } else {
                AlertBox('ERROR', res.message, res.focus);

            }
            setIsLoading(false);
        })
    };
    //---------------Rate Calculate API------------------/
    const handleRateCalculate = async (OrderNo) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OrderNo: OrderNo,
        }
        post(`${API_SERVER}/api/ECOMAPI/RateCalculator`, { ...data }, (res) => {
            if (res.status === 'SUCESS') {
                // AlertBox(res.status, res.message, "");
              
                setIsLoading(false);
                setRateData(res)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                //AlertBox(res.status, res.message, res.focus);
                setRateData({ ...res })
            }
            setIsLoading(false);

        })
    };
    const hndleVerification = (orderNo, tabType) => {
        setIsModalOpen2(true)
        getSteps(orderNo)
        setOrderNo(orderNo)
    };
    const handleVerificationDispatch = (orderNo) => {
        setIsModalOpenDispatch2(true)
        getSteps(orderNo)
        setDispatchModal({ ...DispatchModal, OrderNo: orderNo, })

    }
    //-------------Export Request-------------//
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1',
        tabType: tabType
    }
    const [selectedCharges, setSelectedCharges] = useState([]);
    //  console.log(shippingCharge, "shipingcharge")
    // console.log(Steps, "StepsStepsSteps")

    const handleRadioChange = (event, key) => {
        if (event.target.checked) {
            setSelectedCharges(key);
            setShippingCharge(Ratedata?.chargesBreakup?.[key]);
        }
    };

    const renderChargeRow = (label, key) => (
        <tr key={key} >
            <td style={{ width: "20px" }}>
                <input
                    type="radio"
                    className="radio"
                    name="charge"
                    checked={selectedCharges === key}
                    onChange={(e) => handleRadioChange(e, key)}
                />
            </td>
            <td className='fw-bold HistoryColor'>{label}</td>
            <td className="data-cell">
                {Ratedata?.chargesBreakup?.[key]}
            </td>
        </tr>
    );
    // handleCheck Function for Shipping label download in dispatch
    const handleCheck = (checked, OrderNo) => {
        setCheckAll(false);
        let _data = tableData.Data.map((item) => {
            if (item.OrderNo === OrderNo) {
                return { ...item, CheckBox: checked ? OrderNo : "" };
            }
            return item;
        });
        setTableData({ ...tableData, Data: _data });
        let updatedOrderNoList = [...formData.OrderId.toString().split(',')];
        if (checked) {
            if (!updatedOrderNoList.includes(OrderNo)) {
                updatedOrderNoList.push(OrderNo);
            }
        } else {
            updatedOrderNoList = updatedOrderNoList.filter(orderId => orderId !== OrderNo);
        }

        const OrderIdIDString = updatedOrderNoList.filter(item => item !== "").join(',');
        setFormData({ ...formData, OrderId: OrderIdIDString });
        setShowButton(updatedOrderNoList.length > 0);
    };

    //console.log(formData,"formDataformData")
    // Download Shipment Print  
    const DownloadShipmentPrint = async () => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
        };
        post(`${API_SERVER}/api/OrderManagement/DownloadShippinglabel`, { ...data, OrderId: formData.OrderId }, (res) => {
            if (res.status === "SUCCESS") {
                const zip = new JSZip();
                res.shippingLabelPrint.forEach(item => {
                    const pdfData = item.shipplingLabelbase64;
                    const orderNo = item.orderNo;
                    const pdfFileName = `${orderNo}.pdf`;
                    const binaryString = atob(pdfData);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);
                    for (let i = 0; i < binaryLen; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    zip.file(pdfFileName, bytes);
                });
                zip.generateAsync({ type: "blob" })
                    .then(content => {
                        saveAs(content, "shipping_labels.zip");
                    })
                    .catch(error => {
                        console.error("Failed to generate ZIP file:", error);
                    });
                handleClear()
            } else if (res.message === "EXPIRED") {
                navigate('/Login');
            } else if (res.message !== "" || res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
        });
    };
    // Download BulkInvoice Print
    const DownloadBulkInvoicePrint = async () => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
        };
        post(`${API_SERVER}/api/OrderManagement/GetBulkInvoicePrint`, { ...data, OrderId: formData.OrderId }, (res) => {
            if (res.status === "SUCCESS") {
                const zip = new JSZip();
                res.shippingLabelPrint.forEach(item => {
                    const pdfData = item.shipplingLabelbase64;
                    const orderNo = item.orderNo;
                    const pdfFileName = `${orderNo}.pdf`;
                    const binaryString = atob(pdfData);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);
                    for (let i = 0; i < binaryLen; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    zip.file(pdfFileName, bytes);
                });
                zip.generateAsync({ type: "blob" })
                    .then(content => {
                        saveAs(content, "Invoice_Prints.zip");
                    })
                    .catch(error => {
                        console.error("Failed to generate ZIP file:", error);
                    });
                handleClear()
            } else if (res.message === "EXPIRED") {
                navigate('/Login');
            } else if (res.message !== "" || res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
        });
    };

    useEffect(() => {
        getOrders(currentPageNo, tabType);
        document.title = 'Order'
        setFocus("txtCategory")
        getOrdersTabCounts()
    }, [sortConfig, filterTable.ProcessType, refreshGrid]);

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <div style={{ float: "right" }}>
                                            <button className="btn Exp-btn text-white" onClick={() => {
                                                handleDownloadExcel(API_SERVER + '/api/OrderManagement/ShowOrderMaster', ExportRequest, "Orders")
                                            }}>
                                                <i className="bi bi-download" /> Export
                                            </button>

                                        </div>
                                        <h5 className="m-0 font-weight-bold text-primary ms-3 ">Orders</h5>
                                    </div>
                                </div>
                                {/*  -----Tabs start-----*/}
                                <div className="form-box service-content-area">
                                    <div className="row ">
                                        <Box sx={{ width: '100%' }}>
                                            {Count.map((tab, index) => (
                                                <Badge key={index} badgeContent={tab.totalRecord} color="primary">
                                                    <Tabs
                                                        value={tabType}
                                                        onChange={handleTabChange}
                                                        TabIndicatorProps={{
                                                            style: {
                                                                backgroundColor: "#D97D54",
                                                                height: '3px'
                                                            }
                                                        }}
                                                        aria-label="tab example"
                                                        sx={{
                                                            height: '50px',
                                                            backgroundColor: '#F3F4F6',
                                                            borderRadius: '10px',
                                                            boxShadow: '0px 2px 4px #adc3e4;',
                                                            '& .MuiTab-root': {
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                letterSpacing: '0.4px',
                                                                color: '#4B5563',
                                                                transition: 'color 0.3s',
                                                                '&.Mui-selected': {
                                                                    color: '#D97D54',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <Tab
                                                            value={tab.tabType.toUpperCase()}
                                                            label={tab.tabType}
                                                        />
                                                    </Tabs>
                                                </Badge>
                                            ))}
                                        </Box>
                                    </div>
                                </div>
                                {/*  -----Tabs end-----*/}
                            </div>
                        </div>
                    </div>
                </section>
                {/* -----Main Order Table-----*/}
                <Table
                    Token={Token}
                    PageName='Order'
                    handleFilter={() => {
                        getOrders(currentPageNo, tabType);
                        getOrdersTabCounts()
                        setFilterData({
                            ...filterData,
                        });
                        setFilterTable({
                            ...filterTable,
                        })
                    }}
                    formData={formData}
                    handleAccept={handleAccept}
                    handleVerification={hndleVerification}
                    handleVerificationDispatch={handleVerificationDispatch}
                    CheckAll={CheckAll}
                    tabType={tabType}
                    handleDetails={handleDetails}
                    handleHistory={handleHistory}
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    LabelPrint={handleLabelPrint}
                    GenrateInvoice={handleGenrateInvoice}
                    handleCheck={handleCheck}
                    filterTable={{ tabType }}
                    setFilterTable={setFilterTable}
                    tableData={tableData}
                    refreshGrid={refreshGrid}
                    setRefreshGrid={setRefreshGrid}
                    filterData={{ tabType }}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={{ ...sortConfig }}
                    showButton={showButton}
                    DownloadBulkInvoicePrint={DownloadBulkInvoicePrint}
                    DownloadShipmentPrint={DownloadShipmentPrint}

                />
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
                {/*   -----Product Details Modal Start---------*/}
                <Modal size="xl" show={isModalOpen} style={{ cursor: 'pointer', paddingTop: "31px" }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen(false)} >
                    <Modal.Header closeButton style={{ background: "mintcream" }} >
                        <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Product Details</h6></Modal.Title>

                        <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                            OrderNo:  {OrderNo}</h6></span>
                    </Modal.Header>
                    <Modal.Body style={{ background: "mintcream" }}>
                        <div className="modal-body p-0" style={{ padding: '0px' }}>
                            {ModalData && ModalData ?
                                <>
                                    <div className="form-box p-0  me-3 " >
                                        <div className="row  details-tableAwb" >
                                            <ModalTable
                                                Token={Token}
                                                PageName='Order'
                                                handleFilter={() => {
                                                    setFilterData({
                                                        ...filterData,
                                                    });
                                                }}
                                                Filter={false}
                                                handleAcceptModal={handleAcceptModal}
                                                handleAcceptAllModal={handleAcceptAllModal}
                                                handleDetails={handleDetails}
                                                tabType={tabType}
                                                ProductPreview={handleProductPreviewModal}
                                                tableData={ModalData}
                                                filterData={{ tabType }}
                                                setFilterData={setFilterData}
                                                currentPageNo={currentPageNo}
                                                handleChange1={handleChange1}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </Modal.Body>
                </Modal>
                {/*   -----Product Details Modal End---------*/}

                {/*   -----History View Modal Start---------*/}
                <Modal size="xl" show={isHistoryModal} style={{ cursor: 'pointer', paddingTop: "31px" }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsHistoryModal(false)} >
                    <Modal.Header closeButton style={{ background: "mintcream" }}>
                        <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">History View</h6></Modal.Title>
                        <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary"> OrderNo:  {OrderNo}</h6></span>
                    </Modal.Header>
                    <Modal.Body style={{ background: "mintcream" }} >
                        <div className="modal-body p-0" style={{ padding: '0px' }}>
                            {historyData && historyData ?
                                <>
                                    <div className="form-box p-0  me-3 " >
                                        <div className="row  details-tableAwb" >
                                            <ModalTable
                                                Token={Token}
                                                PageName='Order'
                                                handleFilter={() => {
                                                    setFilterData({
                                                        ...filterData,
                                                    });
                                                }}
                                                Filter={false}
                                                tabType={tabType}
                                                tableData={historyData}
                                                filterData={{ tabType }}
                                                setFilterData={setFilterData}
                                                currentPageNo={currentPageNo}
                                                handleChange1={handleChange1}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </Modal.Body>
                </Modal>
                {/*   -----History View Modal End---------*/}

                {/*   -----Product Preview Modal Start---------*/}
                <Modal size="xl" show={isProductModal} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsProductModal(false)} >
                    <Modal.Header closeButton style={{ background: "mintcream" }} >
                        <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4"> Product Preview</h6></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "mintcream" }}>
                        {productPreviewData && productPreviewData.Data ?
                            <div className="form-box ">
                                <div className="row align-items-end">
                                    <div className="table-container" style={{ maxHeight: '580px', overflowY: 'scroll' }}>
                                        <table className='table table-bordered table-responsive table-hover' style={{ whiteSpace: 'nowrap' }}>
                                            {productPreviewData.Data.map((item, index) => (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <th colSpan='4' className="text-center text-white" style={{ background: '#a98451' }}>Product Details</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ fontSize: '15px', padding: '1px', border: '2px solid gray' }} key={index}>
                                                        {Object.entries(item).reduce((acc, [key, value], idx) => {
                                                            if (idx % 2 === 0) {
                                                                acc.push([]);
                                                            }
                                                            acc[acc.length - 1].push({ key, value });
                                                            return acc;
                                                        }, []).map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {row.map(({ key, value }, cellIndex) => {
                                                                    const truncatedValue = value.length > 30 ? value.slice(0, 30) + '...' : value;
                                                                    return (
                                                                        <React.Fragment key={cellIndex}>
                                                                            <td className='fw-bold HistoryColor'>{key}</td>
                                                                            <td style={key === "Product" ? { background: '#f2dede' } : null} className={key === "Product" ? 'fw-bold  text-success' : ""}>
                                                                                {truncatedValue}
                                                                            </td>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </Modal.Body>
                </Modal>
                {/*   -----Product Preview Modal End---------*/}

                {/*   -----Accept Update Details Modal Start---------*/}
                <Modal show={isModalOpen2} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen2(false,)} size="lg">
                    <Modal.Header closeButton style={{ background: "mintcream" }}>
                        <Modal.Title><div className="d-flex gap-2"><h6> Accepted Update Details</h6> <h6>OrderNo: {OrderNo}</h6></div></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "mintcream" }}>
                        <div className="container-fluid">
                            <div className='row'>
                                {Steps === 'Manifest' ? (
                                    <InputBox
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setFocus("txtStatusDate")
                                            };
                                        }}
                                        divclassname="col col-lg-6  fields"
                                        label='Steps'
                                        id='txtSteps'
                                        maxLength='30'
                                        placeholder="Steps "
                                        value={Steps}
                                        style={{ fontWeight: "bold" }}
                                        disabled={true}
                                        required
                                    />
                                ) : (
                                    <>
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtStatusDate")
                                                };
                                            }}
                                            divclassname="col col-lg-6  fields"
                                            label='Steps'
                                            id='txtSteps'
                                            maxLength='30'
                                            placeholder="Steps "
                                            value={Steps}
                                            style={{ fontWeight: "bold" }}
                                            disabled={true}
                                            required
                                        />
                                        {Steps === 'DISPATCH' && (
                                            <>

                                                <InputBox
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("")
                                                        };
                                                    }}
                                                    divclassname="col col-lg-6  fields"
                                                    label='Tracking No'
                                                    id='txtTrackingNo'
                                                    maxLength='30'
                                                    placeholder="Tracking No"
                                                    value={model.TrackingNo}
                                                    onChange={(e) => setModel({ ...model, TrackingNo: e.target.value })}
                                                    required
                                                />
                                            </>
                                        )}

                                        {Steps !== 'DISPATCH' && (
                                            <>
                                                <DateBox
                                                    id='txtStatusDate'
                                                    label='Status Date'
                                                    placeholder="Status Date"
                                                    divclassname="col-lg-3 col-md-3 col-sm-12 fields"
                                                    required={true}
                                                    selected={model.StatusDate === '' ? '' : new Date(model.StatusDate)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setModel({ ...model, StatusDate: dateArr })
                                                    }}
                                                    minDate={new Date()}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter');
                                                    }}
                                                />
                                                <div className='col-lg-3 col-md-3 col-sm-12  fields'>
                                                    <label className="form-label">Status Time <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        type="time"
                                                        format='hh:mm'
                                                        className="form-control"
                                                        placeholder="Status Time"
                                                        maxLength='10'
                                                        value={model.StatusTime}
                                                        onChange={(e) => setModel({ ...model, StatusTime: e.target.value })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {

                                                            };
                                                        }}
                                                    />
                                                </div>
                                                {Steps !== 'Packing' && (
                                                    //<AutoCompleteBox
                                                    //    label='Delivery Partner'
                                                    //    inputValue={model.Courier ? model.Courier : ''}
                                                    //    divclassname="col col-lg-6  fields"
                                                    //    onInputChange={
                                                    //        (event, newInputValue) => {
                                                    //            handleAutoCompleteChange(event, newInputValue, 'Courier', 'GetCourier', '');
                                                    //        }}
                                                    //    maxLength='10'
                                                    //    PopperComponent={(props) => (
                                                    //        <div {...props} style={{ zIndex: 1501, position: 'absolute', width: '45%' }} />
                                                    //    )}
                                                    //    options={autoCompleteOptions}
                                                    //    placeholder="Delivery Partner"
                                                    //    setOptions={setAutoCompleteOptions}
                                                    //    required={true}
                                                    //    id='txtCourier'
                                                    //    onKeyDown={(e) => {
                                                    //        if (e.key === 'Enter') { setFocus("") };

                                                    //    }}
                                                    //    />
                                                        <SelectInputBox
                                                            id="txtCourier"
                                                            divclassname="col col-lg-4  fields"
                                                            label="Delivery Partner"
                                                            required={true}
                                                            value={model.Courier ? model.Courier : ''}
                                                            onChange={(e) => {
                                                                setModel({ ...model, Courier: e.target.value })
                                                                setCourierCode(e.target.value);
                                                                setRateData({});
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("")
                                                                }
                                                            }}
                                                        ><option value=''>--Select Courier--</option>
                                                            {
                                                                GetCourierOptions?.data?.dataList?.map((item, index) =>
                                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                                )
                                                            }
                                                        </SelectInputBox>

                                                )}
                                                {!VendorCode && shippingFree === "True" && Steps !== 'Packing' && CourierCode!=="DTDC" ? (<div className='col-lg-6 mt-3 gap-2'>
                                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                                        onClick={() => handleRateCalculate(OrderNo, tabType)}
                                                    > Rate Calculate</button>
                                                </div>) : (<></>)}
                                                {Steps !== 'Packing' && (
                                                    <div className="row  details-tableAwb">
                                                        <div className="charge-table-wrapper scrollable-tbody">
                                                            {Ratedata?.customerCode ? (
                                                                <table className="charge-table">
                                                                    <tbody className="" style={{ fontSize: '13px', padding: '1px' }}>
                                                                        {renderChargeRow('Shipping Charge', 'total_charge')}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='col-lg-12 col-md-12 col-sm-12  fields'>
                                                    <div className="form-group text-start mb-3" >
                                                        <label className="form-label mb-1">Remarks</label>
                                                        <textarea
                                                            className="form-control"
                                                            row='1'
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {

                                                                };
                                                            }}
                                                            maxLength='250'
                                                            placeholder="Remarks"
                                                            value={model.Remarks}
                                                            onChange={(e) => setModel({ ...model, Remarks: e.target.value })}
                                                        />
                                                    </div>
                                                </div>

                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer style={{ background: "mintcream" }}>
                        {Steps === 'DISPATCH' && (
                            <>
                                <div className='mb-2 d-md-flex gap-2'>
                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                        onClick={() => handleVerficationDispatchOrder(OrderNo, tabType)}
                                    ><i className="fa">&#xf0c7;</i> Dispatch</button>

                                </div>
                            </>
                        )}
                        {Steps === 'Manifest' && (
                            <>
                                <div className='mb-2 d-md-flex gap-2'>
                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                        onClick={() => {
                                            if (DeliveryPartner === "DTDC") {
                                                DTDCHandleBooking(OrderNo, tabType)
                                            }
                                            else {
                                                handlefetchAwb(OrderNo, tabType)
                                            }
                                           
                                            // handleVerficationSave(OrderNo, tabType)
                                        }}
                                    ><i className="fa">&#xf0c7;</i> Manifest & Save</button>

                                </div>
                            </>
                        )}

                        {Steps !== 'DISPATCH' && Steps !== 'Manifest' && (
                            <>
                                <div className='mb-2 d-md-flex gap-2'>
                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                        onClick={() => handleVerficationSave(OrderNo, tabType)}
                                    ><i className="fa">&#xf0c7;</i> Save</button>
                                </div>
                            </>
                        )}
                        {Steps === 'Packing' && (
                            <>
                                <div className='mb-2 d-md-flex gap-2'>
                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#24c8d0", color: "white" }}
                                        onClick={() => handleLabelPrint(OrderNo)}
                                    ><i className="fa-solid fa-print" />  Label Print</button>
                                    <button type="button" className="btn" data-bs-dismiss="modal" style={{
                                        margin: '5px', minWidth: '30px', backgroundColor: "#5687F5", color: "white"
                                    }}
                                        onClick={() => handleGenrateInvoice(OrderNo)}
                                    ><i className="fa-solid fa-print" />  Genrate Invoice</button>
                                </div>
                                <div className='alert alert-info col-lg-7'>
                                    <span style={{ color: "red" }}> *</span> Pls make  Generate Invoice then save packing process
                                </div>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
                {/*   -----Accept Update Details Modal End---------*/}

                {/*   -----Dispatch Update Details Modal Start---------*/}
                <Modal show={isModalOpenDispatch2} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpenDispatch2(false,)} size="lg">
                    <Modal.Header closeButton style={{ background: "mintcream" }}>
                        <Modal.Title><div className="d-flex gap-2"><h6> Dispatch Update Details</h6> <h6>OrderNo: {OrderNo}</h6></div></Modal.Title>
                    </Modal.Header >
                    <Modal.Body style={{ background: "mintcream" }}>
                        <div className="container-fluid">
                            <div className='row Border-bottom'>
                                <InputBox
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setFocus("txtOrderStatus")
                                        };
                                    }}
                                    divclassname="col col-lg-3  fields"
                                    label='Order No'
                                    id='txtOrderNo'
                                    maxLength='30'
                                    disabled={DispatchModal.OrderNo}
                                    placeholder="Order No"
                                    value={DispatchModal.OrderNo}
                                    required
                                />
                                <SelectInputBox
                                    id="txtOrderStatus"
                                    divclassname="col col-lg-4  fields"
                                    label="Status"
                                    required={true}
                                    value={DispatchModal.OrderStatus}
                                    onChange={(e) => {
                                        setDispatchModal({ ...DispatchModal, OrderStatus: e.target.value })
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setFocus("txtStatusDate")
                                        }
                                    }}
                                ><option value=''>--Select Status--</option>
                                    {
                                        GetOrderOptions?.data?.dataList?.map((item, index) =>
                                            <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                        )
                                    }
                                </SelectInputBox>
                                <DateBox
                                    placeholder='Date'
                                    id="txtStatusDate"
                                    divclassname="col col-lg-2  fields"
                                    label="Date"
                                    required={true}
                                    dateFormat="dd-MMM-yyyy"
                                    minDate={new Date()}
                                    selected={DispatchModal.StatusDate === "" ? "" : new Date(DispatchModal.StatusDate)}
                                    onChange={(e) => setDispatchModal({ ...DispatchModal, StatusDate: formatDate(e) })}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setFocus("txtDescription")
                                        }
                                    }}
                                />
                                <div className='col-lg-3 col-md-3 col-sm-12  fields'>
                                    <label className="form-label">Status Time<span style={{ color: "red" }}> *</span></label>
                                    <input
                                        type="time"
                                        format='hh:mm'
                                        className="form-control"
                                        placeholder="Status Time"
                                        maxLength='10'
                                        value={DispatchModal.StatusTime}
                                        // value={DispatchModal.StatusTime || getCurrentTime()}
                                        onChange={(e) => setDispatchModal({ ...DispatchModal, StatusTime: e.target.value })}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {

                                            };
                                        }}
                                    />
                                </div>
                                <div>
                                    <label className="fw-semibold text-black">Remark</label>
                                    <textarea
                                        placeholder='Remark'
                                        id="txtDescription"
                                        className="col-12 mt-1"
                                        name='Description'
                                        rows='1'

                                        value={DispatchModal.Remark}
                                        onChange={(e) => {
                                            setDispatchModal({ ...DispatchModal, Remark: e.target.value })
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setFocus("submit")
                                            };
                                        }}
                                    />
                                </div>

                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ background: "mintcream" }}>
                        <div className='mb-2 d-md-flex gap-2'>
                            <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                onClick={() => handleVerficationDispatchSave(OrderNo, tabType)
                                }
                            ><i className="fa">&#xf0c7;</i> Save</button>
                        </div>
                    </Modal.Footer>
                </Modal>
                {/*   -----Dispatch Update Details Modal End---------*/}
            </main>
        </>

    )
}
export default OrdersManagment