
import { useEffect, useState } from "react";
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox } from "../Common/Input";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const cookies = new Cookies();
const ChangePassword = ({ open, setOpen }) => {
    const [changePasswordData, setChangePasswordData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' })

    const [policyValidations, setPolicyValidations] = useState({
        noWhitespace: false,
        length: false,
        matchConfirmPassword: false,
        differentOldPassword: false,
        complexity: false
    });

    const [isLoading, setIsLoading] = useState(false);
    const [MenuText, setMenuText] = useState("Change Password");
    const navigate = useNavigate();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const [showPassword, setShowPassword] = useState(false);
    const source = 'web'
    const handleSubmit = async (event) => {
        event.preventDefault();
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token,
            LoginUserCode: loginCodeString,
            OldPassword: changePasswordData.oldPassword,
            NewPassword: changePasswordData.newPassword,
            ConfirmPassword: changePasswordData.confirmPassword,
            Source: source
        }
        setIsLoading(true);
        post(`${API_SERVER}/api/User/ChangePassword`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false)
                AlertBox(res.status, res.message, () => navigate('/Login'));
                navigate('/Login')
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                setIsLoading(false)
                AlertBox(res.status, res.message, res.focus);
            }
        })

    }
    const HandleChange = (prop, value) => {
        setChangePasswordData(changePasswordData => ({ ...changePasswordData, [prop]: value }))
    }
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    const validatePassword = (password, confirmPassword) => {
        if (password !== '') {
            const noWhitespace = !/\s/.test(password);
            const length = password.length >= 8 && password.length <= 15;
            const complexity = /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
            setPolicyValidations({
                noWhitespace,
                length,
                matchConfirmPassword: password === confirmPassword,
                differentOldPassword: password !== changePasswordData.oldPassword,
                complexity
            });
        } else {
            setPolicyValidations({
                noWhitespace: false,
                length: false,
                matchConfirmPassword: false,
                differentOldPassword: false,
                complexity: false
            });
        }
    };
    const handlePasswordChange = (field, value) => {
        setChangePasswordData({ ...changePasswordData, [field]: value });

        if (field === 'newPassword') {
            validatePassword(value, changePasswordData.confirmPassword);
        } else if (field === 'confirmPassword') {
            validatePassword(changePasswordData.newPassword, value);
        }
    };
    const handleReset = () => {
        setChangePasswordData({ ...changePasswordData, newPassword: '', confirmPassword: '' })
        setPolicyValidations({
            noWhitespace: false,
            length: false,
            matchConfirmPassword: false,
            differentOldPassword: false,
            complexity: false
        });
    }

    useEffect(() => {
        document.title = 'Change Password'
        //setTitle('ChangePassword', setOpen);
        setFocus("txtOldPassword")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">{MenuText}</h6>
                                </div>
                            </div>
                            <div className="form-box service-content-area1">
                                
                                <div className="row mx-2">
                                    <div className="col-lg-4 col-md-6 col-sm-12" >
                                        <div className="d-flex mb-1" style={{ position: "relative" }}>
                                            <InputBox
                                                id={'txtoldPassword'}
                                                divclassname='fields col-lg-12 '
                                                placeholder="Old Password"
                                                type={showPassword.oldPassword ? 'text' : 'password'}
                                                label="Old Password"
                                                value={changePasswordData.oldPassword}
                                                onChange={(e) => setChangePasswordData({ ...changePasswordData, oldPassword: e.target.value })}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtnewPassword');
                                                    }
                                                }}
                                            />
                                            <span style={{ position: "relative", right: "26px", top: "26px", cursor: "pointer" }} onClick={() => handleTogglePassword('oldPassword')}>
                                                {showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>

                                        <div className="d-flex mb-1" style={{ position: "relative" }}>
                                            <InputBox
                                                id={'txtnewPassword'}
                                                placeholder="New Password"
                                                type={showPassword.newPassword ? 'text' : 'password'}
                                                divclassname='fields col-lg-12 '

                                                label="New Password"
                                                maxLength='15'
                                                value={changePasswordData.newPassword}
                                                onChange={(e) => handlePasswordChange('newPassword', e.target.value)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtconfirmPassword');
                                                    }
                                                }}
                                            />
                                            <span style={{ position: "relative", right: "26px", top: "26px", cursor: "pointer" }} onClick={() => handleTogglePassword('newPassword')}>
                                                {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                        <div className="d-flex mb-1" style={{ position: "relative" }}>
                                            <InputBox
                                                id={'txtconfirmPassword'}
                                                placeholder="Confirm Password"
                                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                                divclassname='fields col-lg-12 '

                                                label="Confirm Password"
                                                maxLength='15'
                                                value={changePasswordData.confirmPassword}
                                                onChange={(e) => handlePasswordChange('confirmPassword', e.target.value)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleSubmit();
                                                    }
                                                }}
                                            />
                                            <span style={{ position: "relative", right: "26px", top: "26px", cursor: "pointer" }} onClick={() => handleTogglePassword('confirmPassword')}>
                                                {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                        <div className="col-lg-12 mb-5">
                                            <div className="lms-new-button ">
                                                <button className="button save-btn" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Change Password
                                                </button>
                                                <button className="button reset-btn" onClick={handleReset}>
                                                    <i className="bi bi-arrow-clockwise" />Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                        <div className='password-policy-border'>
                                            <div className='w-100 py-2 password-policy px-3'>
                                                <h6 className="m-0 font-weight-bold">{'Password Policy'}</h6>
                                            </div>
                                            <div className='p-2'>
                                                <div className="form-group px-2">
                                                    <p className='changepara fs-7' >
                                                <strong >1.</strong>
                                                White spaces not allowed. {policyValidations.noWhitespace ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                            </p>
                                                    <p className='changepara fs-7'>
                                                <strong >2.</strong>
                                                Password must be 8 to 15 character(s). {policyValidations.length ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                            </p>
                                                    <p className='changepara fs-7'>
                                                <strong >3.</strong>
                                                New password and confirm password should be same. {policyValidations.matchConfirmPassword ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                            </p>
                                                    <p className='changepara fs-7'>
                                                <strong >4.</strong>
                                                Old password and new password should be different. {policyValidations.differentOldPassword ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                            </p>
                                                    <p className='changepara fs-7'>
                                                <strong>5.</strong>
                                                Password must be combination of at least one numeric and one uppercase letter and one lowercase and special character [$#!()]. {policyValidations.complexity ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                            </p>
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
        </main>
    )
}
export default ChangePassword;



