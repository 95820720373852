import { memo, useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from '../Common/Input';
import { post } from '../Common/service';

const cookies = new Cookies();

const Sidebar2 =  ({ open, setOpen }) => {
    const Token =  cookies.get('token')
    let LoginUserCode = cookies.get('loginUserCode');
    let UserName = cookies.get('userName')
    const [menus, setMenus] = useState([]);
    const navigate = useNavigate();
    const getSidebar = async () => {
        let SearchText = '';
        let loginCodeString = '';

        if (Token !== '') {
            loginCodeString = LoginUserCode ? String(LoginUserCode) : '';

            const data = {
                Token: Token,
                LoginUserCode: loginCodeString,
                UserName: UserName,
                SearchText: SearchText
            };

            post(`${API_SERVER}/api/User/GetMenu`, { ...data }, (res) => {
                if (res.status === 'SUCCESS') {
                    setMenus(res.getMenu);
                } else if (res.message !== '') {
                    AlertBox(res.status, res.message, res.focus, () => { navigate('/Login') });
                }
            });
        } else {
            navigate('/Login');
        }
    };

    useEffect(() => {
        if (setOpen) {
            function handleresize() {
                if (window.innerWidth < 1200) {
                    setOpen(false);
                } else {
                    setOpen(true);
                }
            }
            handleresize();
            window.addEventListener("resize", handleresize);
            return () => window.addEventListener("resize", handleresize);
        }
    }, [])

    useEffect(() => {
        getSidebar()
    }, [Token]);

    return (
        <>
            <aside id="sidebar" className={`sidebar ${open ? '' : 'sidebar-open'}`}
            >
                <ul className="sidebar-nav" id="sidebar-nav">
                    {menus !== null ?
                        (menus?.map((menu, index) => (
                            <li className="nav-item" style={{ cursor: "pointer" }} key={index}>
                                {menu.getSubMenu !== null ?
                                    (
                                        <>
                                            <div
                                                className={`nav-link collapsed cursor-pointer d-flex align-items-center flex-row`}
                                                data-bs-target={`#${(menu.menuText).replaceAll(' ', '').replaceAll('&', '') }`}
                                                data-bs-toggle="collapse"
                                                
                                            >
                                                <i className={menu.cssClass} />
                                                <span style={open ? {} : {  }} >{menu.menuText}</span>
                                                <i className="bi bi-chevron-down ms-auto"/>
                                            </div>

                                            <ul
                                                id={`${(menu.menuText).replaceAll(' ', '').replaceAll('&','')}`}
                                                className={`nav-content collapse open sidebar-submenu ${menu.getSubMenu[0].mpageID === localStorage.getItem('ID') ? 'show' : ''}`}
                                                data-bs-parent="#sidebar-nav"
                                            >
                                                {menu?.getSubMenu?.map((subMenu, i) => (
                                                    <li key={i} className='' onClick={() => localStorage.setItem('ID', subMenu.mpageID)}>
                                                        <NavLink to={`/${(subMenu.pageUrl)}`}
                                                            id={subMenu.pageID}>
                                                            <i style={{fontSize:'10px' }} className="fa-regular fa-circle-dot"></i>
                                                            <span>{(subMenu.menuText).trim()}</span>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : (
                                        <NavLink
                                            className="nav-link collapsed"
                                            cursor-pointer
                                            key={index}
                                            to={`/${menu.pageUrl}`}
                                        >
                                            <i className={menu.cssClass} />
                                            <span>{menu.menuText}</span>
                                            <i className="bi bi-chevron-down ms-auto" />
                                        </NavLink>)}
                            </li>
                        ))) : ("")}
                </ul>
            </aside>
        </>
    )
}
export default memo(Sidebar2)


