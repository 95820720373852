
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";

const CourierMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [MenuText, setMenuText] = useState("");
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [formData, setFormData] = useState({
        CourierID:"",
        CourierCode: "",
        CourierName: "",
        CourierToken: "",
        MeterNo: "",
        AccountNo: "",
        Password: "",
        AccountNo1: "",
        Environment:"",
        Active: true,
    });
  
    // Display data and GETDAT

    const getCourierMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowCourierMaster`, { ...data, ...filterData, ...sortConfig, PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                // AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
        
    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            CourierID: "",
            CourierCode: "",
            CourierName: "",
            CourierToken: "",
            MeterNo: "",
            AccountNo: "",
            Password: "",
            AccountNo1: "",
            Environment: "",
            Active: true,
        });
        filterData.SearchText = '';
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getCourierMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateCourierMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                setIsLoading(false);
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditCourierMaster`, { ...data, CourierID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    CourierID: res.courierID,
                    CourierCode: res.courierCode,
                    CourierName: res.courierName,
                    Active: res.active,
                    CourierToken: res.courierToken,
                    MeterNo: res.meterNo,
                    AccountNo: res.accountNo,
                    Password: res.password,
                    AccountNo1: res.accountNo1,
                    Environment: res.environment,

                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteCourierMaster`, { ...data, CourierID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    }

    useEffect(() => {
        document.title = 'Courier Master'
        if (showForm) {
            setFocus("txtCourierCode");
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getCourierMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if(showForm) {
                                            setFocus("txtCourierCode");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowCourierMaster', ExportRequest, 'CourierMaster')
                                    }}

                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Courier Master'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtCourierName")
                                                    };
                                                }}
                                                label='Courier Code'
                                                id='txtCourierCode'
                                                maxLength='30'
                                                placeholder="Courier Code"
                                                value={formData.CourierCode}
                                                onChange={(e) => setFormData({ ...formData, CourierCode: e.target.value })}
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtCourierToken")
                                                    };
                                                }}
                                                label='Courier Name'
                                                id='txtCourierName'
                                                maxLength='30'
                                                placeholder="Courier Name"
                                                value={formData.CourierName}
                                                onChange={(e) => setFormData({ ...formData, CourierName: e.target.value })}

                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtMeterNo")
                                                    };
                                                }}
                                                label='Token'
                                                id='txtCourierToken'
                                                maxLength='30'
                                                placeholder="Token"
                                                value={formData.CourierToken}
                                                onChange={(e) => setFormData({ ...formData, CourierToken: e.target.value })}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAccountNo")
                                                    };
                                                }}
                                                label='Meter No'
                                                id='txtMeterNo'
                                                maxLength='30'
                                                placeholder="Meter No"
                                                value={formData.MeterNo}
                                                onChange={(e) => setFormData({ ...formData, MeterNo: e.target.value})}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPassword")
                                                    };
                                                }}
                                                label='Account No'
                                                id='txtAccountNo'
                                                maxLength='30'
                                                placeholder="Account No"
                                                value={formData.AccountNo}
                                                onChange={(e) => setFormData({ ...formData, AccountNo: e.target.value})}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAccountNo1")
                                                    };
                                                }}
                                                label='Password'
                                                id='txtPassword'
                                                maxLength='30'
                                                placeholder="Password"
                                                value={formData.Password}
                                                onChange={(e) => setFormData({ ...formData, Password: e.target.value })}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtEnvironment")
                                                    };
                                                }}
                                                label='Account No 1'
                                                id='txtAccountNo1'
                                                maxLength='30'
                                                placeholder="Account No 1"
                                                value={formData.AccountNo1}
                                                onChange={(e) => setFormData({ ...formData, AccountNo1: e.target.value})}
                                            />
                                            <SelectInputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("activeCheckbox")
                                                    };
                                                }}
                                                label='Environment'
                                                id='txtEnvironment'
                                                maxLength='30'
                                                placeholder="Environment"
                                                value={formData.Environment}
                                                onChange={(e) => setFormData({ ...formData, Environment: e.target.value })}
                                            >
                                                <option value="">--Select Environment--</option>
                                                <option value="TEST">TEST</option>
                                                <option value="LIVE">LIVE</option>
                                            </SelectInputBox>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.Active}
                                                        onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='CourierMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getCourierMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid }
                        tableData={tableData}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default CourierMaster



