import React from "react";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { NavLink } from "../../../node_modules/react-router-dom/dist/index";
import { API_SERVER } from "../../EnvConfig/env_config";
import { AlertBox } from "../Common/Input";
import { post } from "../Common/service";

const cookies = new Cookies();
const Home = ({ open, setOpen }) => {
    const [menus, setMenus] = useState([]);

    const getSidebar = async () => {
        const Token = await cookies.get('token');
        let LoginUserCode = await cookies.get('loginUserCode');
        let UserName = await cookies.get('userName');
        let SearchText = ''

        if (!Token) {
            const cookies = new Cookies();
            cookies.remove('token');
            cookies.remove('loginUserCode');
            cookies.remove('userName');

        } else {

            let loginCodeString = '';

            if (Token !== '') {
                loginCodeString = LoginUserCode ? String(LoginUserCode) : '';
            }
            const data = {
                Token: Token,
                LoginUserCode: loginCodeString,

                UserName: UserName,
                SearchText: SearchText
            };
            post(`${API_SERVER}/api/User/GetMenu`, { ...data }, (res) => {
                if (res.status === 'SUCCESS') {
                    setMenus(res.getMenu)
                }
                else if (res.message !== "") {
                    AlertBox(res.message,)
                }
            })
        }
    }


    useEffect(() => {
        getSidebar()
    }, []);

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}>
            <div className="container-fluid">
                <div className="row" id="home">
                    {menus?.map((menu, index) => (
                        <div className="col-md-4 col-lg-3 mb-3" key={index}>
                            <div className="card">
                                <div className="card-header d-flex align-items-center">
                                    <i className={menu.cssClass} style={{ marginRight: "10px" }} />
                                    {menu.menuText}
                                </div>
                                <ul className="list-group list-group-flush">
                                    {menu.getSubMenu.map((subMenu, i) => (
                                        <ul key={i} className='mx-2 my-1' style={{ padding: "1px" }} onClick={() => localStorage.setItem('ID', subMenu.mpageID)}>
                                            <NavLink
                                                to={`/${subMenu.pageUrl}`}
                                                id={subMenu.pageID}
                                            >
                                                <i className={subMenu.cssClass} />
                                                <span style={{ padding: "5px" }}>{subMenu.menuText.trim()}</span>
                                            </NavLink>
                                        </ul>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </main>


            );
};

export default Home;