import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie';
import Header from "./components/Layout/Header";
import Sidebar2 from './components/Layout/Sidebar';
import { post } from "./components/Common/service";
import { API_SERVER } from "./EnvConfig/env_config";
const cookies = new Cookies();
const Protected = (Props) => {
    const { Component, open, setOpen, PageUrl } = Props;
    const navigate = useNavigate();
    const [auth, setAuth] = useState(false);

    const checkLogin = async () => {
        const Token = await cookies.get('token');
        let LoginUserCode = await cookies.get('loginUserCode');

        if (!Token) {
            const cookies = new Cookies();
            cookies.remove('token');
            cookies.remove('loginUserCode');
            cookies.remove('userName');
            navigate('/Login');
        } else {

            if (typeof LoginUserCode !== 'string') {
                LoginUserCode = String(LoginUserCode);
            }

            const data = {
                Token: Token,
                LoginUserCode: LoginUserCode,
                PageUrl: PageUrl,
            };

            post(`${API_SERVER}/api/User/ValidateUserPageAccess`, { ...data }, (res) => {
                if (res.status === "SUCCESS") {
                    setAuth(true);
                    document.title = res.menuText;
                } else {
                    const cookies = new Cookies();
                    cookies.remove('token');
                    cookies.remove('loginUserCode');
                    cookies.remove('userName');
                    navigate('/Login');
                }
            })
        }
    };
    useEffect(() => {
        checkLogin();
    }, [])

    return auth ? (
        <>
            <Header setOpen={setOpen} open={open} />
            <Sidebar2 open={open} setOpen={setOpen} />
            <Component open={open} setOpen={setOpen} />
        </>
    ) : <></>
}
export default Protected