
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, handleFileUploader, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import {   FileUploadChild, handleDownloadExcel, } from "../Common/CommanServices";
import { FileUploader } from "react-drag-drop-files";

const BrandRequest = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const [showgrid, setShowGrid] = useState(true)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [isExpanded, setIsExpanded] = useState(false);
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';
    const fileType = 'jpg,png'
    const [formData, setFormData] = useState({
        BrandRequestID:"",
        BrandName: "",
        Discription: "",
        AuthenticationLetterImage: "",
        AuthenticationLetterName: "",
        AuthenticationLetterExt: "",
        Active:true

    });


    // Display data and GETDAT
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode),

    }
    const getBrandRequestData = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: 'S',
            PageIndex: `${PageIndex}`
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowBrandRequestMaster`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            ...formData,
            BrandRequestID: "",
            BrandName: "",
            Discription: "",
            AuthenticationLetterImage: "",
            AuthenticationLetterName: "",
            AuthenticationLetterExt: "",
            Active: true
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getBrandRequestData(value);
    };


    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source,
        }
        post(`${API_SERVER}/api/Master/InsertUpdateBrandRequestMaster`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            BrandRequestID: id
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditBrandRequestMaster`, { ...data }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    BrandRequestID: res.brandRequestID,
                    BrandName: res.brandName,
                    Discription: res.discription,
                    AuthenticationLetterImage: res.authenticationLetterImage,
                    AuthenticationLetterName: res.authenticationLetterName,
                    AuthenticationLetterExt: res.authenticationLetterExt,
                    Active: res.active
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }
    const handleDelete = async (id) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            BrandRequestID: id
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteBrandRequestMaster`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    }
   
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    
    useEffect(() => {
        document.title = 'Brand Request'
        if (showForm) {
            setFocus("txtBrandName")
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getBrandRequestData()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtBrandName");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowBrandRequestMaster', ExportRequest, 'BrandRequest')
                                    }}

                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Brand Request'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-start">
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAuthorizationLetter")
                                                    };
                                                }}
                                                label='Brand Name'
                                                id='txtBrandName'
                                                maxLength='30'
                                                placeholder="Brand Name"
                                                value={formData.BrandName}
                                                onChange={(e) => setFormData({ ...formData, BrandName: e.target.value })}
                                                required
                                            />
                                           
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtAuthorizationLetter">
                                                <div className="fields">
                                                    <label className="form-label ">Authorization Letter<span className="Required">*</span></label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'AuthenticationLetterImage', 'AuthenticationLetterExt', 'AuthenticationLetterName')}
                                                        children={<FileUploadChild label='Authorization Letter' message={formData.AuthenticationLetterName + '.' + formData.AuthenticationLetterExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                            </div>

                                            {formData.AuthenticationLetterImage ?
                                                <div className='col-md-4 col-lg-3 p-3' style={{ position: "relative" }}>
                                                    <img
                                                        onClick={() => handleShowImageInModal(`data:image/${formData.AuthenticationLetterExt};base64,${formData.AuthenticationLetterImage}`)}
                                                        className='border border-success img-thumbnail '
                                                        src={`data:image/png;base64,${formData.AuthenticationLetterImage}`}
                                                        alt='logo'
                                                        style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                    />
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Description</label>
                                                    <textarea
                                                        id="txtDescription"
                                                        className="form-control"
                                                        placeholder='Enter Description'
                                                        cols={500}
                                                        rows="1"
                                                        value={formData.Discription}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Discription: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch pt-4">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.Active}
                                                        onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='BrandRequest'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getBrandRequestData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default BrandRequest