import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import Calendar from 'react-datepicker';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const AutoCompleteBox = (props) => {
    const { setOptions, ...restProps } = props;

    const { divclassname = "col-md-3 col-sm-6 fields",/*divclassname = 'col-lg-3 col-md-4 col-sm-6 fields',*/ onKeyPress, value, onKeyDown, style, inputstyle, maxLength, id, label = '', options, onChange, inputValue, onInputChange, placeholder, required, ignore_common } = restProps

    return (
        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={style}
        >
            {label === '' ? '' :
                <label className="form-label" htmlFor={id}>{label || ''}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>
            }
            <Autocomplete
                inputValue={inputValue}
                onInputChange={onInputChange}
                onChange={onChange}
                freeSolo
                filterOptions={(x) => x}
                options={options ? options : []}
                onClose={(event, reason) => {
                    if (setOptions) {
                        setOptions([]);
                    }
                }}
                autoHighlight={true}
                selectOnFocus={true}
                title={required ? 'Please fill this field.' : ''}
                renderInput={(params) => <TextField
                    {...params}
                    onInput={(e) => {
                        const { value } = e.target;

                        if (value.length > maxLength) {
                            e.target.value = value.slice(0, maxLength);
                        }
                    }}
                    //inputProps={{ ref: ref => (inputrefs.current[id] = ref) }}
                    //inputRef={ref => (inputrefs.current[id] = ref)}
                    sx={inputstyle}
                    placeholder={placeholder}
                    //ref={ref => (inputrefs.current[id] = ref)}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    value={value}
                />}
                {...restProps}
            />
        </div>
    )
}

export const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export const InputBox = (props) => {


    let { divclassname = "col-md-3 col-sm-6 fields", key = '', customclass, onFocus, onBlur, onKeyPress, pattern, mendatory, onKeyDown = null, inputClassName = '', type = 'text', inputtype, divstyle = null,
        inputStyle = null,
        extraLabel, extraLabelStyle = { fontSize: "13px", fontWeight: "300" },
         id, label = '',
        value, onChange, placeholder,
        required, error, confirm_error,
        ignore_common } = props

    return (

        <div
            className={`${ignore_common ? '' : divclassname}`}
            style={divstyle}
            key={key}
        >
            {label !== '' ? <label className="form-label" htmlFor={id}>{label} {extraLabel && <span className="text-danger" style={extraLabelStyle}> {extraLabel}</span>}
                {required || mendatory ? <span style={{ color: "red" }}> *</span> : <></>}
            </label> : <></>}
            <input
                id={id}
                style={inputStyle}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                pattern={pattern}
                // ref={ref => (inputrefs.current[id] = ref)}
                type={inputtype ? inputtype : type}
                className={`form-control ${inputClassName + ' ' + customclass}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete="off"

                {...props}
            />
            {error && <div className="error-msg">{error}</div>}
            {confirm_error && <div className="error-msg">{confirm_error}</div>}
        </div>

    )
}

export const DateBox = (props) => {
    const { divclassname = 'col-md-3 col-sm-6 fields', ignore_common, style, minDate, tooltip,dateFormat = "dd-MMM-yyyy", label, showTimeSelect = false, onKeyDown, maxDate, selected, placeholder, id, onChange, inputStyle, inputClass = 'form-control input-fields', required } = props;
    return (
        <div
            className={` ${ignore_common ? '' : divclassname}`}
            style={style}
            id={id}
        >
            {label && (
                <label className="form-label">
                    {label}
                    {required && <span style={{ color: "red" }}> *</span>}
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '5px', cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                </label>
            )}
            <Calendar
                className={inputClass}
                style={{ inputStyle }}
                id={id}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                selected={selected}
                maxDate={maxDate}
                minDate={minDate}
                // ref={ref => (inputrefs.current[id] = ref?.input?.inputElement)}
                autoComplete="off"
                preventOpenOnFocus={true}
                onKeyDown={onKeyDown}
                onChange={onChange}
                //  showMonthDropdown
                //   showYearDropdown
                // showTimeSelect={showTimeSelect}
                // dropdownMode="select"


                {...props}
            />
        </div>
    )
}

export const AlertBox = (status = '', message = '', focusElementId = '', callBack) => {
    if (message !== undefined && message !== "") {
        Swal.fire({
            text: message,
            icon: status.toLowerCase(),
            title: status.toUpperCase(),
            confirmButtonColor: '#238914',
            allowOutsideClick: false,
            width: 500,
            didClose: () => {
                if (focusElementId !== "")
                    document.getElementById(focusElementId)?.focus();
            }
        }).then((result) => {
            if (result.isConfirmed) {
                callBack && callBack();
            }
        });
    }
}

export const SelectInputBox = (props) => {
    const { divclassname = "col-md-3 col-sm-6 fields",/* divclassname = "col-lg-3 col-md-4 col-sm-6 fields",*/ inputclassname, onKeyDown, divstyle, inputstyle, inputrefs, id, label = '', value, onChange, children, required, ignore_common } = props
    return (
        <div
            //className={` ${divclassname}`}
            className={`${ignore_common ? '' : divclassname}`}
            style={divstyle}
        >
            {label === '' ? '' :
                <label className="form-label" htmlFor={id}>{label || 'Branch'}
                    {required ? <span style={{ color: "red" }}> *</span> : <></>}
                </label>}
            <select
                id={id}
                className={`form-select ${inputclassname}`}
                style={inputstyle}
                onKeyDown={onKeyDown}
                value={value}
                onChange={onChange}
                title={required ? 'Please select an item in the list.' : ''}
                autoComplete="off"
                {...props}
            >
                {children}
            </select>
        </div>
    )
}

export const handleFileUploader = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {

    let extArr = file.name.split(".").pop();

    if (fileTypes.includes(extArr)) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = reader.result;
                setImgRead && setImgRead({ ...imgRead, [imgPropName]: img });
                let logo = reader.result.split(',')[1];
                setFormData({
                    ...FormData,
                    [Photo]: logo,
                    [PhotoExt]: extArr,
                    [PhotoName]: extArr[0]
                })
            };
        }
    } else {
        // If the file extension is not valid, display an error message
        //toast.error('Please Upload Valid File.!')
    }
};



export const FileUploaderChild = (props) => {
    const { message = '', types = [], children } = props;
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="
http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
            <div className=" sc-kAyceB DimIH ">
                {(message.trim() === '.' || message.trim() === '') ?
                    <><span> <span className='sc-fqkvVR text-decoration-underline'>{`Upload`}</span> a right file here</span><span title={`types: ${types.join()}`} className='file-types'>{types.join()}</span> </> :
                    <span className="sc-fqkvVR">{`${message}`}</span>
                }
                {/*{(message.trim() !== '.' || message.trim() !== '') &&*/}
                {/*    <span title={`types: ${types.join()}`} className=''>{types.join()}</span>*/}
                {/*}*/}
                {children}
            </div>
        </>
    );
}
export const MultiValueSelectBox = (props) => {
    const { divclassname = 'col-md-3 mb-1 cntrl-width',
        options, extraLabel, extraLabelStyle = { fontSize: "15px", fontWeight: "300" },
        onFocus, onKeyDown, divstyle, error,
        id, label,
        value, onChange, required, closeMenuOnSelect = false } = props
    return (
        <div
            className={` ${divclassname}`}
            style={divstyle}
        >
            <label className="fw-semibold" htmlFor={id}>{label || 'Branch'} {extraLabel && <span className="text-danger" style={extraLabelStyle}> {extraLabel}</span>}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>

            <CreatableSelect isMulti
                id={id}
                classNames={{
                    valueContainer: (state) =>
                        state ? 'scroll_select' : '',
                    indicatorsContainer: (state) =>
                        state ? 'indicator_cross' : '',
                    control: (state) =>
                        state ? 'setHieght' : '',
                }}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                value={value}
                onChange={onChange}
                closeMenuOnSelect={closeMenuOnSelect}
                name={id}
                options={options}
                {...props}
            />

            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}



export const MultiSelectInputBox = (props) => {
    const { divclassname = 'col-md-3 col-sm-6 fields',
        options,
        onFocus, onKeyDown, divstyle, error,
        id, label,
        value, onChange, required, closeMenuOnSelect = false } = props
    return (
        <div
            className={` ${divclassname}`}
            style={divstyle}
        >
            <label className="fw-semibold" htmlFor={id}>{label || 'Branch'}
                {required ? <span style={{ color: "red" }}> *</span> : <></>}
            </label>
            <Select
                inputId={id}
                classNames={{
                    valueContainer: (state) =>
                        state ? 'scroll_select' : '',
                    indicatorsContainer: (state) =>
                        state ? 'indicator_cross' : '',
                    control: (state) =>
                        state ? 'setHieght' : '',
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#A88551' : '#DDDDDD',
                        boxShadow: state.isFocused ? '0px 0px 0px 0px #A88551' : "",
                    }),
                }}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                //ref={ref => (inputrefs.current[id] = ref)}
                value={value}
                onChange={onChange}
                // defaultValue={value}
                isMulti
                maxMenuHeight={180}
                maxMenuWidth={180}
                closeMenuOnSelect={closeMenuOnSelect}
                name={id}
                options={options}
                {...props}
            />

            {error && <div className="error-msg">{error}</div>}
        </div>
    )
}




export const Switch = (props) => {

    const { id, checked = false, onKeyDown, label, customstyle, onClick,
        extraclassname = `form-check form-switch`,
        onChange, divclassname = 'col-lg-3 col-md-4 mt-4 col-sm-6' } = props

    return (
        <div className={` ${divclassname}`} key={`checkin${id}`}>
            <div className={`${extraclassname}`} >
                <input
                    style={customstyle}
                    className="form-check-input"
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                    //    ref={ref => (inputrefs.current[id] = ref)}
                    {...props}
                />
                {label && <label className="form-check-label" htmlFor={id}>{label}</label>}
            </div>
        </div>
    )
}
export const handleFileUploaderBanner = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    if (fileTypes.includes(extArr[extArr.length - 1])) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    const width = this.width;
                    const height = this.height;
                    if ((width >= 1500 && width <= 1530) && (height >= 275 && height <= 300)) {
                        setImgRead && setImgRead({ ...imgRead, [imgPropName]: reader.result });
                        let logo = reader.result.split(',')[1];
                        setFormData({
                            ...FormData,
                            [Photo]: logo,
                            [PhotoExt]: extArr[1],
                            [PhotoName]: extArr[0]
                        });
                    } else {
                        // Notify user about incorrect image ratio
                        Swal.fire({
                            title: "error",
                            text: "Please upload an image with a ratio of 1500px width and 275px height.",
                            icon: "error"
                        });
                    }
                };
            };
        }
    } else {
    }
};



export const handleFileUploaderCategory = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    if (fileTypes.includes(extArr[extArr.length - 1])) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    const width = this.width;
                    const height = this.height;
                    if ((width >= 190 && width <= 210) && (height >= 190 && height <= 210)) {
                        setImgRead && setImgRead({ ...imgRead, [imgPropName]: reader.result });
                        let logo = reader.result.split(',')[1];
                        setFormData({
                            ...FormData,
                            [Photo]: logo,
                            [PhotoExt]: extArr[1],
                            [PhotoName]: extArr[0]
                        });
                    } else {
                        Swal.fire({
                            title: "error",
                            text: "Please Upload an Image with the Range of width = (200-220)px to Height = (200-220)px",
                            icon: "error"
                        });
                    }
                };
            };
        }
    } else {
    }
};
export const handleFileUploaderBrand = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    if (fileTypes.includes(extArr[extArr.length - 1])) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    const width = this.width;
                    const height = this.height;
                    if ((width >= 180 && width <= 200) && (height >= 130 && height <= 150)) {
                        setImgRead && setImgRead({ ...imgRead, [imgPropName]: reader.result });
                        let logo = reader.result.split(',')[1];
                        setFormData({
                            ...FormData,
                            [Photo]: logo,
                            [PhotoExt]: extArr[1],
                            [PhotoName]: extArr[0]
                        });
                    } else {
                        Swal.fire({
                            title: "error",
                            text: "Please Upload an Image with the Range of Width (180-200)px width to Height (130-150)px ",
                            icon: "error"
                        });
                    }
                };
            };
        }
    } else {

    }
};

export const handleFileUploaderSubCategory = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    if (fileTypes.includes(extArr[extArr.length - 1])) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    const width = this.width;
                    const height = this.height;
                    if ((width >= 200 && width <= 220) && (height >= 200 && height <= 220)) {
                        setImgRead && setImgRead({ ...imgRead, [imgPropName]: reader.result });
                        let logo = reader.result.split(',')[1];
                        setFormData({
                            ...FormData,
                            [Photo]: logo,
                            [PhotoExt]: extArr[1],
                            [PhotoName]: extArr[0]
                        });
                    } else {
                        Swal.fire({
                            title: "error",
                            text: "Please Upload an Image with the Range of Width = (200-220)px to Height = (200-220)px ",
                            icon: "error"
                        });
                    }
                };
            };
        }
    } else {

    }
};
export const handleFileUploaderSection = (file, fileTypes, setFormData, FormData, Photo, PhotoExt, PhotoName, setImgRead, imgRead, imgPropName) => {
    let extArr = file.name.split(".");
    if (fileTypes.includes(extArr[extArr.length - 1])) {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    const width = this.width;
                    const height = this.height;
                    if ((width >= 1295 && width <= 1320)) {
                        setImgRead && setImgRead({ ...imgRead, [imgPropName]: reader.result });
                        let logo = reader.result.split(',')[1];
                        setFormData({
                            ...FormData,
                            [Photo]: logo,
                            [PhotoExt]: extArr[1],
                            [PhotoName]: extArr[0]
                        });
                    } else {
                        Swal.fire({
                            title: "error",
                            text: "Please Upload an Image with Width = (1290-1320)px ",
                            icon: "error"
                        });
                    }
                };
            };
        }
    } else {

    }
};