
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import {  FileUploadChild, ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, handleFileUploader, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";
import Swal from 'sweetalert2';

const CategoryMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [active, setActive] = useState(true);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const navigate = useNavigate();
    const fileType = 'jpg,png'
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [formData, setFormData] = useState({
        CategoryID:'',
        CategoryName: '',
        SequenceNo: '',
        CategoryCommission: '',
        FixedCommission: '',
        CategoryImage: "",
        CategoryImageExt: "",
        CategoryImageName: "",
        CategoryBannerImage: "",
        CategoryBannerImageExt: "",
        CategoryBannerImageName:"",
        ReferralSlabList:[],
        Discription: '',
        IsActive: true,
    });
    const [SlabList, setSlabList] = useState({
        referralSlabID:"",
        minimumAmount: '',
        maximumAmount: '',
        deductionRupees: ''
    })
    const [SlabData, setSlabData] = useState([])
    const [isServiceEdit, setisServiceEdit] = useState(false)
    const [serviceEditIndex, setServiceEditIndex] = useState('')
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
   

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getCategoryMasterData = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        await post(`${API_SERVER}/api/Master/ShowCategoryMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR" ) {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
       
    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            CategoryID: '',
            CategoryName: '',
            SequenceNo: '',
            CategoryCommission: '',
            FixedCommission: '',
            CategoryImage: "",
            CategoryImageExt: "",
            CategoryImageName: "",
            CategoryBannerImage: "",
            CategoryBannerImageExt: "",
            CategoryBannerImageName: "",
            ReferralSlabList: [],
            Discription: '',
            IsActive: true,
        });
        setSlabData([])
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setActive(true);
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }
    const handleSubmit = async (id) => {
        setIsLoading(true)
        await post(`${API_SERVER}/api/Master/InsertUpdateCategoryMaster`, { ...data, Active: active, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditCategoryMaster`, { ...data, CategoryID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    CategoryID: res.editRecord.categoryID,
                    CategoryName: res.editRecord.categoryName,
                    SequenceNo: res.editRecord.sequenceNo,
                    Discription: res.editRecord.discription,
                    CategoryCommission: res.editRecord.categoryCommission,
                    FixedCommission: res.editRecord.fixedCommission,
                    CategoryImage: res.editRecord.categoryImage,
                    CategoryImageName: res.editRecord.categoryImageName,
                    CategoryImageExt: res.editRecord.categoryImageExt,
                    CategoryBannerImage: res.editRecord.categoryBannerImage,
                    CategoryBannerImageExt: res.editRecord.categoryBannerImageExt,
                    CategoryBannerImageName: res.editRecord.categoryBannerImageName,
                    ReferralSlabList: res.editRecord.referralSlabList,
                    IsActive: res.editRecord.isActive
                });
                setSlabData(res.editRecord.referralSlabList || []);
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    };
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteCategoryMaster`, { ...data, CategoryID: id }, (res) => {

            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    };
    const HandleAdd = (type, index) => {
        const newSlab = {
            minimumAmount: SlabList.minimumAmount,
            maximumAmount: SlabList.maximumAmount,
            deductionRupees: SlabList.deductionRupees
        };
        let updatedReferralSlabList;
        if (type === 'Edit') {
            SlabData[index] = newSlab;
            updatedReferralSlabList = SlabData;
        } else {
            updatedReferralSlabList = [...SlabData, newSlab];
        }
        setSlabData(updatedReferralSlabList);
        setFormData({
            ...formData,
            ReferralSlabList: updatedReferralSlabList
        });
        HandleResetSlab();
    };
    const HandleResetSlab = () => {
        setisServiceEdit(false)
        setServiceEditIndex('')
        setSlabList(SlabList => ({ ...SlabList, minimumAmount: '', maximumAmount: '', deductionRupees: '' }))
    }
    //Handle  Edit
    const HandlEditSlab = (item, index) => {
        setServiceEditIndex(index)
        setisServiceEdit(true)
        setSlabList(SlabList => ({ ...SlabList, minimumAmount: item.minimumAmount, maximumAmount: item.maximumAmount, deductionRupees: item.deductionRupees }))
    }

    // delete
    const HandleDeleteSlab = (index) => {
        SlabData.splice(index, 1)
        setSlabData(SlabData => [...SlabData])
        HandleResetSlab()
    }
    const handleChange1 = (event, value) => {
        getCategoryMasterData(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }

    useEffect(() => {
        document.title = 'Category Master'
        if (showForm) {
            setFocus("txtCategoryName")
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getCategoryMasterData()
    }, [sortConfig, refreshGrid])
  
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}
            >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtCategoryName");
                                        }
                                    }}
                                    
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                         handleDownloadExcel(API_SERVER + '/api/Master/ShowCategoryMaster', ExportRequest, 'CategoryMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Category Master'
                                />


                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row ">
                                            <InputBox
                                                label="Category Name"
                                                placeholder="Category Name"
                                                id="txtCategoryName"
                                                type="text"
                                                maxLength='50'
                                                value={formData.CategoryName}
                                                onChange={(e) => setFormData({ ...formData, CategoryName: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSequenceNo") };
                                                }}
                                                required
                                            />
                                            <InputBox
                                                label="Sequence No"
                                                placeholder="Sequence No"
                                                id="txtSequenceNo"
                                                type="text"
                                                maxLength='30'
                                                value={formData.SequenceNo}
                                                onChange={(e) => setFormData({ ...formData, SequenceNo: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCategoryComission") };
                                                }}
                                                required
                                            />
                                            <InputBox
                                                label="Category Comission"
                                                placeholder="Category Comission"
                                                id="txtCategoryComission"
                                                type="text"
                                                maxLength='15'
                                                value={formData.CategoryCommission}
                                                onChange={(e) => {
                                                    let { value } = e.target
                                                    const fixedComission = value.replace(/[^0-9]/g, '')
                                                    setFormData({ ...formData, CategoryCommission: fixedComission })
                                                }}

                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtFixedComission") };
                                                }}
                                                required
                                            />
                                            <InputBox
                                                label="Fixed Comission"
                                                placeholder="Fixed Comission"
                                                id="txtFixedComission"
                                                type="text"
                                                maxLength='15'
                                                value={formData.FixedCommission}
                                                onChange={(e) => {
                                                    let { value } = e.target
                                                    const fixedComission = value.replace(/[^0-9]/g, '')
                                                    setFormData({ ...formData, FixedCommission: fixedComission })
                                                }}

                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                }}
                                                required
                                            />
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtCategoryImage">
                                                <div className="fields">
                                                    <label className="form-label ">Category Image<p className="d-inline text-danger">* </p>
                                                    </label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'CategoryImage', 'CategoryImageExt', 'CategoryImageName')}
                                                        children={<FileUploadChild label='Category Image' message={formData.CategoryImageName + '.' + formData.CategoryImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note: For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (190-210)Pixels.</div>
                                                                <div>* Height should be between (190-210)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.CategoryImage ?
                                                <div className='col-md-4 col-lg-3' style={{ position: "relative" }}>
                                                    <div className="m-4">
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.categoryImageExt};base64,${formData.CategoryImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.CategoryImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative" }}
                                                        />
                                                    </div>
                                                </div>
                                                : <></>
                                            }

                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtCategoryBannerImage">
                                                <div className="fields">
                                                    <label className="form-label ">Category Banner Image<p className="d-inline text-danger">* </p>
                                                    </label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'CategoryBannerImage', 'CategoryBannerImageExt', 'CategoryBannerImageName')}
                                                        children={<FileUploadChild label='Category  Image' message={formData.CategoryBannerImageName + '.' + formData.CategoryBannerImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note: For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (1500-1530)Pixels.</div>
                                                                <div>* Height should be between (275-300)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.CategoryBannerImage ?
                                                <div className='col-md-4 col-lg-3' style={{ position: "relative" }}>
                                                    <div className="m-4">
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.CategoryBannerImageExt};base64,${formData.CategoryBannerImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.CategoryBannerImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative" }}
                                                        />
                                                    </div>
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Description</label>
                                                    <textarea
                                                        id="txtDescription"
                                                        className="col-12"
                                                        name='Description'
                                                        cols={500}
                                                        value={formData.Discription}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Discription: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className={"form-check form-switch pt-4" }>
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsActive}
                                                        onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                           
                                            <fieldset className='col-lg-12 col-md-12 col-sm-12 myfieldset rounded '>
                                                <legend className="mylegend"> <h6 className='m-0' style={{ fontWeight: "bold" }} > Referral Slab </h6></legend>
                                                <div className='row mt-2'>
                                                    <InputBox
                                                        label="Minimum Amount"
                                                        placeholder="Minimum Amount"
                                                        id="txtMinimumAmount"
                                                        type="text"
                                                        maxLength='15'
                                                        value={SlabList.minimumAmount}
                                                        onChange={(e) => {
                                                            let { value } = e.target
                                                            const minimumAmount = value.replace(/[^0-9]/g, '')
                                                            setSlabList({ ...SlabList, minimumAmount: minimumAmount })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtMaximumAmount") };
                                                        }}
                                                    />
                                                       
                                                    <InputBox
                                                        label="Maximum Amount"
                                                        placeholder="Maximum Amount"
                                                        id="txtMaximumAmount"
                                                        type="text"
                                                        maxLength='15'
                                                        value={SlabList.maximumAmount}
                                                        onChange={(e) => {
                                                            let { value } = e.target
                                                            const maximumAmount = value.replace(/[^0-9]/g, '')
                                                            setSlabList({ ...SlabList, maximumAmount: maximumAmount })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtDeductionRupees") };
                                                        }}
                                                    />
                                                      
                                                    <InputBox
                                                        label="Deduction Rupees"
                                                        placeholder="Deduction Rupees"
                                                        id="txtDeductionRupees"
                                                        type="text"
                                                        maxLength='15'
                                                        value={SlabList.deductionRupees}
                                                        onChange={(e) => {
                                                            let { value } = e.target
                                                            const deductionRupees = value.replace(/[^0-9]/g, '')
                                                            setSlabList({ ...SlabList, deductionRupees: deductionRupees })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("btnsave") };
                                                        }}
                                                    />
                                                    <div className='col-lg-3  mt-3'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-success"
                                                            onClick={() => {
                                                                if (parseInt(SlabList.minimumAmount) >= parseInt(SlabList.maximumAmount)) {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'Oops...',
                                                                        text: 'Minimum amount should be less than maximum amount.',
                                                                    });
                                                                } else if (SlabList.minimumAmount && SlabList.maximumAmount && SlabList.deductionRupees) {
                                                                    HandleAdd(isServiceEdit ? 'Edit' : 'Add', serviceEditIndex);
                                                                } else {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'Oops...',
                                                                        text: 'Please fill in all fields.',
                                                                    });
                                                                }
                                                            }}
                                                            style={{ margin: '5px', minWidth: '30px' }}
                                                        >
                                                            Add<i className="bi bi-plus"></i>
                                                        </button>
                                                        <button type="button" className="btn btn-sm btn-danger" onClick={HandleResetSlab} style={{ margin: '5px', minWidth: '30px' }}> <i class="fa-solid fa-arrows-rotate"></i></button>
                                                    </div>
                                                </div>

                                                {SlabData && SlabData.length > 0 &&

                                                    <div className='mt-1 p-2'>
                                                        <div className='row'>
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered" width="100%" cellSpacing="0">
                                                                    <thead className="sticky-top" style={{ backgroundColor: "#555555", color: "white" }}>
                                                                        <tr className='light-bg'>
                                                                            <th >Action</th>
                                                                            <th >Minimum Amount</th>
                                                                            <th >Maximum Amount</th>
                                                                            <th >Deduction Rupees</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {SlabData.map((item, index) => {
                                                                            return <tr key={index}>
                                                                                <td style={{ width: '10%' }}><i className="fa-regular fa-pen-to-square text-success me-2" onClick={() => HandlEditSlab(item, index)} />  <i className="fa-regular fa-trash-can text-danger me-2" onClick={() => HandleDeleteSlab(index)} /></td>
                                                                                <td>{item.minimumAmount}</td>
                                                                                <td>{item.maximumAmount}</td>
                                                                                <td>{item.deductionRupees}</td>
                                                                            </tr>
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>}



                                            </fieldset>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnsave"onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                   
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='CategoryMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getCategoryMasterData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        tableData={tableData}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Category Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow:'auto' }}>
                        <img src={modalImg} alt='CategoryImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default CategoryMaster
