import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { FetchAutoCompleteData } from "../Common/CommanServices";
import { number, setFocus } from '../Common/Common';
import { AlertBox, AutoCompleteBox, InputBox } from '../Common/Input';
import { Table } from "../Common/Table";
import { post } from '../Common/service';
import Loader from '../Layout/Loader';

const RatingReview = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [edit, setEdit] = useState(false)
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate()
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [formData, setFormData] = useState({
        RatingID: "0",
        RatingBy: "",
        Rating: "",
        IsVerify: true,
        Remarks: "",
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [showTable, setshowTable] = useState(false)
    const handleClear = () => {
        setFormData({
            RatingBy: "",
            Rating: "",
            IsVerify: true,
            Remarks: "",
        });
        //setshowTable(false)
        setEdit(false)
    }
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const handleSubmit = async () => {
        setshowTable(true)
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateRatingReview`, { ...data, ...formData }, (res) => {
            if (res.status === "SUCCESS") {
                getRatingReviewData()
                AlertBox(res.status, res.message, res.focus)
                handleClear()
                setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setIsLoading(false)
            }
        });
    };
    const getRatingReviewData = async (CurrentPage) => {
        setshowTable(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowRatingReview`, { ...data, ...filterData, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const handleChange1 = (event, value) => {
        getRatingReviewData(value);
    }
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditRatingReview`, { ...data, RatingID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFocus("txtRatingBy")
                setFormData({
                    RatingID: res.ratingID,
                    RatingBy: res.ratingBy,
                    Rating: res.rating,
                    Remarks: res.remarks,
                    IsVerify: res.isVerify
                });
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteRatingReview`, { ...data, RatingID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
                getRatingReviewData()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    useEffect(() => {
        document.title = 'Rating Review'
        setFocus("txtRatingBy")
    }, [])
    useEffect(() => {
        getRatingReviewData()
    }, [refreshGrid])

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="font-weight-bold text-primary">Rating Review</h6>
                                    </div>
                                </div>
                                <div className="mx-4">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <AutoCompleteBox
                                                id="txtRatingBy"
                                                label='Rating By'
                                                divclassname='col-10 fields'
                                                placeholder='Rating By'
                                                inputValue={formData.RatingBy ? formData.RatingBy : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'RatingBy', 'GetCustomer', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus("txtRating")
                                                    };
                                                }}
                                            />
                                            <InputBox
                                                required
                                                divclassname="col-10 fields"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtDescription")
                                                    };
                                                }}
                                                label='Rating'
                                                id='txtRating'
                                                maxLength={5}
                                                placeholder="Rating"
                                                value={formData.Rating}
                                                onChange={(e) => setFormData({ ...formData, Rating: number(e.target.value) })}
                                            />

                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-12 fields">
                                                <label className="fw-semibold text-black">Remarks</label>
                                                <div>
                                                    <textarea
                                                        placeholder='Remarks'
                                                        id="txtDescription"
                                                        className="col-10"
                                                        name='Description'
                                                        rows=''
                                                        value={formData.Remarks}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Remarks: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                setFocus("activeCheckbox")
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-10 fields ">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Verify
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsVerify}
                                                        onChange={(e) => setFormData({ ...formData, IsVerify: e.target.checked })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                setFocus("submit")
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-11 m-4  z-0">
                                            <div className="lms-new-button text-center">
                                                {edit ? <button className="button save-btn" id='submit' onClick={handleSubmit}>
                                                    <i className="fa-solid fa-pen-to-square"></i> Update
                                                </button> : <></>}
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {showTable && (
                    <Table
                        Token={Token}
                        PageName='Rating Review'
                        handleFilter={() => {
                            getRatingReviewData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        tableData={tableData}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default RatingReview