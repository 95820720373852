import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, SelectInputBox } from '../Common/Input';
import { ModalTable, Table } from "../Common/Table";
import { post } from '../Common/service';
import Loader from '../Layout/Loader';
import DragableModal from '../Common/DragableModal';
import * as React from 'react';
import { DropDownData1 } from '../Common/CommanServices';

const ManageCustomer = ({ open }) => {
    const cookies = new Cookies();
    const [customerid, setCustomerid] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalReviewDataOpen, setIsModalReviewDataOpen] = useState(false);
    const [isModalOpenComplainAdd, setIsModalOpenComplainAdd] = useState(false);
    const [customerNo, setCustomerNo] = useState('')
    const [complainModal, setComplainModal] = useState(false)
    const [OrderData, setOrderDataReview] = useState([]);
    const [ModalDataReview, setModalDataReview] = useState([])
    const [ModalHeader, setModalHeader] = useState('')
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [currentPageNo1, setCurrentPageNo1] = useState(1);
    const [currentPageNo2, setCurrentPageNo2] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [productPreviewData, setProductPreviewData] = useState([])
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [complainData, setComplainData] = useState({
        OrderNo: "",
        Type: "",
        Remarks:""
    })
    const [isProductModal, setIsProductModal] = useState(false)
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    // Show Data on Table
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: loginCodeString,
        Source: 'web',
    }
    const getProductPropertyMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        post(`${API_SERVER}/api/Master/ShowManageCustomer`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
        });
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, value) => {
        getProductPropertyMaster(value);
    };
    const handleComplainView = ( ) => {
        setComplainModal(true)
    };
    
    
    const handleChange3 = (event, value) => {
        handleReviewData ('',value)
    };
    const handleProductPreviewModal = async (OrderNo, OrderStatus, tabType) => {
        setIsProductModal(true)
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/ShowProductDetails`, { ...data, ProductCode: OrderNo, OrderStatus: OrderStatus,TabType: tabType }, (res) => {
            if (res.Status === "SUCCESS") {
                setProductPreviewData({ ...res })
                setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.Status, res.message, res.focus);
                setProductPreviewData({ ...res })
            }
            setIsLoading(false)
        });
    };
    const handleComplainAdd = (CustomerNo) => {
        setIsModalOpenComplainAdd(true)
        setCustomerNo(CustomerNo)
    };
    const handleCheckbox = (itemValue, checkValue, item, index) => {
        const data = { ...tableData }
        data.Data[index].Checkbox = checkValue
        setTableData(data)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const requestData = {
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            CustomerID: item.CustomerID,
            isActive: checkValue,
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/UpdateCustomerStatus`, { ...requestData }, async (res) => {
            if (res.status === "SUCCESS") {
                await AlertBox(res.status, res.message, res.focus);
                await getProductPropertyMaster()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        })
    }

    const handleReviewData = async (id, CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo1(CurrentPage);
        }
        else {
            setCurrentPageNo1(1);
        }
        const currentCustomerId = id || customerid; 
        setCustomerid(currentCustomerId);
        setIsModalReviewDataOpen(true)
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowCustomerReview`, { ...data, ...sortConfig, PageIndex: `${PageIndex}`, Type: 'S', Customer: currentCustomerId }, (res) => {
            if (res.Status === "SUCCESS") {
                setModalDataReview({ ...res })
                setIsModalReviewDataOpen(true)
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                setModalDataReview({ ...res })
            }
            setIsLoading(false)
        });
    };
    let GetOrderOptions = DropDownData1('GetOrderStatus');


    const handleOrderViewData = async (id, CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo2(CurrentPage);
        }
        else {
            setCurrentPageNo2(1);
        }
        const currentCustomerId = id || customerid;
        setCustomerid(currentCustomerId);
        setIsModalOpen(true)
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowCustomerOrderDetails`, { ...data, ...sortConfig, PageIndex: `${PageIndex}`, Type: 'S', Customer: currentCustomerId }, (res) => {
            if (res.Status === "SUCCESS") {
                setOrderDataReview({ ...res })
                setIsModalOpen(true)
                setIsLoading(false)
            }
            else if (res.Status === "ERROR") {
                setOrderDataReview({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            setIsLoading(false)
        });
    };
    const handleChange2 = (event, value) => {
        handleOrderViewData('', value)
    };
    const handleDeleteDataReview = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteRatingReview`, { ...data, RatingID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
                handleReviewData(customerid, currentPageNo)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        document.title = 'Manage Customer'
        if (isModalOpen === true) {
            handleOrderViewData(customerid)
        } else {
            getProductPropertyMaster()
        }
    }, [sortConfig, refreshGrid])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">Manage Customer</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Table
                Token={Token}
                PageName='ManageCustomer'
                handleCheckbox={handleCheckbox}
                refreshGrid={refreshGrid}
                setRefreshGrid={setRefreshGrid}
                handleFilter={() => {
                    getProductPropertyMaster();
                    setFilterData({
                        ...filterData,
                    });
                }}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                tableData={tableData}
                handleComplainView={handleComplainView}
                handleComplainAdd={handleComplainAdd }
                handleReviewData={handleReviewData}
                
                handleOrderViewData={handleOrderViewData}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {isLoading && <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' />}
            <Modal size="xl" show={complainModal} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setComplainModal(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4"> Complaint View</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                <h4>No Complaint Found !</h4>
                </Modal.Body>
                
            </Modal>
            <Modal size="xl" show={isModalOpen} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Order Data</h6></Modal.Title>
                    <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                        {`${OrderData?.TotalRecord !== undefined ? 'Total Records : ' + OrderData?.TotalRecord : 'No Record Found'}`}</h6></span>
                   
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    {OrderData && OrderData?.TotalRecord !== undefined && OrderData.TotalRecord !== 0 ? (
                        <div className="modal-body p-0" style={{ padding: '4px' }}>
                            <div className="form-box p-0  me-3 " >
                                <div className="row  details-tableAwb" >
                                    <ModalTable
                                        Token={Token}
                                        PageName='Manage Customer'
                                        handleFilter={() => {
                                            setFilterData({
                                                ...filterData,
                                            });
                                        }}
                                        Filter={false}
                                        handleDeleteData={handleDeleteDataReview}
                                        handleSorting={handleSorting}
                                        tableData={OrderData}
                                        ProductPreview={handleProductPreviewModal}
                                        setFilterData={setFilterData}
                                        currentPageNo={currentPageNo2}
                                        handleChange1={handleChange2}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : <h3>No Records Found !..</h3>}
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={complainModal} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setComplainModal(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4"> Complaint View</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <h4>No Complaint Found !</h4>
                </Modal.Body>

            </Modal>
            <Modal size="xl" show={isModalReviewDataOpen} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalReviewDataOpen(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Review Data</h6></Modal.Title>
                    <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                        {`${ModalDataReview?.TotalRecord !== undefined ? 'Total Records : ' + ModalDataReview?.TotalRecord : 'No Record Found'}`}</h6></span>

                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    {ModalDataReview && ModalDataReview?.TotalRecord !== undefined && ModalDataReview.TotalRecord !== 0 ? (
                        <div className="modal-body p-0" style={{ padding: '4px' }}>
                            <div className="form-box p-0  me-3 " >
                                <div className="row  details-tableAwb" >
                                    <ModalTable
                                        Token={Token}
                                        PageName='Manage Customer'
                                        handleFilter={() => {
                                            setFilterData({
                                                ...filterData,
                                            });
                                        }}
                                        Filter={false}
                                        handleDeleteData={handleDeleteDataReview}
                                        handleSorting={handleSorting}
                                        tableData={ModalDataReview}
                                        ProductPreview={handleProductPreviewModal}
                                        setFilterData={setFilterData}
                                        currentPageNo={currentPageNo2}
                                        handleChange1={handleChange3}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : <h3>No Records Found !..</h3>}
                </Modal.Body>
            </Modal>
            <Modal size="l" show={isModalOpenComplainAdd} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpenComplainAdd(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Complaint Add</h6></Modal.Title>
               

                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <div className="container-fluid">
                        <div className='row'>
                    <InputBox
                        label="CustomerNo"
                        divclassname="col col-lg-6  fields"
                        placeholder="CustomerNo"
                        id="CustomerNo"
                        type="text"
                        maxLength='50'
                        value={customerNo}
                        disabled={customerNo}
                    />
                    <SelectInputBox
                        id="txtOrderStatus"
                        divclassname="col col-lg-6  fields"
                        label="Status"
                        required={true}
                        value={complainData.Type}
                        onChange={(e) => {
                            setComplainData({ ...complainData, Type: e.target.value })
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                            }
                        }}
                    ><option value=''>--Select Status--</option>
                        {
                            GetOrderOptions?.data?.dataList?.map((item, index) =>
                                <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                            )
                        }
                    </SelectInputBox>
                    <div className='col-lg-12 col-md-12 col-sm-12  fields'>
                        <div className="form-group text-start mb-3" >
                            <label className="form-label mb-1">Remarks</label>
                            <textarea
                                className="form-control"
                                row='1'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {

                                    };
                                }}
                                maxLength='250'
                                placeholder="Remarks"
                                value={complainData.Remarks}
                                onChange={(e) => setComplainData({ ...complainData, Remarks: e.target.value })}
                            />
                        </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ background: "mintcream" }}>
                    <div className='mb-2 d-md-flex gap-2'>
                        <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                            
                        ><i className="fa">&#xf0c7;</i> Save</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={isProductModal} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsProductModal(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4"> Product Preview</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    {productPreviewData && productPreviewData.Data ?
                        <div className="form-box ">
                            <div className="row align-items-end">
                                <div className="table-container" style={{ maxHeight: '580px', overflowY: 'scroll' }}>
                                    <table className='table table-bordered table-responsive table-hover' style={{ whiteSpace: 'nowrap' }}>

                                        {productPreviewData.Data.map((item, index) => (
                                            <>
                                                <thead>
                                                    <tr>
                                                        <th colSpan='4' className="text-center text-white" style={{ background: '#a98451' }}>Product Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '15px', padding: '1px', border: '2px solid gray' }} key={index}>
                                                    {Object.entries(item).reduce((acc, [key, value], idx) => {
                                                        if (idx % 2 === 0) {
                                                            acc.push([]);
                                                        }
                                                        acc[acc.length - 1].push({ key, value });
                                                        return acc;
                                                    }, []).map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {row.map(({ key, value }, cellIndex) => {
                                                                const truncatedValue = value.length > 30 ? value.slice(0, 30) + '...' : value;
                                                                return (
                                                                    <React.Fragment key={cellIndex}>
                                                                        <td className='fw-bold HistoryColor'>{key}</td>
                                                                        <td style={key === "Product" ? { background: '#f2dede' } : null} className={key === "Product" ? 'fw-bold  text-success' : ""}>
                                                                            {truncatedValue}
                                                                        </td>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </Modal.Body>
            </Modal>
        </main>
    )
}
export default ManageCustomer;