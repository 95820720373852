
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FetchAutoCompleteData } from "../Common/CommanServices";
import { TableCOD } from "../Common/Table";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const CODReconciliation = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [MenuText, setMenuText] = useState("COD Reconciliation");
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [OrderNoData, setOrderNoData] = useState('')
    const navigate = useNavigate()
    const [showButton, setShowButton] = useState(false);
    let tody = new Date()
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [IsUpdate, setIsUpdate] = useState(false)
    const [showTable, setShowTable] = useState(true);
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    // const storedTotalAmount = localStorage.getItem('TotalAmount');
    const [formData, setFormData] = useState({
        OrderDateFrom: Todate,
        OrderDateTo: Todate,
        Courier: "",
        OrderNo: [],
        TotalAmount: "",
        ReceivedAmount: "",
        TransectionID:"",
        Remarks: "",

    });

    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    const data = {
        Token: cookies.get('token'),
        LoginUserCode: cookies.get('loginUserCode'),
    }
    const [filterData, setFilterData] = useState({
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const handleClear = () => {
        setFormData({
            OrderDateFrom: Todate,
            OrderDateTo: Todate,
            Courier: "",
            OrderNoID: [],
            TotalAmount: "",
            ReceivedAmount: "",
            TransectionID:"",
            Remarks: "",
        });
        setIsUpdate(true)
    }

    const handleSearch = async (CurrentPage) => {
        setIsLoading(true)
        setShowTable(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, PageIndex: `${PageIndex}`, Token, LoginUserCode: loginCodeString, Source, OrderNo: ""
        }
        post(`${API_SERVER}/api/Payment/CODReconciliation`, { ...data }, (res) => {
            if (res.Status === 'SUCCESS') {
                setIsLoading(false);
                setTableData({ ...res })
            }
            else if (res.Status === 'EXPIRED') {
                navigate('/Login')
            }
            else if (res.Status === 'ERROR') {
                AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    };

    const handleSubmit = async (id) => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source
        }
        if (parseFloat(formData.ReceivedAmount) !== parseFloat(formData.TotalAmount)) {
            AlertBox('ERROR', 'Received amount should be equal to total amount!', '');
            setIsLoading(false);
            return;
        }

        await post(`${API_SERVER}/api/Payment/UpdateCODReconciliation`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleChange1 = (event, value) => {
        handleSearch(value);
    };
    const handleCheck = (id, OrderNo) => {
        let _data = tableData.Data.map((item) => {
            if (item.Checkbox === OrderNo) {
                return { ...item, checkStatus: id };
            } else {
                return { ...item };
            }
        });
        setTableData({ ...tableData, Data: _data });
        let updatedOrderNoList = [];
        if (formData.OrderNo) {
            updatedOrderNoList = [...String(formData.OrderNo).split(',')];
        }
        if (id === true) {
            updatedOrderNoList.push(OrderNo);
        } else {
            let index = updatedOrderNoList.indexOf(OrderNo.toString());
            if (index !== -1) {
                updatedOrderNoList.splice(index, 1);
            }
        }
        const OrderNoIDString = updatedOrderNoList.filter(item => item !== "").join(',');
        let totalAmount = 0;
        _data.forEach(item => {
            if (item.checkStatus) {
                totalAmount += Number(item.PayableAmount);
            }
        });
        setFormData({ ...formData, TotalAmount: totalAmount.toFixed(2), OrderNo: OrderNoIDString });
        setShowButton(updatedOrderNoList.length > 0);
    };
    const HandleScanData = async () => {
        if (!OrderNoData) {
            await AlertBox('ERROR', 'Please enter Order No.!', '');
            return;
        } else {
            const isAvailable = tableData?.Data?.some(item => item.Checkbox === OrderNoData);
            if (isAvailable) {
                handleCheck(true, OrderNoData);
                setOrderNoData('');
            } else {
                setOrderNoData('');
                await AlertBox('ERROR', 'Order Not Found!', '');
                return;
            }
        }
    };
    useEffect(() => {
        document.title = 'COD Reconciliation';
        if (IsUpdate) {
            setIsUpdate(false);
            handleSearch()
        }
        setTimeout(() => {
            setMenuText(document.title);
        }, 500);
    }, [IsUpdate]);


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="font-weight-bold text-primary">{MenuText}</h6>
                                    </div>
                                </div>
                                <div className="mx-4">
                                    <div className="row">
                                        <div className="col-lg-6 ">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">

                                                    <div className="fields">
                                                        <label className="form-label"> Order Date From <span style={{ color: "red" }}> *</span></label>
                                                        <DatePicker
                                                            preventOpenOnFocus={true}
                                                            id='txtOrderFromDate'
                                                            className="form-control"
                                                            placeholderText='Date From'
                                                            dateFormat={'dd-MMM-yyyy'}
                                                            autoComplete='off'
                                                            maxDate={new Date()}
                                                            selected={formData.OrderDateFrom === '' ? '' : new Date(formData.OrderDateFrom)}
                                                            onChange={(e) => {
                                                                let dateArr = e?.toString()?.split(' ')
                                                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                setFormData({ ...formData, OrderDateFrom: dateArr })
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtToDate") };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="fields">
                                                        <label className="form-label">Date To <span style={{ color: "red" }}> *</span></label>
                                                        <DatePicker
                                                            preventOpenOnFocus={true}
                                                            id='txtToDate'
                                                            className="form-control"
                                                            minDate={formData.OrderDateFrom}
                                                            placeholderText='Date To'
                                                            dateFormat={'dd-MMM-yyyy'}
                                                            autoComplete='off'
                                                            selected={formData.OrderDateTo === '' ? '' : new Date(formData.OrderDateTo)}
                                                            onChange={(e) => {
                                                                let dateArr = e?.toString()?.split(' ')
                                                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                setFormData({ ...formData, OrderDateTo: dateArr })
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtCourier") };
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <AutoCompleteBox
                                                    id="txtCourier"
                                                    label='Courier'
                                                    divclassname='col-lg-6 col-md-6 col-sm-12 fields'
                                                    placeholder='Courier'
                                                    inputValue={formData.Courier ? formData.Courier : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'Courier', 'GetCourier', '');
                                                        }}
                                                    maxLength='50'
                                                    required={true}
                                                    options={autoCompleteOptions}
                                                    setOptions={setAutoCompleteOptions}
                                                    onKeyDown={(e) => {

                                                        if (e.key === 'Enter') {
                                                            e.preventDefault()
                                                            setFocus("submit")
                                                        };
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="section dashboard">

                                                <div className="content-box" style={{ paddingTop: "15px" }}>
                                                    <div className="mx-4">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 fields mt-4" style={{ backgroundColor: "#EEEEEE", borderRadius: "5px" }}>
                                                                <label style={{ padding: "5px", display: "flex", justifyContent: "center" }}>Total Amount : <span className="highlighted-amount" style={{ fontWeight: "bold", fontSize: "16px", color: "green" }}>{formData.TotalAmount}</span></label>

                                                            </div>
                                                            <InputBox
                                                                label="Received Amount"
                                                                placeholder="Received Amount"
                                                                divclassname='col-lg-6 col-md-6 col-sm-12 fields'
                                                                id="txtReceivedAmount"
                                                                type="text"
                                                                maxLength='15'
                                                                value={formData.ReceivedAmount}
                                                                onChange={(e) => {
                                                                    let { value } = e.target;
                                                                    const receivedAmount = value.replace(/[^0-9.]/g, '');
                                                                    setFormData({ ...formData, ReceivedAmount: receivedAmount });
                                                                }}
                                                                required
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("TransactionID") };
                                                                }}
                                                            />
                                                            <InputBox
                                                                label="TransactionID"
                                                                placeholder="Transaction ID"
                                                                divclassname='col-lg-6 col-md-6 col-sm-12 fields'
                                                                id="txtTransectionID"
                                                                type="text"
                                                                maxLength='15'
                                                                value={formData.TransectionID}
                                                                onChange={(e) => {

                                                                    setFormData({ ...formData, TransectionID: e.target.value });
                                                                }}
                                                                required
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') { setFocus("txtRemarks") };
                                                                }}
                                                            />
                                                            <div className="col-lg-12 col-md-12 col-sm-12 fields">

                                                                <label className="form-label">Remarks</label>
                                                                <textarea
                                                                    id="txtRemarks"
                                                                    className="col-12"
                                                                    name='Remarks'
                                                                    cols={500}
                                                                    value={formData.Remarks}
                                                                    placeholder="Enter Remarks.."
                                                                    onChange={(e) => {
                                                                        setFormData({ ...formData, Remarks: e.target.value })
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                                    }}
                                                                />


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-11 m-4  z-0">
                                            <div className="lms-new-button ">
                                                {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                    Please wait...
                                                </button> : <button className="button search-btn" id='submit' onClick={handleSearch}>
                                                    <i class="fa-solid fa-magnifying-glass"></i> Search
                                                </button>)}
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                                {showButton && < button className="button Process-btn" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" />
                                                    Save
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {showTable && (
                    <TableCOD
                        Token={Token}
                        PageName='CODReconciliation'
                        handleFilter={() => {
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        Filter={false}
                        showCheckBox={true}
                        OrderNO={OrderNoData}
                        SetOrderNo={setOrderNoData}
                        HandleScanList={HandleScanData}
                        tableData={tableData}
                        handleCheck={handleCheck}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default CODReconciliation
