import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";

const ProductPropertyMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tableData, setTableData] = useState([])
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const navigate = useNavigate();
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        ProductPropertyID: "",
        CategoryName: "",
        PropertyName: "",
        IsActive: true,
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    // Show Data on Table
    const getProductPropertyMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowProductPropertyMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}`  }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status==="ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    //Handle Clear 
    const handleClear = () => {
        setFormData({
            ProductPropertyID: "",
            CategoryName: "",
            PropertyName: "",
            IsActive: true,
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getProductPropertyMaster(value);
    };
    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateProductPropertyMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditProductPropertyMaster`, { ...data, ProductPropertyID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    ProductPropertyID: res.productPropertyID,
                    CategoryName: res.categoryName,
                    PropertyName: res.propertyName,
                    IsActive: res.isActive
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteProductPropertyMaster`, { ...data, ProductPropertyID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }

    const autoCompleteTimeoutRef = useRef(null);

    const handleAutoCompleteChange = async (event, newValue, field, type) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    useEffect(() => {
        document.title = 'Product Property Master'
        if (showForm) {
            setFocus("txtCategoryName")
        }
        setFocus("txtCategoryName")
     
    }, [showForm, setFocus])

    useEffect(() => {
        getProductPropertyMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowProductPropertyMaster', ExportRequest, 'Product Property Master')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Product Property Master'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-top">
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <AutoCompleteBox
                                                        label='Category'
                                                        placeholder='Category'
                                                        divclassname=''
                                                        inputValue={formData.CategoryName ? formData.CategoryName : ''}
                                                        onInputChange={
                                                            (event, newInputValue) => {
                                                                handleAutoCompleteChange(event, newInputValue, 'CategoryName', 'GetCategory', '');
                                                            }}
                                                        maxLength='50'
                                                        options={autoCompleteOptions}
                                                        setOptions={setAutoCompleteOptions}
                                                        required={true}
                                                        id='txtCategoryName'
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtPropertyName") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Property Name<span className="Required">*</span></label>
                                                    <textarea
                                                        id="txtPropertyName"
                                                        className="form-control"
                                                        name='PropertyName'
                                                        //cols={500}
                                                        rows={1}
                                                        value={formData.PropertyName}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, PropertyName: e.target.value })
                                                        }}
                                                        placeholder="Property Name"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                        }}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-4 mt-4 col-sm-6 d-flex align-item-center">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input "
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsActive}
                                                        onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                   
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='ProductPropertyMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getProductPropertyMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        filterData={filterData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>

    )
}
export default ProductPropertyMaster