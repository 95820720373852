
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, handleFileUploader, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';

const NotificationMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [MenuText, setMenuText] = useState("Notification Master");
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const fileType = 'jpg,png'
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        NotificationID: "",
        Title: "",
        Event: "",
        Type:"",
        Body: "",
        NotificationImage:"",
        NotificationImageName:"",
        NotificationImageExt:"",
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    // Display data
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    // Show Data on Table
    const getNotificationMaster = async (CurrentPage) => {

        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowNotificationMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status==="ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    //Handle Clear 
    const handleClear = () => {
        setFormData({
            NotificationID: "",
            Title: "",
            Event: "",
            Type: "",
            Body: "",
            NotificationImage: "",
            NotificationImageName: "",
            NotificationImageExt: "",
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getNotificationMaster(value);
    };


    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateNotificationMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteNotificationMaster`, { ...data, NotificationID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }



    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }

    useEffect(() => { 
        document.title = 'Notification Master'
        if (showForm) {
            setFocus("txtTitle")
        }
        setFocus("txtTitle")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])
    useEffect(() => {
        getNotificationMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                              
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        if (!showForm) {
                                            setFocus("txtTitle");
                                        }
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowNotificationMaster', ExportRequest, 'Notification Master')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading={MenuText}
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtType")
                                                    };
                                                }}
                                                label='Message Title'
                                                id='txtTitle'
                                                maxLength='100'
                                                placeholder="Message Title"
                                                value={formData.Title}
                                                onChange={(e) => setFormData({ ...formData, Title: e.target.value })}

                                                required
                                            />
                                            <SelectInputBox

                                                id='txtType'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtEventType")
                                                    };
                                                }}
                                                label='Type'
                                                value={formData.Type}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, Type: e.target.value,
                                                    })
                                                }}
                                                required={true}
                                                inputclassname='p-1'
                                            >
                                                <option value="">--Select Type--</option>
                                                <option value="Message">Message</option>
                                                <option value="Mail">Mail</option>
                                            </SelectInputBox>
                                            <SelectInputBox

                                                id='txtEventType'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtMessageBody")
                                                    };
                                                }}
                                                label='Event'
                                                value={formData.Event}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, Event: e.target.value,
                                                    })
                                                }}
                                                required={true}
                                                inputclassname='p-1'
                                            >
                                                <option value="">--Select Event--</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Cancel">Cancel</option>
                                                <option value="Approved">Approved</option>


                                            </SelectInputBox>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Message Body</label>
                                                    <textarea
                                                        id="txtMessageBody"
                                                        className="form-control"
                                                        name='Body'
                                                        //cols={500}
                                                        rows={1}
                                                        value={formData.Body}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Body: e.target.value })
                                                        }}
                                                        placeholder="Message Body"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtPrimaryImage") };
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtPrimaryImage">
                                                <div className="fields">
                                                    <label className="form-label ">Primary Image<span className="Required">*</span></label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'NotificationImage', 'NotificationImageExt', 'NotificationImageName')}
                                                        children={<FileUploadChild label='Primary Image' message={formData.NotificationImageName + '.' + formData.NotificationImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note:For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (200-220)Pixels.</div>
                                                                <div>* Length should be between (150-160)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            {formData.NotificationImage ?
                                               <div className='col-lg-3 m-3  ' style={{ width: "auto", position: "relative" }}>
                                                    <img
                                                        onClick={() => handleShowImageInModal(`data:image/${formData.NotificationImageExt};base64,${formData.NotificationImage}`)}
                                                        className='border border-success img-thumbnail '
                                                        src={`data:image/png;base64,${formData.NotificationImage}`}
                                                        alt='logo'
                                                        style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                    />
                                                </div>
                                             : <></>}
                                          
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                   
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='NotificationMaster'
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getNotificationMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        //  loading={loading}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Notification Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='NotificationImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default NotificationMaster







