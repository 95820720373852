import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import {  Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignIn from './components/Layout/Login';
import Protected from './Protected';
import { PageNotFound } from './components/Common/Common';
import { useState } from 'react';

//Dashboard Screen start
import Dashboard from './components/Dashboard/Dashboard';
//Dashboard Screen End

/*UserSectionStart*/
import ChangePassword from './components/UserSection/ChangePassword';
import ResetPassword from './components/UserSection/ResetPassword';
import UserMaster from './components/UserSection/UserMaster';
import GroupMaster from './components/UserSection/GroupMaster';
import GroupMenu from './components/UserSection/GroupMenuAccess';
import CategoryMaster from './components/Master/CategoryMaster';
import SubCategoryMaster from './components/Master/SubCategoryMaster';
import UnitMaster from './components/Master/UnitMaster';
import SectionMaster from './components/Master/SectionMaster';
import PinCodeMaster from './components/Master/PinCodeMaster';
import BrandApplication from './components/Brand/BrandApplication';
import ProductListing from './components/ProductManagment/ProductListing';
import ProductPropertyMaster from './components/ProductManagment/ProductPropertyMaster';
import VendorMaster from './components/VendorManagment/VendorMaster';
import ItemsToSections from './components/ProductManagment/ItemsToSections';
import NotificationMaster from './components/UtilitiesManagement/NotificationMaster';
import OfferMaster from './components/Master/OfferMaster';
import OfferBannerImage from './components/Master/OfferBannerImage';
import ManageCustomer from './components/ManageCustomer/ManageCustomer';
import OrdersManagment from './components/OrderManagment/Order';
import Manifest from './components/OrderManagment/Manifest';
import WareHouseMaster from './components/Master/WarehouseMaster';
import BulkProduct from './components/ProductManagment/BulkProduct';
import TagMaster from './components/Master/TagMaster';
import CourierMaster from './components/Master/CourierMaster';
import ProgressMaster from './components/Master/ProgressMaster';
import OPSProcessMaster from './components/Master/OPSProcessMaster';
import RatingReview from './components/Master/RatingReview';
import Userlog from './components/UserSection/Userlog';
import PaymentLog from './components/PaymentManagment/PaymentLog';
import TaxCategoryMaster from './components/Master/TaxCategoryMaster';
import UserMenuAccess from './components/UserSection/UserMenuAccess';
import ProcessTypeMaster from './components/Master/ProcessTypeMaster';
import ForgotPasswordSlide1 from './components/Layout/ForgetPassword1';
import ForgotPasswordSlide2 from './components/Layout/ForgetPasswordSlide2';
import ProductMultimedia from './components/ProductManagment/ProductMultimedia';
import Home from './components/Dashboard/Home';
import BrandRequest from './components/Brand/BrandRequest';
import ImageView from './components/Common/ImageView';
import CODReconciliation from './components/Accounts/CODReconciliation';
import VendorPayout from './components/Accounts/VendorPayout';
import CODReconciliationReport from './components/Accounts/CODReconsiliationReport';
import VendorPayoutReport from './components/Accounts/VendorPayoutReport';
import ProfitPayoutDetails from './components/Accounts/ProfitPaymentDetails';
import GenrateImage from './components/ProductManagment/GenrateImage';
import PDFView from './components/Common/PDFView';
import ProductVerification from './components/ProductManagment/ProductVerification';
import TodayDeals from './components/ProductManagment/TodayDeal'
import SmsReport from './components/Reports/SmsReport';
import CODOrderPaymentDetails from './components/Accounts/CODOrderPaymentDetails';
import PaidOrderPaymentDetails from './components/Accounts/PaidOrderPaymentDetails';
/*UserSectionEnd*/
import PayoutProcessReport from './components/Accounts/PayoutProcessReport'
import WebSiteConfig from './components/WebSiteConfig/WebSiteConfig'
import VendorVerification from './components/VendorManagment/VendorVerification'
import CancelShippmentReport from './components/Reports/CancelShipmentReport';
import PendingPayments from './components/Accounts/PendingPayments';
import VendorProfileUpdate from './components/VendorManagment/VendorProfileUpdate';
import FAQMaster from './components/GeneralSetting/FAQs';
import BlogMaster from './components/GeneralSetting/Blog';

export const App = () => {
    const [open, setOpen] = useState(true);
    const defaultTheme = createTheme();
    return (
        <ThemeProvider theme={defaultTheme}>
            <Routes>
                <Route path='*' element={<PageNotFound />} />
                <Route path='/Login' element={<SignIn />} />
                <Route  path='/forgotPassword' element={<ForgotPasswordSlide1 />} />
                <Route path='/Login/forgotPassword' element={<ForgotPasswordSlide2 />} />
                <Route path='/PdfView' element={<PDFView/> }/>
                <Route path='/' element={<SignIn />} />
                <Route path='/ImageView' element={<ImageView/>}/>
                <Route path='/Dashboard' element={<Protected setOpen={setOpen} open={open} Component={Dashboard} PageUrl='Dashboard' />} />
                <Route path='/Home' element={<Protected setOpen={setOpen} open={open} Component={Home} PageUrl='Home' />} />
                {/*UserSectionStart*/}
                <Route path='/UserMaster' element={<Protected setOpen={setOpen} open={open} Component={UserMaster} PageUrl='UserMaster' />} />
                <Route path='/ChangePassword' element={<Protected setOpen={setOpen} open={open} Component={ChangePassword} PageUrl='ChangePassword' />} />
                <Route path='/ResetPassword' element={<Protected setOpen={setOpen} open={open} Component={ResetPassword} PageUrl='ResetPassword' />} />
                <Route path='/GroupMaster' element={<Protected setOpen={setOpen} open={open} Component={GroupMaster} PageUrl='GroupMaster' />} />
                <Route path='/GroupMenuAccess' element={<Protected setOpen={setOpen} open={open} Component={GroupMenu} PageUrl='GroupMenuAccess' />} />
                <Route path='/UserMenuAccess' element={<Protected setOpen={setOpen} open={open} Component={UserMenuAccess} PageUrl='UserMenuAccess'/> }/>
                {/*UserSectionEnd*/}
                <Route path='/CategoryMaster' element={<Protected setOpen={setOpen} open={open} Component={CategoryMaster} PageUrl='CategoryMaster' />} />
                <Route path='/SubCategoryMaster' element={<Protected setOpen={setOpen} open={open} Component={SubCategoryMaster} PageUrl='SubCategoryMaster' />} />
                <Route path='/UnitMaster' element={<Protected setOpen={setOpen} open={open} Component={UnitMaster} PageUrl='UnitMaster' />} />
                <Route path='/SectionMaster' element={<Protected setOpen={setOpen} open={open} Component={SectionMaster} PageUrl='SectionMaster' />} />
                <Route path='/PinCodeMaster' element={<Protected setOpen={setOpen} open={open} Component={PinCodeMaster} PageUrl='PinCodeMaster' />} />
                <Route path='/BrandMaster' element={<Protected setOpen={setOpen} open={open} Component={BrandApplication} PageUrl='BrandMaster' />} />
                <Route path='/BrandRequestMaster' element={<Protected setOpen={setOpen} open={open} Component={BrandRequest} PageUrl='BrandRequestMaster'/> }/>
                {/* Product Management */ }
                <Route path='/ProductMaster' element={<Protected setOpen={setOpen} open={open} Component={ProductListing} PageUrl='ProductMaster' />} />
                <Route path='/ProductPropertyMaster' element={<Protected setOpen={setOpen} open={open} Component={ProductPropertyMaster} PageUrl='ProductPropertyMaster' />} />
                <Route path='/ItemsToSections' element={<Protected setOpen={setOpen} open={open} Component={ItemsToSections} PageUrl='ItemsToSections' />} />
                <Route path='/VendorMaster' element={<Protected setOpen={setOpen} open={open} Component={VendorMaster} PageUrl='VendorMaster' />} />
                <Route path='/VendorPayout' element={<Protected setOpen={setOpen} open={open} Component={VendorPayout} PageUrl='VendorPayout' />} />
                <Route path='/OfferMaster' element={<Protected setOpen={setOpen} open={open} Component={OfferMaster} PageUrl='OfferMaster' />} />
                <Route path='/OfferBannerImageMaster' element={<Protected setOpen={setOpen} open={open} Component={OfferBannerImage} PageUrl='OfferBannerImageMaster' />} />
                <Route path='/NotificationMaster' element={<Protected setOpen={setOpen} open={open} Component={NotificationMaster} PageUrl='NotificationMaster' />} />
                <Route path='/ManageCustomer' element={<Protected setOpen={setOpen} open={open} Component={ManageCustomer} PageUrl='ManageCustomer' />} />
                <Route path='/Order' element={<Protected setOpen={setOpen} open={open} Component={OrdersManagment} PageUrl='Order' />} />
                <Route path='/VendorManifest' element={<Protected setOpen={setOpen} open={open} Component={Manifest} PageUrl='VendorManifest' />} />
                <Route path='/WarehouseMaster' element={<Protected setOpen={setOpen} open={open} Component={WareHouseMaster} PageUrl='WarehouseMaster' />} />
                <Route path='/TagMaster' element={<Protected setOpen={setOpen} open={open} Component={TagMaster} PageUrl='TagMaster' />} />
                <Route path='/BulkProduct' element={<Protected setOpen={setOpen} open={open} Component={BulkProduct} PageUrl='BulkProduct' />} />
                <Route path='/CourierMaster' element={<Protected setOpen={setOpen} open={open} Component={CourierMaster} PageUrl='CourierMaster' />} />
                <Route path='/ProgressMaster' element={<Protected setOpen={setOpen} open={open} Component={ProgressMaster} PageUrl='ProgressMaster' />} />
                <Route path='/OPSProcessMaster' element={<Protected setOpen={setOpen} open={open} Component={OPSProcessMaster} PageUrl='OPSProcessMaster' />} />
                <Route path='/RatingReview' element={<Protected setOpen={setOpen} open={open} Component={RatingReview} PageUrl='RatingReview' />} />
                <Route path='/UserLogReport' element={<Protected setOpen={setOpen} open={open} Component={Userlog} PageUrl='UserLogReport' />} />
                <Route path='/PaymentReport' element={<Protected setOpen={setOpen} open={open} Component={PaymentLog} PageUrl='PaymentReport' />} />
                <Route path='/TaxCategoryMaster' element={<Protected setOpen={setOpen} open={open} Component={TaxCategoryMaster} PageUrl='TaxCategoryMaster' />} />
                <Route path='/ProcessMaster' element={<Protected setOpen={setOpen} open={open} Component={ProcessTypeMaster} PageUrl='ProcessMaster' />} />
                <Route path='/ProductMultimedia' element={<Protected setOpen={setOpen} open={open} Component={ProductMultimedia} PageUrl='ProductMultimedia' />} />
                <Route path='/CODReconciliation' element={<Protected setOpen={setOpen} open={open} Component={CODReconciliation} PageUrl='CODReconciliation' />} />
                <Route path='/VendorPayout' element={<Protected setOpen={setOpen} open={open} Component={VendorPayout} PageUrl='VendorPayout' />} />
                <Route path='/CODReconciliationReport' element={<Protected setOpen={setOpen} open={open} Component={CODReconciliationReport} PageUrl='CODReconciliationReport' />} />
                <Route path='/VendorPayoutReport' element={<Protected setOpen={setOpen} open={open} Component={VendorPayoutReport} PageUrl='VendorPayoutReport' />} />
                <Route path='/ProfitPaymentDetails' element={<Protected setOpen={setOpen} open={open} Component={ProfitPayoutDetails} PageUrl='ProfitPaymentDetails' />} />
                <Route path='/GenrateImage' element={<Protected setOpen={setOpen} open={open} Component={GenrateImage} PageUrl='GenrateImage' />} />
                <Route path='/ProductVerification' element={<Protected setOpen={setOpen} open={open} Component={ProductVerification} PageUrl='ProductVerification' />} />
                <Route path='/TodayDeals' element={<Protected setOpen={setOpen} open={open} Component={TodayDeals} PageUrl='TodayDeals' />} />
                <Route path='/SMSReport' element={<Protected setOpen={setOpen} open={open} Component={SmsReport} PageUrl='SMSReport' />} />
                <Route path='/CODOrderPaymentDetails' element={<Protected setOpen={setOpen} open={open} Component={CODOrderPaymentDetails} PageUrl='CODOrderPaymentDetails' />} />
                <Route path='/PaidOrderPaymentDetails' element={<Protected setOpen={setOpen} open={open} Component={PaidOrderPaymentDetails} PageUrl='PaidOrderPaymentDetails' />} />
                <Route path='/PayoutsProcessReport' element={<Protected setOpen={setOpen} open={open} Component={PayoutProcessReport} PageUrl='PayoutsProcessReport' />} />
                <Route path='/WebSiteConfiguration' element={<Protected setOpen={setOpen} open={open} Component={WebSiteConfig} PageUrl='WebSiteConfiguration' />} />
                <Route path='/VendorVerification' element={<Protected setOpen={setOpen} open={open} Component={VendorVerification} PageUrl='VendorVerification' />} />
                <Route path='/CancelShipmentReport' element={<Protected setOpen={setOpen} open={open} Component={CancelShippmentReport} PageUrl='CancelShipmentReport' />} />
                <Route path='/PendingPayments' element={<Protected setOpen={setOpen} open={open} Component={PendingPayments} PageUrl='PendingPayments' />} />
                <Route path='/VendorProfileUpdate' element={<Protected setOpen={setOpen} open={open} Component={VendorProfileUpdate} PageUrl='VendorProfileUpdate' />} />
                {/*General Setting*/ }
                <Route path='/FAQsMaster' element={<Protected setOpen={setOpen} open={open} Component={FAQMaster} PageUrl='FAQsMaster' />} />
                <Route path='/BlogMaster' element={<Protected setOpen={setOpen} open={open} Component={BlogMaster} PageUrl='BlogMaster' />} />
                </Routes>
        </ThemeProvider>
    );

}
