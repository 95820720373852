import React from 'react';
import { ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';

const DragableModal = (props) => {
    return (
        <Draggable handle=".modal-content">
            <ModalDialog {...props} />
        </Draggable>
    );
};

export default DragableModal;
