const Loader = ({ text, size, img_path, background, zIndex }) => {
    return (
        <>
            <div className='loader-layout' style={{ background: `${background}`, zIndex: `${zIndex}` }}>
                <div className='loader-img'>
                    <img src='./assets/img/loading-gif.gif' alt='Loading...' style={{ width: `${size}` }} />
                    <div className='loader-content d-flex fw-bold text-light gap-2'>
                        <div>Please Wait . . .</div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Loader;