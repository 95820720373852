import { useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { ClearSession } from "../Common/Common";
import { HiBellAlert } from "react-icons/hi2"; 
import { post } from '../Common/service';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from '../Common/Input';
import { useEffect } from 'react';

const cookies = new Cookies();
const Header = ({ setOpen, open }) => {
    let UserName = cookies.get('userName');
    let UserCode = cookies.get('loginUserCode')
    let SalesPersonCode = cookies.get('salesPersonCode')
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const VendorCode = cookies.get('loginType') === 'Vendor' 
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };
    const [count, setCount]=useState("")
    const [SessionClear, setSessionClear] = useState(false);
    const handleSignOut = async () => {
        setSessionClear(true);
    };
    let baseUrl;
    if (window.location.hostname.indexOf("react.") >= 0) {
        baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
    } else {
        baseUrl = `${window.location.protocol}//${window.location.host}`;
    }
    const GetCount = async () => {
        await post(`${API_SERVER}/api/report/GetVendorPayoutCount`, { ...data,  }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                setCount(res.count)
            }
            else if (res.message === "EXPIRED") {
             
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
        })
    };
    const navigate = useNavigate();

    const handleClick = () => {
            navigate('/PendingPayments');
    };
    const toggleFullScreen = () => {
        const elem = document.documentElement;
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }
    useEffect(() => {
        GetCount()
    }, [])

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between">
                    <a className="navbar-brand" >
                        {baseUrl.startsWith('https://seller') || baseUrl.startsWith('https://localhost') ? (

                            <img src="assets/img/Final_indo_logo_seller.png" className="img-fluid"
                                alt=""
                                style={{ marginLeft: "22px", width: "175px", height: "74px" }} alt="Seller" />

                        ) :
                            <img src="assets/img/indocraft.png" className="img-fluid"
                                alt=""
                                style={{ marginLeft: "22px", width: "175px", height: "74px" }} alt="Admin" />
                        }
                    </a>
                    <i className="bi bi-list toggle-sidebar-btn" onClick={() => setOpen(!open)} />
                </div>

                <div className="d-flex">
                    {!VendorCode &&
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <button
                                className=""
                                title="show alerts"
                                style={{ border: "none", backgroundColor: "white" }}
                                onClick={handleClick}
                            >
                                <HiBellAlert size={24} />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '-5px',
                                        right: '-5px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '2px 6px',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {count}
                                </span>
                            </button>
                        </div>
                    }
                    <button className="toggle-fullscreen-btn ms-2" onClick={toggleFullScreen} style={{ border: "none", backgroundColor: "white" }}>
                        <i className="bi bi-arrows-fullscreen"></i>
                    </button>
                   
                    <nav className="header-nav">
                        <ul className="d-flex align-items-center" style={{ gap: "6px" }}>
                            <li className="nav-item dropdown pe-3">
                                <button
                                    className="nav-link nav-profile d-flex align-items-center pe-0"
                                    data-bs-toggle="dropdown"
                                    style={{ border: "none", backgroundColor: "white" }}
                                >
                                    <img
                                        src="assets/img/profile-img.Png"
                                        alt="Profile"
                                        className="rounded-circle"
                                    />
                                    <span className="d-none d-md-block dropdown-toggle ps-2">
                                        {`${UserName} (${SalesPersonCode ? SalesPersonCode : UserCode})`}
                                    </span>

                                </button>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                    <li className="dropdown-header content-left-align">
                                        <h6>{UserName}</h6>
                                        <span>User Code : {SalesPersonCode ? SalesPersonCode : UserCode}</span>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li className="dropdown-header content-left-align">
                                        <Link to="/ChangePassword"> Change Password</Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    
                                    <li>
                                        <button className="dropdown-item d-flex align-items-center" onClick={handleSignOut}>
                                            <i className="bi bi-box-arrow-right" />
                                            <span>Sign Out</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            {SessionClear ? <ClearSession /> : <></>}
        </>
    )
}
export default Header;