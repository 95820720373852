import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from '../Common/Input';
import { Table } from "../Common/Table";
import { post } from '../Common/service';
import Loader from '../Layout/Loader';
import * as React from 'react';
import Swal from 'sweetalert2';

const VendorProfileUpdate = ({ open }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    // Show Data on Table
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: loginCodeString,
        Source: 'web',
    }
    const getVendorProfileUpdate = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        post(`${API_SERVER}/api/Master/ShowVendorProfileUpdate`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
        });
    }
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleChange1 = (event, value) => {
        getVendorProfileUpdate(value);
    };
    const handleAccept = async (Id, status) => {
        const confirmAcceptance = async (reason) => {
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
            const requestData = {
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                VendorCode: Id,
                Type: status
            }
            setIsLoading(true);
            await post(`${API_SERVER}/api/Master/VerifyVendorProfileUpdate`, { ...requestData, reason }, (res) => {
                if (res.status === "SUCCESS") {
                    AlertBox(res.status, res.message, res.focus);
                    getVendorProfileUpdate()
                } else if (res.message === "EXPIRED") {
                    navigate('/Login');
                } else {
                    AlertBox(res.status, res.message, res.focus);
                }
                setIsLoading(false);
            });
        };

        let alertMessage = "";
        let title = "";
        let confirmButtonText = "";

        switch (status) {
            case "Accepted":
                alertMessage = "Do you want to accept?";
                title = "Accepted";
                confirmButtonText = "Yes Accept It!"
                break;
            case "Rejected":
                alertMessage = "Do you want to Reject?";
                title = "Rejected";
                confirmButtonText = "Yes Reject It!";
                break;
            default:
                alertMessage = "Do you want to proceed?";
                break;
        }
        if (status === "Accepted") {
            await Swal.fire({
                text: alertMessage,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmAcceptance('');
                }
            });
        }
        if (status === "Rejected") {
            const { value: text, dismiss } = await Swal.fire({
                text: alertMessage,
                icon: "warning",
                input: "textarea",
                inputPlaceholder: "Type your Reason here...",
                inputAttributes: {
                    "aria-label": "Type your Reason here"
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Please enter a reason.");
                    }
                    return value;
                }
            });

            if (text && !dismiss) {
                confirmAcceptance(text);
            }
        }
    };
    const ImageView = (ProductUrl) => {
        window.open(ProductUrl, '_blank')
    }
    //const handleCheckbox = (itemValue, checkValue, item, index) => {
    //    const data = { ...tableData }
    //    data.Data[index].Checkbox = checkValue
    //    setTableData(data)
    //    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    //    const requestData = {
    //        Token: cookies.get('token'),
    //        LoginUserCode: loginCodeString,
    //        Source: 'web',
    //        VendorCode: item.Vendor,
    //        isActive: checkValue,
    //    }
    //    setIsLoading(true)
    //    post(`${API_SERVER}/api/Master/VerifyVendorProfileUpdate`, { ...requestData }, async (res) => {
    //        if (res.status === "SUCCESS") {
    //            await AlertBox(res.status, res.message, res.focus);
    //            await getVendorProfileUpdate()
    //        }
    //        else if (res.message === "EXPIRED") {
    //            navigate('/Login')
    //        }
    //        else {
    //            AlertBox(res.status, res.message, res.focus);
    //        }
    //        setIsLoading(false)
    //    })
    //}
    useEffect(() => {
        document.title = 'Vendor Verify'
        getVendorProfileUpdate()
    }, [sortConfig, refreshGrid])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">Vendor Profile Update</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Table
                Token={Token}
                PageName='VendorProfileUpdate'
                // handleCheckbox={handleCheckbox}
                ImageView={ImageView}
                refreshGrid={refreshGrid}
                setRefreshGrid={setRefreshGrid}
                handleFilter={() => {
                    getVendorProfileUpdate();
                    setFilterData({
                        ...filterData,
                    });
                }}
                handleAccept={handleAccept}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                tableData={tableData}
                filterData={filterData}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
            />
            {isLoading && <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' />}

        </main>
    )
}
export default VendorProfileUpdate;
