import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, DateBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { ReportTable, Table, TableVendor } from "../Common/Table";
import { FetchAutoCompleteData } from "../Common/CommanServices";
import Modal from 'react-bootstrap/Modal';
import { useRef } from "react";
import { IoMdSync } from "react-icons/io";
import DragableModal from "../Common/DragableModal";

const VendorPayout = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [ModalData, setModalData] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [PayoutData, setPayoutData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [MenuText, setMenuText] = useState("Vendor Payout");
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [PayoutCode, setPayoutCode] = useState('')
    const [PayoutId, setPayoutId] = useState('')

    const [CheckAll, setCheckAll] = useState(false)
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [showTable, setShowTable] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [formData, setFormData] = useState({
        Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        PaymentType: "",
        PaymentDate: Todate,
        PaymentDetailsID: [],
    });
    const [modalForm, setModalform] = useState({
        TranscationID: "",
        TranscationDate: new Date(),
        Amount:""

    })
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode),

    }
    /*    const shouldShowButton = formData.PaymentDetailsID.length > 0;*/

    const getVendorPayout = async (CurrentPage, type) => {
        let PageIndex = 1;
        if (CurrentPage !== undefined && typeof CurrentPage !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        } else {
            setCurrentPageNo(1);
        }

        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            ...formData,
            Source: 'web',
            Type: type,
            PageIndex: `${PageIndex}`
        };

        setIsLoading(true);

        post(`${API_SERVER}/api/OrderManagement/GetVendorPayoutData`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                if (type === 'P') {
                    setPayoutData({ ...res });
                } else {
                    setTableData({ ...res });
                }
            } else if (res.Message === "EXPIRED") {
                navigate('/Login');
            } else if (res.Message !== "" && res.Status === "ERROR") {
              //  AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res });
            }
            setIsLoading(false);
        });
    };
    const getVendorDetails = async (PayoutID, ProcessPayoutCode) => {
        setIsModalOpen(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            ...formData,
            Source: 'web',
            Type: "D",
        };
        setIsLoading(true);
        post(`${API_SERVER}/api/OrderManagement/GetVendorPayoutData`, { ...data, PayoutCode: ProcessPayoutCode }, (res) => {
            if (res.Status === "SUCCESS") {
                setModalData({ ...res });
            } else if (res.Message === "EXPIRED") {
                navigate('/Login');
            } else if (res.Message !== "" && res.Status === "ERROR") {
               // AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res });
            }
            setIsLoading(false);
        });
    };



    const handleClear = () => {
        setFormData({
            Vendor: "",
            PaymentType: "",
            PaymentDate: "",
            PaymentDetailsID: [],
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setColoredRow(null)
        setSortConfig({ SortColumn: null, SortOrder: null })
        setCheckAll(false)
        setShowTable(false);
        setShowButton(false)
    }
    const handleReset = () => {
        setModalform({
            PayoutID: "",
            PayoutCode: "",
            TranscationID: "",
            TranscationDate: new Date(),

        });
    }

    const handleChange1 = (event, value) => {
        getVendorPayout(value);
    };

   
    const handleCheck = (id, OrderNo) => {
        setCheckAll(false);
        let _data = tableData.Data.map((item) => {
            if (item.Checkbox === OrderNo) {
                return { ...item, checkStatus: id };
            } else {
                return { ...item };
            }
        });
        setTableData({ ...tableData, Data: _data });

        let updatedOrderNoList = [...formData.PaymentDetailsID.toString().split(',')];
        if (id === true) {
            updatedOrderNoList.push(OrderNo);
        } else {
            let index = updatedOrderNoList.indexOf(OrderNo.toString());
            if (index !== -1) {
                updatedOrderNoList.splice(index, 1);
            }
        }
        const paymentDetailsIDString = updatedOrderNoList.filter(item => item !== "").join(',');
        setFormData({ ...formData, PaymentDetailsID: paymentDetailsIDString });

        setShowButton(updatedOrderNoList.length > 0);
    };
   // let status = VendorCode.toString().includes('V')

    const VendorPayoutProcess = async () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source,
            PaymentDetailsID: formData.PaymentDetailsID,
        }
        post(`${API_SERVER}/api/OrderManagement/VendorPayoutProcess`, { ...data, }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                getVendorPayout()
                setColoredRow(null)
                setEdit(false);
                handleClear();
                setFormData({ ...formData, PaymentDetailsID: [] });
                setShowButton(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
                setFormData({ ...formData, PaymentDetailsID: [] });
                setShowButton(false)
            }
            setIsLoading(false);
        })
    };


    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    //const getVendorAfterSpilt = (value) => {
    //    const test = value.split(" : ")[0];

    //    setFormData(pre => ({ ...pre, Vendor:test }))

    //};
    const handleUpdatePayoutUpdate = async (id) => {
        setIsModalOpen2(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            PayoutCode: PayoutCode
        }
        post(`${API_SERVER}/api/OrderManagement/UpdatePayoutProcess`, { ...modalForm, ...data, PayoutId }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                setIsModalOpen2(false)
                AlertBox(res.status, res.message, res.focus);

                handleClear()

            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const hndleVerification = (PayoutID,ProcessPayoutCode) => {
        setIsModalOpen2(true)
        setPayoutId(PayoutID)
        setPayoutCode(ProcessPayoutCode)
    };
    useEffect(() => {
        getVendorPayout(currentPageNo, 'P');
    }, []);

    useEffect(() => {
        document.title = 'Vendor Payout'
        setFocus("txtVendor")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [sortConfig])


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">{MenuText}</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">
                                    <div className="row align-items-top">
                                        <div className="col-lg-12 row">
                                            <div className="col-lg-4">
                                                <AutoCompleteBox
                                                    label='Vendor'
                                                    inputValue={formData.Vendor ? formData.Vendor : ''}
                                                    divclassname='col-12 fields'
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetVendor', '');
                                                        }}
                                                    maxLength='50'
                                                    options={autoCompleteOptions}
                                                    disabled={VendorName}
                                                    placeholder="Vendor"
                                                    setOptions={setAutoCompleteOptions}
                                                    onChange={(e, newInputValue) => {
                                                        // getVendorAfterSpilt(newInputValue);

                                                    }}
                                                    id='txtVendor'
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtPaymentType") };
                                                    }}
                                                />
                                                <SelectInputBox
                                                    id='txtPaymentType'
                                                    divclassname='col-12 fields'
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtPaymentDate")
                                                        };
                                                    }}
                                                    label='Payment Type'
                                                    value={formData.PaymentType}
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData, PaymentType: e.target.value,
                                                        })
                                                    }}
                                                    inputclassname='p-1'
                                                >
                                                    <option value=''>--Select--</option>
                                                    <option value='COD'>COD</option>
                                                    <option value='Online'>Online</option>
                                                </SelectInputBox>
                                                <DateBox
                                                    id='txtPaymentDate'
                                                    label='Payment Date'
                                                    divclassname='col-12 fields'
                                                    placeholder="Payment  Date"
                                                    selected={formData.PaymentDate === '' ? '' : new Date(formData.PaymentDate)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setFormData({ ...formData, PaymentDate: dateArr })
                                                    }}
                                                    maxDate={new Date()}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("submit") };
                                                    }}
                                                />
                                           
                                            </div>
                                            <div className="col-lg-8">
                                                <TableVendor
                                                    Token={Token}
                                                    PageName='VendorPayout'
                                                    handleFilter={() => {
                                                        setFilterData({
                                                            ...filterData,
                                                        });
                                                    }}
                                                    handleVerification={hndleVerification}
                                                    handleDetails={getVendorDetails}
                                                    Filter={false}
                                                    tableData={PayoutData}
                                                    handleCheck={handleCheck}
                                                    filterData={filterData}
                                                    setFilterData={setFilterData}
                                                    currentPageNo={currentPageNo}
                                                    handleChange1={handleChange1}
                                                    handleSorting={handleSorting}
                                                    sortConfig={sortConfig}
                                                />
                                                <Modal show={isModalOpen2} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen2(false,)} size="lg">
                                                    <Modal.Header closeButton>
                                                        <Modal.Title><div className="d-flex gap-2"><h6>Update Payment Payout</h6></div></Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="container-fluid">
                                                            <div className='row'>
                                                                <InputBox
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            setFocus("")
                                                                        };
                                                                    }}
                                                                    divclassname="col col-lg-3  fields"
                                                                    label='Payout Code'
                                                                    id='txtPayoutCode'
                                                                    maxLength='20'
                                                                    placeholder="Payout Code"
                                                                    value={PayoutCode}
                                                                    disabled
                                                                    required
                                                                />
                                                                <InputBox
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            setFocus("")
                                                                        };
                                                                    }}
                                                                    divclassname="col col-lg-3  fields"
                                                                    label='Transaction ID'
                                                                    id='txtTransactionID'
                                                                    maxLength='20'
                                                                    // disabled={modalForm.PayoutID}
                                                                    placeholder="Transaction ID"
                                                                    value={modalForm.TranscationID}
                                                                    onChange={(e) => setModalform({ ...modalForm, TranscationID: e.target.value })}
                                                                    required
                                                                />
                                                                <DateBox
                                                                    id='txtTransactionDate'
                                                                    label='Transaction Date'
                                                                    divclassname='col-lg-3 fields'
                                                                    placeholder="Transaction  Date"
                                                                    required={true}
                                                                    selected={modalForm.TranscationDate === '' ? '' : new Date(modalForm.TranscationDate)}
                                                                    onChange={(e) => {
                                                                        let dateArr = e?.toString()?.split(' ')
                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                        setModalform({ ...modalForm, TranscationDate: dateArr })
                                                                    }}
                                                                    maxDate={new Date()}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("txtAmount") };
                                                                    }}
                                                                />
                                                                <InputBox
                                                                    label="Amount"
                                                                    placeholder="Amount"
                                                                    divclassname='col-lg-3 fields'
                                                                    id="txtAmount"
                                                                    type="text"
                                                                    maxLength='15'
                                                                    value={modalForm.Amount}
                                                                    onChange={(e) => {
                                                                        let { value } = e.target;
                                                                        const amount = value.replace(/[^0-9.]/g, '');
                                                                        setModalform({ ...modalForm, Amount: amount });
                                                                    }}
                                                                    required
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') { setFocus("") };
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer >
                                                        <div className='mb-2 d-md-flex gap-2 ' style={{ display: "flex", justifyContent: "flex-start" }}>
                                                            <button type="button" className="btn" data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                                                                onClick={() => handleUpdatePayoutUpdate(PayoutData)
                                                                }
                                                            > <i className="fa">&#xf0c7;</i> Update</button>

                                                            <button type="button" className="btn" style={{ margin: '5px', minWidth: '30px', backgroundColor: '#DD3A44', color: 'white', borderColor: '#DD3A44' }} onClick={handleReset}  > <i className="bi bi-arrow-clockwise" /> Reset </button>

                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                                <Modal size="xl" show={isModalOpen} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen(false)} >
                                                    <Modal.Header closeButton >
                                                        <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Payout Details</h6></Modal.Title>
                                                        <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">
                                                            {`${ModalData?.TotalRecord !== undefined ? 'Total Records : ' + ModalData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                                        <span></span>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-body p-0" style={{ padding: '0px' }}>
                                                            {ModalData && ModalData ?
                                                                <>
                                                                    <div className="form-box p-0  me-3 " >
                                                                        <div className="row  details-tableAwb" >
                                                                            <ReportTable
                                                                                Token={Token}
                                                                                PageName='VendorPayout'
                                                                                handleFilter={() => {
                                                                                    setFilterData({
                                                                                        ...filterData,
                                                                                    });
                                                                                }}
                                                                                Filter={false}
                                                                                tableData={ModalData}
                                                                                filterData={filterData}
                                                                                setFilterData={setFilterData}
                                                                                currentPageNo={currentPageNo}
                                                                                handleChange1={handleChange1}
                                                                           
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                        </div>

                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className='mb-2 d-md-flex gap-2'>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-12 mt-2 ms-3 pb-4">
                                    <div className="lms-new-button">

                                        <button className="button search-btn" id="submit" onClick={() => {
                                            getVendorPayout(undefined, 'S')
                                            setShowTable(true)
                                        }}>
                                            <i className="fa-solid fa-magnifying-glass" ></i> Search
                                        </button>
                                        <button className="button reset-btn" onClick={() => handleClear()}>
                                            <i className="bi bi-arrow-clockwise" /> Reset
                                        </button>
                                        {showButton && < button className="button Process-btn" onClick={() => VendorPayoutProcess()}>
                                            <IoMdSync /> Payout Process
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {showTable && (
                    <Table
                        Token={Token}
                        PageName='VendorPayout'
                        handleFilter={() => {
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        Filter={false}
                        tableData={tableData}
                        handleCheck={handleCheck}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}

                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default VendorPayout