import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import config, { API_SERVER } from '../../EnvConfig/env_config';
import { setFocus } from '../Common/Common';
import { AlertBox } from '../Common/Input';
import { post } from '../Common/service';
import Loader from './Loader';
import './LoginCSS.css';
import PasswordChecklist from "react-password-checklist";
import { FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';

const cookies = new Cookies();
export default function SignIn() {
    const StaticToken = config.secret_key;
    const source = 'web'

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState({ userCode: '', password: '' });
    let [state, setState] = useState(false);
    let baseUrl;
    if (window.location.hostname.indexOf("react.") >= 0) {
        baseUrl = `${window.location.protocol}//${window.location.host}/${window.location.pathname.split('/')[1]}`;
    } else {
        baseUrl = `${window.location.protocol}//${window.location.host}`;
    }
    //console.log(baseUrl, "76438364868368");
    const loginTypeUrl = baseUrl;
    const handleLogin = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const data = {
            Token: StaticToken,
            UserCode: user.userCode !== '' ? user.userCode : '',
            Password: user.password,
            Source: source,
            LoginTypeUrl: loginTypeUrl
        }
        post(`${API_SERVER}/api/UserLogin/Login`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                cookies.set('token', res.token);
                cookies.set('loginUserCode', res.loginUserCode);
                cookies.set('userName', res.userName);
                cookies.set('salesPersonCode', res.salesPersonCode);
                cookies.set('warehouseCode', res.warehouseCode);
                cookies.set('warehouseName', res.warehouseName);
                cookies.set('loginType', res.loginType);
                cookies.set('userGroupName', res.userGroupName);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate(location.state || '/Dashboard')
                    localStorage.setItem('ID', "0")
                }, 700)
            } else {
                setState(false);
                setIsLoading(false);
                AlertBox(res.status, res.message, res.focus);
            }
        })
    };
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    useEffect(() => {
        setFocus("txtUserCode");
        const cookies = new Cookies();
        cookies.remove('token')
        cookies.remove('loginUserCode')
        cookies.remove('userName')
        cookies.remove('CookieConsentByFineshop')
        cookies.remove('csrftoken')
        cookies.remove('salesPersonCode')
        cookies.remove('warehouseCode')
        cookies.remove('warehouseName')
        cookies.remove('loginType')
        cookies.remove('userGroupName')
    }, [])

    return (
        <>
            <div className='login_main'>

                <div className='dot_shape position-absolute'>
                    <img src="./assets/img/dot.png" alt="shape" />
                </div>
                <div className='india_clip position-absolute'>
                </div>
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-4 col-md-6 col-sm-9">
                            <div className="fxt-column-wrap justify-content-center">
                                <div className='form-container '>
                                    <div className='text-center mb-5'>
                                        {loginTypeUrl.startsWith('https://seller') || loginTypeUrl.startsWith('https://localhost')? (

                                            <img src="./assets/img/Final_indo_logo_seller.png" style={{ width: '200px' }} alt="Seller" />

                                        ) :
                                            <img src="./assets/img/indocraft.png" style={{ width: '200px' }} alt="Admin" />
                                        }
                                        <h4 className='mt-4 fw-bold'>Login into account</h4>
                                        <p className='text-black-50'>Use your credentials to access your account.</p>
                                    </div>
                                    <div class="form-group mb-3 position-relative">
                                        <input
                                            id="txtUserCode"
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            name='userCode'
                                            placeholder='User Code / Mobile No'
                                            maxLength='50'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtPassword") };
                                            }}
                                            value={user.userCode}
                                            onChange={(e) => {
                                                setUser({ ...user, userCode: e.target.value })
                                            }} />
                                        <div className='input_icon'>
                                            <FaUser />
                                        </div>
                                    </div>
                                    <div class="form-group mb-3 position-relative">
                                        <input
                                            id="txtPassword"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleLogin(e)
                                                };
                                            }}
                                            type={showPassword.password ? 'text' : 'password'}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder='Password'
                                            maxLength='20'
                                            name='Password'
                                            value={user.password}
                                            onChange={(e) => setUser({ ...user, password: e.target.value })} />
                                        <span style={{ position: "absolute", right: "22px", top: "50%", transform: 'translateY(-58%)', cursor: "pointer" }} onClick={() => handleTogglePassword('password')}>
                                            {showPassword.password ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                        <div className='input_icon'>
                                            <FaLock />
                                        </div>
                                    </div>
                                    {/*{user.password !== '' && <>*/}
                                    {/*    <PasswordChecklist*/}
                                    {/*        rules={["minLength", "number", "lowercase", "capital", "specialChar",]}*/}
                                    {/*        minLength={8}*/}
                                    {/*        style={{ color: "black" }}*/}
                                    {/*        value={user.password}*/}
                                    {/*        iconSize='10'*/}
                                    {/*        messages={{*/}
                                    {/*            minLength: "Password has at least 8 caracteres.",*/}
                                    {/*            number: "Password has at least a number.",*/}
                                    {/*            lowercase: "Password has at least lowercase letter.",*/}
                                    {/*            capital: "Password has at least uppercase letter.",*/}
                                    {/*            specialChar: "Password has at least a special character.",*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</>}*/}
                                    <div className='d-flex justify-content-between login-trouble mb-3' >
                                        <small><Link to='/forgotPassword' style={{ textDecoration: "underline" }} >Forgot Password?</Link>  </small>
                                    </div>
                                    <div className="py-2 loading">
                                        <LoadingButton loading={state} disabled={isLoading} style={{ background: "var(--mycolorFilterCheckText)", borderRadius: '30px' }} loadingPosition='center' onClick={handleLogin} className=' py-2 w-100' variant="contained"> Login </LoadingButton>
                                        {isLoading && (<Loader text='AUTHENTICATING' size='100px' img_path='./assets/img/giphy.gif' />)}
                                    </div>
                                    <br />
                                    {(loginTypeUrl.startsWith('https://seller') || loginTypeUrl.startsWith('https://localhost')) && (
                                        <div className="d-flex justify-content-between login-trouble mb-3">
                                            <small>
                                                Don't have an account?{' '}
                                                <a
                                                    href="https://www.indocraftworld.com/signup"
                                                    style={{ textDecoration: 'underline' }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Create a new account.
                                                </a>
                                            </small>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}





