

import { Cookies } from 'react-cookie';
import { useEffect, useState} from "react";
import { ProductHeader, setFocus } from "../Common/Common";
import { post } from "../Common/service";
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from '../Common/Table';
import { handleDownloadExcel } from '../Common/CommanServices';
const cookies = new Cookies();

const GroupMasterForm = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [ColoredRow, setColoredRow] = useState(null);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [filterList, setFilterList] = useState([]);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [showForm, setShowForm] = useState(false);
    const [showgrid, setShowGrid] = useState(true)
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [MenuText, setMenuText] = useState("Group Master");
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [formData, setFormData] = useState({
        UserGroupID: '',
        GroupName: '',
        Active: true
    });
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }


    };
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getGroupMasterData = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/User/ShowUserGroupMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === 'SUCCESS') {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status==="ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        })
    }

    

    const handleClear = () => {
        setFormData({
            GroupName: '',
            UserGroupID: '',
            Active: true,
        })
        filterData.SearchText = ''
        setColoredRow(null)
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/User/InsertUpdateUserGroupMaster`, { ...data,...formData }, (res) => {
            if (res.status === "SUCCESS") {
                setIsLoading(false);
                AlertBox(res.status, res.message, res.focus);

                handleClear();
                setIsLoading(false)
                setEdit(false);
                setColoredRow(null)
                setShowForm(false)
                setShowGrid(true)
                getGroupMasterData();
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                setIsLoading(false)
                AlertBox(res.status, res.message, res.focus);
            }
        })
        setIsLoading(false)
    };

    const handleEditData = async (id) => {
        post(`${API_SERVER}/api/User/EditUserGroupMaster`, { ...data, UserGroupID: id }, (res) => {

            setEdit(true)
            if (res.status === 'SUCCESS') {
                setColoredRow(id)
                setFormData({ ...formData, UserGroupID: id, GroupName: res.groupName, Active: res.active });
                setShowGrid(false)
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

            }
        })
    }
    const handleDelete = async (id) => {
        post(`${API_SERVER}/api/User/DeleteUserGroupMaster`, { ...data, UserGroupID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);

            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

            }
            handleClear()
            getGroupMasterData();
        })
       
    }
    //let PageName = 'GroupMaster'
    const getSearchingList = async () => {
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data, PageName: 'GroupMaster' }, (res) => {
            if (res.status === 'SUCCESS') {
                let list = res.getSearchingList
                setFilterData({ ...filterData, SearchFieldValue: list[0].searchingValue })
                setFilterList(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

            }

        })
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleChange1 = (event, value) => {
        getGroupMasterData(value);
    };
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    useEffect(() => {
        document.title = 'Group Master'
        getSearchingList()
        setFocus("txtGroupName")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])
    useEffect(() => {
        getGroupMasterData()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtUserCode");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/User/ShowUserGroupMaster', ExportRequest, 'Group Master')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Group Master'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">

                                                <InputBox
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("activeCheckbox")
                                                        };
                                                    }}
                                                    label='Group Name'
                                                    id='txtGroupName'
                                                    maxLength='30'
                                                placeholder="Group Name"
                                                value={formData.GroupName}
                                                onChange={(e) => setFormData({ ...formData, GroupName: e.target.value })}
                                                    required
                                                />


                                                <div className="col-lg-3 col-md-4 col-sm-6">
                                                    <div className="form-check form-switch">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="activeCheckbox"
                                                        >
                                                            Active
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="activeCheckbox"
                                                            name='Active'
                                                            checked={formData.Active}
                                                            onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleSubmit()
                                                                };
                                                            }}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="lms-new-button text-center">
                                                        {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                            Please wait...
                                                        </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                            <i className="bi bi-check-lg" /> Update
                                                        </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                            Please wait...
                                                        </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                            <i className="bi bi-check-lg" /> Save
                                                        </button>)}

                                                        <button className="button reset-btn" onClick={handleClear}>
                                                            <i className="bi bi-arrow-clockwise" /> Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                }
                    </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                <Table
                    Token={Token}
                    PageName='GroupMaster'
                    handleEdit={handleEditData}
                    handleDelete={handleDelete}
                    handleFilter={() => {
                        getGroupMasterData();
                        setFilterData({
                            ...filterData,
                        });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                    //  loading={loading}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            
            </main>
        </>
    )
}
export default GroupMasterForm