import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, } from '../Common/Input';
import { post } from '../Common/service';
import Loader from '../Layout/Loader';
import * as React from 'react';
import { TableBrand } from '../Common/Table'
import Swal from 'sweetalert2';

const ProductVerification = ({ open }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        PageSize: "20",
        "AdvanceFilter": []
    })
    const [globaltabtype, setGlobaltabtype] = useState('ALL')
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getProductVerification = async (CurrentPage, tabType) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        post(`${API_SERVER}/api/Master/ShowProductVerification`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
        });
    }
    const handleTabClick = (s) => {
        setGlobaltabtype(s)
        getProductVerification(currentPageNo, s)
    } 
    const handleAccept = (s) => {
        setGlobaltabtype(s)
        getProductVerification(currentPageNo, s)
    } 
    const PreviewProduct = (ProductUrl) => {
        window.open(ProductUrl, '_blank')
    }
    const [checkId, setCheckId] = useState('')
    const [productCodeVerify, setproductCodeVerify] = useState([])
    const [allChecked, setAllChecked] = useState(false);
    const handleAllCheckBoxaction = (status) => {
        if (status) {
            const allProductCode = tableData?.Data?.map((e) => e.ProductCode)
            setproductCodeVerify(allProductCode)
        } else {
            setproductCodeVerify([])
        }   
    }
    const handleVerifyProductsApi = async () => {
        if (productCodeVerify.length === 0) {
            Swal.fire({
                text: "Please Select Product",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#d33",
                allowOutsideClick: true,
            })
            return;
        }
        await Swal.fire({
            text: "Do you want to Verify Product?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes Verify!",
        }).then(async(result) => {
            if (result.isConfirmed) {
                const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
                const requestData = {
                    Token: cookies.get('token'),
                    LoginUserCode: loginCodeString,
                    Source: 'web',
                    ProductID: productCodeVerify.join(','),
                    Status: "Accept",
                    Reason:"",
                }
              
                setIsLoading(true);
                await post(`${API_SERVER}/api/Master/VerifyProduct`, { ...requestData }, (res) => {
                    if (res.status === "SUCCESS") {
                        AlertBox(res.status, res.message, res.focus);
                        getProductVerification(currentPageNo, globaltabtype);
                        var ab = document.getElementById(`row${checkId}VerifyCheckBox`)
                        ab.checked = false;
                        setAllChecked(false);
                    } else if (res.message === "EXPIRED") {
                        navigate('/Login');
                    } else {
                        AlertBox(res.status, res.message, res.focus);
                    }
                    setIsLoading(false);
                });
            }
        });
      
    }
    const handleRejectProductApi = async () => {
        Swal.fire({
            title: "Are you sure?",
            input: "text",
            inputLabel: "Want to reject it .!",
            inputPlaceholder: 'Enter Remarks',
            inputAttributes: {
                maxlength: "250",
                autocapitalize: "off",
                autocorrect: "off",
                autocomplete: "off",
            },
            icon: "warning",
            showCancelButton: true,
            returnFocus: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            keydownListenerCapture: true,
            stopKeydownPropagation: false,
            inputValidator: (value) => {
                if (!value) {
                    return "Please Enter Remarks .!";
                }
            },
            didOpen: () => {
                document.addEventListener('keydown', function (event) {
                    if (event.key === 'Enter') {
                        Swal.clickConfirm();
                    } else if (event.key === 'Escape') {
                        Swal.clickCancel();
                    }
                });
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const inputValue = result.value; 
                const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
                const requestData = {
                    Token: cookies.get('token'),
                    LoginUserCode: loginCodeString,
                    Source: 'web',
                    ProductID: productCodeVerify.join(','),
                    Status: "REJECTED",
                    Reason: inputValue,
                }
                setIsLoading(true);
                await post(`${API_SERVER}/api/Master/VerifyProduct`, { ...requestData }, (res) => {
                    if (res.status === "SUCCESS") {
                        AlertBox(res.status, res.message, res.focus);
                        getProductVerification(currentPageNo, globaltabtype);
                        var ab = document.getElementById(`row${checkId}VerifyCheckBox`)
                        ab.checked = false;
                        setAllChecked(false);
                    } else if (res.message === "EXPIRED") {
                        navigate('/Login');
                    } else {
                        AlertBox(res.status, res.message, res.focus);
                    }
                    setIsLoading(false);
                });
            } else {
                //NotificationSound('info', 'Bill verification cancelled.');
            }
        });
    }

    const handleVerifyProducts = async (status, item) => {
        setCheckId(item.ProductCode)
       // console.log(item.ProductCode, "itemitem")
        if (status) {
            const temp = [...productCodeVerify, item.ProductCode]
            setproductCodeVerify(temp)

        } else {
            const temp = productCodeVerify.filter((e) => e !== item.ProductCode)
            setproductCodeVerify(temp)
        }     
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    const handleChange1 = (event, value) => {
        getProductVerification(value);
    };
    
    useEffect(() => {
        document.title = 'Manage Customer'
        getProductVerification()
    }, [sortConfig, refreshGrid])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">Product Verification</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TableBrand
                Token={Token}
                PageName='ProductVerification'
                handleTabClick={handleTabClick}
                handleFilter={() => {
                    getProductVerification(currentPageNo, globaltabtype);
                    setFilterData({
                        ...filterData,
                    });
                }}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                handleAccept={handleAccept}
                handleRejectProductApi={handleRejectProductApi}
                tableData={tableData}
                filterData={filterData}
                refreshGrid={refreshGrid}
                setRefreshGrid={setRefreshGrid}
                setFilterData={setFilterData}
                currentPageNo={currentPageNo}
                handleChange1={handleChange1}
                handleSorting={handleSorting}
                sortConfig={sortConfig}
                PreviewProduct={PreviewProduct}
                handleVerifyProducts={handleVerifyProducts}
                allCheckBoxaction={true}
                allChecked={allChecked} 
                handleAllCheckBoxaction={handleAllCheckBoxaction }
                handleVerifyProductsApi={handleVerifyProductsApi }
            />
            {isLoading && <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' />}
           
        </main>
    )
}
export default ProductVerification;