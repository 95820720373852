import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import React from 'react';

const GenrateImage = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [formData, setFormData] = useState({
        ProductMultimediaID: "",
        bulkImageList: [],
        videoLink: "",  
    });
    const [showTable, setShowTable] = useState(false);
    const [images, setImages] = useState([]);
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    const handleClear = () => {
        setFormData({
            ProductMultimediaID: "",
            bulkImageList: [],
            videoLink: "", 
        });
        setImages([]);
        setShowTable(false);
        setTableData([]);
        const fileInput = document.getElementById('fileinput');
        if (fileInput) fileInput.value = "";
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        const newImages = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let extArr = file.name.split(".");
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    let logo = reader.result.split(',')[1];
                    newImages.push({
                        imgName: extArr[0],
                        imgExt: extArr[1],
                        img: logo,
                        isPrimaryImage: false,
                        type: 'Image',
                        sequence: `${i + 1}`
                    });

                    if (newImages.length === files.length) {
                        setImages(prevImages => [...prevImages, ...newImages]);
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            bulkImageList: [...prevFormData.bulkImageList, ...newImages]
                        }));
                    }
                };
            };
        }
    };

    const handleDeleteImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
        setFormData(prevFormData => ({
            ...prevFormData,
            bulkImageList: updatedImages
        }));

        // Update the file input
        const fileInput = document.getElementById('fileinput');
        const dataTransfer = new DataTransfer();
        updatedImages.forEach(image => {
            const file = dataURLtoFile(`data:image/${image.imgExt};base64,${image.img}`, `${image.imgName}.${image.imgExt}`);
            dataTransfer.items.add(file);
        });
        fileInput.files = dataTransfer.files;
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            bulkImageList: images,
            videoLink: formData.videoLink,  // Include video link in the data
            Token,
            LoginUserCode: loginCodeString,
            Source,
        };
        post(`${API_SERVER}`, { ...data, productCode: formData.Product }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                handleClear();
            } else if (res.status === "EXPIRED") {
                navigate('/Login');
            } else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        });
    };

    const handleVideoLinkChange = (e) => {
        setFormData({ ...formData, videoLink: e.target.value });
    };

    useEffect(() => {
        document.title = 'Product Multimedia';
        setFocus("txtCategoryName");
    }, []);

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}>
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">Genrate Image</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area">
                                    <div className="row ">
                                        <div className="col-lg-12 d-flex " id="txtPrimaryImage">
                                            <div className=" col-lg-5 col-md-5 col-sm-12 fields ">
                                                <label className="form-label">Bulk Image  <span className="text-danger" style={{ fontSize: "12px", fontWeight: "300" }}>(1000x1000 pixels For best View)</span></label>
                                                <input
                                                    className='form-control form-control-sm'
                                                    id="fileinput"
                                                    type="file"
                                                    multiple
                                                    onChange={handleImageChange}
                                                />
                                                <div id="image-preview" className="image-preview mt-3">
                                                    {images.map((image, index) => (
                                                        <div key={index} className="image-container">
                                                            <img
                                                                src={`data:image/${image.imgExt};base64,${image.img}`}
                                                                className="preview-image"
                                                                alt={`Preview ${index}`}
                                                            />
                                                            <button
                                                                type="button"
                                                                className="delete-button"
                                                                onClick={() => handleDeleteImage(index)}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className=" col-lg-5 col-md-5 col-sm-12 fields ms-2 ">
                                                <label className="form-label">YouTube Video Link</label>
                                                <input
                                                    className='form-control form-control-sm'
                                                    type="text"
                                                    value={formData.videoLink}
                                                    onChange={handleVideoLinkChange}
                                                    placeholder="Enter YouTube video link"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                        </div>
                                      
                                            

                                <div className="col-lg-12 p-4 z-0">
                                    <div className="lms-new-button text-center">
                                        <button className="button save-btn" id="submit" onClick={handleSubmit}>
                                            <i className="bi bi-check-lg" /> Upload
                                        </button>
                                        <button className="button reset-btn" onClick={handleClear}>
                                            <i className="bi bi-arrow-clockwise" /> Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {isLoading && <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' />}
            </main>
        </>
    );
}

export default GenrateImage;
