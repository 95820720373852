import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import React from 'react';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox, handleFileUploader, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { Table } from "../Common/Table";
import { DropDownData, FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";
import Modal from 'react-bootstrap/Modal';
import DragableModal from "../Common/DragableModal";
import ImageView from "../Common/ImageView";

const VendorMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [varificationID, setVarificationID] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [MenuText, setMenuText] = useState("Vendor Profile");
    const [IsGSTINVerified, setIsGSTINVerified] = useState(false);

    const [ModalData, setModalData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const Source = 'web ';
    const navigate = useNavigate();
    const fileType = 'jpg,png'
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [globaltabtype, setGlobaltabtype] = useState('ALL')
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode').toString() : '';
    const initialShowGrid = VendorCode ? false : true;
    const [resData, setResData] = useState({})
    const [showgrid, setShowGrid] = useState(initialShowGrid);
    const [formData, setFormData] = useState({
        "VendorID": "",
        "VendorName": "",
        "MobileNo": "",
        "Email": "",
        "Password": "",
        "PinCode": "",
        "Address": "",
        "Country": "",
        "State": "",
        "City": "",
        "AccountManager": "",
        "ShopName": "",
        "ShopImage": "",
        "ShopImageName": "",
        "ShopImageExt": "",
        "ShopDiscription": "",
        "GSTNumber": "",
        "AadharNo": "",
        "BankName": "",
        "AccountNo": "",
        "IFSCCode": "",
        "PanNumber": "",
        "GSTImage": "",
        "GSTImageName": "",
        "GSTImageExt": "",
        "PanImageFront": "",
        "PanImageFrontExt": "",
        "PanImageFrontName": "",
        "BankImageFront": "",
        "BankImageFrontExt": "",
        "BankImageFrontName": "",
        "SignatureImage": "",
        "SignatureImageExt": "",
        "SignatureImageName": "",
        "AdharImageFront": "",
        "AdharImageFrontName": "",
        "AdharImageFrontExt": "",
        "AdharImageBack": "",
        "AdharImageBackName": "",
        "AdharImageBackExt": "",
        "IsVerified": false,
        "Declaration": false,
        "UpdatedFields": "",
    });

    const [isVisible, setIsVisible] = useState(true);

    // Function to handle the close button click
    const handleClose = () => {
        setIsVisible(false);
    };

    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [model, setModel] = useState({
        "VerificationRemark": "",
        "IsVerified": false,
        "IsActive": false
    })
    const getModalVendorVerification = async (VendorID) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            VendorID: VendorID
        }
        post(`${API_SERVER}/api/Master/GetDataForVendorVerification`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setModel({
                    IsActive: res.isActive,
                    IsVerified: res.isVerified
                })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);

            }
        });
    }
    const getVendorMaster = async (CurrentPage, tabtype) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowVendorMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}`, TabType: tabtype }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            "VendorID": "",
            "VendorName": "",
            "MobileNo": "",
            "Email": "",
            "Password": "",
            "PinCode": "",
            "Address": "",
            "Country": "",
            "State": "",
            "City": "",
            "AccountManager": "",
            "ShopName": "",
            "ShopImage": "",
            "ShopImageName": "",
            "ShopImageExt": "",
            "ShopDiscription": "",
            "GSTNumber": "",
            "BankName": "",
            "AccountNo": "",
            "IFSCCode": "",
            "PanNumber": "",
            "GSTImage": "",
            "GSTImageName": "",
            "AadharNo": "",
            "GSTImageExt": "",
            "PanImageFront": "",
            "PanImageFrontExt": "",
            "PanImageFrontName": "",
            "BankImageFront": "",
            "BankImageFrontExt": "",
            "BankImageFrontName": "",
            "SignatureImage": "",
            "SignatureImageExt": "",
            "SignatureImageName": "",
            "AdharImageFront": "",
            "AdharImageFrontName": "",
            "AdharImageFrontExt ": "",
            "AdharImageBack": "",
            "AdharImageBackName": "",
            "AdharImageBackExt": "",
            "IsVerified": false,
            "Declaration": false,
            "UpdatedFields": "",

        });
        setIsGSTINVerified(false)
        setModel({
            "VerificationRemark": "",
            "IsVerified": false,
            "IsActive": false
        })
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getVendorMaster(value);
    };

    const hndleVerification = (id) => {
        setIsModalOpen2(true)
        setVarificationID(id)
        getModalVendorVerification(id)
    };

    const handleTabClick = (tabtype) => {
        setGlobaltabtype(tabtype)
        getVendorMaster(currentPageNo, tabtype)
    }


    const handleVerficationSave = async (id) => {
        post(`${API_SERVER}/api/Master/VendorVerification`, { ...data, ...model, VendorID: varificationID ? varificationID : '' }, async (res) => {
            if (res.status === 'SUCCESS') {
                await setIsLoading(false);
                await setIsModalOpen2(false)
                await AlertBox(res.status, res.message, res.focus);
                await getVendorMaster();
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

            }
            setIsLoading(false);
        })
    };
    const [apiStatus, setApiStatus] = useState(null);
    const [isvalidateGst, setIsValidateGst] = useState('')

    const handleBlur = async () => {
        post(`${API_SERVER}/api/ECOMAPI/GSTVerification`, { gstin: formData.GSTNumber }, (res) => {
            if (res.status === "SUCCESS") {
                setApiStatus('success');
                setResData({ ...res.data })
                setIsGSTINVerified(true)
                setIsVisible(true)
            } else if (res.status === "ERROR") {
                setApiStatus('error');
                setIsValidateGst('txtGSTNumber')
                setIsGSTINVerified(false)
            }
        });

    };

    const handleSubmit = async () => {
        //if (!formData.Declaration) {
        //    AlertBox("Error", "Please check the Declaration", "txtDeclaration");
        //    return;
        //}
        //if (apiStatus !== 'success') {
        //    AlertBox("Error", "Please enter a valid GSTIN Number", "txtGSTNumber");
        //    return;
        //}
        post(`${API_SERVER}/api/Master/InsertUpdateVendorMaster`, { ...data, ...formData, Token, IsGSTINVerified }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                if (VendorCode) {

                }
                else {
                    handleClear()
                }
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
                setApiStatus('success')
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                if (res.status === 'ERROR' && res.focus === 'txtGSTNumber') {
                    setApiStatus('error')
                    setIsValidateGst('txtGSTNumber')
                }
                setApiStatus(null)
                setIsValidateGst('')
            }
            setIsLoading(false);
        })
    };
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditVendorMaster`, { ...data, VendorID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                if (VendorCode) {
                    setShowForm(true)
                    setShowGrid(false)
                    setIsGSTINVerified(res.isGSTINVerified)
                    setFormData({
                        ...formData,
                        "VendorID": res.vendorID,
                        "VendorName": res.vendorName,
                        "MobileNo": res.mobileNo,
                        "Email": res.email,
                        "Password": res.password,
                        "PinCode": res.pinCode,
                        "Address": res.address,
                        "Country": res.country,
                        "State": res.state,
                        "City": res.city,
                        "AccountManager": res.accountManager,
                        "ShopName": res.shopName,
                        "ShopImage": res.shopImage,
                        "ShopImageName": res.shopImageName,
                        "ShopImageExt": res.shopImageExt,
                        "ShopDiscription": res.shopDiscription,
                        "GSTNumber": res.gstNumber,
                        "BankName": res.bankName,
                        "AccountNo": res.accountNo,
                        "IFSCCode": res.ifscCode,
                        "PanNumber": res.panNumber,
                        "GSTImage": res.gstImage,
                        "GSTImageName": res.gstImageName,
                        "GSTImageExt": res.gstImageExt,
                        "PanImageFront": res.panImageFront,
                        "PanImageFrontExt": res.panImageFrontExt,
                        "PanImageFrontName": res.panImageFrontName,
                        "BankImageFront": res.bankImageFront,
                        "BankImageFrontExt": res.bankImageFrontExt,
                        "BankImageFrontName": res.bankImageFrontName,
                        "SignatureImage": res.signatureImage,
                        "SignatureImageExt": res.signatureImageExt,
                        "SignatureImageName": res.signatureImageName,
                        "AdharImageFront": res.adharImageFront,
                        "AdharImageFrontName": res.adharImageFrontName,
                        "AdharImageFrontExt ": res.adharImageFrontExt,
                        "AdharImageBack": res.adharImageBack,
                        "AdharImageBackName": res.adharImageBackName,
                        "AdharImageBackExt": res.adharImageBackExt,
                        "IsVerified": res.isVerified,
                        "IsActive": res.isActive,
                        "AadharNo": res.aadharNo

                    });
                }
                setIsGSTINVerified(res.isGSTINVerified)
                setFormData({
                    ...formData,
                    "VendorID": res.vendorID,
                    "VendorName": res.vendorName,
                    "MobileNo": res.mobileNo,
                    "Email": res.email,
                    "Password": res.password,
                    "PinCode": res.pinCode,
                    "Address": res.address,
                    "Country": res.country,
                    "State": res.state,
                    "City": res.city,
                    "AccountManager": res.accountManager,
                    "ShopName": res.shopName,
                    "ShopImage": res.shopImage,
                    "ShopImageName": res.shopImageName,
                    "ShopImageExt": res.shopImageExt,
                    "ShopDiscription": res.shopDiscription,
                    "GSTNumber": res.gstNumber,
                    "BankName": res.bankName,
                    "AccountNo": res.accountNo,
                    "IFSCCode": res.ifscCode,
                    "PanNumber": res.panNumber,
                    "GSTImage": res.gstImage,
                    "GSTImageName": res.gstImageName,
                    "GSTImageExt": res.gstImageExt,
                    "PanImageFront": res.panImageFront,
                    "PanImageFrontExt": res.panImageFrontExt,
                    "PanImageFrontName": res.panImageFrontName,
                    "BankImageFront": res.bankImageFront,
                    "BankImageFrontExt": res.bankImageFrontExt,
                    "BankImageFrontName": res.bankImageFrontName,
                    "SignatureImage": res.signatureImage,
                    "SignatureImageExt": res.signatureImageExt,
                    "SignatureImageName": res.signatureImageName,
                    "AdharImageFront": res.adharImageFront,
                    "AdharImageFrontName": res.adharImageFrontName,
                    "AdharImageFrontExt ": res.adharImageFrontExt,
                    "AdharImageBack": res.adharImageBack,
                    "AdharImageBackName": res.adharImageBackName,
                    "AdharImageBackExt": res.adharImageBackExt,
                    "IsVerified": res.isVerified,
                    "IsActive": res.isActive,
                    "AadharNo": res.aadharNo
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}`, { ...data, VendorID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }

    const handleDetails = async (VendorCode) => {
        setIsModalOpen(true)
        post(`${API_SERVER}/api/Master/ShowVendorDetails`, { ...data, VendorCode: VendorCode }, (res) => {
            if (res.status === "SUCCESS") {
                setModalData({ ...res })
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
        });
    };

    const autoCompleteTimeoutRef = useRef(null);
    //const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
    //    setFormData({ ...formData, [field]: newValue })
    //    if (autoCompleteTimeoutRef.current) {
    //        clearTimeout(autoCompleteTimeoutRef.current);
    //    }
    //    if (event?.type === 'change') {
    //        autoCompleteTimeoutRef.current = setTimeout(async () => {
    //            const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
    //            setAutoCompleteOptions(autoOptions ? autoOptions : []);
    //        },)
    //    }
    //};
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData(prevState => {
            const updatedData = { ...prevState, [field]: newValue };
            const updatedFieldsSet = new Set(prevState.UpdatedFields.split(',').filter(Boolean));
            updatedFieldsSet.add(field);
            updatedData.UpdatedFields = Array.from(updatedFieldsSet).join(',');

            return updatedData;
        });
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                try {
                    const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                    setAutoCompleteOptions(autoOptions || []);
                } catch (error) {
                    console.error("Error fetching auto-complete options:", error);
                    setAutoCompleteOptions([]);
                }
            }, 100);
        }
    };
    const handleFieldChange = (fieldName, value) => {
        setFormData(prevState => {
            const updatedFields = new Set(prevState.UpdatedFields?.split(',').filter(Boolean));
            updatedFields.add(fieldName);

            return {
                ...prevState,
                [fieldName]: value,
                UpdatedFields: Array.from(updatedFields).join(',')
            };
        });
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    let GetCountry = DropDownData('GetCountry', showForm);
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleShowImageInModal = (Image) => {
        setIsModalOpen3(true)
        setModalImg(Image)
    }

    useEffect(() => {
        document.title = 'Vendor Profile'
        if (showForm) {
            setFocus("txtCategory")
        }
        if (VendorCode) {
            handleEditData(LoginUserCode)
        }
        setFocus("txtVendorName")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])

    //const {
    //    gstin,
    //    tradeNam,
    //    lgnm,
    //    sts,
    //    rgdt,
    //    lstupdt,
    //    ctb,
    //    nba = [],
    //    pradr = {},
    //    stj,
    //    stjCd,
    //    ctjCd,
    //    ctj,
    //    einvoiceStatus,
    //} = resData;

    const handleShowFile = async (url, fileType) => {
        if (fileType === 'pdf') {
            window.open(`/PDFView/#${url}`, '_blank');
        }
        else if (fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${url}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${url}`, '_blank');
        }
    }

    useEffect(() => {

        getVendorMaster(currentPageNo, globaltabtype)
    }, [sortConfig, refreshGrid])

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                {VendorCode ?
                                    <div className="heading-box">
                                        <div className="heading">
                                            <h6 className="m-0 font-weight-bold text-primary">Vendor Profile</h6>
                                        </div>
                                    </div>
                                    :
                                    <ProductHeader
                                        onMainButtonClick={() => {
                                            toggleModal(showForm ? 'Back' : 'add');
                                            handleClear()
                                            if (!showForm) {
                                                setFocus("txtUserCode");
                                            }
                                        }}
                                        onFilterButtonClick={() => {
                                            setIsExpanded(!isExpanded)
                                        }}
                                        isExpanded={isExpanded}
                                        onExportButtonClick={() => {
                                            handleDownloadExcel(API_SERVER + '/api/Master/ShowVendorMaster', ExportRequest, 'VendorMaster')
                                        }}
                                        showForm={showForm}
                                        buttonText='Add New'
                                        heading={MenuText}
                                    />

                                }
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-top">

                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtMobileNo")
                                                    };
                                                }}
                                                label='Vendor Name'
                                                id='txtVendorName'
                                                maxLength='50'
                                                placeholder="Vendor Name"
                                                value={formData.VendorName}
                                                required
                                                onChange={(e) => handleFieldChange("VendorName", e.target.value)}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtEmail")
                                                    };
                                                }}
                                                label='Mobile No'
                                                id='txtMobileNo'
                                                maxLength='13'
                                                placeholder="Mobile No"
                                                value={formData.MobileNo}
                                                onChange={(e) => handleFieldChange("MobileNo", e.target.value.replace(/[^0-9]/g, ''))}

                                                required
                                            />

                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPassword")
                                                    };
                                                }}
                                                label='Email'
                                                id='txtEmail'
                                                maxLength='100'
                                                placeholder="Email"
                                                value={formData.Email}
                                                onChange={(e) => handleFieldChange("Email", e.target.value)}

                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAddress")
                                                    };
                                                }}
                                                label='Password'
                                                id='txtPassword'
                                                maxLength='13'
                                                placeholder="Password"
                                                value={formData.Password}
                                                onChange={(e) => handleFieldChange("Password", e.target.value)}
                                                type="password"
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPinCode")
                                                    };
                                                }}
                                                label='Address'
                                                id='txtAddress'
                                                maxLength='100'
                                                placeholder="Address"
                                                value={formData.Address}
                                                onChange={(e) => handleFieldChange("Address", e.target.value)}

                                                required
                                            />
                                            <AutoCompleteBox
                                                label='PinCode'
                                                inputValue={formData.PinCode ? formData.PinCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'PinCode', 'GetPinCode', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtPinCode'
                                                placeholder="PinCode"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCountry") };
                                                }}
                                            />

                                            <SelectInputBox
                                                id='txtCountry'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtState")
                                                    };

                                                }}
                                                label='Country'
                                                value={formData.Country}
                                                onChange={(e) => handleFieldChange("Country", e.target.value)}
                                                required={true}
                                                inputclassname='p-1'
                                            >
                                                <option value="">Select</option>
                                                {GetCountry?.data?.dataList?.map((item, index) =>
                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>

                                                )}
                                            </SelectInputBox>

                                            <AutoCompleteBox
                                                label='State'
                                                inputValue={formData.State ? formData.State : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'State', 'GetState', '');
                                                    }}
                                                maxLength='50'
                                                placeholder="State"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtState'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCity") };
                                                }}
                                            />
                                            <AutoCompleteBox
                                                label='City'
                                                inputValue={formData.City ? formData.City : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'City', 'GetStateWiseCity', formData.State);
                                                    }}
                                                maxLength='50'
                                                placeholder="City"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtCity'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtAccountManager") };
                                                }}
                                            />
                                            {!VendorCode &&
                                                <AutoCompleteBox
                                                    label='Account Manager'
                                                    inputValue={formData.AccountManager ? formData.AccountManager : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'AccountManager', 'GetAccountManager', '');
                                                        }}
                                                    maxLength='10'
                                                    options={autoCompleteOptions}
                                                    placeholder="Account Manager"
                                                    setOptions={setAutoCompleteOptions}
                                                    id='txtAccountManager'
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtBankName") };

                                                    }}
                                                />
                                            }
                                            <div className="mt-2 p-3" style={{ display:'none' }}>
                                                <textarea
                                                    id="txtUpdatedFields"
                                                    className="col-12"
                                                    placeholder="Updated Fields...."
                                                    cols={500}
                                                    value={formData.UpdatedFields}
                                                    //   onChange={(e) => { setFormData({ ...formData, ShopDiscription: e.target.value }) }}
                                                    onChange={(e) => setFormData({ ...formData, UpdatedFields: e.target.value })}
                                                    disabled={true}
                                                />
                                            </div>
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAccountNo")
                                                    };
                                                }}
                                                label='Bank Name'
                                                id='txtBankName'
                                                maxLength='30'
                                                placeholder="Bank Name"
                                                value={formData.BankName}
                                                onChange={(e) => handleFieldChange("BankName", e.target.value)}

                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtIFSCCode")
                                                    };
                                                }}
                                                label='Account No'
                                                id='txtAccountNo'
                                                maxLength='20'
                                                placeholder="Account No"
                                                value={formData.AccountNo}
                                                onChange={(e) => handleFieldChange("AccountNo", e.target.value)}

                                                required
                                            />
                                            <div className="row pe-0">
                                                <InputBox
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtGSTNumber")
                                                        };
                                                    }}
                                                    label='IFSC Code'
                                                    id='txtIFSCCode'
                                                    maxLength='30'
                                                    placeholder="IFSC Code"
                                                    value={formData.IFSCCode}
                                                    onChange={(e) => handleFieldChange("IFSCCode", e.target.value)}

                                                    required
                                                />
                                                <div className=" col-lg-3 col-md-3 col-sm-12 mt-2 ">
                                                    <div className="fields">
                                                        <label className="form-label">Bank Details</label>
                                                        <FileUploader
                                                            classes='file-uploader emp-photo'
                                                            types={fileType.split(",")}
                                                            handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'BankImageFront', 'BankImageFrontExt', 'BankImageFrontName')}
                                                            children={<FileUploadChild label='' message={formData.BankImageFrontName + '.' + formData.BankImageFrontExt} types={fileType.split(",")} />}
                                                        />
                                                    </div>
                                                    <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2 mb-3">
                                                        <div style={{ fontSize: '13px' }}>Note:</div>
                                                        <div className="notefont">
                                                            <div>* Upload Cancel cheque</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.BankImageFront ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.BankImageFrontExt};base64,${formData.BankImageFront}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.BankImageFront}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row pe-0">
                                                <div className="fields col-lg-2 col-sm-12 col-md-6">
                                                    <label className="form-label text-nowrap">GSTIN No<span className="Required">*</span>
                                                        {apiStatus === 'error' && isvalidateGst === 'txtGSTNumber' ? (
                                                            <span style={{ color: 'red', marginLeft: "2px", fontSize: "14px", whiteSpace: "nowrap" }}>✘ Unverified</span>
                                                        ) :
                                                            (apiStatus === 'success') ? (
                                                                <span style={{ color: 'green', marginLeft: "2px", fontSize: "14px", whiteSpace: "nowrap" }}>✔ Verified</span>
                                                            ) :

                                                                ''
                                                        }

                                                    </label>
                                                    <div className='col-lg-12 field'>
                                                        <input
                                                            type="text"
                                                            id="txtGSTNumber"
                                                            maxLength="20"
                                                            placeholder="GSTIN No"
                                                            value={formData.GSTNumber}
                                                            onChange={(e) => handleFieldChange("GSTNumber", e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setFocus("txtAadharNo")
                                                                };
                                                            }}
                                                            className='col-lg-12 field'
                                                        />
                                                    </div>
                                               
                                                </div>
                                                <div className="col-lg-5 col-md-12  col-sm-12 mt-3">
                                                    <button className="btn btn-success" onClick={handleBlur}>
                                                        <i className="bi bi-check-lg " style={{ textWrap: "nowrap" }} /> Gst Verification
                                                    </button>
                                                </div>


                                                <div className="fields col-lg-3 col-md-12 col-sm-12" id="txtGSTImage">
                                                    <label className="form-label ">GST Image<span className="Required">*</span></label>

                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'GSTImage', 'GSTImageExt', 'GSTImageName')}
                                                        children={<FileUploadChild label='' message={formData.GSTImageName + '.' + formData.GSTImageExt} types={fileType.split(",")} />}
                                                    />

                                                </div>
                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.GSTImage ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.GSTImageExt};base64,${formData.GSTImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.GSTImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            {apiStatus === 'success' && !VendorCode && isVisible === true ? <div className="col-lg-12 col-md-12 mt-3 col-sm-12 mb-2">
                                                <div className='Product_container'>
                                                    <div className='w-100 py-2 HeaderforProductlist px-3'>
                                                        <h6 className="m-0 font-weight-bold">{'Gst Verification Details'}</h6>
                                                        <button type="button" class="btn-close" aria-label="Close" style={{ fontSize: '30px' }} onClick={handleClose}></button>
                                                    </div>
                                                    <div className='p-4'>
                                                        <div className="form-group px-2">
                                                            <div className="form-group px-2">
                                                                <p><strong>GSTIN:</strong> {resData.gstin}</p>
                                                                <p><strong>Trade Name:</strong> {resData.tradeNam}</p>
                                                                <p><strong>Legal Name:</strong> {resData.lgnm}</p>
                                                                <p><strong>GST Status:</strong> {resData.sts}</p>
                                                                <p><strong>GST Registration Date:</strong> {resData.rgdt}</p>
                                                                <p><strong>Last Updated:</strong> {resData.lstupdt}</p>
                                                                <p><strong>Business Type:</strong> {resData.ctb}</p>
                                                                <p><strong>Category:</strong> {resData.nba}</p>
                                                                <p><strong>Address:</strong> {resData?.pradr?.addr?.bno || ''}, {resData?.pradr?.addr?.bnm || ''}, {resData?.pradr?.addr?.flno || ''}, {resData?.pradr?.addr?.loc || ''}, {resData?.pradr?.addr?.dst || ''}, {resData?.pradr?.addr?.stcd || ''} - {resData?.pradr?.addr?.pncd || ''}</p>

                                                                <p><strong>Service Type:</strong> {resData?.pradr?.ntr}</p>
                                                                <p><strong>Circle:</strong> {resData.stj}</p>
                                                                <p><strong>Circle Code:</strong> {resData.stjCd}</p>
                                                                <p><strong>CTJ Code:</strong> {resData.ctjCd}</p>
                                                                <p><strong>CTJ:</strong> {resData.ctj}</p>
                                                                <p><strong>E-Invoice Status:</strong> {resData.einvoiceStatus}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                : <></>}


                                            <div className="row pe-0">
                                                <InputBox
                                                    divclassname="col-lg-2 fields"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtPanNumber")
                                                        };
                                                    }}
                                                    label='Aadhaar No'
                                                    id='txtAadharNo'
                                                    maxLength='12'
                                                    placeholder="Aadhaar No"
                                                    value={formData.AadharNo}
                                                    onChange={(e) => handleFieldChange("AadharNo", e.target.value.replace(/[^0-9]/g, ''))}

                                                    required
                                                />
                                                <div className="fields col-lg-3 col-md-3 col-sm-12">
                                                    <label className="form-label ">Aadhaar Front</label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'AdharImageFront', 'AdharImageFrontExt', 'AdharImageFrontName')}
                                                        children={<FileUploadChild label='' message={formData.AdharImageFrontName + '.' + formData.AdharImageFrontExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.AdharImageFront ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.AdharImageFrontExt};base64,${formData.AdharImageFront}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.AdharImageFront}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                                <div className="fields col-lg-3 col-md-3 col-sm-12">
                                                    <label className="form-label ">Aadhaar Back</label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'AdharImageBack', 'AdharImageBackExt', 'AdharImageBackName')}
                                                        children={<FileUploadChild label='' message={formData.AdharImageBackName + '.' + formData.AdharImageBackExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.AdharImageBack ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.AdharImageBackExt};base64,${formData.AdharImageBack}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.AdharImageBack}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row pe-0">
                                                <InputBox
                                                    divclassname="col-lg-2 fields"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtShopName")
                                                        };
                                                    }}
                                                    label='PAN No'
                                                    id='txtPanNumber'
                                                    maxLength='30'
                                                    placeholder="Pan No"
                                                    value={formData.PanNumber}
                                                    onChange={(e) => handleFieldChange("PanNumber", e.target.value)}
                                                    required
                                                />
                                                <div className="fields col-lg-3 col-md-3 col-sm-12">
                                                    <label className="form-label ">PAN Details<span className="Required">*</span></label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'PanImageFront', 'PanImageFrontExt', 'PanImageFrontName')}
                                                        children={<FileUploadChild label='' message={formData.PanImageFrontName + '.' + formData.PanImageFrontExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.PanImageFront ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.PanImageFrontExt};base64,${formData.PanImageFront}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.PanImageFront}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                                <div className="fields col-lg-3 col-md-3 col-sm-12">
                                                    <label className="form-label ">Authorized Pan Card Signature<span className="Required">*</span></label>

                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'SignatureImage', 'SignatureImageExt', 'SignatureImageName')}
                                                        children={<FileUploadChild label='' message={formData.SignatureImageName + '.' + formData.SignatureImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>

                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.SignatureImage ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.SignatureImageExt};base64,${formData.SignatureImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.SignatureImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row pe-0">
                                                <InputBox
                                                    divclassname="col-lg-2 fields"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtShopDescription")
                                                        };
                                                    }}
                                                    label='Shop Name'
                                                    id='txtShopName'
                                                    maxLength='30'
                                                    placeholder="Shop Name"
                                                    value={formData.ShopName}
                                                    onChange={(e) => handleFieldChange("ShopName", e.target.value)}
                                                    required
                                                />
                                                <div className="fields col-lg-3 col-md-3 col-sm-12">
                                                    <label className="form-label ">Shop Image<span className="Required">*</span></label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'ShopImage', 'ShopImageExt', 'ShopImageName')}
                                                        children={<FileUploadChild label='' message={formData.ShopImageName + '.' + formData.ShopImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>

                                                <div className='col-md-2 mt-3' style={{ position: "relative" }}>
                                                    {formData.ShopImage ?
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.ShopImageExt};base64,${formData.ShopImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.ShopImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                        />
                                                        : <></>
                                                    }

                                                </div>
                                                <div className="fields col-lg-5 col-md-5 col-sm-12">
                                                    <label className="form-label">Shop Description</label>

                                                    <textarea
                                                        id="txtShopDescription"
                                                        className="col-12"
                                                        placeholder="Shop Description"
                                                        cols={500}
                                                        value={formData.ShopDiscription}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, ShopDiscription: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("") };
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                    <div className={"form-check pt-4"} style={{ border: "1px dashed #ff6d6d", borderRadius: "10px", padding: "20px 0px 20px 55px" }}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="txtDeclaration"
                                                            name='Active'
                                                            checked={formData.Declaration}
                                                            onChange={(e) => handleFieldChange("Declaration", e.target.checked)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleSubmit();
                                                                };
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="txtDeclaration"
                                                        >
                                                            I hereby declare that the information given above and in the enclosed documents is
                                                            true to the best of my knowledge and belief and nothing has been concealed therein. I
                                                            understand that if the information given by me is proved false/not true, I will take full responsibility for any discrepancies or issues.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {VendorCode ?
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>

                                            </div>
                                            :
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='VendorMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleDetails={handleDetails}
                        handleVerification={hndleVerification}
                        handleFilter={() => {
                            getVendorMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        handleTabClick={handleTabClick}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        tableData={tableData}
                        //  loading={loading}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}

            </main >


            <Modal size="lg" show={isModalOpen} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen(false)}>
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6>Vendor Details</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <div className="modal-body p-0" style={{ padding: '8px' }}>

                        {ModalData && ModalData ?
                            <>
                                <div className='main-card card border-0'>
                                    <div className='card-body p-3'>
                                        <div className="table_heading d-flex justify-content-between mb-3">
                                            <div style={{ flexGrow: 1 }}>Vendor Detail</div>

                                        </div>

                                        <div className="form-box p-0 service-content-area me-3 " >
                                            <div className="row  details-tableAwb" >
                                                <div className="table-responsive">
                                                    <table className='mb-0 table table-bordered table-hover table-striped' style={{ whiteSpace: 'nowrap' }}>
                                                        <tbody style={{ fontSize: '13px', padding: '1px' }}>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hVendor}</td>
                                                                <td>{ModalData.vendor} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hShopName}</td>
                                                                <td>{ModalData.shopName} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor ' >{ModalData.hMobile}</td>
                                                                <td>  {ModalData.mobile} </td>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hEmail} </td>
                                                                <td>  {ModalData.email} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor'>{ModalData.hAddress} </td>
                                                                <td>  {ModalData.address} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hPinCode}  </td>
                                                                <td>  {ModalData.pinCode} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor'>{ModalData.hCity}</td>
                                                                <td>  {ModalData.city} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hState}</td>
                                                                <td> {ModalData.state}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hShopDescription} </td>
                                                                <td>  {ModalData.shopDescription} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hgstNumber} </td>
                                                                <td>{ModalData.gstNumber}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hpanNumber}   </td>

                                                                <td>  {ModalData.panNumber}</td>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hSignature}  </td>
                                                                <td>
                                                                    {ModalData.signature !== '' && ModalData.signature !== null ?
                                                                        <>
                                                                            <i
                                                                                className="fa fa-image me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.signature}`)}
                                                                            />
                                                                            <i
                                                                                className="fa fa-download me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.signature}`, 'down')}
                                                                            />
                                                                        </> : ''
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hBankName}  </td>
                                                                <td> {ModalData.bankName} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hAccountNumber}  </td>
                                                                <td> {ModalData.accountNumber} </td>
                                                            </tr>

                                                            <tr>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hifscCode} </td>
                                                                <td> {ModalData.ifscCode} </td>
                                                                <td className='fw-bold HistoryColor' > {ModalData.hCountry}  </td>
                                                                <td> {ModalData.country} </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hCountry}   </td>
                                                                <td> {ModalData.country} </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hgstImage}
                                                                </td>
                                                                <td>
                                                                    {ModalData.gstImage !== '' && ModalData.gstImage !== null ?
                                                                        <>
                                                                            <i
                                                                                className="fa fa-image me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.gstImage}`)}
                                                                            />
                                                                            <i
                                                                                className="fa fa-download me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.gstImage}`, 'down')}
                                                                            />
                                                                        </> : ''}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hAadharFront}   </td>
                                                                <td>
                                                                    {ModalData.aadharFront !== '' && ModalData.aadharFront !== null ?
                                                                        <>
                                                                            <i
                                                                                className="fa fa-image me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.aadharFront}`)}
                                                                            />
                                                                            <i
                                                                                className="fa fa-download me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.aadharFront}`, 'down')}
                                                                            />
                                                                        </> : ''}
                                                                </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hAadharBack}   </td>
                                                                <td>
                                                                    {ModalData.aadharBack !== '' && ModalData.aadharBack !== null ?
                                                                        <>
                                                                            <i
                                                                                className="fa fa-image me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.aadharBack}`)}
                                                                            />
                                                                            <i
                                                                                className="fa fa-download me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.aadharBack}`, 'down')}
                                                                            />
                                                                        </> : ''}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hPanDetails}   </td>
                                                                <td>
                                                                    {ModalData.panDetails !== '' && ModalData.panDetails !== null ?
                                                                        <>
                                                                            <i
                                                                                className="fa fa-image me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.panDetails}`)}
                                                                            />
                                                                            <i
                                                                                className="fa fa-download me-2"
                                                                                title="Show Image"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.panDetails}`, 'down')}
                                                                            />
                                                                        </> : ''}
                                                                </td>
                                                                <td className='fw-bold HistoryColor' >{ModalData.hBankDetails}   </td>
                                                                <td>
                                                                    {ModalData.bankDetails !== '' && ModalData.bankDetails !== null ?
                                                                        <>
                                                                    <i
                                                                        className="fa fa-image me-2"
                                                                        title="Show Image"
                                                                        style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                onClick={() => handleShowImageInModal(`data:image/jpeg;base64,${ModalData.bankDetails}`)}
                                                                    />
                                                                    <i
                                                                        className="fa fa-download me-2"
                                                                        title="Show Image"
                                                                        style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                        onClick={() => handleShowFile(`data:image/jpeg;base64,${ModalData.bankDetails}`, 'down')}
                                                                            />
                                                                        </> : ''}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ''}

                    </div>
                    {/*{ModalData && ModalData.Data ?*/}
                    {/*    <div className="form-box ">*/}
                    {/*        <div className="row align-items-end">*/}
                    {/*            <div className="table-container" style={{ maxHeight: '580px', overflowY: 'scroll' }}>*/}
                    {/*                <table className='table table-bordered table-responsive table-hover' style={{ whiteSpace: 'nowrap' }}>*/}

                    {/*                    {ModalData.Data.map((item, index) => (*/}
                    {/*                        <>*/}
                    {/*                            <thead>*/}
                    {/*                                <tr>*/}
                    {/*                                    <th colSpan='4' className="text-center text-white" style={{ background: '#a98451' }}>Product Details</th>*/}
                    {/*                                </tr>*/}
                    {/*                            </thead>*/}
                    {/*                            <tbody style={{ fontSize: '15px', padding: '1px', border: '2px solid gray' }} key={index}>*/}
                    {/*                                {Object.entries(item).reduce((acc, [key, value], idx) => {*/}
                    {/*                                    if (idx % 2 === 0) {*/}
                    {/*                                        acc.push([]);*/}
                    {/*                                    }*/}
                    {/*                                    acc[acc.length - 1].push({ key, value });*/}
                    {/*                                    return acc;*/}
                    {/*                                }, []).map((row, rowIndex) => (*/}
                    {/*                                    <tr key={rowIndex}>*/}
                    {/*                                        {row.map(({ key, value }, cellIndex) => {*/}
                    {/*                                            const truncatedValue = value.length > 30 ? value.slice(0, 30) + '...' : value;*/}
                    {/*                                            return (*/}
                    {/*                                                <React.Fragment key={cellIndex}>*/}
                    {/*                                                    <td className='fw-bold HistoryColor'>{key}</td>*/}
                    {/*                                                    <td style={key === "Product" ? { background: '#f2dede' } : null} className={key === "Product" ? 'fw-bold  text-success' : ""}>*/}
                    {/*                                                        {truncatedValue}*/}
                    {/*                                                    </td>*/}
                    {/*                                                </React.Fragment>*/}
                    {/*                                            );*/}
                    {/*                                        })}*/}
                    {/*                                    </tr>*/}
                    {/*                                ))}*/}
                    {/*                            </tbody>*/}

                    {/*                        </>*/}
                    {/*                    ))}*/}
                    {/*                </table>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    : null*/}
                    {/*}*/}
                </Modal.Body>
            </Modal>


            <Modal show={isModalOpen2} onHide={() => setIsModalOpen2(false)}>
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title><h6>Verification Details</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12'>

                                <div className="form-check form-switch">
                                    <label
                                        className="form-check-label"
                                        htmlFor="activeCheckbox"
                                    >
                                        Verified
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="VerifiedCheckbox"
                                        name='Verified'
                                        checked={model.IsVerified}
                                        onChange={(e) => setModel({ ...model, IsVerified: e.target.checked })}

                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {

                                            };
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                <div className="form-check form-switch">
                                    <label
                                        className="form-check-label"
                                        htmlFor="activeCheckbox"
                                    >
                                        Active
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="activeCheckbox"
                                        name='Active'
                                        checked={model.IsActive}
                                        onChange={(e) => setModel({ ...model, IsActive: e.target.checked })}

                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {

                                            };
                                        }}
                                    />
                                </div>

                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className="form-group text-start mb-3" >
                                    <label className="form-label mb-1">Remarks </label>
                                    <textarea
                                        className="form-control"
                                        row='1'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {

                                            };
                                        }}
                                        maxLength='250'
                                        placeholder="Remarks"
                                        value={model.VerificationRemark}
                                        onChange={(e) => setModel({ ...model, VerificationRemark: e.target.value })}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ background: "mintcream" }}>

                    <div className='mb-2 d-md-flex gap-2'>
                        <button type="button" className="btn " data-bs-dismiss="modal" style={{ margin: '5px', minWidth: '30px', backgroundColor: "#639E19", color: "white" }}
                            onClick={() => handleVerficationSave()}
                        > <i className="fa">&#xf0c7;</i> Save</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal closeButton size='lg' show={isModalOpen3} onHide={() => {
                setIsModalOpen3(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton style={{ background: "mintcream" }}>
                    <Modal.Title>
                        {'View Image' }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='vendorImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default VendorMaster
