
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, DateBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { FetchAutoCompleteData } from "../Common/CommanServices";
import { useRef } from "react";

const Manifest = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate();
    const [CheckAll, setCheckAll] = useState(false)
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    let date = new Date();
    let newdate = date.toString()?.split(' ')
    newdate = `${newdate[2]}-${newdate[1]}-${newdate[3]}`

    const [formData, setFormData] = useState({
        OrderDateFrom: newdate,
        OrderDateTo: newdate,
        Vendor: "",
        ManifestDate: "",
        DeliveryBoyName: "",
        OrderNoList: [],
        ManifestRemarks: ""
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getManifest = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            ...formData,
            Source: 'web',
            Type: 'S',
            PageIndex: `${PageIndex}`
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/OrderManagement/GetOrderForVendorManifest`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" && res.Status === "ERROR") {
                AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }

    const handleClear = () => {
        setFormData({
            OrderDateFrom: "",
            OrderDateTo: "",
            Vendor: "",
            ManifestDate: "",
            DeliveryBoyName: "",
            ManifestRemarks: ""
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
        setCheckAll(false)
    }

    const handleChange1 = (event, value) => {
        getManifest(value);
    };

    const handleCheck = (id, OrderNo) => {
        setCheckAll(false)
        let _data = tableData.Data.map((item) => {
            if (item.Checkbox === OrderNo) {
                return ({ ...item, checkStatus: id })
            } else {
                return ({ ...item })
            }
        });
        setTableData({ ...tableData, Data: _data });
        let updatedOrderNoList = formData['OrderNoList']
        if (id === true) {
            updatedOrderNoList.push(OrderNo)
        }
        else {
            let temp = updatedOrderNoList;
            let index;
            for (let i = 0; i < temp.length; i++) {
                if (temp[i] === OrderNo) {
                    index = i;
                    temp.splice(index, 1)
                }
            }
            updatedOrderNoList = [...temp]
        }
        setFormData({ ...formData, OrderNoList: updatedOrderNoList });
    };

   
    const handleCheckAll = (value) => {
        setCheckAll(value);
        setTableData(prevTableData => {
            const updatedTableData = prevTableData?.Data.map(item => ({
                ...item,
                checkStatus: value
            }));
            const updatedList = value ? updatedTableData.map(item => item.Checkbox) : [];

            setFormData(prevFormData => ({
                ...prevFormData,
                OrderNoList: updatedList
            }));
            return {
                ...prevTableData,
                Data: updatedTableData
            };
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source,
            OrderNoList: formData.OrderNoList,
        }

        post(`${API_SERVER}/api/OrderManagement/SaveVendorManifestDetail`, { ...data, }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                setEdit(false);
                handleClear();
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false);
        })
    };


    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    useEffect(() => {
        document.title = 'Manifest'
        setFocus("txtDateFrom")
      
    }, [])
    useEffect(() => {
        if (formData.Vendor !== "") {
            getManifest(currentPageNo)
        }
    }, [sortConfig])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">Manifest</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area">
                                    <div className="row align-items-top">
                                        <div className="col-lg-3 col-md-3 col-sm-6 ">
                                            <div className="fields d-flex gap-1">
                                                <DateBox
                                                    id='txtDateFrom'
                                                    divclassname="col-6"
                                                    label='Order From'
                                                    placeholder="Order Date"
                                                    required={false}
                                                    selected={formData.OrderDateFrom === '' ? '' : new Date(formData.OrderDateFrom)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setFormData({ ...formData, OrderDateFrom: dateArr })
                                                    }}
                                                    maxDate={new Date()}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                    }}
                                                />

                                                <DateBox
                                                    id='txtToDate'
                                                    divclassname="col-6"
                                                    label='Order To'
                                                    placeholder="To Date"
                                                    required={false}
                                                    selected={formData.OrderDateTo === '' ? '' : new Date(formData.OrderDateTo)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setFormData({ ...formData, OrderDateTo: dateArr })
                                                    }}
                                                    maxDate={new Date()}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtVendor") };
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <AutoCompleteBox
                                            label='Vendor'
                                            inputValue={formData.Vendor ? formData.Vendor : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetVendor', '');
                                                }}
                                            maxLength='50'
                                            options={autoCompleteOptions}
                                            placeholder="Vendor"
                                            setOptions={setAutoCompleteOptions}
                                            required={true}
                                            id='txtVendor'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtManifestDate") };
                                            }}
                                        />

                                        <DateBox
                                            id='txtManifestDate'
                                            label='Manifest Date'
                                            placeholder="Manifest  Date"
                                            required={true}
                                            selected={formData.ManifestDate === '' ? '' : new Date(formData.ManifestDate)}
                                            onChange={(e) => {
                                                let dateArr = e?.toString()?.split(' ')
                                                dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                setFormData({ ...formData, ManifestDate: dateArr })
                                            }}
                                            maxDate={new Date()}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtDeliveryBoyName") };
                                            }}
                                        />

                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtManifestRemarks")
                                                };
                                            }}
                                            label='Delivery Boy Name'
                                            id='txtDeliveryBoyName'
                                            maxLength='50'
                                            placeholder="Delivery Boy Name"
                                            value={formData.DeliveryBoyName}
                                            onChange={(e) => setFormData({ ...formData, DeliveryBoyName: e.target.value })}

                                            required
                                        />
                                        <div className="col-lg-3 col-md-4 col-sm-6">
                                            <div className="fields">
                                                <label className="form-label">Manifest Remarks</label>

                                                <textarea
                                                    id="txtManifestRemarks"
                                                    className="col-12"
                                                    placeholder="Manifest Remarks"
                                                    cols={500}
                                                    value={formData.ManifestRemarks}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, ManifestRemarks: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("btnsave") };
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button text-center">
                                                {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                    Please wait...
                                                </button> : <button className="button save-btn" onClick={()=>handleSubmit()}>
                                                    <i className="bi bi-check-lg" /> Update
                                                </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                    Please wait...
                                                </button> : <button className="button save-btn" id="btnsave" onClick={()=>handleSubmit()}>
                                                    <i className="bi bi-check-lg" /> Save
                                                </button>)}
                                                <button className="button search-btn" onClick={()=>getManifest()}>
                                                    <i class="fa-solid fa-magnifying-glass"></i> Search
                                                </button>
                                                <button className="button reset-btn" onClick={()=>handleClear()}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <Table
                    Token={Token}
                    PageName='VendorManifest'
                    handleFilter={() => {
                        getManifest();
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    tableData={tableData}
                    //loading={loading}
                    handleCheck={handleCheck}
                    handleCheckAll={handleCheckAll}
                    checkAll={CheckAll}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />

                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default Manifest





