import axios from "axios";

const post = (url, payload, onDone) => {
    axios({
        method: "post",
        url: url,
        data: payload,
         headers: {
            "Content-Type": "application/json" // Ensure payload is treated as JSON
        }
    }).then(res => {
        onDone(res.data);

    }).catch(error => {
        if (error.response) {
            console.log(error.response)
        }
    });
};

export { post };
