
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, Switch } from '../Common/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { FetchAutoCompleteData, getCommonData, handleDownloadExcel } from "../Common/CommanServices";
import Select, { components } from 'react-select';

const ItemsToSections = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const userGroupName = cookies.get('userGroupName') ? cookies.get('userGroupName') : '';
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        ItemsToSectionID: "",
        Section: "",
        Vendor: "",
        Category: "",
        SubCategory: "",
        Items: [],
        Active: true,
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    // Show Data on Table
    const getItemsToSections = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowItemsToSection`, { ...data, ...filterData, ...sortConfig, ...formData, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }

    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    //Handle Clear 
    const handleClear = () => {
        setFormData({
            ItemsToSectionID: "",
            Section: "",
            Vendor: "",
            Category: "",
            SubCategory: "",
            Items: "",
            Active: true,
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getItemsToSections(value);
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateItemsToSections`, { ...data, ...formData, Items: formData.Items.map(ele => ele.value).join(",") }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true);
        post(`${API_SERVER}/api/Master/EditItemsToSections`, { ...data, ItemsToSectionID: id }, (res) => {
            setEdit(true);
            if (res.status === 'SUCCESS') {
                const items = res.items?.split(',').map(ele => ({ value: ele.split(' ')[0], label: ele }));
                const tooltips = res.productPreviewUrl?.split(',') || [];
                const itemsWithTooltips = items.map((item, index) => ({
                    ...item,
                    tooltip: tooltips[index] || ''
                }));
                setFormData({
                    ...formData,
                    ItemsToSectionID: res.itemsToSectionID,
                    Category: res.category,
                    SubCategory: res.subCategory,
                    Items: itemsWithTooltips,
                    Vendor: res.vendor,
                    Section: res.section,
                    Active: res.active
                });
                setShowForm(true);
            } else if (res.message === "EXPIRED") {
                navigate('/Login');
            } else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }

            setIsLoading(false);
        });
    };
  
    
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteItemsToSection`, { ...data, ItemsToSectionID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const CustomMultiValueContainer = ({ data, handleTooltip, ...props }) => {
        const handleIconClick = () => {
            window.open(data.tooltip, '_blank');
        };

        return (
            <components.MultiValueContainer {...props}>
                <components.MultiValueLabel >
                    {data.tooltip && (
                        <div>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="custom-tooltip-icon"
                                title="Product Preview"
                                onMouseEnter={() => handleTooltip(data.tooltip)}
                                onMouseLeave={() => handleTooltip('')}
                                onClick={handleIconClick}
                            />
                        </div>
                    )}
                </components.MultiValueLabel > 
                <components.MultiValueRemove  {...props}
                
                >

                </components.MultiValueRemove>
            </components.MultiValueContainer>
        );
    };



    //Mutltiple select Box data
    const GetItemsData = (value,vendor,category,subcategory) => {
        setItemsData([])
        let temp = {
            Token: cookies.get('token'),
            LoginUserCode: `${LoginUserCode}`,
            Type: "GetSubCategoryWiseItems",
            Prefix: '',
            ContextKey: vendor,
            ContextKey1: category,
            ContextKey2: value,
        }
        getCommonData({ ...temp }, (res) => {
            if (res && res.status) {
                if (res.status === "ERROR") {
                    setItemsData([])
                } else {
                    let data = res && res.autoCompleteData && res.autoCompleteData.length > 0 ? res.autoCompleteData : []
                    setItemsData(data)
                }
            }
        });
    }

    const ItemsOptions = itemsData.map((ele) => {
        return { label: ele.values, value: ele.values.split(" ")[0], tooltip: ele.tooltip }
    });

    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    useEffect(() => {
        document.title = 'Items To Sections'
        if (showForm) {
            setFocus("txtSection")
        }
        setFocus("txtSection")
    }, [showForm, setFocus])

    useEffect(() => {
        getItemsToSections()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowItemsToSection', ExportRequest, 'Items To Sections')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Items To Sections'
                                />
                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row ">
                                            <AutoCompleteBox
                                                label='Section Name'
                                                placeholder='Section Name'
                                                // divclassname=''
                                                inputValue={formData.Section ? formData.Section : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'Section', 'GetSection', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtSection'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtVendor") };
                                                }}
                                            />
                                            <AutoCompleteBox
                                                label='Vendor'
                                                placeholder='Vendor'
                                                // divclassname=''
                                                inputValue={formData.Vendor ? formData.Vendor : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetAccountManagerWiseVendor', userGroupName.toString(), LoginUserCode.toString());
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtVendor'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCategory") };
                                                }}
                                            />
                                            <AutoCompleteBox
                                                label='Category'
                                                placeholder='Category'
                                                // divclassname=''
                                                inputValue={formData.Category ? formData.Category : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'Category', 'GetCategory', formData.Vendor);
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtCategory'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSubCategory") };
                                                }}
                                            />
                                            <AutoCompleteBox
                                                label='SubCategory'
                                                placeholder='SubCategory'
                                                inputValue={formData.SubCategory ? formData.SubCategory : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'SubCategory', 'GetSubCategoryCategoryWise', formData.Category);
                                                        GetItemsData(newInputValue, formData.Vendor, formData.Category, formData.SubCategory)
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtSubCategory'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtItems") };
                                                }}
                                            />

                                            <div className='col-lg-9 col-md-9 col-sm-12' >
                                                <div className='col-lg-9 col-md-9 col-sm-12' >
                                                    <label className="fw-semibold" > Items
                                                        <span style={{ color: "red" }}> *</span>
                                                    </label>
                                                    <Select
                                                        isMulti
                                                        value={formData.Items}
                                                        classNames={{
                                                            valueContainer: (state) =>
                                                                state ? 'scroll_select' : '',
                                                            indicatorsContainer: (state) =>
                                                                state ? 'indicator_cross' : '',
                                                            control: (state) =>
                                                                state ? 'setHieght' : '',
                                                        }}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                borderColor: state.isFocused ? '#A88551' : '#DDDDDD',
                                                                boxShadow: state.isFocused ? '0px 0px 0px 0px #A88551' : "",
                                                            }),
                                                        }}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Items: e })
                                                        }}
                                                        components={{ MultiValueContainer: CustomMultiValueContainer }}
                                                        options={ItemsOptions}
                                                    />
                                                </div>
                                            </div>
                                            <Switch
                                                label='Active'
                                                checked={formData.Active}
                                                id='activeCheckbox'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit();
                                                    };
                                                }}
                                                onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}
                                            />

                                            <div className="col-lg-12 mt-4 z-0">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='ItemsToSection'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getItemsToSections();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        tableData={tableData}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>

    )
}
export default ItemsToSections







