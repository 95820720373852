
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, DateBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from '../Common/Table';
import { FetchAutoCompleteData, handleDownloadExcel } from '../Common/CommanServices';

const UserMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showgrid, setShowGrid] = useState(true)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [groupNameDropdown, setGroupNameDropdown] = useState([]);
    const [active, setActive] = useState(true);
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [groupNameValue, setGroupNameValue] = useState('');
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [MenuText, setMenuText] = useState("User Master");
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode')
    const Source = 'web';
    const [isExpanded, setIsExpanded] = useState(false);
    const VendorName = cookies.get('userName');
    const VendorCode = cookies.get('loginUserCode')
    const VendorType = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : '';
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [refreshGrid, setRefreshGrid] = useState(false);
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true); 
        }
        
        
    };
    const [formData, setFormData] = useState({
        UserID:'',
        UserName: '',
        Password: '',
        Address: '',
        GroupName:'',
        MobileNo: '',
        EmailId: '',
        PinCode: '',
        City: '',
        dateOfJoining:''
    });
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getUserMasterData = async (CurrentPage) => {

        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            Type: 'S',
            PageIndex: `${PageIndex}`
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/User/ShowUserMaster`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status==="ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,15})/;
        return regex.test(password);
    };

    const handleClear = () => {
        setFormData({
            UserID: '',
            UserName: '',
            Password: '',
            Address: '',
            GroupName: '',
            MobileNo: '',
            EmailId: '',
            PinCode: '',
            City: '',
            dateOfJoining: ''
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setActive(true);
        setGroupNameValue('')
        setEdit(false)
        setColoredRow(null)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const userGroupDropdownList = async () => {
        let Condition = "And UserGroupID<>3";
        let TextPart = "UserGroupName"
        let ValuePart = "UserGroupID"
        let TableName = "UserGroupMaster"
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = { Token, LoginUserCode: loginCodeString, Source, Condition, TextPart, ValuePart, TableName }
        post(`${API_SERVER}/api/User/BindDropDownList`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setGroupNameDropdown(res.dropDownList);

            }
            else if (res.message === "EXPIRED") {
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }

        });
    };
    // Function to set the default groupNameValue based on the VendorCode
    
   

    const handleSubmit = async () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source, DateofJoining: document.getElementById('txtDOJ').value, UserCode: userCode.value,
           
            Active: active,
        }
        post(`${API_SERVER}/api/User/InsertUpdateUserMaster`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setColoredRow(null)
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/User/EditUserMaster`, { ...data, UserID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
               
                setUserCode({ ...userCode, value: res.userCode })
                setFormData({ ...formData, UserName: res.userName, GroupName: res.groupName, Password: res.password, MobileNo: res.mobileNo, EmailId: res.emailID, Address: res.address, PinCode: res.pinCode, City: res.city, dateOfJoining: res.dateofJoining });
                setActive(res.active);
                setColoredRow(id)
                setShowGrid(false)
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/User/DeleteUserMaster`, { ...data, UserID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }
  
    const handleChange1 = (event, value) => {
        getUserMasterData(value);
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let status = VendorCode.toString().includes('V')

    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    useEffect(() => {
        document.title = 'User Master'
        userGroupDropdownList();
        if (showForm) {
            setFocus("txtUserCode")
        }
        setFocus("txtUserCode")
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])

    useEffect(() => {
        getUserMasterData()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtUserCode");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/User/ShowUserMaster', ExportRequest, 'User Master')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading={MenuText}
                                />


                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                       
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtUserName")
                                                    };
                                                }}
                                                label='User Code'
                                                id='txtUserCode'
                                                placeholder="System Generated"
                                                value={userCode.value}
                                                disabled
                                                required
                                            />
                                     
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPassword")
                                                    };
                                                }}
                                                label='User Name'
                                                id='txtUserName'
                                                maxLength='30'
                                                placeholder="User Name"
                                                value={formData.UserName}
                                                onChange={(e) => setFormData({ ...formData, UserName: e.target.value })}
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtGroupName")
                                                    };
                                                }}
                                                type="password"
                                                label='Password'
                                                id='txtPassword'
                                                maxLength='15'
                                                minLength='8'
                                                placeholder="Password"
                                                disabled={edit}
                                                value={formData.Password}
                                                onChange={(e) => setFormData({ ...formData, Password: e.target.value })}
                                                required
                                            />
                                           
                                            <SelectInputBox
                                                id="txtGroupName"
                                                divclassname="col col-lg-3 fields"
                                                label="Group Name"
                                                required={true}
                                                value={formData.GroupName ? formData.GroupName:""}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, GroupName: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtMobileNumber")
                                                    }
                                                }}
                                            >
                                                <option value=''>--Group Name--</option>
                                                {
                                                    groupNameDropdown?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.valuePart}>{item.textPart}</option>
                                                        )
                                                    })
                                                }
                                            </SelectInputBox>
                                            {VendorType &&
                                                <AutoCompleteBox
                                                    label='Vendor'
                                                    inputValue={formData.Vendor ? formData.Vendor : ''}
                                                    onInputChange={
                                                        (event, newInputValue) => {
                                                            handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetVendor', '');
                                                        }}
                                                    maxLength='10'
                                                    disabled={status}
                                                    options={autoCompleteOptions}
                                                    placeholder="Vendor"
                                                    setOptions={setAutoCompleteOptions}
                                                    required={true}
                                                    id='txtVendor'
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtVarientOf") };

                                                    }}
                                                />
                                            }
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtEmailID")
                                                    };
                                                }}
                                                type="tel"
                                                label='Mobile No'
                                                id='txtMobileNumber'
                                                maxLength='10'
                                                placeholder="Mobile No"
                                                value={formData.MobileNo}
                                                onChange={(e) => {
                                                    let { value } = e.target
                                                    const mobileNo = value.replace(/[^0-9]/g, '')
                                                    setFormData({ ...formData, MobileNo: mobileNo })
                                                }}
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {

                                                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                        if (!emailRegex.test(formData.EmailId)) {
                                                            setTimeout(() => {
                                                                AlertBox('ERROR', "Please enter a valid Email Id.", '')
                                                            }, 0)
                                                            setFocus('txtEmailId');
                                                        }
                                                        else {
                                                            setFocus('txtAddress');
                                                        }
                                                    }
                                                }}
                                                label='Email ID '
                                                id='txtEmailID'
                                                maxLength='50'
                                                placeholder="Email ID"
                                                value={formData.EmailId}
                                                onChange={(e) => setFormData({ ...formData, EmailId: e.target.value })}
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPinCode")
                                                    };
                                                }}
                                                label='Address'
                                                id='txtAddress'
                                                maxLength='200'
                                                placeholder="Address"
                                                value={formData.Address}
                                                onChange={(e) => setFormData({ ...formData, Address: e.target.value })}
                                                required
                                            />
                                            <AutoCompleteBox
                                                label='Pin Code'
                                                inputValue={formData.PinCode ? formData.PinCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'PinCode', 'GetPinCode', '');
                                                    }}
                                                maxLength='6'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                placeholder="Pin Code"
                                                required={true}
                                                id='txtPinCode'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCity") };
                                                }}
                                            />
                                    
                                            <AutoCompleteBox
                                                label='City'
                                                inputValue={formData.City ? formData.City : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'City', 'GetCityPinCodeWise', formData.PinCode);
                                                    }}
                                                maxLength='50'
                                                placeholder="City"
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtCity'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtDOJ") };
                                                }}
                                            />
                                          
                                            <DateBox
                                                id='txtDOJ'
                                                label='Date of Joining'
                                                placeholder="Date of Joining"
                                                required={false}
                                                selected={formData.dateOfJoining === '' ? '' : new Date(formData.dateOfJoining)}
                                                onChange={(e) => {
                                                    let dateArr = e?.toString()?.split(' ')
                                                    dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                    setFormData({ ...formData, dateOfJoining: dateArr })
                                                }}
                                                maxDate={new Date() }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                }}
                                            />
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={active}
                                                        onChange={(e) => setActive(e.target.checked)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                   
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                               

                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='UserMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getUserMasterData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        tableData={tableData}
                        //  loading={loading}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                   
                )}
             
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default UserMaster






