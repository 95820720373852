
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import {  ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { DropDownData, FetchAutoCompleteData, handleDownloadExcel, } from "../Common/CommanServices";;

const WareHouseMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const VendorName = cookies.get('loginType') === 'Vendor'?cookies.get('userName'):"";
    const VendorCode = cookies.get('loginType') === 'Vendor' ?cookies.get('loginUserCode'):""
    const [isExpanded, setIsExpanded] = useState(false);
    const [formData, setFormData] = useState({
        "WarehouseID": "",
        "VendorCode": cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        "WarehouseName": "",
        "FirstName": "",
        "LastName": "",
        "EmailID": "",
        "Country": "",
        "State": "",
        "City": "",
        "PinCode": "",
        "Address1": "",
        "Address2": "",
        "PhoneNo": "",
        "Company": "",
        "IsActive": true
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getWarehouseMaster = async (CurrentPage) => {

        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowWareHouseMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            ...formData,
            "WarehouseID": "",
            "VendorCode": VendorCode ? VendorCode + ' : ' + VendorName : "",
            "WarehouseName": "",
            "FirstName": "",
            "LastName": "",
            "EmailID": "",
            "Country": "",
            "State": "",
            "City": "",
            "PinCode": "",
            "Address1": "",
            "Address2": "",
            "PhoneNo": "",
            "Company": "",
            "IsActive": true
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getWarehouseMaster(value);
    };


    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateWareHouseMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    let GetCountry = DropDownData('GetCountry', showForm);
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditWareHouseMaster`, { ...data, WarehouseID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {

                setFormData({
                    ...formData,
                    "WarehouseID": res.warehouseID,
                    "VendorCode": res.vendorCode ,
                    "WarehouseName": res.warehouseName ,
                    "FirstName": res.firstName,
                    "LastName": res.lastName ,
                    "EmailID": res.emailID,
                    "Country": res.country,
                    "State": res.state,
                    "City": res.city,
                    "PinCode": res.pinCode,
                    "Address1": res.address1,
                    "Address2": res.address2,
                    "PhoneNo": res.phoneNo,
                    "Company": res.company,
                    "IsActive": res.isActive

                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteWareHouseMaster`, { ...data, WarehouseID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    }
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let status = VendorCode.toString().includes('V')
    useEffect(() => {
        document.title = 'Warehouse Master'
        if (showForm) {
            setFocus("txtVendor")
        }
        setFocus("txtVendor")
       
    }, [showForm, setFocus])
    useEffect(() => {
        getWarehouseMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">

                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowWareHouseMaster', ExportRequest, 'WarehouseMaster')
                                    }}

                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Warehouse Master'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                            <AutoCompleteBox
                                                label='Vendor'
                                                inputValue={formData.VendorCode ? formData.VendorCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'VendorCode', 'GetVendor', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                placeholder="Vendor"
                                                disabled={status}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                id='txtVendor'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtWarehouseName") };
                                                }}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtFirstName")
                                                    };
                                                }}
                                                label='Warehouse Name'
                                                id='txtWarehouseName'
                                                maxLength='30'
                                                placeholder="Warehouse Name"
                                                value={formData.WarehouseName}
                                                onChange={(e) => setFormData({ ...formData, WarehouseName: e.target.value })}

                                                required
                                            />
                                           
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtCountry")
                                                    };
                                                }}
                                                label='Email'
                                                id='txtEmail'
                                                maxLength='30'
                                                placeholder="Email"
                                                value={formData.EmailID}
                                                onChange={(e) => setFormData({ ...formData, EmailID: e.target.value })}
                                            />
                                            <SelectInputBox
                                                id='txtCountry'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtState")
                                                    };
                                                }}
                                                label='Country'
                                                value={formData.Country}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, Country: e.target.value,
                                                    })
                                                }}
                                                inputclassname='p-1'
                                            > <option value="">-Select Country-</option>
                                                {GetCountry?.data?.dataList?.map((item, index) =>
                                                    <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>

                                                )}
                                            </SelectInputBox>
                                            <AutoCompleteBox
                                                label='State'
                                                inputValue={formData.State ? formData.State : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'State', 'GetState', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtState'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtCity") };
                                                }}
                                                placeholder="State"
                                            />
                                            <AutoCompleteBox
                                                label='City'
                                                inputValue={formData.City ? formData.City : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'City', 'GetStateWiseCity', formData.State);
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtCity'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtPincode") };
                                                }}
                                                placeholder="City"
                                            />
                                            <AutoCompleteBox
                                                label='Pin Code'
                                                inputValue={formData.PinCode ? formData.PinCode : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'PinCode', 'GetPinCode', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtPincode'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtAddress1") };
                                                }} 
                                                placeholder="Pin Code"
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtAddress2")
                                                    };
                                                }}
                                                label='Address1'
                                                id='txtAddress1'
                                                maxLength='100'
                                                placeholder="Address1"
                                                value={formData.Address1}
                                                onChange={(e) => setFormData({ ...formData, Address1: e.target.value })}

                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtPhoneNo")
                                                    };
                                                }}
                                                label='Address2'
                                                id='txtAddress2'
                                                maxLength='100'
                                                placeholder="Address2"
                                                value={formData.Address2}
                                                onChange={(e) => setFormData({ ...formData, Address2: e.target.value })}

                                              
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtCompany")
                                                    };
                                                }}
                                                label='Phone No'
                                                id='txtPhoneNo'
                                                maxLength='13'
                                                placeholder="Phone No"
                                                value={formData.PhoneNo}
                                                onChange={(e) => {
                                                    let { value } = e.target
                                                    const mobileNo = value.replace(/[^0-9]/g, '')
                                                    setFormData({ ...formData, PhoneNo: mobileNo })
                                                }}
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("activeCheckbox")
                                                    };
                                                }}
                                                label='Company'
                                                id='txtCompany'
                                                maxLength='50'
                                                placeholder="Company"
                                                value={formData.Company}
                                                onChange={(e) => setFormData({ ...formData, Company: e.target.value })}
                                            />
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.IsActive}
                                                        onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='WarehouseMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getWarehouseMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default WareHouseMaster


