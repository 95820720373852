
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import {  ProductHeader, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";

const TaxCategoryMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        TaxCategoryID: "",
        TaxCategoryName: "",
        TaxPercentage:"",
        Active: true,
    });

    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode').toString();
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getTaxCategoryMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowTaxCategoryMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
     function convertTwoDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }
    const handleClear = () => {
        setFormData({
            TaxCategoryID: "",
            TaxCategoryName: "",
            TaxPercentage:"",
            Active: true,
        });
        filterData.SearchText = '';
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getTaxCategoryMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateTaxCategoryMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditTaxCategoryMaster`, { ...data, TaxCategoryID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    TaxCategoryID: res.taxCategoryID,
                    TaxCategoryName: res.taxCategoryName,
                    TaxPercentage: res.taxPercentage,
                    Active: res.active,
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
   
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteTaxCategoryMaster`, { ...data, TaxCategoryID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            handleClear();
            setIsLoading(false)
        })
    }

    useEffect(() => {
        document.title = 'TaxCategory Master'
        if (showForm) {
            setFocus("txtTaxCategory")
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getTaxCategoryMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">

                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                       
                                    }}

                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowTaxCategoryMaster', ExportRequest, 'TaxCategoryMaster')
                                    }}

                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Tax Category Master'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">

                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("activeCheckbox")
                                                    };
                                                }}
                                                label='Tax Category Name'
                                                id='txtTaxCategory'
                                                maxLength='30'
                                                placeholder="Tax Category Name"
                                                value={formData.TaxCategoryName}
                                                onChange={(e) => setFormData({ ...formData, TaxCategoryName: e.target.value })}
                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("activeCheckbox")
                                                    };
                                                }}
                                                label='Tax Percentage'
                                                id='txtTaxPercentage'
                                                maxLength='30'
                                                placeholder="Tax Percentage"
                                                value={formData.TaxPercentage}
                                                onChange={(e) => {
                                                    const value = convertTwoDecimalsOnValue(e.target.value);
                                                    if (value <= 100) {
                                                        setFormData({ ...formData, TaxPercentage: value });
                                                    }
                                                }}
                                              
                                                required
                                            />
                                           
                                           
                                        
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.Active}
                                                        onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='TaxCategoryMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getTaxCategoryMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        //  loading={loading}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default TaxCategoryMaster

