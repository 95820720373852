import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';


//react toast 
import { API_SERVER } from "../../EnvConfig/env_config";
import { AlertBox } from "../Common/Input";
import { post } from "../Common/service";


//loder


const ForgotPasswordSlide2 = () => {
    const navigate = useNavigate();
    const inputrefs = useRef([]);
    let url = window.location.search;
    const queryString = new URLSearchParams(url);
    const UserCode = queryString.get('UserCode');
    const Token = queryString.get('Token');

    //initial state
    const [recipe, setRecipe] = useState({ NewPassword: '', ConfirmPassword: '', UserCode: UserCode })
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Indo CraftWorld: Forgot Password'
    }, [])

    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setRecipe(recipe => ({ ...recipe, [prop]: value }))
    }

    //validation handler
    const Validate = () => {
        setErrors({})
        let isValid = true;
        let validation = [
            { field: "NewPassword", msg: 'Please Enter New Password.' },
            { field: "ConfirmPassword", msg: 'Please Enter Confirm Password.' }
        ]


        validation.map(item => {
            if (!recipe[item.field] || recipe[item.field].length <= 0) {
                setErrors(errors => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });

        if (recipe.NewPassword && recipe.ConfirmPassword) {
            if (recipe.ConfirmPassword !== recipe.NewPassword) {
                setErrors(errors => ({ ...errors, confirm_password_match: 'Confirm Password Not Match' }));
                isValid = false;
            }
        }
        return isValid;
    }

    //submit button Handler
    const HandleSubmit = (event) => {
        event.preventDefault();
            setLoading(true)
            post(`${API_SERVER}/api/UserLogin/UpdateForgotPassword`, { ...recipe, UserCode, Token }, (res) => {
                // console.log(res);
                if (res && res.status) {
                    if (res.status === "SUCESS") {
                        AlertBox(res.status, res.message, res.focus)
                    }
                    if (res.status === "ERROR") {
                        setRecipe(recipe => ({ ...recipe, NewPassword: '', ConfirmPassword: '' }))
                        AlertBox(res.status, res.message, res.focus)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        AlertBox(res.message)
                        navigate('/Login')
                    }
                }
            });
        }

    


    return (
        <>
            {/* Forgot Password Form Start */}


           
            <div className='login_main'>
               
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-md-6">
                            <div className='fxt-column-wrap justify-content-center'>
                                <div className='lftimg'>
                                    <img src="./assets/img/login-bg.svg" alt="login" />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="fxt-column-wrap justify-content-center">
                                <div className='form-container '>
                                    <div className='text-center mb-5'>
                                        <img src="./assets/img/indocraft.png" style={{ width: '200px' }} alt="QR Code" />
                                      
                                    </div>
                                    <h2 className="text-white py-2">Welcome Back!</h2>
                                    <div class="form-group mb-3 position-relative">
                                        <h5 className='fs-5 text-center mt-4'><span style={{ color: 'gray', fontWeight: "bold" }}>Forgot</span> <span style={{ color: '#fdb813', fontWeight: "bold" }}>Password</span></h5>
                                        <div className="form-group text-start mb-3">
                                            <label className="form-label mb-1">User Code<span className='required'>*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="txtUserCode"
                                                ref={ref => (inputrefs.current['txtNewPassword'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtNewPassword'].focus() };
                                                }}
                                                autoComplete="off"
                                                placeholder="User Code"
                                                name='UserCode'
                                                value={UserCode}
                                                disabled={UserCode}
                                                onChange={(e) => HandleChange('userCode', e.target.value)}
                                            />
                                        </div>

                                        <div className=" text-start" >
                                            <label className="form-label mb-1">New Password <span className='required'>*</span></label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="txtNewPassword"
                                                ref={ref => (inputrefs.current['txtNewPassword'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['txtconfirmPassword'].focus() };
                                                }}
                                                placeholder="New Password"
                                                name='New Password'
                                                value={recipe.NewPassword}
                                                onChange={(e) => HandleChange('NewPassword', e.target.value)}
                                            />

                                            {errors.NewPassword && <div className="error-msg">{errors.NewPassword}</div>}

                                        </div>

                                        <div className=" text-start mb-3 mt-2" >
                                            <label className="form-label mb-1">Confirm Password <span className='required'>*</span></label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="txtconfirmPassword"
                                                ref={ref => (inputrefs.current['txtconfirmPassword'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { inputrefs.current['btnSubmit'].focus() };
                                                }}
                                                placeholder="Confirm Password"
                                                name='Confirm Password'
                                                value={recipe.ConfirmPassword}
                                                onChange={(e) => HandleChange('ConfirmPassword', e.target.value)}
                                            />

                                            {errors.ConfirmPassword && <div className="error-msg">{errors.ConfirmPassword}</div>}
                                            {errors.confirm_password_match && <div className="error-msg">{errors.confirm_password_match}</div>}
                                        </div>

                                        <button
                                            ref={ref => (inputrefs.current['btnSubmit'] = ref)}
                                            className='btn-primary btn w-100'
                                            onClick={HandleSubmit}
                                        >
                                            Update Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Forgot Password Form End */}

            {/* Loder start*/}
          
            {/* Loder End*/}
        </>

    )
}

export default ForgotPasswordSlide2;
