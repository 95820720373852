
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { ReportTable } from "../Common/Table";
import { FetchAutoCompleteData } from "../Common/CommanServices";
import DatePicker from 'react-datepicker';

const PaymentLog = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [formData, setFormData] = useState({
        PaymentDetailsID: "",
        Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        PaymentType: "",
        PaymentFromDate: Todate,
        PaymentToDate: Todate,
        TransectionID: "",
        PayableAmount: "",
        BankName: "",
        IFSCCode: "",
        AccountNo: "",
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: loginCodeString,
        Source: 'web',
    }
    const getPaymentMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Payment/ShowPaymentReport`, { ...data, ...filterData, ...formData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
               
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
          setIsLoading(false)
        });

    }
    const handleClear = () => {
        setFormData({
            PaymentDetailsID: "",
            Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
            PaymentType: "",
            PaymentFromDate: Todate,
            PaymentToDate: Todate,
            TransectionID: "",
            PayableAmount: "",
            BankName: "",
            IFSCCode: "",
            AccountNo: "",
        });
        filterData.SearchText = '';
        getPaymentMaster();
        setSortConfig({ SortColumn: null, SortOrder: null })
    }
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const handleChange1 = (event, value) => {
        getPaymentMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const UpdateData = async () => {
        post(`${API_SERVER}/api/Payment/UpdatePaymentReport`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                getPaymentMaster()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
        })
    };

    const handleCheck = (id, PaymentDetailsID) => {
        setFormData({
            ...formData,
            PaymentDetailsID: id ? PaymentDetailsID : ""
        });
    };

    useEffect(() => {
        document.title = 'Payment Log'
        setFocus("txtVendor")
    }, [])
    useEffect(() => {
        getPaymentMaster();
    }, [sortConfig])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">Payment Report</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">
                                    <div className="row align-items-end">
                                        <AutoCompleteBox
                                            label='Vendor'
                                            inputValue={formData.Vendor ? formData.Vendor : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetVendor', '');
                                                }}
                                            maxLength='10'
                                            options={autoCompleteOptions}
                                            disabled={VendorName}
                                            placeholder="Vendor"
                                            setOptions={setAutoCompleteOptions}
                                           
                                            id='txtVendor'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtPaymentType") };
                                            }}
                                        />
                                        <SelectInputBox
                                            id='txtPaymentType'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtPaymentDate")
                                                };
                                            }}
                                            label='Payment Type'
                                            value={formData.PaymentType}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData, PaymentType: e.target.value,
                                                })
                                            }}
                                          
                                            inputclassname='p-1'
                                        >
                                            <option value=''>--Select--</option>
                                            <option value='COD'>COD</option>
                                            <option value='Online'>Online</option>
                                        </SelectInputBox>
                                        <div className="col-lg-3 fileds row pe-0 m-0 p-0">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label">From Date<span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtFromDate'
                                                        className="form-control"
                                                        placeholderText='From Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        maxDate={new Date()}
                                                        //showMonthDropdown
                                                        selected={formData.PaymentFromDate === '' ? '' : new Date(formData.PaymentFromDate)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, PaymentFromDate: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtToDate") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 ">
                                                <div className="fields ">
                                                    <label className="form-label">To Date<span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtToDate'
                                                        className="form-control "
                                                        minDate={formData.DateFrom}
                                                        placeholderText='To Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        selected={formData.PaymentToDate === '' ? '' : new Date(formData.PaymentToDate)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, PaymentToDate: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtOrderNo") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtPayableAmount")
                                                };
                                            }}
                                            label='Transaction ID'
                                            divclassname="col-md-3 col-sm-6 fields "
                                            id='txttransectionID'
                                            maxLength='30'
                                            placeholder="Transaction ID"
                                            value={formData.TransectionID}
                                            onChange={(e) => setFormData({ ...formData, TransectionID: e.target.value })}
                                        />

                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtBankName")
                                                };
                                            }}
                                            label='Payable Amount'
                                            id='txtPayableAmount'
                                            maxLength='30'
                                            placeholder="Payable Amount"
                                            value={formData.PayableAmount}
                                            onChange={(e) => setFormData({ ...formData, PayableAmount: e.target.value })}
                                        />
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtIFSCCode")
                                                };
                                            }}
                                            label='Bank Name'
                                            id='txtBankName'
                                            maxLength='30'
                                            
                                            placeholder="Bank Name"
                                            value={formData.BankName}
                                            onChange={(e) => setFormData({ ...formData, BankName: e.target.value })}
                                        />
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtAccountNo")
                                                };
                                            }}
                                            label='IFSC Code'
                                            id='txtIFSCCode'
                                            maxLength='20'
                                            placeholder="IFSC Code"
                                            value={formData.IFSCCode}
                                            onChange={(e) => setFormData({ ...formData, IFSCCode: e.target.value })}
                                        />
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("searchbtn")
                                                };
                                            }}
                                            label='Account No'
                                            id='txtAccountNo'
                                            maxLength='10'
                                            placeholder="Account No"
                                            value={formData.AccountNo}
                                            onChange={(e) => setFormData({ ...formData, AccountNo: e.target.value })}
                                        />
                                      
                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button text-center">
                                                <button className="button search-btn" id="searchbtn" onClick={getPaymentMaster}>
                                                    <i className="fa-solid fa-magnifying-glass" /> Search
                                                </button>
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                                {formData.PaymentDetailsID ? (
                                                    <button className="button save-btn" id="btnSave" onClick={UpdateData}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>
                                                ) : null}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <ReportTable
                    Token={Token}
                    PageName='PaymentLog'
                    handleFilter={() => {
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    tableData={tableData}
                    handleCheck={handleCheck}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                    formData={formData}
                />

                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
        </>

    )
}
export default PaymentLog

