
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { number, setFocus, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FetchAutoCompleteData } from "../Common/CommanServices";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import React from 'react';

const ProductMultimedia = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('ImageTab');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const navigate = useNavigate();
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [tableData, setTableData] = useState([])
    const [formData, setFormData] = useState({
        ProductMultimediaID: "",
        Product: "",
        bulkImageList: [],
        VideoLinkList: [],
    });
    const [images, setImages] = useState([]);
    const LoginUserCode = cookies.get('loginUserCode');
    const [videoLinkForm, setVideoLinkForm] = useState({
        sequence: '',
        imgName: '',
        imgExt: "mp4",
        img: '',
        isPrimaryImage: false,
        type: 'Video'
    })
    const [showTable, setshowTable] = useState(false)
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    //---------handle Clear--
    const handleClear = () => {
        setFormData({
            ProductMultimediaID: "",
            Product: "",
            bulkImageList: [],
            VideoLinkList: [],
        });
        setVideoLinkForm({
            sequence: '',
            imgName: '',
            imgExt: "mp4",
            img: '',
            isPrimaryImage: false,
            type: 'Video'
        })
        setImages([])
        setshowTable(false)
        setTableData([])
        const fileInput = document.getElementById('fileinput');
        fileInput.value = "";
    }
    //-----------Autocomplete---
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, '');
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            }, 500)
        }
    };
    //-----------Edit Product Multimedia--
    const editProductMultimedia = async (product) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditProductMultimedia`, { ...data, ProductCode: product }, (res) => {
            if (res.status === "SUCCESS") {
                setImages(res.bulkImageList)
            }
            else if (res.status === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                setImages([])
            }
            setIsLoading(false)
        });
    }
    //----------getProductMultimedia----
    const getProductMultimediaData = (product) => {
        setshowTable(true)
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowProductMultimedia`, { ...data, Type: 'S', ProductCode: product, ...filterData }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    }
    //------------Image Uploader-----
    const handleImageChange = (e) => {
        const files = e.target.files;
        const validFileTypes = ['image/jpeg', 'image/png'];
        const newImages = [];
        const currentLength = images.length;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const extArr = file.name.split(".");
            const reader = new FileReader();

            if (validFileTypes.includes(file.type)) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let logo = reader.result.split(',')[1];
                    newImages.push({
                        imgName: extArr[0],
                        imgExt: extArr[1],
                        img: logo,
                        isPrimaryImage: i === 0 && currentLength === 0,
                        type: 'Image',
                        sequence: `${currentLength + i + 1}`
                    });
                    if (newImages.length === files.length) {
                        const allImages = [...images, ...newImages];
                        const sortedImages = sortImagesByPrimary(allImages);
                        setImages(sortedImages);
                        setFormData({
                            ...formData,
                            BulkImageList: sortedImages
                        });
                    }
                };
            } else {
                alert('Only JPEG and PNG files are allowed');
            }
        }
    }
    //-------handleSubmit------
    const handleSubmit = async () => {
        setIsLoading(true)
        const sequenceValid = formData.BulkImageList.every((image, index) => image.sequence == index + 1);
        if (!sequenceValid) {
            setIsLoading(false);
            Swal.fire({
                title: 'Error!',
                text: 'The image sequence is not correct. Please check and try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        post(`${API_SERVER}/api/Master/InsertupdateProductMultimedia`, { ...data, productCode: formData.Product, bulkImageList: images, }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                handleClear();
            }
            else if (res.status === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId)
    };


    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }
    //--------handleDelete Image----
    const handleDeleteImage = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this Image/Video. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedImages = [...images];
                updatedImages.splice(index, 1);
                setImages(updatedImages);
                setFormData({
                    ...formData,
                    bulkImageList: updatedImages
                });
                Swal.fire('Deleted!', 'The Image/Video has been deleted.', 'success');
            } else {
                Swal.fire('Cancelled', 'The Image/Video was not deleted.', 'info');
            }
        });

    };
    //--------Youtube Video Link
    const handleAddLinks = () => {
        const { imgName, sequence } = videoLinkForm;

        if (!imgName && !sequence) {
            AlertBox('Error', "Please Fill Value", 'txtShortVideoLink');
            return;
        }

        const newVideoLink = {
            ...videoLinkForm,
            imgExt: "mp4",
            img: '',
            isPrimaryImage: false,
            type: 'Video'
        };
        setImages(prevImages => [...prevImages, newVideoLink]);
        setFormData(prevFormData => {
            const updatedBulkImageList = Array.isArray(prevFormData.bulkImageList)
                ? [...prevFormData.bulkImageList, newVideoLink]
                : [newVideoLink];

            return {
                ...prevFormData,
                bulkImageList: updatedBulkImageList
            };
        });
        setVideoLinkForm({
            sequence: '',
            imgName: '',
            imgExt: "mp4",
            img: '',
            isPrimaryImage: false,
            type: 'Video'
        });
    };
    //-----make primary image---
    const HandleMakePrimary = (index) => {
        const updatedImages = images.map((image, i) => ({
            ...image,
            isPrimaryImage: i === index
        }));

        const sortedImages = sortImagesByPrimary(updatedImages);
        setImages(sortedImages);
        setFormData({ ...formData, BulkImageList: sortedImages });
    }
    const sortImagesByPrimary = (images) => {
        return images.sort((a, b) => b.isPrimaryImage - a.isPrimaryImage);
    }

    const handleVideoLinkChange = (e) => {
        let value = e.target.value;
        const iframeRegex = /<iframe.*?src="(.*?)"/;
        const match = value.match(iframeRegex);
        if (match) {
            value = match[1];
        }
        setVideoLinkForm({ ...videoLinkForm, imgName: value });
    };

    useEffect(() => {
        document.title = 'Product Multimedia'
        setFocus("txtCategoryName")

    }, [])


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box" style={{ padding: "8px 1.25rem" }}>
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">Product MultiMedia</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">
                                    <div className="row ">
                                        <AutoCompleteBox
                                            divclassname="col-md-4 col-sm-4 fields"
                                            label='Product'
                                            value={formData.Product ? formData.Product : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'Product', 'GetNewRequiredProduct');
                                                }}
                                            onChange={(event, newInputValue) => {
                                                if (formData.Product !== "" && newInputValue !== "") {
                                                    getProductMultimediaData(newInputValue)
                                                    editProductMultimedia(newInputValue)
                                                }
                                            }}
                                            maxLength='100'
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}
                                            required={true}
                                            placeholder="Product"
                                            id='txtProduct'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("submit")
                                                }
                                            }}
                                        />
                                        <div className="col-md-7 col-sm-7 mt-2">
                                            {tableData && tableData.Data ?
                                                <div className="form-box ">
                                                    <div className="row align-items-end">
                                                        <div className="table-container" >
                                                            <div className="table-responsive">
                                                            <table className='table table-bordered table-responsive table-hover' style={{ whiteSpace: 'nowrap' }}>

                                                                {showTable && tableData.Data.map((item, index) => (
                                                                    <>
                                                                        <thead>
                                                                            <tr>
                                                                                <th colSpan='4' className=" text-white" style={{ background: '#a98451' }}>Product Details</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody style={{ fontSize: '15px', padding: '1px', border: '2px solid gray' }} key={index}>
                                                                            {Object.entries(item).reduce((acc, [key, value], idx) => {
                                                                                if (idx % 1 === 0) {
                                                                                    acc.push([]);
                                                                                }
                                                                                acc[acc.length - 1].push({ key, value });
                                                                                return acc;
                                                                            }, []).map((row, rowIndex) => (
                                                                                <tr key={rowIndex}>
                                                                                    {row.map(({ key, value }, cellIndex) => {
                                                                                        const truncatedValue = value.length > 30 ? value.slice(0, 30) + '...' : value;
                                                                                        return (
                                                                                            <React.Fragment key={cellIndex}>
                                                                                                <td className='fw-bold HistoryColor'>{key}</td>
                                                                                                <td style={key === "Product" ? { background: '#f2dede' } : null} className={key === "Product" ? 'fw-bold  text-success' : ""}>
                                                                                                    {truncatedValue}
                                                                                                </td>
                                                                                            </React.Fragment>
                                                                                        );
                                                                                    })}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>

                                                                    </>
                                                                ))}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className='row mt-2 mx-3 '>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <b className={`nav-link ${activeTab === 'ImageTab' ? 'active' : ''}`}
                                                    style={activeTab === 'ImageTab' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                                                    id="ImageTab-tab"
                                                    onClick={() => handleTabClick('ImageTab')}
                                                > Images</b>
                                            </li>
                                            <li className="nav-item">
                                                <b
                                                    className={`nav-link ${activeTab === 'VideoTab' ? 'active' : ''}`}
                                                    style={activeTab === 'VideoTab' ? { cursor: 'pointer', borderTop: '2px solid black' } : { cursor: 'pointer', border: '1px solid white', color: 'black' }}
                                                    id="VideoTab-tab"
                                                    onClick={() => handleTabClick('VideoTab')}
                                                >
                                                    Videos
                                                </b>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className={`tab-pane fade ${activeTab === 'ImageTab' ? 'show active' : ''}`} id="ImageTab" role="tabpanel">
                                                <div className='mt-2'>
                                                    <div className="col-lg-12 d-flex flex-column" id="txtPrimaryImage">
                                                        <div className="fields mt-3">
                                                            <label className="form-label">Bulk Image  <span className="text-danger" style={{ fontSize: "12px", fontWeight: "300" }}>(1000x1000 pixels)</span></label>
                                                            <input
                                                                className='form-control form-control-sm'
                                                                id="fileinput"
                                                                type="file"
                                                                multiple
                                                                onChange={handleImageChange}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className={`tab-pane fade ${activeTab === 'VideoTab' ? 'show active' : ''}`} id="VideoTab" role="tabpanel">
                                                <div className='row mt-2'>
                                                    <InputBox
                                                        divclassname='col-md-6 col-sm-6 fields'
                                                        id="txtShortVideoLink"
                                                        label='Short Video Link'
                                                        placeholder='Short Video Link'
                                                        maxLength='100'
                                                        value={videoLinkForm.imgName}
                                                        //  onChange={(e) => setVideoLinkForm({ ...videoLinkForm, imgName: e.target.value })}
                                                        onChange={handleVideoLinkChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtSequence") };
                                                        }}
                                                    />
                                                    <InputBox
                                                        id="txtSequence"
                                                        label='Display Sequence'
                                                        placeholder='Display Sequence'
                                                        maxLength='3'
                                                        value={videoLinkForm.sequence}
                                                        onChange={(e) => setVideoLinkForm({ ...videoLinkForm, sequence: number(e.target.value) })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { handleAddLinks() };
                                                        }}
                                                    />
                                                    <div className='col-md-3 col-sm-6 mt-md-4'>

                                                        <button className="btn btn-sm btn-success" onClick={handleAddLinks}>
                                                            <i className="bi bi-plus-lg" /> Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {images.length > 0 ?
                                            <div className='pt-3 '>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr className='light-bg'>
                                                                <th>Action</th>
                                                                <th >Image/Video</th>
                                                                <th >Image/Video Name</th>
                                                                <th >Make Primary</th>
                                                                <th >Sequence</th>
                                                                <th >Type</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {images.map((image, index) => {
                                                                return <tr key={index} className=''>
                                                                    <td >
                                                                        <i className="fa fa-trash-can text-danger me-2" style={{ cursor: 'pointer' }} onClick={() => handleDeleteImage(index)} /></td>
                                                                    <td className='p-0' style={{ cursor: 'pointer' }}>
                                                                        <img
                                                                            className="border border-success img-thumbnail"
                                                                            key={index}
                                                                            src={image.type === 'Video' ? "assets/img/video_show_on_table.png" : `data:image/${image.imgName};base64,${image.img}`}
                                                                            alt={image.imgExt}
                                                                            style={image.type === 'Video' ? { width: '80px', height: '40px', padding: '1px' } : { width: '50px', height: '50px' }}
                                                                            onClick={() => image.type !== "Video" && handleShowImageInModal(`data:image/${image.imgName};base64,${image.img}`)}
                                                                        />
                                                                    </td>
                                                                    <td >{image.imgName}</td>
                                                                    <td ><input type='radio' name={`PrimaryImage-${index}`} disabled={image.type === 'Video'} checked={index === 0 ? true : image.isPrimaryImage} onChange={(e) => HandleMakePrimary(index, e.target.checked)} /></td>
                                                                    <td className='m-0 p-0' style={{ maxWidth: '0rem' }} >
                                                                        <InputBox divclassname='' customclass='pb-2' value={image.sequence} onChange={(e) => {
                                                                            const data = [...images];
                                                                            data[index] = { ...data[index], sequence: e.target.value }
                                                                            setImages(data)
                                                                            setFormData({ ...formData, BulkImageList: data });
                                                                        }} /></td>
                                                                    <td >{image.type}</td>
                                                                </tr>
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>
                                <div className="col-lg-12 p-4 z-0">
                                    <div className="lms-new-button text-center">
                                        <button className="button save-btn" id="submit" onClick={() => handleSubmit()}>
                                            <i className="bi bi-check-lg" /> Update
                                        </button>
                                        <button className="button reset-btn" onClick={() => handleClear()}>
                                            <i className="bi bi-arrow-clockwise" /> Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Product Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center'>
                        <img src={modalImg} alt='ProductImageinModal' style={{ width: "-webkit-fill-available" }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ProductMultimedia