import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus, setTitle, } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";

const OfferMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const Token = cookies.get('token')
    const [formData, setFormData] = useState({
        OfferID: "",
        DiscountType: "FLAT",
        Amount: "",
    });
    const LoginUserCode = cookies.get('loginUserCode');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }

    const getOfferMaster =  (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowOfferMaster`, {  ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            OfferID: "",
            DiscountType: "FLAT",
            Amount: "",
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setColoredRow(null)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getOfferMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit =  () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateOfferMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setColoredRow(null)
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData =  (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditOfferMaster`, { ...data, ...formData, OfferID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    OfferID: res.offerID,
                    DiscountType: res.discountType,
                    Amount: res.amount

                });
                setShowForm(true)
                setColoredRow(id)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
  
    const handleDelete =  (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteOfferMaster`, { ...data, OfferID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }
 
  function convertTwoDecimalsOnValue(value) {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    useEffect(() => {
        document.title = 'Offer Master'
        if (showForm) {
            setFocus("Flat")
        }
    }, [showForm, setFocus])
    useEffect(() => {
        getOfferMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("Flat");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowOfferMaster', ExportRequest, 'OfferMaster')
                                    }}
                                    isExpanded={isExpanded}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Offer Master'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row align-items-end">
                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Discount Type<span className="Required">*</span></label>
                                                    <br></br>
                                                    <div className="btn-group w-100">
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="Flat"
                                                            id="Flat"
                                                            value="FLAT"
                                                            autoComplete="off"
                                                            checked={formData.DiscountType === 'FLAT'}
                                                            onChange={(e) => setFormData({ ...formData, DiscountType: e.target.value })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("Percentage") };
                                                            }}
                                                        />
                                                        <label className={`btn ${formData.DiscountType === 'FLAT' ? 'btn-warning' : 'btn-light'}`} htmlFor="Flat">
                                                            Flat
                                                        </label>
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="Flat"
                                                            id="Percentage"
                                                            value="PERCENTAGE"
                                                            autoComplete="off"
                                                            checked={formData.DiscountType === 'PERCENTAGE'}
                                                            onChange={(e) => setFormData({ ...formData, DiscountType: e.target.value })}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') { setFocus("txtAmount") };
                                                            }}
                                                        />
                                                        <label
                                                            className={`btn ${formData.DiscountType === 'PERCENTAGE' ? 'btn-warning' : 'btn-light'}`}
                                                            htmlFor="Percentage"
                                                        >
                                                            Percentage
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("btnSave")
                                                    };
                                                }}
                                                label='Discount'
                                                id='txtAmount'
                                                maxLength='30'
                                                placeholder="Discount"
                                                value={formData.Amount}
                                                onChange={(e) => setFormData({ ...formData, Amount: convertTwoDecimalsOnValue(e.target.value) })}

                                                required
                                            />
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='OfferMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getOfferMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        filterData={filterData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>

    )
}
export default OfferMaster

