
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { FetchAutoCompleteData, handleDownloadExcel } from "../Common/CommanServices";
import { Table } from "../Common/Table";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const ProfitPayoutDetails = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [showgrid, setShowgrid] = useState(false);
    const [tableData, setTableData] = useState([])
    const [MenuText, setMenuText] = useState("Profit Payment Details");
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate()

    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });

    const [IsUpdate, setIsUpdate] = useState(false)
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const [formData, setFormData] = useState({
        DateFrom: Todate,
        DateTo: Todate,
        Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        OrderNo: "",
        AccountType:""
    });

    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode),
    }
    const [filterData, setFilterData] = useState({
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const handleClear = () => {
        setFormData({
            DateFrom: Todate,
            DateTo: Todate,
            Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "" ,
            OrderNo: "",
            AccountType:""
        });
        setIsUpdate(true)
    }

    const handleShow = async (CurrentPage) => {
        setShowgrid(true)
        setIsLoading(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, PageIndex: `${PageIndex}`, Token, LoginUserCode: loginCodeString, Source,
        }
        post(`${API_SERVER}/api/Report/VendorPayoutReport`, { ...data }, (res) => {
            if (res.Status === 'SUCCESS') {
                setIsLoading(false);
                setTableData({ ...res })
            }
            else if (res.Message === 'EXPIRED') {
                navigate('/Login')
            }
            else if (res.Status === 'ERROR') {
                AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    };
    const handleChange1 = (event, value) => {
        handleShow(value);
    };

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let ExportRequest = {
        ...filterData,
        ...formData,
        ...data,
        Type: 'E',
        PageIndex: '1',
    }

    useEffect(() => {
        document.title = 'Profit Payout Report';
        setFocus("txtFromDate");
        if (IsUpdate) {
            setIsUpdate(false);
            handleShow()
        }
        setTimeout(() => {
            setMenuText(document.title);
        }, 200);
    }, [IsUpdate]);


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <div style={{ float: "right" }}>
                                            <button className="btn Exp-btn text-white" onClick={() => {
                                                handleDownloadExcel(API_SERVER + '', ExportRequest, "Profit Payment Report")
                                            }}>
                                                <i className="bi bi-download" /> Export
                                            </button>

                                        </div>
                                        <h6 className="font-weight-bold text-primary">{MenuText}</h6>
                                    </div>
                                </div>
                                <div className="mx-4">
                                    <div className="row">
                                        <div className="col-lg-3 row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label"> From Date <span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtFromDate'
                                                        className="form-control"
                                                        placeholderText='From Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        maxDate={new Date()}
                                                        //showMonthDropdown
                                                        selected={formData.DateFrom === '' ? '' : new Date(formData.DateFrom)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, DateFrom: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtToDate") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label">To Date <span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtToDate'
                                                        className="form-control"
                                                        minDate={formData.DateFrom}
                                                        placeholderText='To Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        selected={formData.DateTo === '' ? '' : new Date(formData.DateTo)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, DateTo: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtOrderNo") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <AutoCompleteBox
                                            id="txtVendor"
                                            label='Vendor'
                                            divclassname='col-lg-3 col-md-3 col-sm-12 fields'
                                            placeholder='Vendor'
                                            inputValue={formData.Vendor ? formData.Vendor : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetVendor', '');
                                                }}
                                            maxLength='50'
                                            disabled={VendorName}
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}
                                            onKeyDown={(e) => {

                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                    setFocus("txtPayoutCode")
                                                };
                                            }}
                                        />
                                        <AutoCompleteBox
                                            id="txtOrderNo"
                                            label='Order No'
                                            divclassname='col-lg-3 fields'
                                            placeholder='Order No'
                                            inputValue={formData.OrderNo ? formData.OrderNo : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'OrderNo', 'GetOrderNo', '');
                                                }}
                                            maxLength='50'
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}

                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                    setFocus("txtVendor")
                                                };
                                            }}
                                        />
                                        <SelectInputBox
                                            id='txtAccountType'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("")
                                                };

                                            }}
                                            label='AccountType'
                                            value={formData.AccountType}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData, AccountType: e.target.value,
                                                })
                                            }}
                                            inputclassname='p-1'
                                        >
                                            <option value="">Select</option>
                                            <option value="COD">COD</option>
                                            <option value="Prepaid">Prepaid</option>
                                        </SelectInputBox>
                                        <div className="col-lg-4 m-4  z-0">
                                            <div className="lms-new-button ">
                                                {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                    Please wait...
                                                </button> : <button className="button search-btn" id='submit' onClick={handleShow}>
                                                    <i class="fa-solid fa-magnifying-glass"></i> Search
                                                </button>)}
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Table
                    Token={Token}
                    PageName='VendorPayoutReport'
                    handleFilter={() => {
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    Filter={false}
                    tableData={tableData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />

                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default ProfitPayoutDetails

