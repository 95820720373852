
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import {InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import {  handleDownloadExcel } from "../Common/CommanServices";
import { ReportTable, Table } from "../Common/Table";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DragableModal from "../Common/DragableModal";

const PayoutProcessReport = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [showgrid, setShowgrid] = useState(false);
    const [ModalData, setModalData] = useState([])
    const [tableData, setTableData] = useState([])
    const [MenuText, setMenuText] = useState(" Payout Process Report");
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [IsUpdate, setIsUpdate] = useState(false)
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;

    const [formData, setFormData] = useState({
        DateFrom: Todate,
        DateTo: Todate,
        PayoutId: "",
      
    });

    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';

    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode),
    }
    const [filterData, setFilterData] = useState({
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const handleClear = () => {
        setFormData({
            DateFrom: Todate,
            DateTo: Todate,
            PayoutId: "",
            
        });
        setIsUpdate(true)
    }

    const handleShow = async (CurrentPage) => {
        setShowgrid(true)
        setIsLoading(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }

        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, PageIndex: `${PageIndex}`, Token, LoginUserCode: loginCodeString, Source,
        }
        post(`${API_SERVER}/api/Report/ShowPayoutsProcessReport`, { ...data }, (res) => {
            if (res.Status === 'SUCCESS') {
                setIsLoading(false);
                setTableData({ ...res })
            }
            else if (res.Message === 'EXPIRED') {
                navigate('/Login')
            }
            else if (res.Status === 'ERROR') {
                // AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    };
    const handleChange1 = (event, value) => {
        handleShow(value);
    };
    const getProductPayoutDetails = async (PayoutID, ProcessPayoutCode) => {
        setIsModalOpen(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...filterData,
            ...sortConfig,
            Token: cookies.get('token'),
            LoginUserCode: loginCodeString,
            ...formData,
            Source: 'web',
            Type: "D",
        };
        setIsLoading(true);
        post(`${API_SERVER}/api/OrderManagement/GetVendorPayoutData`, { ...data,  PayoutCode: PayoutID, }, (res) => {
            if (res.Status === "SUCCESS") {
                setModalData({ ...res });
            } else if (res.Message === "EXPIRED") {
                navigate('/Login');
            } else if (res.Message !== "" && res.Status === "ERROR") {
                // AlertBox(res.Status, res.Message, res.focus);
                setTableData({ ...res });
            }
            setIsLoading(false);
        });
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';
        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    let ExportRequest = {
        ...filterData,
        ...formData,
        ...data,
        Type: 'E',
        PageIndex: '1',
    }

    useEffect(() => {
        document.title = 'Payout Process Report';
        setFocus("txtFromDate");
        if (IsUpdate) {
            setIsUpdate(false);
            handleShow()
        }
        setTimeout(() => {
            setMenuText(document.title);
        }, 500);
    }, [IsUpdate]);


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <div style={{ float: "right" }}>
                                            <button className="btn Exp-btn text-white" onClick={() => {
                                                handleDownloadExcel(API_SERVER + '/api/Report/ShowPayoutsProcessReport', ExportRequest, "Payout Process Report")
                                            }}>
                                                <i className="bi bi-download" /> Export
                                            </button>

                                        </div>
                                        <h6 className="font-weight-bold text-primary">{MenuText}</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">
                                    <div className="row align-items-top">
                                        <div className="col-lg-3 row">

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label"> From Date <span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtFromDate'
                                                        className="form-control"
                                                        placeholderText='From Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        maxDate={new Date()}
                                                        //showMonthDropdown
                                                        selected={formData.DateFrom === '' ? '' : new Date(formData.DateFrom)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, DateFrom: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtToDate") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label">To Date <span style={{ color: "red" }}> *</span> </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtToDate'
                                                        className="form-control"
                                                        minDate={formData.DateFrom}
                                                        placeholderText='To Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        selected={formData.DateTo === '' ? '' : new Date(formData.DateTo)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, DateTo: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtPayoutId") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <InputBox
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("submit")
                                                };
                                            }}
                                            label='Payout Id'
                                            id='txtPayoutId'
                                            maxLength='30'
                                            placeholder="Payout Id"
                                            value={formData.PayoutId}
                                            onChange={(e) => setFormData({ ...formData, PayoutId: e.target.value })}
                                        />
                                        
                                    </div>
                                    <div className="col-lg-4 m-4  z-0">
                                        <div className="lms-new-button ">
                                            {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                Please wait...
                                            </button> : <button className="button search-btn" id='submit' onClick={handleShow}>
                                                <i class="fa-solid fa-magnifying-glass"></i> Search
                                            </button>)}
                                            <button className="button reset-btn" onClick={handleClear}>
                                                <i className="bi bi-arrow-clockwise" /> Reset
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Table
                    Token={Token}
                    PageName='PayoutProcessReport'
                    handleFilter={() => {
                        setFilterData({
                            ...filterData,
                        });
                    }}
                    Filter={false}
                    tableData={tableData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    handleDetails={getProductPayoutDetails}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                />
                <Modal size="xl" show={isModalOpen} style={{ cursor: 'pointer' }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsModalOpen(false)} >
                    <Modal.Header closeButton style={{ background: "mintcream" }}>
                        <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Payout Details</h6></Modal.Title>
                        <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">
                            {`${ModalData?.TotalRecord !== undefined ? 'Total Records : ' + ModalData?.TotalRecord : 'No Record Found'}`}</h6></span>
                        <span></span>
                    </Modal.Header>
                    <Modal.Body style={{ background: "mintcream" }}>
                        <div className="modal-body p-0" style={{ padding: '0px' }}>
                            {ModalData && ModalData ?
                                <>
                                    <div className="form-box p-0  me-3 " >
                                        <div className="row  details-tableAwb" >
                                            <ReportTable
                                                Token={Token}
                                                PageName='VendorPayout'
                                                handleFilter={() => {
                                                    setFilterData({
                                                        ...filterData,
                                                    });
                                                }}
                                                Filter={false}
                                                tableData={ModalData}
                                                filterData={filterData}
                                                setFilterData={setFilterData}
                                                currentPageNo={currentPageNo}
                                                handleChange1={handleChange1}

                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>

                    </Modal.Body>
                </Modal>
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default PayoutProcessReport
