
import { useState } from 'react';
import { Cookies } from 'react-cookie';
import { useEffect } from 'react';
import { API_SERVER } from '../../EnvConfig/env_config';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { post } from '../Common/service';
import { AlertBox, AutoCompleteBox, InputBox } from '../Common/Input';
import { getCommonData } from '../Common/CommanServices';
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const cookies = new Cookies();
function ResetPassword({ open, setOpen }) {
    const [resetPasswordData, setResetPasswordData] = useState({ UserCode: '', NewPassword: '', ConfirmPassword: '' });

    const [policyValidations, setPolicyValidations] = useState({
        noWhitespace: false,
        length: false,
        matchConfirmPassword: false,
        differentOldPassword: false,
        complexity: false
    });

    const navigate = useNavigate();
    const [resetPasswordAutoComplete, setResetPasswordAutocomplete] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [MenuText, setMenuText] = useState("Reset Password");
    const [showPassword, setShowPassword] = useState(false);
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web'

    const HandleChange = (prop, value) => {
        setResetPasswordData(resetPasswordData => ({ ...resetPasswordData, [prop]: value }))
    }
    const handleTogglePassword = (field) => {
        setShowPassword((prevShowPassword) => ({ ...prevShowPassword, [field]: !prevShowPassword[field] }));
    };
    const handleSubmit = () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            ...resetPasswordData,
            Token: Token,
            LoginUserCode: loginCodeString,
            Source: Source
        }
        post(`${API_SERVER}/api/User/ResetPassword`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, res.focus);

                handleReset();
                setIsLoading(false);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);

                setFocus(res.focus)
                setIsLoading(false)
            }
        })
    };

    const handleReset = () => {
        setResetPasswordData({ ...resetPasswordData, UserCode: '', NewPassword: '', ConfirmPassword: '' });
        setPolicyValidations({
            noWhitespace: false,
            length: false,
            matchConfirmPassword: false,
            differentOldPassword: false,
            complexity: false
        });
    }
    const validatePassword = (password, confirmPassword) => {
        if (password !== '') {
            const noWhitespace = !/\s/.test(password);
            const length = password.length >= 8 && password.length <= 15;
            const complexity = /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
            setPolicyValidations({
                noWhitespace,
                length,
                matchConfirmPassword: password === confirmPassword,
                differentOldPassword: password !== resetPasswordData.oldPassword,
                complexity
            });
        } else {
            setPolicyValidations({
                noWhitespace: false,
                length: false,
                matchConfirmPassword: false,
                differentOldPassword: false,
                complexity: false
            });
        }
    };
    const handlePasswordChange = (field, value) => {
        setResetPasswordData({ ...resetPasswordData, [field]: value });
        if (field === 'NewPassword') {
            validatePassword(value, resetPasswordData.ConfirmPassword);
        } else if (field === 'ConfirmPassword') {
            validatePassword(resetPasswordData.NewPassword, value);
        }
    };
    const getUser = () => {
        setResetPasswordAutocomplete([]);
        let temp = {
            Type: "GetUserCode",
            Prefix: "",
            ContextKey: "",
            ContextKey2: ""
        };
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token,
            LoginUserCode: loginCodeString
        };
        getCommonData({ ...data, ...temp }, (res) => {
            if (res && res.status === "SUCCESS") {
                let UserData = res.autoCompleteData || [];
                setResetPasswordAutocomplete(UserData);
            } else {
                setResetPasswordAutocomplete([]);
            }
        });
    };
    const UserOption = [];

    for (let i = 0; i < resetPasswordAutoComplete.length; i++) {
        UserOption.push(resetPasswordAutoComplete[i].values);
    };

    useEffect(() => {
        document.title = 'Reset Password'
        //setTitle('ResetPassword', setOpen);
        setFocus('txtUserCode');
        setTimeout(() => {
            setMenuText(document.title);
        }, 500)
    }, [])

    return (
        <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="content-box">
                            <div className="heading-box">
                                <div className="heading">
                                    <h6 className="m-0 font-weight-bold text-primary">{MenuText}</h6>
                                </div>
                            </div>
                            <div className="form-box service-content-area1">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12" >
                                        <div className="fields">
                                            <AutoCompleteBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtNewPassword") };
                                                }}
                                                label='User '
                                                placeholder="User "
                                                maxLength='50'
                                                options={UserOption}
                                                id='txtUserCode'
                                                required={true}
                                                ignore_common={true}
                                                onFocus={() => getUser()}
                                                autoComplete="off"
                                                inputValue={resetPasswordData.UserCode ? resetPasswordData.UserCode : ''}
                                                onInputChange={(event, newInputValue) => {
                                                    HandleChange('UserCode', newInputValue)

                                                }}
                                            />
                                        </div>

                                        <div className="d-flex mb-1" style={{ position: "relative" }}>
                                            <InputBox
                                                id={'txtnewPassword'}
                                                placeholder="New Password"
                                                type={showPassword.NewPassword ? 'text' : 'password'}
                                                divclassname='fields col-lg-12'
                                                label="New Password"
                                                maxLength='15'
                                                value={resetPasswordData.NewPassword}
                                                onChange={(e) => handlePasswordChange('NewPassword', e.target.value)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        setFocus('txtconfirmPassword');
                                                    }
                                                }}
                                            />
                                            <span style={{ position: "relative", right: "26px", top: "26px", cursor: "pointer" }} onClick={() => handleTogglePassword('NewPassword')}>
                                                {showPassword.NewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>

                                        <div className="d-flex mb-1" style={{ position: "relative" }}>
                                            <InputBox
                                                id={'txtconfirmPassword'}
                                                placeholder="Confirm Password"
                                                type={showPassword.ConfirmPassword ? 'text' : 'password'}
                                                divclassname='fields col-lg-12 '
                                                label="Confirm Password"
                                                maxLength='15'
                                                value={resetPasswordData.ConfirmPassword}
                                                onChange={(e) => handlePasswordChange('ConfirmPassword', e.target.value)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleSubmit(event);
                                                    }
                                                }}
                                            />
                                            <span style={{ position: "relative", right: "26px", top: "26px", cursor: "pointer" }} onClick={() => handleTogglePassword('ConfirmPassword')}>
                                                {showPassword.ConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                        <div className="col-lg-12 mb-4">
                                            <div className="lms-new-button text-center">
                                                <button className="button save-btn" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Reset Password
                                                </button>
                                                <button className="button reset-btn" onClick={handleReset}>
                                                    <i className="bi bi-arrow-clockwise" />Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-sm-12'>
                                        <div className='password-policy-border'>
                                            <div className='w-100 py-2 password-policy px-3'>
                                                <h6 className="m-0 font-weight-bold">{'Password Policy'}</h6>
                                            </div>
                                            <div className='p-4'>
                                                <div className="form-group px-2">
                                                    <p className='changepara' >
                                                        <strong >1.</strong>
                                                        White spaces not allowed. {policyValidations.noWhitespace ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                    </p>
                                                    <p className='changepara'>
                                                        <strong >2.</strong>
                                                        Password must be 8 to 15 character(s). {policyValidations.length ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                    </p>
                                                    <p className='changepara'>
                                                        <strong >3.</strong>
                                                        New password and confirm password should be same. {policyValidations.matchConfirmPassword ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                    </p>
                                                    
                                                    <p className='changepara'>
                                                        <strong>4.</strong>
                                                        Password must be combination of at least one numeric and one uppercase letter and one lowercase and special character [$#!()]. {policyValidations.complexity ? <FaCheck style={{ color: "green", marginBottom: "4px" }} /> : <ImCross className='checkSymbol' style={{ color: "red", fontSize: "10px", marginBottom: "4px" }} />}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}

        </main>
    );
}
export default ResetPassword;