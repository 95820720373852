
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import {  setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { FetchAutoCompleteData } from "../Common/CommanServices";

import DatePicker from 'react-datepicker';

const TodayDeals = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const userGroupName = cookies.get('userGroupName') ? cookies.get('userGroupName') : '';
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [formData, setFormData] = useState({
        Category: "",
        SubCategory: "",
        Vendor: "",
        FromDate: Todate,
        ToDate: Todate,
        ProductCode: ""
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    // Show Data on Table
    const getTodayDeals = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowTodayDeals`, { ...data, ...filterData, ...sortConfig, ...formData, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "" || res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }
    
    //Handle Clear 
    const handleClear = () => {
        setFormData({
            ...formData,
            Category: "",
            SubCategory: "",
            Vendor: "",
            FromDate: Todate,
            ToDate: Todate,
            ProductCode: ""
        });
        filterData.SearchText = '';
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getTodayDeals(value);
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/SaveTodayDeals`, { ...data, ...formData}, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                getTodayDeals()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };
    
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2,contextKey3) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2, contextKey3);
                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
 
    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    useEffect(() => {
        document.title = 'Today Deals'
        setFocus("txtCategory")
    }, [])

    useEffect(() => {
        getTodayDeals()
    }, [sortConfig, refreshGrid, formData.Category, formData.SubCategory, formData.Vendor, formData.FromDate, formData.ToDate, formData.ProductCode])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box" style={{ padding: "8px 1.25rem" }}>
                                    <div className="heading">
                                        <h6 className="m-0 font-weight-bold text-primary">Today Deals</h6>
                                    </div>
                                </div>
                                    <div className="form-box service-content-area">
                                        <div className="row ">
                                           
                                            <AutoCompleteBox
                                                label='Category'
                                                placeholder='Category'
                                                inputValue={formData.Category ? formData.Category : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'Category', 'GetCategory', formData.Vendor);
                                                }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtCategory'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSubCategory") };
                                                }}
                                            />
                                            <AutoCompleteBox
                                                label='SubCategory'
                                                placeholder='SubCategory'
                                                inputValue={formData.SubCategory ? formData.SubCategory : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'SubCategory', 'GetSubCategoryCategoryWise', formData.Category);
                                                       // GetItemsData(newInputValue)
                                                }}
                                            
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                id='txtSubCategory'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtVendor") };
                                                }}
                                            />
                                        <AutoCompleteBox
                                            label='Vendor'
                                            placeholder='Vendor'
                                            inputValue={formData.Vendor ? formData.Vendor : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'Vendor', 'GetAccountManagerWiseVendor', userGroupName.toString(), LoginUserCode.toString());
                                                }}
                                           
                                            maxLength='50'
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}
                                            id='txtVendor'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') { setFocus("txtFromDate") };
                                            }}
                                        />
                                        <div className="col-lg-3 row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label"> From Date  </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtFromDate'
                                                        className="form-control"
                                                        placeholderText='From Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        maxDate={new Date()}
                                                        selected={formData.FromDate === '' ? '' : new Date(formData.FromDate)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, FromDate: dateArr })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtToDate") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="fields">
                                                    <label className="form-label">To Date  </label>
                                                    <DatePicker
                                                        preventOpenOnFocus={true}
                                                        id='txtToDate'
                                                        className="form-control"
                                                        minDate={formData.DateFrom}
                                                        placeholderText='To Date'
                                                        dateFormat={'dd-MMM-yyyy'}
                                                        autoComplete='off'
                                                        selected={formData.ToDate === '' ? '' : new Date(formData.ToDate)}
                                                        onChange={(e) => {
                                                            let dateArr = e?.toString()?.split(' ')
                                                            dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                            setFormData({ ...formData, ToDate: dateArr })

                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtProduct") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <AutoCompleteBox
                                            divclassname="col-md-3 col-sm-3 fields"
                                            label='Product '
                                            value={formData.ProductCode ? formData.ProductCode : ''}
                                            onInputChange={
                                                (event, newInputValue) => {
                                                    handleAutoCompleteChange(event, newInputValue, 'ProductCode', 'GetProductVendorWise', formData.Vendor, formData.Category, formData.SubCategory);
                                                }}
                                           
                                            maxLength='100'
                                            options={autoCompleteOptions}
                                            setOptions={setAutoCompleteOptions}
                                            placeholder="Product "
                                            id='txtProduct'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("submit")
                                                }
                                            }}
                                        />
                                            <div className="col-lg-12 mt-4 z-0">
                                            <div className="lms-new-button text-center">
                                                {formData.Vendor && formData.ProductCode && formData.Category && formData.SubCategory && (
                                                    <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>
                                                )}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
                    <Table
                        Token={Token}
                        PageName='TodayDeals'
                       // handleEdit={() =>  }
                     //   handleDelete={}
                        handleFilter={() => {
                            getTodayDeals()
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        tableData={tableData}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>

    )
}
export default TodayDeals