
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { BulkClearData, DownloadFileAtURL, ERRORDATALIST, formatDate, HandleBulkInsertData, ImportButton, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, AutoCompleteBox, DateBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { DropDownData1, FetchAutoCompleteData, readFile } from "../Common/CommanServices";
import { toast } from 'react-toastify';
import React from 'react';

const ProgressMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showResponse, setshowResponse] = useState([])
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate()
    let tody = new Date()
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    function getCurrentTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    const [formData, setFormData] = useState({
        OrderNo: "",
        OrderStatus: "",
        StatusDate: Todate,
        StatusTime: getCurrentTime(),
        Remark: "",
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';
    const [status, setStatus] = useState([]);
    const [ImportData, setImportData] = useState([]);
    const [showTable, setshowTable] = useState(false)
    const [Name, setName] = useState('');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const [enterPressed, setEnterPressed] = useState(false);

    let GetOrderOptions = DropDownData1('GetOrderStatus');
    const inputref = useRef([]);


    const handleClear = () => {
        setFormData({
            OrderNo: "",
            OrderStatus: "",
            StatusDate: "",
            StatusTime: "",
            Remark: "",
        });
        setEdit(false)
        setColoredRow(null)
        setshowTable(false)
        setImportData([])
        BulkClearData()
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/SaveShipmentMovementMaster`, { ...data, ...formData }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus)
                setIsLoading(false)
                handleClear()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setIsLoading(false)
            }
        });
    };

    const handleInputBox = (newOrderNo) => {
        console.log('lllllllllll', newOrderNo)
        setshowTable(true)
        let OrderNo = newOrderNo

        post(`${API_SERVER}/api/Master/ShowShipmentMovementMaster`, { ...data, OrderNo }, (res) => {
            if (res.Status === "SUCCESS") {
                setshowResponse({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
            }
        });
    }
    const handleBulk = async () => {

        if (ImportData.length !== 0) {
            if (true) {

                //  setSubLoading(true)
                let Data = await HandleBulkInsertData(`${API_SERVER}/api/Master/SaveShipmentMovementMaster`, ImportData, Token, String(LoginUserCode), '', '')
                setStatus(Data)
                // setSubLoading(false)
                toast.info(`SUCCESS : ${Data.SuccessCount} Error : ${Data.ErrorCount}`)

            } else {
                toast.error('Header Format is not valid !', {
                    position: 'top-center'
                })

            }
        } else {
            toast.error('Please Choose File With Valid Data.!')

        }

    };
    const BulkSubmit = async (e) => {
        let data = await readFile(e)
        setImportData(data.exceljson)
        setName(data.FileName)
        let a = document.querySelectorAll('.jRuVVs')
        if (a.length > 0) {
            a[0].innerHTML = data.FileName
        }
    };

    const DownloadFormat = async () => {
        await DownloadFileAtURL('ProgressStatus.xlsx')
    };

    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
    const ErrorData = async () => {
        await ERRORDATALIST(status, 'ProgressStatusError')
    };

    useEffect(() => {
        if (enterPressed) {
            handleInputBox(formData.OrderNo);
            setFocus("txtOrderStatus");
            setEnterPressed(false);
        }
    }, [enterPressed]);

    useEffect(() => {
        document.title = 'Progress Master'
        if (showForm) {
            setFocus("txtOrderNo")
        }
        setFocus("txtOrderNo")

    }, [])

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box" style={{ padding: "8px 1.25rem" }}>
                                    <div className="heading">
                                        <h6 className="font-weight-bold text-primary">Progress Master</h6>
                                    </div>
                                </div>
                                <div className="form-box service-content-area1">

                                    <div className="col-lg-12 row">
                                        <div className="col-lg-4">
                                            <AutoCompleteBox
                                                id="txtOrderNo"
                                                label='Order No'
                                                divclassname='col-12 fields'
                                                placeholder='Order No'
                                                inputValue={formData.OrderNo ? formData.OrderNo : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'OrderNo', 'GetOrderNo', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                required={true}
                                                onKeyDown={(e) => {
                                                    //if (e.key === 'Enter') {
                                                    //    e.preventDefault()
                                                    //    handleInputBox(formData.OrderNo)
                                                    //    setFocus("txtOrderStatus")
                                                    //};
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        setEnterPressed(true); // Set the state when Enter is pressed
                                                    }
                                                }}

                                            />
                                            <SelectInputBox
                                                id="txtOrderStatus"
                                                divclassname="col fields"
                                                label="Status"
                                                required={true}
                                                value={formData.OrderStatus}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, OrderStatus: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputref.current['txtStatusDate'].focus();
                                                    }
                                                }}
                                            ><option value=''>--Select Status--</option>
                                                {
                                                    GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                        <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                    )
                                                }
                                            </SelectInputBox>
                                            <div className="d-flex">

                                                <DateBox
                                                    placeholder='Date'
                                                    id="txtStatusDate"
                                                    inputrefs={inputref}
                                                    divclassname="col-lg-7 fields"

                                                    label="Date"
                                                    required={true}
                                                    maxDate={new Date()}
                                                    dateFormat="dd-MMM-yyyy"
                                                    selected={formData.StatusDate === "" ? "" : new Date(formData.StatusDate)}
                                                    onChange={(e) => setFormData({ ...formData, StatusDate: formatDate(e) })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("txtDescription")
                                                        }
                                                    }}
                                                />
                                                <div className='col-lg-5 col-md-5 col-sm-12 ms-2 fields' style={{ width: "39.666667%" }}>
                                                    <label className="form-label">Status Time <span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        type="time"
                                                        format='hh:mm'
                                                        className="form-control"
                                                        placeholder="Status Time"
                                                        maxLength='10'
                                                        value={formData.StatusTime}
                                                        onChange={(e) => setFormData({ ...formData, StatusTime: e.target.value })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {

                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="fw-semibold text-black">Remark</label>
                                                <textarea
                                                    placeholder='Remark'
                                                    id="txtDescription"
                                                    className="col-12 mt-1"
                                                    name='Description'
                                                    rows='3'
                                                    value={formData.Remark}
                                                    onChange={(e) => {
                                                        setFormData({ ...formData, Remark: e.target.value })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setFocus("submit")
                                                        };
                                                    }}
                                                />
                                            </div>
                                            <ImportButton
                                                handleBulk={handleBulk}
                                                BulkSubmit={(e) => BulkSubmit(e)}
                                                downloadFormat={DownloadFormat}
                                                ErrorData={ErrorData}
                                                Status={status}
                                            >
                                            </ImportButton>

                                            <div className="col-lg-12 m-5 z-0">
                                                <div className="lms-new-button text-center">
                                                    {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id='submit' onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="col-12 ">

                                                {showResponse && showResponse.Data ?
                                                    <div className="form-box ">
                                                        <div className="row align-items-end">
                                                            <div className="table-container" >
                                                                <table className='table table-bordered table-responsive table-hover' style={{ whiteSpace: 'nowrap' }}>

                                                                    {showTable && showResponse.Data.map((item, index) => (
                                                                        <>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th colSpan='4' className="text-center text-white" style={{ background: '#a98451' }}>Order Details</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style={{ fontSize: '15px', padding: '1px', border: '2px solid gray' }} key={index}>
                                                                                {Object.entries(item).reduce((acc, [key, value], idx) => {
                                                                                    if (idx % 1 === 0) {
                                                                                        acc.push([]);
                                                                                    }
                                                                                    acc[acc.length - 1].push({ key, value });
                                                                                    return acc;
                                                                                }, []).map((row, rowIndex) => (
                                                                                    <tr key={rowIndex}>
                                                                                        {row.map(({ key, value }, cellIndex) => {
                                                                                            const truncatedValue = value.length > 30 ? value.slice(0, 30) + '...' : value;
                                                                                            return (
                                                                                                <React.Fragment key={cellIndex}>
                                                                                                    <td className='fw-bold HistoryColor'>{key}</td>
                                                                                                    <td style={key === "Product" ? { background: '#f2dede' } : null} className={key === "Product" ? 'fw-bold  text-success' : ""}>
                                                                                                        {truncatedValue}
                                                                                                    </td>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>

                                                                        </>
                                                                    ))}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="form-box service-content-area">
                        <div className="row">

                            <div className="col-lg-1"></div>


                        </div>
                    </div>
                </section>
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default ProgressMaster