import { API_SERVER } from "../../EnvConfig/env_config";
import { post } from "./service";
import ExcelJS from 'exceljs';
import { Cookies } from 'react-cookie';
import { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { AlertBox } from "./Input";

export const getCommonData = (payload, onDone) => {
    post(`${API_SERVER}/api/AutoComplete/AutoComplete`, payload, (res) => {
        onDone(res);
    });
};

export const getSearchingColumn = (payload, onDone) => {
    post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, payload, (res) => {
        onDone(res);
    });
};

export const getMasterData = (payload, onDone) => {
    post(`${API_SERVER}api/AutoComplete/GetMasterData`, payload, (res) => {
        onDone(res);
    });
};
export const FetchAutoCompleteData = async (data, Prefix = '', type, contextKey = '', contextKey2 = '', contextKey3 = '') => {
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const requestData = {
        Token,
        
        ...data
    };
    let dataList = [];
    try {
        if (Prefix !== null && Prefix !== undefined && Prefix !== '') {
            const abortController = new AbortController();
            const response = await fetch(`${API_SERVER}/api/AutoComplete/AutoComplete`, {
                method: 'POST',
                body: JSON.stringify({ ...requestData, Type: type, Prefix: String(Prefix), LoginUserCode: loginCodeString, ContextKey: contextKey, ContextKey2: contextKey2, ContextKey3: contextKey3 }),
                headers: {
                    "Content-Type": "application/json"
                },
                signal: abortController.signal
            });
            const res = await response.json();
            if (res.status === 'SUCCESS' && res.autoCompleteData) {
                dataList = res.autoCompleteData.map(item => item.values);
            } else {
                console.error('Error fetching autocomplete data:', res.message);
            }

            return dataList;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching suggestions:', error);
        return [];
    }
};
export const DropDownData = (Type, shouldFetch = false, ContextKey = '', ContextKey2 = '', ContextKey3= '', ContextKey4= '') => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const requestData = {
        Token,
        LoginUserCode: loginCodeString,
    };
    useEffect(() => {
        if (!shouldFetch) {
            setLoading(false);
            return;
        }
        const abortController = new AbortController();
        fetch(`${API_SERVER}/api/AutoComplete/GetMasterData`, {
            method: 'POST',
            body: JSON.stringify({ ...requestData, Type, ContextKey, ContextKey2, ContextKey3, ContextKey4 }),
            headers: {
                "Content-Type": "application/json"
            },
            signal: abortController.signal
        }).then((res) => res.json())
            .then((res) => {
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            })
            .finally(() => {
                abortController.abort();
            });
    }, [Type, shouldFetch, ContextKey, ContextKey2, ContextKey3, ContextKey4,])
    return { data, error, loading };
};

export const DropDownData1 = (Type, ContextKey = '', ContextKey2 = '', ContextKey3 = '', ContextKey4 = '') => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const cookies = new Cookies();
    const Token = cookies.get('token');
    const LoginUserCode = cookies.get('loginUserCode');
    const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
    const requestData = {
        Token,
        LoginUserCode: loginCodeString,
    };
    useEffect(() => {

        const abortController = new AbortController();
        fetch(`${API_SERVER}/api/AutoComplete/GetMasterData`, {
            method: 'POST',
            body: JSON.stringify({ ...requestData, Type, ContextKey, ContextKey2, ContextKey3, ContextKey4 }),
            headers: {
                "Content-Type": "application/json"
            },
            signal: abortController.signal
        }).then((res) => res.json())
            .then((res) => {
                setData(res)
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            })
            .finally(() => {
                abortController.abort();
            });
    }, [Type, ContextKey, ContextKey2, ContextKey3, ContextKey4])
    return { data, error, loading };
};
export const handleDownloadExcel = async (handleApiUrl, requestData, FileName, enableAlternateColor = false, enableHeaderColor = true, enableFilters = false) => {
    const apiUrl = handleApiUrl;
    const requestBody = requestData;
    post(apiUrl, requestBody, async (res) => {
        if (res.Status === "SUCCESS") {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1', {
                views: [{ state: 'frozen', ySplit: 1 }],
            });

            // Example: Set header row to bold and background color
            const headerRow = worksheet.addRow(res?.HeadersKey?.length > 0
                ? res.HeadersKey.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res?.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                ))


            const headerKey = (res?.HeadersKey?.length > 0 && res?.HeadersValue?.length > 0)

                ? res.HeadersValue.filter((key) => {
                    return res?.HideColumns?.length > 0 ? !res?.HideColumns?.includes(key) : key
                })
                : Array.from(
                    new Set(
                        res.Data?.flatMap((data) => Object.keys(data))
                            .filter((columnName) => !res?.HideColumns?.includes(columnName))
                    )
                );


            headerRow.font = { bold: true };
            if (enableHeaderColor) {
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'AAAAAA' },
                };
            }

            // Example: Enable filters
            if (enableFilters) {
                worksheet.autoFilter = {
                    from: { row: 1, column: 1 },
                    to: { row: 1, column: headerKey.length },
                };
            }
            for (let i = 2; i <= res.Data.length + 1; i++) {
                const row = worksheet.getRow(i);

                if (enableAlternateColor && i % 2 === 0) {
                    row.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'DDDDDD' },
                    };
                }

                row.values = res.HeadersValue?.length > 0 ?
                    res.HeadersValue?.map((key) => res.Data[i - 2][key])
                    : headerKey?.map((key) => res.Data[i - 2][key]);
            }
            // Set column widths to fit content
            worksheet.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const length = cell.value ? String(cell.value).length : 0;
                    maxLength = Math.max(maxLength, length);
                });
                column.width = maxLength < 12 ? 12 : maxLength;
            });

            const buffer = await workbook.xlsx.writeBuffer();
            const data = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${FileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else if (res.Status === 'ERROR' ) {
            AlertBox(res.Status, res.Message, res.Focus);
        }
        else if (res.Status === 'UNAUTHORIZED') {
            AlertBox(res.status, res.message, res.focus);
        }
    })

};
export const downloadImage = (image, name) => {
    const linkSource = `data:image/png;base64,${image}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
}

//export const HandleBulkInsertData = async (url, Records, data) => {
//    let SuccessCount = 0;
//    let SuccessData = "";
//    let ErrorData = "";
//    let ErrorCount = 0;
//    let id = "";
//    if (Records !== null) {
//        post(url, Records, (res) => {
//            if (res.status === "SUCCESS") {
//                SuccessData = "" + res.status;
//                SuccessCount = SuccessCount + 1;
//            } else if (res.status === 'ERROR' || res.status === 400) {
//                ErrorData = res.message;
//                ErrorCount = ErrorCount + 1;
//            }
//        })
//    } else {
//        alert('No Record Found !')
//    }
//    return { SuccessCount, SuccessData, ErrorCount, ErrorData }
//}

//export const ERRORDATALIST = (Status, FileName) => {
//    const workbook = XLSX.utils.book_new();
//    let worksheet = XLSX.utils.aoa_to_sheet([
//        (Object.keys(Status.ErrorData[0]))
//        ,
//        ...Status?.ErrorData?.map((records) =>
//            Object.keys(Status.ErrorData[0])?.map((data, i) =>
//                records[data]
//            )
//        ),
//    ]);
//    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
//    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
//    const url = window.URL.createObjectURL(data);
//    const link = document.createElement('a');
//    link.href = url;
//    link.setAttribute('download', `${FileName}.xlsx`);
//    document.body.appendChild(link);
//    link.click();
//    document.body.removeChild(link);
//    window.URL.revokeObjectURL(url);

//}
export const ERRORDATALIST = (Status, FileName) => {
    const workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.aoa_to_sheet([
        (Object.keys(Status.ErrorData[0]))
        ,
        ...Status?.ErrorData?.map((records) =>
            Object.keys(Status.ErrorData[0])?.map((data, i) =>
                records[data]
            )
        ),
    ]);
    XLSX.utils.book_append_sheet(workbook, worksheet, FileName);
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${FileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

}
export const HandleBulkInsertData = async (url, Records, data) => {
    let SuccessCount = 0
    let ErrorData = []
    let ErrorCount = 0
    if (Records.length !== 0) {
        console.log(Records, "Records,Records")
        for (let i = 0; i < Records.length; i++) {
            let stringifiedRecord = {};
            for (const key in Records[i]) {
                if (Object.hasOwnProperty.call(Records[i], key)) {
                    stringifiedRecord[key] = Records[i][key].toString();
                }
            }
            let response = { data, ...stringifiedRecord };
            await fetch(url, {
                method: 'POST',
                body: JSON.stringify(response),
                headers: {
                    "Content-Type": "application/json"
                },
                // eslint-disable-next-line no-loop-func
            }).then(async (resp) => {
                let res = await resp.json();
                if (res.status === "SUCCESS") {
                    SuccessCount = SuccessCount + 1
                } else if (res.status === 'ERROR') {
                    ErrorData.push({ ...Records[i], ERROR: res.message })
                    console.log(ErrorData, 'tttttttttttt')
                    ErrorCount = ErrorCount + 1
                }
            }
            )
        }
    } else {
        alert('No Record Found !')
    }

    return { SuccessCount, ErrorCount, ErrorData }
}

export const FileUploadChild = (props) => {
    const { label = '', message = '', types = [], children } = props;
    return (
        <>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.33317 6.66667H22.6665V16H25.3332V6.66667C25.3332 5.196 24.1372 4 22.6665 4H5.33317C3.8625 4 2.6665 5.196 2.6665 6.66667V22.6667C2.6665 24.1373 3.8625 25.3333 5.33317 25.3333H15.9998V22.6667H5.33317V6.66667Z" fill="#0658C2"></path><path d="M10.6665 14.6667L6.6665 20H21.3332L15.9998 12L11.9998 17.3333L10.6665 14.6667Z" fill="#0658C2"></path><path d="M25.3332 18.6667H22.6665V22.6667H18.6665V25.3333H22.6665V29.3333H25.3332V25.3333H29.3332V22.6667H25.3332V18.6667Z" fill="#0658C2"></path></svg>
            <div className="sc-eqUAAy file-children">
                {(message.trim() === '.' || message.trim() === '') ?
                    <span className="sc-fqkvVR"><span>{`Upload ${label}`}</span> or drop a file right here</span> :
                    <span className="sc-fqkvVR"><span className='text-success'>{`${message.substring(0, 8) + '...'}`}</span>uploaded successfully!</span>
                }
                {(message.trim() !== '.' || message.trim() !== '') &&
                    <span title={`types: ${types.join()}`} className="file-types text-info">{types.join()}</span>
                }
                {children}
            </div>
        </>
    );
}
export const readFile = async (e) => {
    const file = e
    let FileName = (e.name);
    //let ext = extArr[1];
    //let name = extArr[0];
    const data = await file.arrayBuffer(file)
    const excelFile = XLSX.read(data)
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]]
    const exceljson = XLSX.utils.sheet_to_json(excelSheet)
    return { exceljson, FileName }
}

