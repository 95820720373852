import { useEffect, useState } from "react";
import { post } from "../Common/service";
import { API_SERVER } from "../../EnvConfig/env_config";
import { useNavigate } from "react-router-dom";
import { Cookies } from 'react-cookie';
import { AlertBox } from "../Common/Input";

const WebSiteConfig = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const [websiteConfiguration, setWebsiteConfiguration] = useState({
        flashMessage: false,
        shopInMostPopularCategories: false,
        featuredBrands: false,
        shopByDiscounts: false,
        adSection: false,
        productCategory: false,
        vendorName: false,
        ratingStar: false,
        onlyLeftInStock: false,
        ratingAndReview: false,
        experienceOurAppOnGooglePlay: false,
        experienceOurAppOnAppleStore: false,
        accordian: false,
        accordianIndocraftDetails: false,
        requiredOtherProduct:false,
    })
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const handleClear = () => {
        setWebsiteConfiguration({
            flashMessage: false,
            shopInMostPopularCategories: false,
            featuredBrands: false,
            shopByDiscounts: false,
            adSection: false,
            productCategory: false,
            vendorName: false,
            ratingStar: false,
            onlyLeftInStock: false,
            ratingAndReview: false,
            experienceOurAppOnGooglePlay: false,
            experienceOurAppOnAppleStore: false,
            accordian: false,
            accordianIndocraftDetails: false,
            requiredOtherProduct: false,
        });
    }
    const getWebsiteConfigData = async () => {
        setIsLoading(true)
        await post(`${API_SERVER}/api/Master/GetWebSiteConfigurationData`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                setIsLoading(false)
                setWebsiteConfiguration({
                    flashMessage: res.flashMessage,
                    shopInMostPopularCategories: res.shopinMostPopularCategories,
                    featuredBrands: res.featuredBrands,
                    shopByDiscounts: res.shopByDiscounts,
                    adSection: res.adSection,
                    productCategory: res.productCategory,
                    vendorName: res.vendorName,
                    ratingStar: res.ratingStar,
                    onlyLeftInStock: res.onlyLeftinStock,
                    ratingAndReview: res.ratingandReview,
                    experienceOurAppOnGooglePlay: res.experienceourapponGooglePlay,
                    experienceOurAppOnAppleStore: res.experienceourapponAppleStore,
                    accordian: res.accordian,
                    accordianIndocraftDetails: res.accordianIndocraftDetails,
                    requiredOtherProduct: res.requiredOtherProduct,
                })
              
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {

            }
            setIsLoading(false)
        });
    }
    const handleSubmit = async () => {
        setIsLoading(true)
        await post(`${API_SERVER}/api/Master/WebSiteConfiguration`, { ...data, ...websiteConfiguration }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status,res.message,res.focus)
                getWebsiteConfigData()
                handleClear()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus)
            }
            setIsLoading(false);
        })
    };
    const [isCollapsed, setIsCollapsed] = useState({
        HomePageConfig: true,
        ProductViewConfig: true,
        ProductDetailsPageConfig: true,
        FooterConfig: true,

    })
    useEffect(() => {
        getWebsiteConfigData()
    }, [])

    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >

                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">

                                <div className="form-box service-content-area">
                                    <h6 className="font-weight-bold text-primary" style={{ fontWeight: "bold", fontSize: "20px" }}>Website Configuration</h6>
                                    <div className="row align-items-end">
                                        <div className="Product_container my-3" >
                                            <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, HomePageConfig: !isCollapsed.HomePageConfig }) }}>
                                                <h6 className={` m-0   ${isCollapsed.HomePageConfig ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                    <span className=''>Home Page Configuration </span>

                                                </h6>
                                                <div  >
                                                    <i className={`fa   ${!isCollapsed.HomePageConfig ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                            </div>
                                            {isCollapsed.HomePageConfig ? <div className='row mt-2 mx-0'>
                                                <div className="mx-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="flashMessage"
                                                                >
                                                                    Flash Message
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="flashMessage"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.flashMessage}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, flashMessage: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="shopInMostPopularCategories"
                                                                >
                                                                    Shop in Most Popular Categories
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="shopInMostPopularCategories"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.shopInMostPopularCategories}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, shopInMostPopularCategories: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="featuredBrands"
                                                                >
                                                                    Featured Brands
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="featuredBrands"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.featuredBrands}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, featuredBrands: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="shopByDiscounts"
                                                                >
                                                                    Shop By Discounts
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="shopByDiscounts"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.shopByDiscounts}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, shopByDiscounts: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="adSection"
                                                                >
                                                                    Ad Section
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="adSection"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.adSection}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, adSection: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div> : <></>}
                                        </div>
                                        <div className="Product_container my-3" >
                                            <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductViewConfig: !isCollapsed.ProductViewConfig }) }}>
                                                <h6 className={` m-0   ${isCollapsed.ProductViewConfig ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                    <span className=''>Product View Configuration </span>

                                                </h6>
                                                <div  >
                                                    <i className={`fa   ${!isCollapsed.ProductViewConfig ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                            </div>
                                            {isCollapsed.ProductViewConfig ? <div className='row mt-2 mx-0'>
                                                <div className="mx-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="productCategory"
                                                                >
                                                                    Product Category
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="productCategory"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.productCategory}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, productCategory: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="vendorName"
                                                                >
                                                                    Vendor Name
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="vendorName"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.vendorName}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, vendorName: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="ratingStar"
                                                                >
                                                                    Rating Star
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="ratingStar"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.ratingStar}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, ratingStar: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="onlyLeftInStock"
                                                                >
                                                                    Only Left in Stock
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="onlyLeftInStock"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.onlyLeftInStock}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, onlyLeftInStock: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="requiredOtherProduct"
                                                                >
                                                                   Required Other Product
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="RequiredOtherProduct"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.requiredOtherProduct}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, requiredOtherProduct: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>
                                                </div>



                                            </div> : <></>}
                                        </div>
                                        <div className="Product_container my-3" >
                                            <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, ProductDetailsPageConfig: !isCollapsed.ProductDetailsPageConfig }) }}>
                                                <h6 className={` m-0   ${isCollapsed.ProductDetailsPageConfig ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                    <span className=''>Product Details Page Configuration </span>

                                                </h6>
                                                <div  >
                                                    <i className={`fa   ${!isCollapsed.ProductDetailsPageConfig ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                            </div>
                                            {isCollapsed.ProductDetailsPageConfig ? <div className='row mt-2 mx-0'>
                                                <div className="mx-4">
                                                    <div className="row">

                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="ratingAndReviewProductDetails"
                                                                >
                                                                    Rating and Review
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="ratingAndReviewProductDetails"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.ratingAndReview}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, ratingAndReview: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div> : <></>}
                                        </div>
                                        <div className="Product_container my-3" >
                                            <div style={{ cursor: 'pointer' }} className="HeaderforProductlist px-2" onClick={() => { setIsCollapsed({ ...isCollapsed, FooterConfig: !isCollapsed.FooterConfig }) }}>
                                                <h6 className={` m-0   ${isCollapsed.FooterConfig ? ' ' : ''}`} style={{ fontWeight: "bold" }} >
                                                    <span className=''>Footer Configuration</span>

                                                </h6>
                                                <div  >
                                                    <i className={`fa   ${!isCollapsed.FooterConfig ? 'fa-chevron-down' : 'fa-chevron-up'}`} aria-hidden="true"></i></div>
                                            </div>
                                            {isCollapsed.FooterConfig ? <div className='row mt-2 mx-0'>

                                                <div className="mx-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="experienceOurAppOnGooglePlay"
                                                                >
                                                                    Experience our app on Google Play
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="experienceOurAppOnGooglePlay"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.experienceOurAppOnGooglePlay}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, experienceOurAppOnGooglePlay: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="experienceOurAppOnAppleStore"
                                                                >
                                                                    Experience our app on Apple Store
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="experienceOurAppOnAppleStore"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.experienceOurAppOnAppleStore}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, experienceOurAppOnAppleStore: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="accordian"
                                                                >
                                                                    Accordian
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="accordian"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.accordian}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, accordian: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="form-check form-switch">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="accordianIndocraftDetails"
                                                                >
                                                                    Accordian - Indocraft Details
                                                                </label>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="accordianIndocraftDetails"
                                                                    name='Active'
                                                                    checked={websiteConfiguration.accordianIndocraftDetails}
                                                                    onChange={(e) => setWebsiteConfiguration({ ...websiteConfiguration, accordianIndocraftDetails: e.target.checked })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div> : <></>}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div style={{ padding: '10px 0px  0px 10px' }}>
                                <button
                                    className="button btn apply-btn  mt-0"
                                    style={{ padding: '2px 80px', width: 'fit-Content', backgroundColor: "#689D15", color: "white" }}
                                    onClick={() => { handleSubmit() }}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>

                    </div>

                </section>
            </main>
        </>
    )
}

export default WebSiteConfig;