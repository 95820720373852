
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { DropDownData, handleDownloadExcel } from "../Common/CommanServices";
import { Table } from "../Common/Table";

const OPSProcessMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showgrid, setShowGrid] = useState(true)
    const [showForm, setShowForm] = useState(false);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [tableData, setTableData] = useState([])
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const navigate = useNavigate()
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const [formData, setFormData] = useState({
        OPSProcessID: "",
        ProcessType: "",
        Steps: "",
        SequenceNo: ""
    }
    );
    const [isExpanded, setIsExpanded] = useState(false);
    const Token = cookies.get('token')
    const [showTable, setshowTable] = useState(false)
    const LoginUserCode = cookies.get('loginUserCode');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    let GetOrderOptions = DropDownData('GetOPSProcessType', showForm);

    const handleClear = () => {
        setFormData({
            OPSProcessID: "",
            ProcessType: "",
            Steps: "",
            SequenceNo: ""
        });
        setSortConfig({ SortColumn: null, SortOrder: null })
        setEdit(false)
        setshowTable(false)
    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const getOPSMasterData = async (CurrentPage, ProcessType) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowOPSProcessMaster`, { ...data, ProcessType, ...filterData, ...sortConfig, Type: "S", PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const handleChange1 = (val) => {
        getOPSMasterData(val);
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateOPSProcessMaster`, { ...data, ...formData }, (res) => {
            if (res.status === "SUCCESS") {
                setIsLoading(false);
                AlertBox(res.status, res.message, res.focus)
                handleClear()
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.status === "ERROR") {
                AlertBox(res.status, res.message, res.focus);
                setIsLoading(false)
            }
        });
    };
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    const handleEditData = async (id, ProcessType) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditOPSProcessMaster`, { ...data, OPSProcessID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    OPSProcessID: res.opsProcessID,
                    ProcessType: res.processType,
                    Steps: res.steps,
                    SequenceNo: res.sequenceNo,
                });
                getOPSMasterData('', res.processType)
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }
    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteOPSProcessMaster`, { ...data, OPSProcessID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
                getOPSMasterData()
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
                setTableData({ ...res })
            }
            setIsLoading(false)
            getOPSMasterData()
        })
    }
    useEffect(() => {
        document.title = 'OPSProcessMaster'
        if (showForm) {
            setFocus("txtProcessType");
        }
    }, [showForm, setFocus])

    useEffect(() => {
        getOPSMasterData('',)
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtProcessType");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowOPSProcessMaster', ExportRequest, 'OPSProcessMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='OPS Process Master'
                                />
                                {
                                    showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                            <SelectInputBox
                                                id="txtProcessType"
                                                divclassname="col col-lg-3 fields"
                                                label="Process Type"
                                                required={true}
                                                value={formData.ProcessType}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, ProcessType: e.target.value })
                                                    getOPSMasterData('', e.target.value)
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtSteps")
                                                    }
                                                }}
                                            ><option value=''>--Select Process--</option>
                                                {
                                                    GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                        <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                    )
                                                }
                                            </SelectInputBox>
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtSequenceNo")
                                                    };
                                                }}
                                                label='Steps'
                                                id='txtSteps'
                                                maxLength='30'
                                                placeholder="Steps"
                                                value={formData.Steps}
                                                onChange={(e) => setFormData({ ...formData, Steps: e.target.value })}

                                                required
                                            />
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("submit")
                                                    };
                                                }}
                                                label='SequenceNo'
                                                id='txtSequenceNo'
                                                maxLength='30'
                                                placeholder="Sequence No"
                                                value={formData.SequenceNo}
                                                onChange={(e) => setFormData({ ...formData, SequenceNo: e.target.value })}
                                                required
                                            />
                                            <div className="col col-lg-3 ">
                                                <div className="lms-new-button text-center d-flex gap-2 ps-3 mt-4">
                                                    <button className="button save-btn" id='submit' onClick={handleSubmit}>
                                                        {edit ? <><i className="bi bi-check-lg" /> Update</> : <><i className="bi bi-check-lg" /> Save </>}

                                                    </button>
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='OPSProcessMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getOPSMasterData();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        //loading={loading}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={() => handleChange1(currentPageNo)}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}

                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default OPSProcessMaster