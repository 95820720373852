
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, number, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, InputBox, handleFileUploader } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { FileUploader } from "react-drag-drop-files";
import { handleDownloadExcel } from "../Common/CommanServices";
import Modal from 'react-bootstrap/Modal';

const SectionMaster = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [showgrid, setShowGrid] = useState(true)
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const fileType = 'jpg,png'
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        SectionID: "",
        SectionCode: "",
        SectionName: '',
        SortNo: "",
        NoOfRows: "",
        SectionType: "",
        Sponsored: true,
        AdImage: '',
        AdImageExt: "",
        AdImageName: "",
        ProductPath: '',
        IsActive: true,
        IsScrollable: true,
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');

    // Display data and GETDAT
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    const getSectionMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowSectionMaster`, { ...data, ...filterData, ...sortConfig, Type: 'S', PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })

            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            SectionID: "",
            SectionCode: "",
            SectionName: '',
            SortNo: "",
            NoOfRows: "",
            SectionType: "",
            AdImage: '',
            AdImageExt: "",
            AdImageName: "",
            IsActive: true,
            IsScrollable: true,
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setColoredRow(null)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getSectionMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateSectionMaster`, { ...data, ...formData }, (res) => {
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setColoredRow(null)
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditSectionMaster`, { ...data, SectionID: id }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    SectionID: res.editRecord.sectionID,
                    SectionCode: res.editRecord.sectionCode,
                    SectionType: res.editRecord.sectionType,
                    Sponsored: res.editRecord.sponsored,
                    SectionName: res.editRecord.sectionName,
                    SortNo: res.editRecord.sortNo,
                    NoOfRows: res.editRecord.noOfRows,
                    IsActive: res.editRecord.isActive,
                    IsScrollable: res.editRecord.isScrollable,
                    AdImage: res.editRecord.adImage,
                    AdImageExt: res.editRecord.adImageExt,
                    AdImageName: res.editRecord.adImageName,
                    ProductPath: res.editRecord.productPath,
                });
                setShowForm(true)
                setColoredRow(id)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteSectionMaster`, { ...data, SectionID: id }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }


    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }

    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }
     
    useEffect(() => {
        document.title = 'Section Master';
        if (showForm) {
            setFocus("txtSectionName");
        }
    }, [showForm, setFocus]);

    useEffect(() => {
        getSectionMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                        if (!showForm) {
                                            setFocus("txtUserCode");
                                        }
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowSectionMaster', ExportRequest, 'SectionMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Section Master'
                                />


                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                           
                                            <InputBox
                                                label='Section Code'
                                                maxLength='30'
                                                placeholder='System Generated'
                                                value={formData.SectionCode}
                                                disabled
                                                required
                                            />

                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSortNo") };
                                                }}
                                                placeholder='Section Name'
                                                label='Section Name'
                                                id="txtSectionName"
                                                maxLength='30'
                                                value={formData.SectionName}
                                                onChange={(e) => setFormData({ ...formData, SectionName: e.target.value })}

                                                required
                                            />
                                            <InputBox
                                                id="txtSortNo"
                                                type="text"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtNoOfRows") };
                                                }}
                                                placeholder='Sort No '
                                                label='Sort No '
                                                value={formData.SortNo}
                                                onChange={(e) => setFormData({ ...formData, SortNo: number(e.target.value) })}
                                                maxLength='30'
                                                required
                                            />
                                           
                                            <div className="col-lg-3 col-md-3 fields">
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                                <label className="form-label"> Section Type<span style={{ color: "red" }}> *</span></label>
                                                {formData.SectionType === 'AD' &&
                                                        <div className="col-md-6 d-flex">
                                                            <label className="form-label">Sponsored<span style={{ color: "red" }}> *</span></label>
                                                    <div className="form-check1 form-switch1">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="SponserdCheckbox"
                                                            checked={formData.Sponsored}
                                                            onChange={(e) => setFormData({ ...formData, Sponsored: e.target.checked })}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    if (e.key === 'Enter') { setFocus("txtSectionName") };
                                                                };
                                                            }}
                                                        />

                                                            </div>
                                                            
                                                        </div>
                                                
                                                    }
                                                </div>
                                                <select
                                                    id="txtSectionType"
                                                    className="fields col-lg-12 col-md-12"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                    }}
                                                    value={formData.SectionType}
                                                    onChange={(e) => setFormData({ ...formData, SectionType: e.target.value, AdImageName: '', AdImage: "", AdImageExt: "", ProductPath: '' })}
                                                required
                                                children={<>
                                                    <option value=''>--Select--</option>
                                                    <option value='AD'>AD</option>
                                                    <option value='Product'>Product</option>
                                                </>
                                               }
                                                />
                                            </div>



                                            {formData.SectionType === 'AD' &&
                                                <>
                                               
                                                    <div className="col-lg-3 col-md-4 col-sm-6 "
                                                        id="txtBannerImage">
                                                    <div className="fields">
                                                        <label className="form-label gap-2">AD Image<span className="Required">*  </span>
                                                        </label>
                                                            <FileUploader
                                                                classes='file-uploader emp-photo'
                                                            types={fileType.split(",")}
                                                            handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'AdImage', 'AdImageExt', 'AdImageName')}
                                                                children={<FileUploadChild label='Banner Image' message={`${formData.AdImageName}.${formData.AdImageExt}`} types={fileType.split(",")} />}
                                                            />
                                                    </div>
                                                    <div className="col">
                                                        <div className="my-2">
                                                            <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                                <div style={{ fontSize: '13px' }}>Note: For Best View</div>
                                                                <div className="notefont">
                                                                    <div>* Width should be between (1290-1320)Pixels.</div>
                                                                    <div>* Maximum Image Size 2 mb.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    {formData.AdImage ?
                                                    <div className='col-lg-3 col-sm-6 ' >
                                                        <div className="m-3">
                                                            <img
                                                                onClick={() => handleShowImageInModal(`data:image/${formData.AdImageExt};base64,${formData.AdImage}`)}
                                                                className='border border-success img-thumbnail '
                                                                src={`data:image/png;base64,${formData.AdImage}`}
                                                                alt='logo'
                                                                style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                            />
                                                        </div>
                                                        </div>
                                                        : <></>}


                                                    <InputBox
                                                        id="txtProductPath"
                                                        type="text"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtSectionType") };
                                                        }}
                                                        placeholder='Product Path'
                                                        label='Product Path'
                                                        value={formData.ProductPath}
                                                        onChange={(e) => setFormData({ ...formData, ProductPath: e.target.value })}
                                                        maxLength='250'
                                                    required
                                                    />
                                                </>
                                            }
                                            <InputBox
                                                id="txtNoOfRows"
                                                type="text"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') { setFocus("txtSectionType") };
                                                }}
                                                inputStyle={{ minWidth: '70px', textAlign: 'right' }}
                                                placeholder='No of Rows'
                                                label='No of Rows'
                                                value={formData.NoOfRows}
                                                onChange={(e) => setFormData({ ...formData, NoOfRows: number(e.target.value) })}
                                                maxLength='30'
                                            //required
                                            />
                                            <div className="col-md-3">
                                                <div className={formData.AdImage !== "" ? 'pt-1' : 'pt-4'}>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label">Active</label>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="activeCheckbox"
                                                    checked={formData.IsActive}
                                                    onChange={(e) => setFormData({ ...formData, IsActive: e.target.checked })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            if (e.key === 'Enter') { setFocus("scrollableCheckbox") };
                                                        };
                                                    }}
                                                    />
                                                </div>
                                              </div> 
                                            </div>
                                            <div className="col-md-3">
                                                <div className={formData.AdImage !== "" ? 'pt-1' :'pt-4'}>
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label">Scrollable</label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="scrollableCheckbox"
                                                        checked={formData.IsScrollable}
                                                        onChange={(e) => setFormData({ ...formData, IsScrollable: e.target.checked })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                             </div>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='SectionMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getSectionMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Section Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='SectionImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default SectionMaster