
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AutoCompleteBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { DropDownData1, FetchAutoCompleteData } from "../Common/CommanServices";
import { ReportTable } from "../Common/Table";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
const Userlog = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [isLoading, setIsLoading] = useState(false);
    const [showgrid, setShowgrid] = useState(false);
    const [tableData, setTableData] = useState([])
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
    const navigate = useNavigate()
    let tody = new Date()
    const [IsUpdate, setIsUpdate] = useState(false)
    const toDateArr = tody?.toString()?.split(' ');
    const Todate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[3]}`;
    const [formData, setFormData] = useState({
        DateFrom: Todate,
        DateTo: Todate,
        UserName: "",
        LogType: "",
        FormName: "",
        ActionDescription: "",
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode').toString();
    const Source = 'web';
   
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: cookies.get('loginUserCode').toString(),
    }
    let GetOrderOptions = DropDownData1('GetLogType',);
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })
    const handleClear = () => {
        setFormData({
            DateFrom: Todate,
            DateTo: Todate ,
            UserName: "",
            LogType: "",
            FormName: "",
            ActionDescription: "",
        });
        setIsUpdate(true)
    }

    const handleSubmit = async (CurrentPage) => {
        setShowgrid(true)
        setIsLoading(true)
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
       
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, PageIndex: `${PageIndex}`, Token, LoginUserCode: loginCodeString, Source,
        }
        post(`${API_SERVER}/api/User/ShowUserLogReport`, { ...data }, (res) => {
            if (res.Status === 'SUCCESS') {
                setIsLoading(false);
                setTableData({ ...res })
            }
            else if(res.Message === 'EXPIRED') {
                navigate('/Login')
            }
            else if(res.Status === 'ERROR' ) {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });
    };
    const handleChange1 = (event, value) => {
        handleSubmit(value);
    };
    
    const autoCompleteTimeoutRef = useRef(null);
    const handleAutoCompleteChange = async (event, newValue, field, type, contextkey, contextKey2) => {
        setFormData({ ...formData, [field]: newValue })
        if (autoCompleteTimeoutRef.current) {
            clearTimeout(autoCompleteTimeoutRef.current);
        }
        if (event?.type === 'change') {
            autoCompleteTimeoutRef.current = setTimeout(async () => {
                const autoOptions = await FetchAutoCompleteData(data, newValue, type, contextkey, contextKey2);

                setAutoCompleteOptions(autoOptions ? autoOptions : []);
            },)
        }
    };
   
    useEffect(() => {
        handleSubmit()
    }, []);
    useEffect(() => {
        document.title = 'User Log Report';
        //setFocus("txtFromDate");
        if (IsUpdate) {
            setIsUpdate(false);
            handleSubmit() 
        }
    }, [IsUpdate]);


    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <div className="heading-box">
                                    <div className="heading">
                                        <h6 className="font-weight-bold text-primary">User Log Report</h6>
                                    </div>
                                </div>
                                <div className="mx-4">
                                    <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="fields">
                                                    <label className="form-label">Date From</label>
                                                <DatePicker
                                                    preventOpenOnFocus={true}
                                                    id='txtFromDate'
                                                    className="form-control"
                                                    placeholderText='Date From'
                                                    dateFormat={'dd-MMM-yyyy'}
                                                    autoComplete='off'
                                                    maxDate={new Date()}
                                                    //showMonthDropdown
                                                    selected={formData.DateFrom === '' ? '' : new Date(formData.DateFrom)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setFormData({ ...formData, DateFrom: dateArr })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                    }}
                                                    />
                                                 </div>
                                                 </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="fields">
                                                    <label className="form-label">Date To</label>
                                                <DatePicker
                                                    preventOpenOnFocus={true}
                                                    id='txtToDate'
                                                    className="form-control"
                                                    minDate={formData.DateFrom}
                                                    placeholderText='Date To'
                                                    dateFormat={'dd-MMM-yyyy'}
                                                    autoComplete='off'
                                                    selected={formData.DateTo === '' ? '' : new Date(formData.DateTo)}
                                                    onChange={(e) => {
                                                        let dateArr = e?.toString()?.split(' ')
                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                        setFormData({ ...formData, DateTo: dateArr })
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') { setFocus("txtUserName") };
                                                    }}
                                                    />
                                                 </div>
                                                </div>
                                              <AutoCompleteBox
                                                id="txtUserName"
                                                label='User'
                                                divclassname='col-lg-3 fields'
                                                placeholder='User Name'
                                                inputValue={formData.UserName ? formData.UserName : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'UserName', 'GetUserCode', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                onKeyDown={(e) => {
                                                   
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus("txtLogType")
                                                    };
                                                }}
                                            />
                                            <SelectInputBox
                                                id="txtLogType"
                                                divclassname="col-lg-3 fields"
                                                label="Log Type"
                                                value={formData.LogType}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, LogType: e.target.value })
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtFormName")
                                                    }
                                                }}
                                            ><option value=''>--Select Type--</option>
                                                {
                                                    GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                        <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                    )
                                                }
                                            </SelectInputBox>
                                            <AutoCompleteBox
                                                id="txtFormName"
                                                label='Form Name'
                                                divclassname='col-lg-3 fields'
                                                placeholder='Form Name'
                                                inputValue={formData.FormName ? formData.FormName : ''}
                                                onInputChange={
                                                    (event, newInputValue) => {
                                                        handleAutoCompleteChange(event, newInputValue, 'FormName', 'GetFormName', '');
                                                    }}
                                                maxLength='50'
                                                options={autoCompleteOptions}
                                                setOptions={setAutoCompleteOptions}
                                                onKeyDown={(e) => {
                                                  
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault()
                                                        setFocus("txtActionDescription")
                                                    };
                                                }}
                                            />
                                            <div className="col-lg-3 fields">
                                            <label className="fw-semibold text-black">Action Description</label>
                                                <div>
                                                    <textarea
                                                        placeholder='Action Description'
                                                        id="txtActionDescription"
                                                        className="col-12"
                                                        name='Description'
                                                        rows=''
                                                        value={formData.ActionDescription}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, ActionDescription: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                setFocus("submit")
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                          
                                        <div className="col-11 m-4  z-0">
                                            <div className="lms-new-button text-center">
                                                {(isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                    Please wait...
                                                </button> : <button className="button search-btn" id='submit' onClick={handleSubmit}>
                                                        <i class="fa-solid fa-magnifying-glass"></i> Search
                                                </button>)}
                                                <button className="button reset-btn" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && (
                    <ReportTable
                        Token={Token}
                        PageName='UserLogReport'
                        handleFilter={() => {
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        tableData={tableData}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}
            </main>
        </>
    )
}
export default Userlog