
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, setFocus } from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, handleFileUploader, InputBox, SelectInputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import { Table } from "../Common/Table";
import { handleDownloadExcel } from "../Common/CommanServices";
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';

const OfferBannerImage = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [ColoredRow, setColoredRow] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [tableData, setTableData] = useState([])
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [MenuText, setMenuText] = useState("Offer Banner ");
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const fileType = 'jpg,png'
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": []
    })

    const [formData, setFormData] = useState({
        "OfferBannerID": "",
        "SequenceNo": "",
        "BannerType": "",
        "Active": true,
        "BannerImg": "",
        "BannerImgExt": "",
        "BannerImgName": ""
    });
    const Token = cookies.get('token')
    const LoginUserCode = cookies.get('loginUserCode');
    const data = {
        Token: Token,
        LoginUserCode: LoginUserCode ? String(LoginUserCode) : "",
        Source: 'web'
    }
    // Display data and GETDAT
    const getOfferMaster = async (CurrentPage) => {
        let PageIndex = 1
        if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
            PageIndex = CurrentPage;
            setCurrentPageNo(CurrentPage);
        }
        else {
            setCurrentPageNo(1);
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/ShowOfferBannerImageMaster`, { ...data, ...filterData, ...sortConfig, Type: "S", PageIndex: `${PageIndex}` }, (res) => {
            if (res.Status === "SUCCESS") {
                setTableData({ ...res })
                setIsLoading(false)
            }
            else if (res.Message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Message !== "" || res.Status === "ERROR") {
                setTableData({ ...res })
            }
            setIsLoading(false)
        });

    }
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            "OfferBannerID": "",
            "SequenceNo": "",
            "BannerType": "",
            "Active": true,
            "BannerImg": "",
            "BannerImgExt": "",
            "BannerImgName": ""
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getOfferMaster(value);
    };

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/InsertUpdateOfferBannerImageMaster`, { ...data, ...formData }, (res) => {
            localStorage.setItem("Response", JSON.stringify(res))
            if (res.status === 'SUCCESS') {
                setIsLoading(false);
                AlertBox(res.status, res.message, "");
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OfferBannerID: id
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditOfferBannerImageMaster`, { ...data }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    "OfferBannerID": res.offerBannerID,
                    "SequenceNo": res.sequenceNo,
                    "BannerType": res.bannerType,
                    "Active": res.active,
                    "BannerImg": res.bannerImg,
                    "BannerImgExt": res.bannerImgExt,
                    "BannerImgName": res.bannerImgName
                });
                setShowForm(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false)
        });
    }

    const handleDelete = async (id) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            OfferBannerID: id
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteOfferBannerImageMaster`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }


    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }
    let ExportRequest = {
        ...filterData,
        ...data,
        Type: 'E',
        PageIndex: '1'
    }
    useEffect(() => {
        document.title = 'Offer Banner Image'
        if (showForm) {
            setFocus("txtSequenceNo")
        }
    }, [showForm, setFocus])
    useEffect(() => {
        getOfferMaster()
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        handleClear()
                                       
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowOfferBannerImageMaster', ExportRequest, 'OfferBannerMaster')
                                    }}
                                    isExpanded={isExpanded}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Offer/Banner Image'
                                />

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row">
                                            <InputBox
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtType")
                                                    };
                                                }}
                                                label='Sequence No'
                                                id='txtSequenceNo'
                                                maxLength='30'
                                                placeholder="Sequence No"
                                                value={formData.SequenceNo}
                                                onChange={(e) => setFormData({ ...formData, SequenceNo: e.target.value })}

                                                required
                                            />
                                            <SelectInputBox
                                                id='txtType'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setFocus("txtBannerType")
                                                    };
                                                }}
                                                label='Banner Type'
                                                value={formData.BannerType}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData, BannerType: e.target.value,
                                                    })
                                                }}
                                                required={true}
                                                inputclassname='p-1'
                                            >
                                                <option value=''>--Select--</option>
                                                <option value='Offer'>Offer</option>
                                                <option value='Banner'>Banner</option>
                                            </SelectInputBox>
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtBannerImage">
                                                <div className="fields">
                                                    <label className="form-label ">Banner Image</label>
                                                    <span style={{ color: "red" }}> *</span>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'BannerImg', 'BannerImgExt', 'BannerImageName')}
                                                        children={<FileUploadChild label='Banner Image' message={formData.BannerImgExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note: For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (1500-1530)Pixels.</div>
                                                                <div>* Height should be between (275-300)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.BannerImg ?
                                                <div className='col-lg-3 p-3  ' style={{ left: '15px', position: "relative" }}>
                                                    <img
                                                        onClick={() => handleShowImageInModal(`data:image/${formData.BannerImgExt};base64,${formData.BannerImg}`)}
                                                        className='border border-success img-thumbnail '
                                                        src={`data:image/png;base64,${formData.BannerImg}`}
                                                        alt='logo'
                                                        style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                    />
                                                </div>
                                                :
                                                <></>}
                                            <div className="col-lg-3 col-md-4 col-sm-6 pt-4">
                                                <div className="form-check form-switch">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.Active}
                                                        onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" id="btnSave" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}

                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <Table
                        Token={Token}
                        PageName='OfferBannerImageMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleFilter={() => {
                            getOfferMaster();
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        tableData={tableData}
                        //  loading={loading}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Offer Banner Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='OfferBannerImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default OfferBannerImage


