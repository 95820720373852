import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import config, { API_SERVER } from "../../EnvConfig/env_config";
import { AlertBox } from "../Common/Input";
import { post } from "../Common/service";

//custom images

//loder



const ForgotPasswordSlide1 = () => {
    // initial state
    const [user, setUser] = useState({ userCode: '' });
    const [isSeller, setIsSeller] = useState(false);
    const [loading, setLoading] = useState(false);
    const [focus, setFocus] = useState('');
    const [alert, setAlert] = useState({ status: '', message: '', focus: '' });
    const StaticToken = config.secret_key;
    const source = 'web'
    useEffect(() => {
        setFocus("floatingInput")
        document.title = 'Indo CraftWorld Forget Password'
    }, [])

    //Recipe Change Handler
    const HandleChange = (prop, value) => {
        setUser(user => ({ ...user, [prop]: value }))
    }


    //submit button Handler
    const HandleSubmit = () => {
        const data = {
            Token: StaticToken,
            UserCode: user.userCode !== '' ? user.userCode : '',
            Source: source
        }
        setLoading(true)
        if (isSeller === true) {
            post(`${API_SERVER}/api/UserLogin/ForgetPasswordForSeller`, { ...data }, (res) => {
                if (res.status === "SUCCESS") {
                    setAlert({ status: res.status, message: res.message, focus: '' });
                }
                if (res.status === "ERROR") {
                    setUser(user => ({ ...user, userCode: "" }))
                    AlertBox(res.status, res.message, res.focus);

                    setLoading(false)
                } else {
                    setLoading(false)
                }
            });
        }
        else {
            post(`${API_SERVER}/api/UserLogin/ForgetPassword`, { ...data }, (res) => {
                if (res.status === "SUCCESS") {
                    setAlert({ status: res.status, message: res.message, focus: '' });
                }
                if (res.status === "ERROR") {
                    setUser(user => ({ ...user, userCode: "" }))
                    AlertBox(res.status, res.message, res.focus);
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            });
        }
    }
    useEffect(() => {
        const url = window.location.href;
        if (url.includes("seller")) {
            setIsSeller(true);
        }
    }, []);
    return (
        <>
            <div className='login_main'>
                <div className='dot_shape position-absolute'>
                    <img src="./assets/img/dot.png" alt="shape" />
                </div>
                <div className='india_clip position-absolute'>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-md-6">
                 <div className='fxt-column-wrap justify-content-center'>
                     <div className='lftimg'>
                         <img src="./assets/img/login-bg.svg" alt="login" />
                     </div>
                 </div>
             </div> */}
                        <div className="col-md-4">
                            <div className="fxt-column-wrap justify-content-center">
                                <div className='form-container '>
                                    <div className='text-center mb-5'>
                                        <img src="./assets/img/indocraft.png" style={{ width: '200px' }} alt="QR Code" />

                                    </div>
                                    <div class="form-group mb-3 position-relative">
                                        <h5 className='fs-5 text-center mt-4'><span style={{ color: 'gray', fontWeight: "bold" }}>Forgot</span> <span style={{ color: '#fdb813', fontWeight: "bold" }}>Password</span></h5>
                                        {alert?.status !== 'SUCCESS' && (
                                            <>
                                                <div className="form-group text-start mb-3">
                                                    <label className="form-label mb-1">{isSeller === false ? 'User Code' : 'Email ID'}<span className='required'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="floatingInput"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("btnsubmit") };
                                                        }}
                                                        autoComplete="off"
                                                        placeholder={isSeller === false ? "User Code" : "Email ID"}
                                                        name='UserCode'
                                                        value={user.userCode}
                                                        onChange={(e) => HandleChange('userCode', e.target.value)}
                                                    />
                                                </div>

                                                <button
                                                    id="btnsubmit"
                                                    className='btn-primary btn w-100'
                                                    onClick={HandleSubmit}
                                                >
                                                    Reset Forgot Password
                                                </button>
                                                <div className='login-trouble mb-2 mt-4'>
                                                    <Link to='/Login' style={{ textDecoration: "underline" }}> Back To Login</Link>
                                                </div>
                                            </>
                                        )}
                                        {alert?.status === 'SUCCESS' && (
                                            <>
                                                <div className="alert alert-success" role="alert">
                                                    {alert?.message}
                                                </div>
                                                <div className='login-trouble mb-2'>
                                                    <button
                                                        id="btnsubmit"
                                                        className='btn-primary btn w-100'
                                                    >
                                                        <Link to='/Login'><span style={{ color: "white" }}>Back To Login</span></Link>
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Forgot Password Form End */}
        </>

    )
}

export default ForgotPasswordSlide1;