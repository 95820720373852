import { useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import { Cookies } from 'react-cookie';
import { FaFilePdf } from "react-icons/fa6";
import { API_SERVER } from "../../EnvConfig/env_config";
import { post } from "./service";
import { Navigate } from "../../../node_modules/react-router-dom/dist/index";
import { AlertBox, DateBox, InputBox, SelectInputBox, Switch } from "./Input";
import Swal from 'sweetalert2';
import { DropDownData1, } from "./CommanServices";
import { setFocus } from "./Common";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
export const Table = ({ Token, PageName, handleTabClick, tableData,
    ProductPreview, handleVerificationDispatch, handleHistory,
    handleCheck, ShowEyeRecords, filterTable, setFilterTable,
    handleReviewData, handleOrderViewData, handleCheckAll, handleComplainView, handleComplainAdd, DownloadShipmentPrint, DownloadBulkInvoicePrint,
    checkAll, showCheckBox, currentPageNo, handleChange1,
    handleSorting, handleAccept, sortConfig, filterData,
    tabType, isExpanded, setIsExpanded, setFilterData,
    handleFilter, Filter = true, handleEdit, handleDelete, showButton = false,
    handleDetails, handleVerification, handleCheckbox,
    refreshGrid, setRefreshGrid, }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [defaultValue, setDefaultValue] = useState('All');
    const LoginUserCode = cookies.get('loginUserCode');
    const handleDeleteData = (UserID) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(UserID)
            }
        });
    }

    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [serchingData, setSerchingData] = useState([]);

    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };
    const getSearchingColumn = async () => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName: PageName
        }
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setSerchingData(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                Navigate('/Login')
            }
            else if (res.message !== "") {
            }
        })
    };

    useEffect(() => {
        if (PageName !== "Product Multimedia") {
            getSearchingColumn();
        }
    }, []);


    const handleShowFile = async (value) => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    function formatDate(inputDate) {
        if (!inputDate) return ""; // Return empty string if inputDate is null or undefined
        const dateObj = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));
    let GetOrderOptions = DropDownData1('GetOPSProcessType');

    const handleClear = () => {
        const clearedData = [];
        Object.keys(serchingData).forEach(key => {
            clearedData[key] = { ...serchingData[key], searchingText: '', comparisonType: '', searchingText1: "" };
        });
        setSerchingData(clearedData);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        setRefreshGrid(!refreshGrid)
    };

    useEffect(() => {
        setMainData(tableData?.Data)

    }, [tableData, serchingData,]);
    useEffect(() => {
        setDefaultValue('All');
    }, []);
    return (

        <>  {
            tableData && (
                <section className="recoard-table mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            {Filter && PageName !== "Product Multimedia" && PageName !== "PaidOrderPaymentDetails" && PageName !== "PendingPayments" && PageName !== "TodayDeals" && PageName !== "CODOrderPaymentDetails" && PageName !== "VendorPayout" && PageName !== 'CODReconciliation' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'PayoutProcessReport' &&
                                <div className={`col-lg-${isExpanded ? '12' : '5'}`}>
                                    {isExpanded ? null : (
                                        <div className="content-box">
                                            <div className="heading-box">
                                                <div className="heading">
                                                    <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                        Searching
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="filter-section  gap-2">
                                                <span className="gap-2">
                                                    <div className='select_records_filter'>
                                                        {serchingData && (
                                                            Object.keys(serchingData).map((key, index) => (
                                                                <div key={index} className="row mx-1 mb-1 pb-0">
                                                                    {serchingData[key].inputType === 'date' ? (

                                                                        <label className="col-lg-4 col-md-4 col-sm-6 pe-0 fields " style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'checkbox' ? (

                                                                        <label className="col-lg-4 col-md-4 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'dropdown' ? (

                                                                        <label className="col-lg-4 col-md-4 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : (
                                                                        <SelectInputBox
                                                                            divclassname="col-lg-4 col-md-3 col-sm-6 pe-0 fields"
                                                                            style={{ fontSize: "15px" }}
                                                                            inputclassname=''
                                                                            value={serchingData[key].comparisonType}
                                                                            onChange={(e) => {
                                                                                const updatedData = [...serchingData];
                                                                                updatedData[key].comparisonType = e.target.value;
                                                                                setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                            }}
                                                                            children={<>
                                                                                <option value=''>{isExpanded ? '--Select--' : '-Select-'}</option>
                                                                                <option value="Equal">Equal To</option>
                                                                                <option value="NotEqual">Not Equal</option>
                                                                                <option value="StartWith">Start With</option>
                                                                                <option value="Exactly">Exactly</option>
                                                                                <option value="Contains">Contains</option>

                                                                            </>}
                                                                        />
                                                                    )}
                                                                    {serchingData[key].inputType === 'date' ? (
                                                                        <>
                                                                            <div className="col-lg-3 col-md-3 col-sm-3 pe-0 ">
                                                                                <DateBox
                                                                                    id='txtDateFrom'
                                                                                    divclassname="col-lg-4 col-md-4 col-sm-3 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="From Date"
                                                                                    value={formatDate(serchingData[key].searchingText) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = dateArr;
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}

                                                                                    // onChange={(e) => handleDateChange(e, setFilterData, FilterData, 'FromDate')}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-3 pe-0 d-flex">
                                                                                <DateBox
                                                                                    id='txtDateTo'
                                                                                    divclassname="col-lg-4 col-md-4 col-sm-3 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="To Date"
                                                                                    value={formatDate(serchingData[key].searchingText1) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText1 = dateArr || "";
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="btn btn-link ms-1" title="Clear Date"
                                                                                    onClick={() => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = "";
                                                                                        updatedData[key].searchingText1 = "";
                                                                                        updatedData[key].comparisonType = "";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                        setRefreshGrid(!refreshGrid)
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-refresh"></i>
                                                                                </button>
                                                                            </div>

                                                                        </>)
                                                                        : serchingData[key].inputType === 'checkbox' ? (
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 pe-0 d-flex ">
                                                                                <Switch
                                                                                    divclassname="mt-0"
                                                                                    checked={serchingData[key]?.searchingText === "TRUE"}
                                                                                    onChange={(e) => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = e.target.checked ? "TRUE" : "FALSE";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) :
                                                                            serchingData[key].inputType === 'dropdown' ? (
                                                                                <SelectInputBox
                                                                                    divclassname="col-lg-8 col-md-8 col-sm-6 pe-0 fields"
                                                                                    inputclassname=''
                                                                                    value={serchingData[key].searchingText || 'All'}
                                                                                    onChange={(e) => {
                                                                                        const selectedValue = e.target.value || 'All';
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = selectedValue;
                                                                                        updatedData[key].comparisonType = "Equal";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <option value='All'>Select</option>
                                                                                    {serchingData[key].dropdownValue.map((value, index) => (
                                                                                        <option key={index} value={value}>{serchingData[key].dropdownLabel[index]}</option>
                                                                                    ))}
                                                                                </SelectInputBox>
                                                                            ) :

                                                                                (
                                                                                    <div className="col-lg-8 col-md-8 col-sm-6 pe-0">
                                                                                        <div className="did-floating-label-content">
                                                                                            <input className="did-floating-input" type="text" placeholder=" "
                                                                                                value={serchingData[key].searchingText || ''}
                                                                                                onChange={(e) => {
                                                                                                    const updatedData = [...serchingData];
                                                                                                    updatedData[key].searchingText = e.target.value || "";
                                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                                }} />
                                                                                            <label className="did-floating-label">{serchingData[key].placeholderText}</label>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                    <div className="row mx-1 ms-0  mb-3 ps-0">
                                                        <div className="col-8 pe-0  mb-3">
                                                            <button className="button btn apply-btn  mt-0" style={{ padding: '2px', width: '100%', backgroundColor: "#689D15", color: "white" }} onClick={() => {
                                                                handleFilter();
                                                            }}> Apply Filter </button>
                                                        </div>
                                                        <div className="col-4 ps-1">
                                                            <button className="button btn clear-btn mt-0  w-100" style={{ padding: '2px', backgroundColor: '#DD3A44', color: 'white', borderColor: '#DD3A44' }} onClick={handleClear}  > Clear </button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className={`col-lg-${isExpanded ? '0' : (Filter && PageName !== "Product Multimedia" && PageName !== "PaidOrderPaymentDetails" && PageName !== "PendingPayments" && PageName !== "VendorPayout" && PageName !== "CODOrderPaymentDetails" && PageName !== "CODReconciliation" && PageName !== "TodayDeals" && PageName !== "CODReconsiliationReport" ? '7' : '12')} col-md-${isExpanded ? '0' : (Filter ? '12' : '12')} col-sm-${isExpanded ? '0' : (Filter ? '12' : '12')} `}>
                                <div className="content-box">
                                    <div className="heading-box d-block d-sm-flex " style={{ justifyContent: "space-between" }}>
                                        <div className="heading d-flex gap-2">
                                            <div>
                                                {(PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== 'PayoutProcessReport' && PageName !== "PendingPayments" && PageName !== "CODReconsiliationReport" && PageName !== "PaidOrderPaymentDetails" && PageName !== "CODOrderPaymentDetails" && PageName !== "CODReconciliation" && PageName !== "TodayDeals" && PageName !== "VendorPayoutReport" && PageName !== "VendorOuststanding") && (<button
                                                    className={`btn-sm btn text-white py-0 ${!isExpanded ? 'Exp-btn' : 'btn-danger'}`}
                                                    onClick={handleExpandToggle}
                                                >
                                                    {isExpanded ? <i className='fa fa-chevron-right py-1' /> : <i className='fa fa-chevron-left py-1' />}
                                                </button>
                                                )}
                                            </div>
                                            <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                            <span>{showCheckBox && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</span>
                                        </div>
                                        {tableData && tableData.TabTypes && (
                                            <div>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {tableData.TabTypes.map((tabTypeWithCount, index) => {
                                                        const [tabType, count] = tabTypeWithCount.split(' : ');
                                                        return (
                                                            <li className="nav-item" role="presentation" key={index}>
                                                                <button
                                                                    className={`nav-link position-relative ${index === 0 ? 'active' : ''}`}
                                                                    id={`${tabType.toLowerCase()}-tab`}
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target={`#${tabType.toLowerCase()}`}
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls={tabType.toLowerCase()}
                                                                    aria-selected={index === 0 ? "true" : "false"}
                                                                    onClick={() => {
                                                                        handleTabClick(tabType)
                                                                    }}
                                                                >
                                                                    {tabType} <span className="badge position-absolute" style={{ top: '-10px', right: '-10px', borderRadius: '30px', backgroundColor: '#1976d2', zIndex: '996' }}>{count}</span>
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        )}

                                        {tableData && tableData.Dropdown && tableData.Dropdown.length > 0 && (
                                            <div style={{ width: "50%" }}>
                                                <SelectInputBox
                                                    id="txtProcessType"
                                                    divclassname="col col-lg-6 fields"
                                                    required={true}
                                                    value={filterTable.ProcessType}
                                                    onChange={(e) => {
                                                        setFilterTable({ ...filterTable, ProcessType: e.target.value });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {

                                                        }
                                                    }}
                                                ><option value=''>--Select Process--</option>
                                                    {
                                                        GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                            <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                        )
                                                    }
                                                </SelectInputBox>
                                            </div>
                                        )}
                                        <div className="d-flex gap-2">
                                            {showButton &&
                                                <>
                                                    < button className="btn Exp-btn text-white " style={{ float: "right", textWrap: "nowrap" }} onClick={DownloadShipmentPrint}>
                                                        <i className="fa fa-download" />Shipment Print
                                                    </button>
                                                    < button className="btn Exp-btn text-white " style={{ float: "right", textWrap: "nowrap" }} onClick={DownloadBulkInvoicePrint}>
                                                        <i className="fa fa-download" />BulkInvoice Print
                                                    </button>
                                                </>

                                            }

                                        </div>
                                    </div>
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped mb-1">
                                                <thead>
                                                    <tr className='light-bg'>

                                                        {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                            <th>Action</th> :
                                                            <></>
                                                        }

                                                        {finalHeaderKeys?.map((th, headIndex) => (
                                                            <th key={`th${headIndex || 0}`}

                                                            >
                                                                {th}
                                                                {
                                                                    (sortConfig?.SortColumn !== null
                                                                        && sortConfig?.SortOrder !== null
                                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                                        (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'ItemsToSection' && PageName !== 'UserLogReport' && <>
                                                                                <i className="fa fa-arrow-up ms-2" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'DESC' ? "#FFFFFF" : 'red', cursor: "pointer" }}></i>
                                                                                <i className="fa fa-arrow-down" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#FFFFFF' : 'red', cursor: "pointer" }}  ></i>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && <>
                                                                                <i className="fa fa-arrow-up ms-2" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])}></i>
                                                                                <i className="fa fa-arrow-down" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} ></i>
                                                                            </>
                                                                        )
                                                                }
                                                            </th> || <></>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MainData?.map((item, bodyIndex) => (
                                                        <tr
                                                            className="TableTrhover"
                                                            key={`row${bodyIndex}`}
                                                            onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                            style={{
                                                                background:
                                                                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                        ? '#fee77f'
                                                                        : ''
                                                            }}
                                                        >


                                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' && PageName !== 'VendorPayout' && PageName !== 'CODReconciliation' && PageName !== "PaidOrderPaymentDetails" && PageName !== 'CODReconsiliationReport' && PageName !== 'TodayDeals' && PageName !== 'VendorPayoutReport' && PageName !== "CODOrderPaymentDetails" && (
                                                                <td

                                                                    key={`tdAction${bodyIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background:
                                                                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                ? '#fee77f'
                                                                                : '', display:"flex"

                                                                    }}
                                                                >
                                                                    {tableData.Actions?.map((btn, index) => (
                                                                        <span key={index}>
                                                                            {btn === 'SendMail' && <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} />}
                                                                            {btn === 'Edit' && <i className="bi bi-pencil-square info me-2" title='Edit' style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} />}
                                                                            {btn === 'Del' && <i className=" bi bi-trash3 danger me-2" title='Delete' style={{ cursor: "pointer", color: "#C42B1C" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} />}
                                                                            {btn === 'Print' && <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}print${index}`} />}
                                                                            {btn === 'View' && <i className="fa fa-eye text-primary me-2" title='Details View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.View, "", tabType)} />}
                                                                            {btn === 'History' && <i className="fa-solid fa-history me-2" title='History View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}History${index}`} onClick={() => handleHistory(item.History, "", tabType)} />}
                                                                            {btn === 'Update' &&

                                                                                <i className=" fa-solid fa-circle-info me-2" title='Update ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update)} />}
                                                                            {btn === 'UpdateDispatch' && <i className=" fa-solid fa-circle-info me-2" title='Update Dispatch ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}UpdateDispatch${index}`} onClick={() => handleVerificationDispatch(item.UpdateDispatch)} />}
                                                                            {/* {btn === 'ProductPreview' && <i className=" fa-solid fa-boxes-packing" title='Product Preview' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}ProductPreview${index}`} onClick={() => ProductPreview(item.ProductPreview)} />}*/}
                                                                            {btn === 'ProductPreview' && item.ProductPreview.trim() !== '' && (
                                                                                <i
                                                                                    className="fa-solid fa-boxes-packing"
                                                                                    title='Product Preview'
                                                                                    style={{ cursor: "pointer", color: "#21A892" }}
                                                                                    key={`row${bodyIndex}ProductPreview${index}`}
                                                                                    onClick={() => ProductPreview(item.ProductPreview)}
                                                                                />
                                                                            )}
                                                                            {btn === 'CheckBox' &&
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`row${item.OrderNo}CheckBox`}
                                                                                    checked={item.CheckBox === item.OrderNo}
                                                                                    onChange={(e) => handleCheck(e.target.checked, item.OrderNo, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {btn === 'Switch' &&
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Switch}Switch`}
                                                                                    checked={item.Switch === 'true' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {btn === 'ReviewView' && <i className="fa-solid fa-comment me-2" title='Review View' onClick={() => handleReviewData(item.ReviewView)} style={{ cursor: "pointer", color: "#21A892" }}></i>}
                                                                            {btn === 'ComplainView' && <i className="fa-solid danger fa-boxes-packing me-2" title='Complaint View' onClick={() => handleComplainView(item.ComplainView)} style={{ cursor: "pointer", color: "#C42B1C" }}></i>}
                                                                            {btn === 'ComplainAdd' && <i className="fa-solid fa-user-pen me-2" title='Complaint Add' onClick={() => handleComplainAdd(item.ComplainAdd)} style={{ cursor: "pointer", color: "#C42B1C" }} />}
                                                                            {btn === 'OrderView' && <i className="fa-solid fa-folder-open me-2" title='Order View' onClick={() => handleOrderViewData(item.OrderView)} style={{ cursor: "pointer" }}></i>}
                                                                            {btn === 'ShowAlert' && <i className="fa fa-bell me-2" title='Alerts'  ></i>}
                                                                            {btn === 'Verify' &&
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Verify}Verify`}
                                                                                    checked={item.Verify === '' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Verify, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {/*{item.img ? `data:image/png;base64,${item.img}`: ""}*/}
                                                                            {btn === 'Accept' &&
                                                                                <button
                                                                                    className="button btn mt-0"
                                                                                    id={item.Accept}

                                                                                    key={`row${bodyIndex}Accept${index}`}
                                                                                    style={{ width: "auto", marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                    onClick={() => handleAccept(item.Accept, 'Accepted', "")}

                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>Accept</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Reject' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Reject}
                                                                                    key={`row${bodyIndex}Reject${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Reject, 'Rejected', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px", }}> Reject</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Remove' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Remove}
                                                                                    key={`row${bodyIndex}Remove${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Remove, 'Removed', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>  Remove</div>
                                                                                </button>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            )}
                                                            {/*{*/}
                                                            {/*    tableData?.Data && tableData?.Data[0]?.hasOwnProperty("Checkbox") && tableData?.Actions.includes('Checkbox') === false ?*/}
                                                            {/*    <td className='text-center'>*/}
                                                            {/*        <input*/}
                                                            {/*            className="form-check-input mt-0"*/}
                                                            {/*            type="checkbox"*/}
                                                            {/*            checked={item.checkStatus}*/}
                                                            {/*            id={item.Checkbox}*/}
                                                            {/*            onChange={(e) => handleCheck(e.target.checked, item.Checkbox ,item.Amount, bodyIndex)}*/}
                                                            {/*        />*/}
                                                            {/*    </td> : <></>*/}
                                                            {/*}*/}
                                                            {/*{finalHeadersValue?.map((key, tdIndex) => (*/}
                                                            {/*    (typeof (item[key]) === 'object') ? (*/}
                                                            {/*        Array.isArray(item[key]) ?*/}
                                                            {/*            (item[key].length > 0 ?*/}
                                                            {/*                <td*/}
                                                            {/*                    key={`tdAction${bodyIndex}`}*/}
                                                            {/*                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)} */}
                                                            {/*                    style={{*/}
                                                            {/*                        background:*/}
                                                            {/*                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'*/}
                                                            {/*                                ? '#fee77f'*/}
                                                            {/*                                : ''*/}
                                                            {/*                    }}*/}
                                                            {/*                >*/}
                                                            {/*                    <i className="fa fa-eye"*/}
                                                            {/*                        data-bs-toggle="modal" data-bs-target="#exampleModal"*/}
                                                            {/*                        style={{ cursor: 'pointer', color: '#5a8dee' }}*/}
                                                            {/*                        onClick={() => ShowEyeRecords(item[key], key)}*/}

                                                            {/*                    />*/}
                                                            {/*                </td> : <td key={`row${bodyIndex}`}></td>) : (*/}
                                                            {/*                typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?*/}
                                                            {/*                    <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>*/}
                                                            {/*                    </td> : typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`} ></td>) :*/}
                                                            {/*                        (<td key={`key${tdIndex}`} className='text-center' >*/}
                                                            {/*                            <i className="fa fa-image me-2"*/}
                                                            {/*                                title='show Image'*/}
                                                            {/*                                onClick={() => { handleShowFile(item[key]) }}*/}
                                                            {/*                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}*/}
                                                            {/*                            />*/}

                                                            {/*                        </td>))*/}
                                                            {/*    )*/}
                                                            {/*        :*/}
                                                            {/*        <td*/}
                                                            {/*            key={`row${tdIndex}`}*/}
                                                            {/*            onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}*/}
                                                            {/*            style={{*/}
                                                            {/*                background:*/}
                                                            {/*                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'*/}
                                                            {/*                        ? '#fee77f'*/}
                                                            {/*                        : ''*/}
                                                            {/*            }}*/}
                                                            {/*        >*/}
                                                            {/*            {item[key]}</td>*/}
                                                            {/*))}*/}
                                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                                <td
                                                                    key={`row${tdIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background: (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc' ? '#fee77f' : ''
                                                                    }}
                                                                >
                                                                    {/* Render content based on the type of data */}
                                                                    {typeof item[key] === 'object' ? (
                                                                        Array.isArray(item[key]) ? (
                                                                            item[key].length > 0 ? (
                                                                                <i className="fa fa-eye" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ cursor: 'pointer', color: '#5a8dee' }} onClick={() => ShowEyeRecords(item[key], key)} />
                                                                            ) : null
                                                                        ) : (
                                                                            item[key]?.FileType === 'pdf' ? (
                                                                                <span onClick={() => { handleShowFile(item[key]) }}>
                                                                                    {/* Render PDF icon or content */}
                                                                                </span>
                                                                            ) : (
                                                                                item[key]?.FileType === '' ? null : (
                                                                                    <i className="fa fa-image me-2" title='show Image' onClick={() => { handleShowFile(item[key]) }} style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />
                                                                                )
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        // Render normal cell content
                                                                        key === 'Checkbox' ? (
                                                                            <input type="checkbox" checked={item.checkStatus} onChange={(e) => handleCheck(e.target.checked, item.Checkbox, item.Amount, bodyIndex)} />
                                                                        ) : item[key]
                                                                    )}
                                                                </td>
                                                            ))}


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {countNo > 1 &&
                                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
export const TableBrand = ({ Token, PageName, handleTabClick, tableData, handleDownloadExcelFile, handleDownload, handleVerifyProducts,
    ProductPreview, ImageView, handleVerificationDispatch, handleHistory,
    handleCheck, ShowEyeRecords, filterTable, setFilterTable, handleAllCheckBoxaction,
    handleReviewData, handleOrderViewData, GenrateInvoice, handleComplainView, LabelPrint,
    checkAll, showCheckBox, currentPageNo, handleChange1, handleVerifyProductsApi, handleRejectProductApi,
    handleSorting, handleAccept, sortConfig, filterData,
    tabType, isExpanded, setIsExpanded, setFilterData, PreviewProduct,
    handleFilter, Filter = true, handleEdit, handleDelete,
    handleDetails, handleVerification, handleCheckbox, allCheckBoxaction = false,
    refreshGrid, setRefreshGrid, }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [defaultValue, setDefaultValue] = useState('All');
    const LoginUserCode = cookies.get('loginUserCode');
    const handleDeleteData = (UserID) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(UserID)
            }
        });
    }

    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [serchingData, setSerchingData] = useState([]);
    const [allCheckedForVerification, setAllCheckedForVerification] = useState(false);

    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const [ColoredRow, setColoredRow] = useState('');

    const handleHighlight = (id, index) => {
        const highlightedId = id !== undefined ? id : index;
        setColoredRow(highlightedId);
    };

    const getSearchingColumn = async () => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName: PageName
        }
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                /*let list = res.getSearchingList*/
                /* setFilterData({ ...filterData, SearchFieldValue: list[0].searchingValue })*/
                setSerchingData(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                Navigate('/Login')
            }
            else if (res.message !== "") {
            }
        })
    };

    useEffect(() => {
        if (PageName !== "Product Multimedia") {
            getSearchingColumn();
        }
    }, []);


    const handleShowFile = async (value) => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    function formatDate(inputDate) {
        if (!inputDate) return ""; // Return empty string if inputDate is null or undefined
        const dateObj = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));
    let GetOrderOptions = DropDownData1('GetOPSProcessType');

    const handleClear = () => {
        const clearedData = [];
        Object.keys(serchingData).forEach(key => {
            clearedData[key] = { ...serchingData[key], searchingText: '', comparisonType: '', searchingText1: "" };
        });
        setSerchingData(clearedData);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        setRefreshGrid(!refreshGrid)
    };
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        handleTabClick(tableData.TabTypes[newValue].split(' : ')[0]);
    };
    useEffect(() => {
        setMainData(tableData?.Data)

    }, [tableData, serchingData,]);
    useEffect(() => {
        setDefaultValue('All');
    }, []);
    return (

        <>  {
            tableData && (
                <section className="recoard-table mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            {Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== 'CODReconciliation' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' &&
                                <div className={`col-lg-${isExpanded ? '12' : '5'}`}>
                                    {isExpanded ? null : (
                                        <div className="content-box">
                                            <div className="heading-box">
                                                <div className="heading">
                                                    <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                        Searching
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="filter-section  gap-2">
                                                <span className="gap-2">
                                                    <div className='select_records_filter'>
                                                        {serchingData && (
                                                            Object.keys(serchingData).map((key, index) => (
                                                                <div key={index} className="row mx-1 mb-1 pb-0">
                                                                    {serchingData[key].inputType === 'date' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields me-4" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'checkbox' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'dropdown' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : (
                                                                        <SelectInputBox
                                                                            divclassname="col-lg-4 col-md-3 col-sm-6 pe-0 fields"
                                                                            style={{ fontSize: "15px" }}
                                                                            inputclassname=''
                                                                            value={serchingData[key].comparisonType}
                                                                            onChange={(e) => {
                                                                                const updatedData = [...serchingData];
                                                                                updatedData[key].comparisonType = e.target.value;
                                                                                setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                            }}
                                                                            children={<>
                                                                                <option value=''>{isExpanded ? '--Select--' : '-Select-'}</option>
                                                                                <option value="Equal">Equal To</option>
                                                                                <option value="NotEqual">Not Equal</option>
                                                                                <option value="StartWith">Start With</option>
                                                                                <option value="Exactly">Exactly</option>
                                                                                <option value="Contains">Contains</option>

                                                                            </>}
                                                                        />
                                                                    )}
                                                                    {serchingData[key].inputType === 'date' ? (
                                                                        <>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 ms-4">
                                                                                <DateBox
                                                                                    id='txtDateFrom'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="From Date"
                                                                                    value={formatDate(serchingData[key].searchingText) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = dateArr;
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}

                                                                                    // onChange={(e) => handleDateChange(e, setFilterData, FilterData, 'FromDate')}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 d-flex">
                                                                                <DateBox
                                                                                    id='txtDateTo'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="To Date"
                                                                                    value={formatDate(serchingData[key].searchingText1) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText1 = dateArr || "";
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="btn btn-link ms-1" title="Clear Date"
                                                                                    onClick={() => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = "";
                                                                                        updatedData[key].searchingText1 = "";
                                                                                        updatedData[key].comparisonType = "";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-refresh"></i>
                                                                                </button>
                                                                            </div>

                                                                        </>)
                                                                        : serchingData[key].inputType === 'checkbox' ? (
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 pe-0 d-flex ">
                                                                                <Switch
                                                                                    divclassname="mt-0"
                                                                                    checked={serchingData[key]?.searchingText === "TRUE"}
                                                                                    onChange={(e) => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = e.target.checked ? "TRUE" : "FALSE";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) :
                                                                            serchingData[key].inputType === 'dropdown' ? (
                                                                                <SelectInputBox
                                                                                    divclassname="col-lg-7 col-md-8 col-sm-6 pe-0 fields"
                                                                                    inputclassname=''
                                                                                    value={serchingData[key].searchingText || 'All'}
                                                                                    onChange={(e) => {
                                                                                        const selectedValue = e.target.value || 'All';
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = selectedValue;
                                                                                        updatedData[key].comparisonType = "Equal";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <option value='All'>Select</option>
                                                                                    {serchingData[key].dropdownValue.map((value, index) => (
                                                                                        <option key={index} value={value}>{serchingData[key].dropdownLabel[index]}</option>
                                                                                    ))}
                                                                                </SelectInputBox>
                                                                            ) :

                                                                                (
                                                                                    <div className="col-lg-8 col-md-8 col-sm-6 pe-0">
                                                                                        <div className="did-floating-label-content">
                                                                                            <input className="did-floating-input" type="text" placeholder=" "
                                                                                                value={serchingData[key].searchingText || ''}
                                                                                                onChange={(e) => {
                                                                                                    const updatedData = [...serchingData];
                                                                                                    updatedData[key].searchingText = e.target.value || "";
                                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                                }} />
                                                                                            <label className="did-floating-label">{serchingData[key].placeholderText}</label>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                    <div className="row mx-1 ms-0  mb-3 ps-0">
                                                        <div className="col-8 pe-0  mb-3">
                                                            <button className="button btn apply-btn  mt-0" style={{ padding: '2px', width: '100%', backgroundColor: "#689D15", color: "white" }} onClick={() => {
                                                                handleFilter();
                                                            }}> Apply Filter </button>
                                                        </div>
                                                        <div className="col-4 ps-1">
                                                            <button className="button btn clear-btn mt-0  w-100" style={{ padding: '2px', backgroundColor: '#DD3A44', color: 'white', borderColor: '#DD3A44' }} onClick={handleClear}  > Clear </button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className={`col-lg-${isExpanded ? '0' : (Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconciliation" && PageName !== "CODReconsiliationReport" ? '7' : '12')} col-md-${isExpanded ? '0' : (Filter ? '12' : '12')} col-sm-${isExpanded ? '0' : (Filter ? '12' : '12')} `}>
                                <div className="content-box">
                                    <div className="heading-box d-block d-sm-flex">
                                        <div>
                                            {(PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconsiliationReport" && PageName !== "CODReconciliation" && PageName !== "VendorPayoutReport") && (<button
                                                className={`btn-sm btn text-white py-0 ${!isExpanded ? 'Exp-btn' : 'btn-danger'}`}
                                                onClick={handleExpandToggle}
                                            >
                                                {isExpanded ? <i className='fa fa-chevron-right py-1' /> : <i className='fa fa-chevron-left py-1' />}
                                            </button>
                                            )}
                                        </div>
                                        <div className=" d-flex justify-content-between w-full ">
                                            <div className="heading d-flex gap-2">
                                                <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary" >
                                                    {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                                <span>{showCheckBox && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</span>
                                            </div>


                                        </div>

                                        {
                                            allCheckBoxaction && 
                                            <>
                                                <div className="col-sm-4">
                                                    <button onClick={() => handleVerifyProductsApi()} className="button btn apply-btn  mt-0" style={{ padding: '5px 10px', width: '100%', backgroundColor: "#689D15", color: "white" }}>Verify Product</button>
                                                </div>
                                                <div className="col-sm-4 ">
                                                    <button onClick={() => handleRejectProductApi()} className="button btn reject-btn  mt-0" style={{ padding: '5px 10px', width: '100%', backgroundColor: "#dd3a44", color: "white" }}>Reject Product</button>

                                                </div>
                                            </>
                                        
                                        }

                                        {tableData && tableData.TabTypes && (
                                            <Box >
                                                {tableData.TabTypes.map((tabTypeWithCount, index) => {
                                                    const [tabType, count] = tabTypeWithCount.split(' : ');
                                                    return (
                                                        <Badge key={index} badgeContent={count} color="primary" sx={{ marginRight: '8px' }}>
                                                            <Tabs
                                                                value={selectedTab}
                                                                onChange={handleChange}
                                                                TabIndicatorProps={{
                                                                    style: {
                                                                        backgroundColor: "#D97D54",
                                                                        height: '3px'

                                                                    }
                                                                }}
                                                                aria-label="tab example"
                                                                sx={{
                                                                    height: '50px',
                                                                    backgroundColor: '#F3F4F6',
                                                                    borderRadius: '10px',
                                                                    marginBottom: '10px',
                                                                    boxShadow: '0px 2px 4px #adc3e4;',
                                                                    '& .MuiTab-root': {
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        letterSpacing: '0.4px',
                                                                        color: '#4B5563',
                                                                        transition: 'color 0.3s',
                                                                        '&.Mui-selected': {
                                                                            color: '#D97D54',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <Tab
                                                                    key={index}
                                                                    value={index}
                                                                    id={`${tabType.toLowerCase()}-tab`}
                                                                    aria-controls={tabType.toLowerCase()}
                                                                    label={tabType}
                                                                />
                                                            </Tabs>
                                                        </Badge>
                                                    );
                                                })}
                                            </Box>
                                        )}
                                        {tableData && tableData.Dropdown && tableData.Dropdown.length > 0 && (
                                            <div style={{ width: "50%" }}>
                                                <SelectInputBox
                                                    id="txtProcessType"
                                                    divclassname="col col-lg-6 fields"
                                                    required={true}
                                                    value={filterTable.ProcessType}
                                                    onChange={(e) => {
                                                        setFilterTable({ ...filterTable, ProcessType: e.target.value });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {

                                                        }
                                                    }}
                                                ><option value=''>--Select Process--</option>
                                                    {
                                                        GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                            <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                        )
                                                    }
                                                </SelectInputBox>
                                            </div>
                                        )}
                                    </div>
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped mb-1">
                                                <thead>
                                                    <tr className='light-bg'>

                                                        {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                            <th>{allCheckBoxaction && <input
                                                                className="form-check-input "
                                                                type="checkbox"
                                                                onChange={(e) => {
                                                                    handleAllCheckBoxaction(e.target.checked)
                                                                    setAllCheckedForVerification(e.target.checked)
                                                                }}
                                                            />} Action  </th> :
                                                            <></>
                                                        }

                                                        {finalHeaderKeys?.map((th, headIndex) => (
                                                            <th key={`th${headIndex || 0}`}

                                                            >
                                                                {th}
                                                                {
                                                                    (sortConfig?.SortColumn !== null
                                                                        && sortConfig?.SortOrder !== null
                                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                                        (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'UserLogReport' && <>
                                                                                <i className="fa fa-arrow-up ms-2" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'DESC' ? "#FFFFFF" : 'red', cursor: "pointer" }}></i>
                                                                                <i className="fa fa-arrow-down" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#FFFFFF' : 'red', cursor: "pointer" }}  ></i>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && <>
                                                                                <i className="fa fa-arrow-up ms-2" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])}></i>
                                                                                <i className="fa fa-arrow-down" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} ></i>
                                                                            </>
                                                                        )
                                                                }
                                                            </th> || <></>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MainData?.map((item, bodyIndex) => (
                                                        <tr
                                                            className="TableTrhover"
                                                            key={`row${bodyIndex}`}
                                                            onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView, bodyIndex)}
                                                            style={{
                                                                background:
                                                                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                        ? '#fee77f'
                                                                        : ''
                                                            }}
                                                        >


                                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' && PageName !== 'CODReconciliation' && PageName !== 'CODReconsiliationReport' && PageName !== 'VendorPayout' && (
                                                                <td

                                                                    key={`tdAction${bodyIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView, bodyIndex)}
                                                                    style={{
                                                                        background:
                                                                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                ? '#fee77f'
                                                                                : '',

                                                                    }}
                                                                >
                                                                    {tableData.Actions?.map((btn, index) => (
                                                                        <span key={index}>
                                                                            {btn === 'SendMail' && <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} />}
                                                                            {btn === 'Edit' && <i className="bi bi-pencil-square info me-2" title='Edit' style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} />}
                                                                            {btn === 'Del' && <i className=" bi bi-trash3 danger me-2" title='Delete' style={{ cursor: "pointer", color: "#C42B1C" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} />}
                                                                            {btn === 'Invoice' && <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}Invoice${index}`} onClick={() => GenrateInvoice(item.Invoice)} />}
                                                                            {btn === 'LabelPrint' && <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}LabelPrint${index}`} onClick={() => LabelPrint(item.LabelPrint)} />}
                                                                            {btn === 'View' && <i className="fa fa-eye text-primary me-2" title='Details View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.View, "", tabType)} />}
                                                                            {btn === 'History' && <i className="fa-solid fa-history me-2" title='History View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}History${index}`} onClick={() => handleHistory(item.History, "", tabType)} />}
                                                                            {btn === 'Update' &&

                                                                                <i className=" fa-solid fa-circle-info me-2" title='Update ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update)} />}
                                                                            {btn === 'UpdateDispatch' && <i className=" fa-solid fa-circle-info me-2" title='Update Dispatch ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}UpdateDispatch${index}`} onClick={() => handleVerificationDispatch(item.UpdateDispatch)} />}
                                                                            {/* {btn === 'ProductPreview' && <i className=" fa-solid fa-boxes-packing" title='Product Preview' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}ProductPreview${index}`} onClick={() => ProductPreview(item.ProductPreview)} />}*/}
                                                                            {btn === 'ProductPreview' && item.ProductPreview.trim() !== '' && (
                                                                                <i
                                                                                    className="fa-solid fa-boxes-packing"
                                                                                    title='Product Preview'
                                                                                    style={{ cursor: "pointer", color: "#21A892" }}
                                                                                    key={`row${bodyIndex}ProductPreview${index}`}
                                                                                    onClick={() => ProductPreview(item.ProductPreview)}
                                                                                />
                                                                            )}
                                                                            {btn === 'PreviewProduct' && (
                                                                                <i
                                                                                    className="fa-solid fa-boxes-packing"
                                                                                    title='Preview Product'
                                                                                    style={{ cursor: "pointer", color: "#21A892" }}
                                                                                    key={`row${bodyIndex}PreviewProduct${index}`}
                                                                                    onClick={() => PreviewProduct(item.ProductPreview)}
                                                                                />
                                                                            )}
                                                                            {btn === 'ImageView' && item.ImageView !== '' && (
                                                                                <i
                                                                                    className="fa fa-image me-2"
                                                                                    title="Show Image"
                                                                                    style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                    key={`row${bodyIndex}ImageView${index}`}
                                                                                    onClick={() => ImageView(item.ImageView)}
                                                                                />
                                                                            )}

                                                                            {btn === 'Verify' &&
                                                                                <input
                                                                                    className="form-check-input  me-2"
                                                                                    type="checkbox"
                                                                                    checked={allCheckedForVerification ? allCheckedForVerification : item.checkStatus}
                                                                                    //  id={item.Checkbox}
                                                                                    id={`row${item.ProductCode}VerifyCheckBox`}
                                                                                    onChange={(e) => {
                                                                                        handleVerifyProducts(e.target.checked, item)
                                                                                    }}
                                                                                />}

                                                                            {btn === 'Switch' &&
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Switch}Switch`}
                                                                                    checked={item.Switch === 'true' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {btn === 'ReviewView' && <i className="fa-solid fa-comment me-2" title='Review View' onClick={() => handleReviewData(item.ReviewView)} style={{ cursor: "pointer", color: "#21A892" }}></i>}
                                                                            {btn === 'ComplainView' && <i className="fa-solid danger fa-boxes-packing me-2" title='Complaint View' onClick={() => handleComplainView(item.ComplainView)} style={{ cursor: "pointer", color: "#C42B1C" }}></i>}
                                                                            {btn === 'OrderView' && <i className="fa-solid fa-folder-open" title='Order View' onClick={() => handleOrderViewData(item.OrderView)} style={{ cursor: "pointer" }}></i>}
                                                                            {btn === 'RERY' &&
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="checkbox"
                                                                                    checked={item.checkStatus}
                                                                                    id={item.Checkbox}
                                                                                    onChange={(e) => {
                                                                                        handleCheck(e.target.checked, item.Checkbox, bodyIndex)
                                                                                    }}
                                                                                />}
                                                                            {/*{item.img ? `data:image/png;base64,${item.img}`: ""}*/}
                                                                            {btn === 'Accept' &&
                                                                                <button
                                                                                    className="button btn mt-0"
                                                                                    id={item.Accept}

                                                                                    key={`row${bodyIndex}Accept${index}`}
                                                                                    style={{ width: "auto", marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                    onClick={() => handleAccept(item.Accept, 'Accepted', "")}

                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>Accept</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Reject' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Reject}
                                                                                    key={`row${bodyIndex}Reject${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Reject, 'Rejected', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px", }}> Reject</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Remove' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Remove}
                                                                                    key={`row${bodyIndex}Remove${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Remove, 'Removed', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>  Remove</div>
                                                                                </button>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            )}
                                                            {
                                                                tableData?.Data && tableData?.Data[0]?.hasOwnProperty("Checkbox") && tableData?.Actions.includes('Checkbox') === false ?
                                                                    <td className='text-center'>
                                                                        <input
                                                                            className="form-check-input mt-0"
                                                                            type="checkbox"
                                                                            checked={item.checkStatus}
                                                                            id={item.Checkbox}
                                                                            onChange={(e) => handleCheck(e.target.checked, item.Checkbox, item.Amount, bodyIndex)}
                                                                        />
                                                                    </td> : <></>
                                                            }
                                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                                (typeof (item[key]) === 'object') ? (
                                                                    Array?.isArray(item[key]) ?
                                                                        (item[key].length > 0 ?
                                                                            <td className='text-center' key={`key${tdIndex}`}>
                                                                                <i className="fa fa-eye"
                                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                                    style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                                    onClick={() => ShowEyeRecords(item[key], key)}
                                                                                />
                                                                            </td> : <td key={`key${tdIndex}`}></td>) : (
                                                                            typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?
                                                                                <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                                    <FaFilePdf title='open PDF' style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />
                                                                                </td> :
                                                                                typeof (item[key]) === 'object' && item[key]?.FileType === 'xlsx' ?
                                                                                    <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleDownloadExcelFile(item[key]) }}>
                                                                                        <FaFilePdf title='Download Excel' style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />
                                                                                    </td> :

                                                                                    typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`}></td>) :
                                                                                        (<td key={`key${tdIndex}`} className='text-center' >
                                                                                            <i className="fa fa-image me-2"
                                                                                                title='show Image'

                                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}
                                                                                            />
                                                                                            <i className='fa fa-download'
                                                                                                title='Download'
                                                                                                onClick={() => { handleDownload(item[key]) }}
                                                                                                style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />
                                                                                        </td>
                                                                                        )
                                                                        )
                                                                )
                                                                    :
                                                                    <td
                                                                        key={`row${tdIndex}`}
                                                                        onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView, bodyIndex)}
                                                                        style={{
                                                                            background:
                                                                                (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                    ? '#fee77f'
                                                                                    : ''
                                                                        }}
                                                                    >
                                                                        {item[key]}</td>
                                                            ))}


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {countNo > 1 &&
                                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export const TableVendor = ({ Token, PageName, handleTabClick, tableData,
    ProductPreview, handleVerificationDispatch, handleHistory,
    handleCheck, ShowEyeRecords, filterTable, setFilterTable,
    handleReviewData, handleOrderViewData, handleCheckAll, handleComplainView,
    checkAll, showCheckBox, currentPageNo, handleChange1,
    handleSorting, handleAccept, sortConfig, filterData,
    tabType, isExpanded, setIsExpanded, setFilterData,
    handleFilter, Filter = true, handleEdit, handleDelete,
    handleDetails, handleVerification, handleCheckbox,
    refreshGrid, setRefreshGrid, }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [defaultValue, setDefaultValue] = useState('All');
    const LoginUserCode = cookies.get('loginUserCode');
    const handleDeleteData = (UserID) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(UserID)
            }
        });
    }

    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [serchingData, setSerchingData] = useState([]);

    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };
    const getSearchingColumn = async () => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName: PageName
        }
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                setSerchingData(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                Navigate('/Login')
            }
            else if (res.message !== "") {
            }
        })
    };

    useEffect(() => {
        if (PageName !== "Product Multimedia") {
            getSearchingColumn();
        }
    }, []);


    const handleShowFile = async (value) => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    function formatDate(inputDate) {
        if (!inputDate) return ""; // Return empty string if inputDate is null or undefined
        const dateObj = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));
    let GetOrderOptions = DropDownData1('GetOPSProcessType');

    const handleClear = () => {
        const clearedData = [];
        Object.keys(serchingData).forEach(key => {
            clearedData[key] = { ...serchingData[key], searchingText: '', comparisonType: '', searchingText1: "" };
        });
        setSerchingData(clearedData);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        setRefreshGrid(!refreshGrid)
    };

    useEffect(() => {
        setMainData(tableData?.Data)

    }, [tableData, serchingData,]);
    useEffect(() => {
        setDefaultValue('All');
    }, []);
    return (

        <>  {
            tableData && (
                <section className="recoard-table mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            {Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== 'CODReconciliation' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' &&
                                <div className={`col-lg-${isExpanded ? '12' : '5'}`}>
                                    {isExpanded ? null : (
                                        <div className="content-box">
                                            <div className="heading-box">
                                                <div className="heading">
                                                    <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                        Searching
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="filter-section  gap-2">
                                                <span className="gap-2">
                                                    <div className='select_records_filter'>
                                                        {serchingData && (
                                                            Object.keys(serchingData).map((key, index) => (
                                                                <div key={index} className="row mx-1 mb-1 pb-0">
                                                                    {serchingData[key].inputType === 'date' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields me-4" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'checkbox' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'dropdown' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : (
                                                                        <SelectInputBox
                                                                            divclassname="col-lg-4 col-md-3 col-sm-6 pe-0 fields"
                                                                            style={{ fontSize: "15px" }}
                                                                            inputclassname=''
                                                                            value={serchingData[key].comparisonType}
                                                                            onChange={(e) => {
                                                                                const updatedData = [...serchingData];
                                                                                updatedData[key].comparisonType = e.target.value;
                                                                                setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                            }}
                                                                            children={<>
                                                                                <option value=''>{isExpanded ? '--Select--' : '-Select-'}</option>
                                                                                <option value="Equal">Equal To</option>
                                                                                <option value="NotEqual">Not Equal</option>
                                                                                <option value="StartWith">Start With</option>
                                                                                <option value="Exactly">Exactly</option>
                                                                                <option value="Contains">Contains</option>

                                                                            </>}
                                                                        />
                                                                    )}
                                                                    {serchingData[key].inputType === 'date' ? (
                                                                        <>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 ms-4">
                                                                                <DateBox
                                                                                    id='txtDateFrom'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="From Date"
                                                                                    value={formatDate(serchingData[key].searchingText) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = dateArr;
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}

                                                                                    // onChange={(e) => handleDateChange(e, setFilterData, FilterData, 'FromDate')}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 d-flex">
                                                                                <DateBox
                                                                                    id='txtDateTo'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="To Date"
                                                                                    value={formatDate(serchingData[key].searchingText1) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText1 = dateArr || "";
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="btn btn-link ms-1" title="Clear Date"
                                                                                    onClick={() => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = "";
                                                                                        updatedData[key].searchingText1 = "";
                                                                                        updatedData[key].comparisonType = "";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-refresh"></i>
                                                                                </button>
                                                                            </div>

                                                                        </>)
                                                                        : serchingData[key].inputType === 'checkbox' ? (
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 pe-0 d-flex ">
                                                                                <Switch
                                                                                    divclassname="mt-0"
                                                                                    checked={serchingData[key]?.searchingText === "TRUE"}
                                                                                    onChange={(e) => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = e.target.checked ? "TRUE" : "FALSE";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) :
                                                                            serchingData[key].inputType === 'dropdown' ? (
                                                                                <SelectInputBox
                                                                                    divclassname="col-lg-7 col-md-8 col-sm-6 pe-0 fields"
                                                                                    inputclassname=''
                                                                                    value={serchingData[key].searchingText || 'All'}
                                                                                    onChange={(e) => {
                                                                                        const selectedValue = e.target.value || 'All';
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = selectedValue;
                                                                                        updatedData[key].comparisonType = "Equal";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <option value='All'>Select</option>
                                                                                    {serchingData[key].dropdownValue.map((value, index) => (
                                                                                        <option key={index} value={value}>{serchingData[key].dropdownLabel[index]}</option>
                                                                                    ))}
                                                                                </SelectInputBox>
                                                                            ) :

                                                                                (
                                                                                    <div className="col-lg-8 col-md-8 col-sm-6 pe-0">
                                                                                        <div className="did-floating-label-content">
                                                                                            <input className="did-floating-input" type="text" placeholder=" "
                                                                                                value={serchingData[key].searchingText || ''}
                                                                                                onChange={(e) => {
                                                                                                    const updatedData = [...serchingData];
                                                                                                    updatedData[key].searchingText = e.target.value || "";
                                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                                }} />
                                                                                            <label className="did-floating-label">{serchingData[key].placeholderText}</label>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                    <div className="row mx-1 ms-0  mb-3 ps-0">
                                                        <div className="col-8 pe-0  mb-3">
                                                            <button className="button btn apply-btn  mt-0" style={{ padding: '2px', width: '100%', backgroundColor: "#689D15", color: "white" }} onClick={() => {
                                                                handleFilter();
                                                            }}> Apply Filter </button>
                                                        </div>
                                                        <div className="col-4 ps-1">
                                                            <button className="button btn clear-btn mt-0  w-100" style={{ padding: '2px', backgroundColor: '#DD3A44', color: 'white', borderColor: '#DD3A44' }} onClick={handleClear}  > Clear </button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className={`col-lg-${isExpanded ? '0' : (Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconciliation" && PageName !== "CODReconsiliationReport" ? '7' : '12')} col-md-${isExpanded ? '0' : (Filter ? '12' : '12')} col-sm-${isExpanded ? '0' : (Filter ? '12' : '12')} `}>
                                <div className="content-box">
                                    <div className="heading-box d-block d-sm-flex">
                                        <div>
                                            {(PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconsiliationReport" && PageName !== "CODReconciliation") && (<button
                                                className={`btn-sm btn text-white py-0 ${!isExpanded ? 'Exp-btn' : 'btn-danger'}`}
                                                onClick={handleExpandToggle}
                                            >
                                                {isExpanded ? <i className='fa fa-chevron-right py-1' /> : <i className='fa fa-chevron-left py-1' />}
                                            </button>
                                            )}
                                        </div>
                                        <div className="heading d-flex gap-2">
                                            <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                            <span>{showCheckBox && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</span>
                                        </div>
                                        {tableData && tableData.TabTypes && (
                                            <div>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {tableData.TabTypes.map((tabTypeWithCount, index) => {
                                                        const [tabType, count] = tabTypeWithCount.split(' : ');
                                                        return (
                                                            <li className="nav-item" role="presentation" key={index}>
                                                                <button
                                                                    className={`nav-link position-relative ${index === 0 ? 'active' : ''}`}
                                                                    id={`${tabType.toLowerCase()}-tab`}
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target={`#${tabType.toLowerCase()}`}
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls={tabType.toLowerCase()}
                                                                    aria-selected={index === 0 ? "true" : "false"}
                                                                    onClick={() => {
                                                                        handleTabClick(tabType)
                                                                    }}
                                                                >
                                                                    {tabType} <span className="badge position-absolute" style={{ top: '-10px', right: '-10px', borderRadius: '30px', backgroundColor: '#1976d2', zIndex: '996' }}>{count}</span>
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        )}

                                        {tableData && tableData.Dropdown && tableData.Dropdown.length > 0 && (
                                            <div style={{ width: "50%" }}>
                                                <SelectInputBox
                                                    id="txtProcessType"
                                                    divclassname="col col-lg-6 fields"
                                                    required={true}
                                                    value={filterTable.ProcessType}
                                                    onChange={(e) => {
                                                        setFilterTable({ ...filterTable, ProcessType: e.target.value });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {

                                                        }
                                                    }}
                                                ><option value=''>--Select Process--</option>
                                                    {
                                                        GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                            <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                        )
                                                    }
                                                </SelectInputBox>
                                            </div>
                                        )}
                                    </div>
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped mb-1">
                                                <thead>
                                                    <tr className='light-bg'>

                                                        {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                            <th>Action</th> :
                                                            <></>
                                                        }

                                                        {finalHeaderKeys?.map((th, headIndex) => (
                                                            <th key={`th${headIndex || 0}`}

                                                            >
                                                                {th}
                                                                {
                                                                    (sortConfig?.SortColumn !== null
                                                                        && sortConfig?.SortOrder !== null
                                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                                        (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'UserLogReport' && <>
                                                                                <i className="fa fa-arrow-up ms-2" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'DESC' ? "#FFFFFF" : 'red', cursor: "pointer" }}></i>
                                                                                <i className="fa fa-arrow-down" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#FFFFFF' : 'red', cursor: "pointer" }}  ></i>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && <>
                                                                                <i className="fa fa-arrow-up ms-2" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])}></i>
                                                                                <i className="fa fa-arrow-down" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} ></i>
                                                                            </>
                                                                        )
                                                                }
                                                            </th> || <></>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MainData?.map((item, bodyIndex) => (
                                                        <tr
                                                            className="TableTrhover"
                                                            key={`row${bodyIndex}`}
                                                            onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                            style={{
                                                                background:
                                                                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                        ? '#fee77f'
                                                                        : ''
                                                            }}
                                                        >


                                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' && PageName !== 'CODReconciliation' && PageName !== '' && (
                                                                <td

                                                                    key={`tdAction${bodyIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background:
                                                                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                ? '#fee77f'
                                                                                : '',

                                                                    }}
                                                                >
                                                                    {tableData.Actions?.map((btn, index) => (
                                                                        <span key={index}>
                                                                            {btn === 'SendMail' && <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} />}
                                                                            {btn === 'Edit' && <i className="bi bi-pencil-square info me-2" title='Edit' style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} />}
                                                                            {btn === 'Del' && <i className=" bi bi-trash3 danger me-2" title='Delete' style={{ cursor: "pointer", color: "#C42B1C" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} />}
                                                                            {btn === 'Invoice' && <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}Invoice${index}`} />}
                                                                            {btn === 'View' && <i className="fa fa-eye text-primary me-2" title='Details View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.Update, item.PayoutsProcessCode)} />}
                                                                            {btn === 'History' && <i className="fa-solid fa-history me-2" title='History View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}History${index}`} onClick={() => handleHistory(item.History, "", tabType)} />}
                                                                            {btn === 'Update' &&

                                                                                <i className=" fa-solid fa-circle-info me-2" title='Update ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update, item.PayoutsProcessCode)} />}
                                                                            {btn === 'UpdateDispatch' && <i className=" fa-solid fa-circle-info me-2" title='Update Dispatch ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}UpdateDispatch${index}`} onClick={() => handleVerificationDispatch(item.UpdateDispatch)} />}
                                                                            {/* {btn === 'ProductPreview' && <i className=" fa-solid fa-boxes-packing" title='Product Preview' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}ProductPreview${index}`} onClick={() => ProductPreview(item.ProductPreview)} />}*/}
                                                                            {btn === 'ProductPreview' && item.ProductPreview.trim() !== '' && (
                                                                                <i
                                                                                    className="fa-solid fa-boxes-packing"
                                                                                    title='Product Preview'
                                                                                    style={{ cursor: "pointer", color: "#21A892" }}
                                                                                    key={`row${bodyIndex}ProductPreview${index}`}
                                                                                    onClick={() => ProductPreview(item.ProductPreview)}
                                                                                />
                                                                            )}

                                                                            {btn === 'Switch' &&
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Switch}Switch`}
                                                                                    checked={item.Switch === 'true' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {btn === 'ReviewView' && <i className="fa-solid fa-comment me-2" title='Review View' onClick={() => handleReviewData(item.ReviewView)} style={{ cursor: "pointer", color: "#21A892" }}></i>}
                                                                            {btn === 'ComplainView' && <i className="fa-solid danger fa-boxes-packing me-2" title='Complaint View' onClick={() => handleComplainView(item.ComplainView)} style={{ cursor: "pointer", color: "#C42B1C" }}></i>}
                                                                            {btn === 'OrderView' && <i className="fa-solid fa-folder-open" title='Order View' onClick={() => handleOrderViewData(item.OrderView)} style={{ cursor: "pointer" }}></i>}
                                                                            {btn === 'RERY' &&
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="checkbox"
                                                                                    checked={item.checkStatus}
                                                                                    id={item.Checkbox}
                                                                                    onChange={(e) => {
                                                                                        handleCheck(e.target.checked, item.Checkbox, bodyIndex)
                                                                                    }}
                                                                                />}
                                                                            {/*{item.img ? `data:image/png;base64,${item.img}`: ""}*/}
                                                                            {btn === 'Accept' &&
                                                                                <button
                                                                                    className="button btn mt-0"
                                                                                    id={item.Accept}

                                                                                    key={`row${bodyIndex}Accept${index}`}
                                                                                    style={{ width: "auto", marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                    onClick={() => handleAccept(item.Accept, 'Accepted', "")}

                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>Accept</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Reject' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Reject}
                                                                                    key={`row${bodyIndex}Reject${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Reject, 'Rejected', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px", }}> Reject</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Remove' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Remove}
                                                                                    key={`row${bodyIndex}Remove${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Remove, 'Removed', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>  Remove</div>
                                                                                </button>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            )}

                                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                                <td
                                                                    key={`row${tdIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background: (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc' ? '#fee77f' : ''
                                                                    }}
                                                                >
                                                                    {/* Render content based on the type of data */}
                                                                    {typeof item[key] === 'object' ? (
                                                                        Array.isArray(item[key]) ? (
                                                                            item[key].length > 0 ? (
                                                                                <i className="fa fa-eye" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ cursor: 'pointer', color: '#5a8dee' }} onClick={() => ShowEyeRecords(item[key], key)} />
                                                                            ) : null
                                                                        ) : (
                                                                            item[key]?.FileType === 'pdf' ? (
                                                                                <span onClick={() => { handleShowFile(item[key]) }}>
                                                                                    {/* Render PDF icon or content */}
                                                                                </span>
                                                                            ) : (
                                                                                item[key]?.FileType === '' ? null : (
                                                                                    <i className="fa fa-image me-2" title='show Image' onClick={() => { handleShowFile(item[key]) }} style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />
                                                                                )
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        // Render normal cell content
                                                                        key === 'Checkbox' ? (
                                                                            <input type="checkbox" checked={item.checkStatus} onChange={(e) => handleCheck(e.target.checked, item.Checkbox, item.Amount, bodyIndex)} />
                                                                        ) : item[key]
                                                                    )}
                                                                </td>
                                                            ))}


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {countNo > 1 &&
                                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
export const TableCOD = ({ Token, PageName, handleTabClick, tableData,
    ProductPreview, handleVerificationDispatch, handleHistory,
    handleCheck, ShowEyeRecords, filterTable, setFilterTable,
    handleReviewData, handleOrderViewData, HandleScanList, OrderNO, SetOrderNo, handleComplainView,
    checkAll, showCheckBox, currentPageNo, handleChange1,
    handleSorting, handleAccept, sortConfig, filterData,
    tabType, isExpanded, setIsExpanded, setFilterData,
    handleFilter, Filter = true, handleEdit, handleDelete,
    handleDetails, handleVerification, handleCheckbox,
    refreshGrid, setRefreshGrid, }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [defaultValue, setDefaultValue] = useState('All');
    const LoginUserCode = cookies.get('loginUserCode');
    const handleDeleteData = (UserID) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(UserID)
            }
        });
    }

    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [serchingData, setSerchingData] = useState([]);

    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };
    const getSearchingColumn = async () => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName: PageName
        }
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                /*let list = res.getSearchingList*/
                /* setFilterData({ ...filterData, SearchFieldValue: list[0].searchingValue })*/
                setSerchingData(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                Navigate('/Login')
            }
            else if (res.message !== "") {
            }
        })
    };

    useEffect(() => {
        if (PageName !== "Product Multimedia") {
            getSearchingColumn();
        }
    }, []);


    const handleShowFile = async (value) => {
        const loginCodeString = String(LoginUserCode)
        let data = {
            Token, LoginUserCode: loginCodeString, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    function formatDate(inputDate) {
        if (!inputDate) return ""; // Return empty string if inputDate is null or undefined
        const dateObj = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();
        return `${day}-${month}-${year}`;
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));
    let GetOrderOptions = DropDownData1('GetOPSProcessType');

    const handleClear = () => {
        const clearedData = [];
        Object.keys(serchingData).forEach(key => {
            clearedData[key] = { ...serchingData[key], searchingText: '', comparisonType: '', searchingText1: "" };
        });
        setSerchingData(clearedData);
        setFilterData({ ...filterData, AdvanceFilter: [] });
        setRefreshGrid(!refreshGrid)
    };

    useEffect(() => {
        setMainData(tableData?.Data)

    }, [tableData, serchingData,]);
    useEffect(() => {
        setDefaultValue('All');
    }, []);
    return (

        <>  {
            tableData && (
                <section className="recoard-table mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            {Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== 'CODReconciliation' && PageName !== 'KycDetails' && PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' &&
                                <div className={`col-lg-${isExpanded ? '12' : '5'}`}>
                                    {isExpanded ? null : (
                                        <div className="content-box">
                                            <div className="heading-box">
                                                <div className="heading">
                                                    <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                        Searching
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="filter-section  gap-2">
                                                <span className="gap-2">
                                                    <div className='select_records_filter'>
                                                        {serchingData && (
                                                            Object.keys(serchingData).map((key, index) => (
                                                                <div key={index} className="row mx-1 mb-1 pb-0">
                                                                    {serchingData[key].inputType === 'date' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields me-4" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'checkbox' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : serchingData[key].inputType === 'dropdown' ? (

                                                                        <label className="col-lg-3 col-md-3 col-sm-6 pe-0 fields" style={{ display: "flex", justifyContent: "start", fontWeight: "bold", marginTop: "5px", whiteSpace: "nowrap" }}>{serchingData[key].placeholderText} </label>

                                                                    ) : (
                                                                        <SelectInputBox
                                                                            divclassname="col-lg-4 col-md-3 col-sm-6 pe-0 fields"
                                                                            style={{ fontSize: "15px" }}
                                                                            inputclassname=''
                                                                            value={serchingData[key].comparisonType}
                                                                            onChange={(e) => {
                                                                                const updatedData = [...serchingData];
                                                                                updatedData[key].comparisonType = e.target.value;
                                                                                setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                            }}
                                                                            children={<>
                                                                                <option value=''>{isExpanded ? '--Select--' : '-Select-'}</option>
                                                                                <option value="Equal">Equal To</option>
                                                                                <option value="NotEqual">Not Equal</option>
                                                                                <option value="StartWith">Start With</option>
                                                                                <option value="Exactly">Exactly</option>
                                                                                <option value="Contains">Contains</option>

                                                                            </>}
                                                                        />
                                                                    )}
                                                                    {serchingData[key].inputType === 'date' ? (
                                                                        <>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 ms-4">
                                                                                <DateBox
                                                                                    id='txtDateFrom'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="From Date"
                                                                                    value={formatDate(serchingData[key].searchingText) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = dateArr;
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}

                                                                                    // onChange={(e) => handleDateChange(e, setFilterData, FilterData, 'FromDate')}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 pe-0 d-flex">
                                                                                <DateBox
                                                                                    id='txtDateTo'
                                                                                    divclassname="col-lg-3 col-md-3 col-sm-6 w-100 fields"
                                                                                    required={false}
                                                                                    placeholder="To Date"
                                                                                    value={formatDate(serchingData[key].searchingText1) || ""}
                                                                                    onChange={(e) => {
                                                                                        let dateArr = e?.toString()?.split(' ')
                                                                                        dateArr = `${dateArr[2]}-${dateArr[1]}-${dateArr[3]}`
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText1 = dateArr || "";
                                                                                        updatedData[key].comparisonType = "Between";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') { setFocus("txtToDate") };
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="btn btn-link ms-1" title="Clear Date"
                                                                                    onClick={() => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = "";
                                                                                        updatedData[key].searchingText1 = "";
                                                                                        updatedData[key].comparisonType = "";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-refresh"></i>
                                                                                </button>
                                                                            </div>

                                                                        </>)
                                                                        : serchingData[key].inputType === 'checkbox' ? (
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 pe-0 d-flex ">
                                                                                <Switch
                                                                                    divclassname="mt-0"
                                                                                    checked={serchingData[key]?.searchingText === "TRUE"}
                                                                                    onChange={(e) => {
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = e.target.checked ? "TRUE" : "FALSE";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        ) :
                                                                            serchingData[key].inputType === 'dropdown' ? (
                                                                                <SelectInputBox
                                                                                    divclassname="col-lg-7 col-md-8 col-sm-6 pe-0 fields"
                                                                                    inputclassname=''
                                                                                    value={serchingData[key].searchingText || 'All'}
                                                                                    onChange={(e) => {
                                                                                        const selectedValue = e.target.value || 'All';
                                                                                        const updatedData = [...serchingData];
                                                                                        updatedData[key].searchingText = selectedValue;
                                                                                        updatedData[key].comparisonType = "Equal";
                                                                                        setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                    }}
                                                                                >
                                                                                    <option value='All'>Select</option>
                                                                                    {serchingData[key].dropdownValue.map((value, index) => (
                                                                                        <option key={index} value={value}>{serchingData[key].dropdownLabel[index]}</option>
                                                                                    ))}
                                                                                </SelectInputBox>
                                                                            ) :

                                                                                (
                                                                                    <div className="col-lg-8 col-md-8 col-sm-6 pe-0">
                                                                                        <div className="did-floating-label-content">
                                                                                            <input className="did-floating-input" type="text" placeholder=" "
                                                                                                value={serchingData[key].searchingText || ''}
                                                                                                onChange={(e) => {
                                                                                                    const updatedData = [...serchingData];
                                                                                                    updatedData[key].searchingText = e.target.value || "";
                                                                                                    setFilterData({ ...filterData, AdvanceFilter: updatedData });
                                                                                                }} />
                                                                                            <label className="did-floating-label">{serchingData[key].placeholderText}</label>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                    <div className="row mx-1 ms-0  mb-3 ps-0">
                                                        <div className="col-8 pe-0  mb-3">
                                                            <button className="button btn apply-btn  mt-0" style={{ padding: '2px', width: '100%', backgroundColor: "#689D15", color: "white" }} onClick={() => {
                                                                handleFilter();
                                                            }}> Apply Filter </button>
                                                        </div>
                                                        <div className="col-4 ps-1">
                                                            <button className="button btn clear-btn mt-0  w-100" style={{ padding: '2px', backgroundColor: '#DD3A44', color: 'white', borderColor: '#DD3A44' }} onClick={handleClear}  > Clear </button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className={`col-lg-${isExpanded ? '0' : (Filter && PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconciliation" && PageName !== "CODReconsiliationReport" ? '7' : '12')} col-md-${isExpanded ? '0' : (Filter ? '12' : '12')} col-sm-${isExpanded ? '0' : (Filter ? '12' : '12')} `}>
                                <div className="content-box">
                                    <div className="heading-box d-block d-sm-flex">
                                        <div>
                                            {(PageName !== "Product Multimedia" && PageName !== "VendorPayout" && PageName !== "CODReconsiliationReport" && PageName !== "CODReconciliation") && (<button
                                                className={`btn-sm btn text-white py-0 ${!isExpanded ? 'Exp-btn' : 'btn-danger'}`}
                                                onClick={handleExpandToggle}
                                            >
                                                {isExpanded ? <i className='fa fa-chevron-right py-1' /> : <i className='fa fa-chevron-left py-1' />}
                                            </button>
                                            )}
                                        </div>
                                        <div className="heading d-flex gap-2">
                                            <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                            {/*<span>{showCheckBox && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</span>*/}
                                            <span><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">{(showCheckBox || PageName === 'POD Upload') && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `, Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</h6></span>
                                            {tableData?.Data && tableData?.Data.length > 0 && <div className='col-md-4'>
                                                <InputBox
                                                    type="text"
                                                    className="fields"
                                                    placeholder={"Order No"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            HandleScanList()
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    value={OrderNO}
                                                    onChange={(e) => SetOrderNo(e.target.value.trim())}
                                                />
                                            </div>}
                                        </div>
                                        {tableData && tableData.TabTypes && (
                                            <div>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {tableData.TabTypes.map((tabTypeWithCount, index) => {
                                                        const [tabType, count] = tabTypeWithCount.split(' : ');
                                                        return (
                                                            <li className="nav-item" role="presentation" key={index}>
                                                                <button
                                                                    className={`nav-link position-relative ${index === 0 ? 'active' : ''}`}
                                                                    id={`${tabType.toLowerCase()}-tab`}
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target={`#${tabType.toLowerCase()}`}
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls={tabType.toLowerCase()}
                                                                    aria-selected={index === 0 ? "true" : "false"}
                                                                    onClick={() => {
                                                                        handleTabClick(tabType)
                                                                    }}
                                                                >
                                                                    {tabType} <span className="badge position-absolute" style={{ top: '-10px', right: '-10px', borderRadius: '30px', backgroundColor: '#1976d2', zIndex: '996' }}>{count}</span>
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        )}

                                        {tableData && tableData.Dropdown && tableData.Dropdown.length > 0 && (
                                            <div style={{ width: "50%" }}>
                                                <SelectInputBox
                                                    id="txtProcessType"
                                                    divclassname="col col-lg-6 fields"
                                                    required={true}
                                                    value={filterTable.ProcessType}
                                                    onChange={(e) => {
                                                        setFilterTable({ ...filterTable, ProcessType: e.target.value });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {

                                                        }
                                                    }}
                                                ><option value=''>--Select Process--</option>
                                                    {
                                                        GetOrderOptions?.data?.dataList?.map((item, index) =>
                                                            <option key={item.name + index} diviser={item.code} value={item.code}>{item.name}</option>
                                                        )
                                                    }
                                                </SelectInputBox>
                                            </div>
                                        )}
                                    </div>
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped mb-1">
                                                <thead>
                                                    <tr className='light-bg'>

                                                        {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                            <th>Action</th> :
                                                            <></>
                                                        }

                                                        {finalHeaderKeys?.map((th, headIndex) => (
                                                            <th key={`th${headIndex || 0}`}

                                                            >
                                                                {th}
                                                                {
                                                                    (sortConfig?.SortColumn !== null
                                                                        && sortConfig?.SortOrder !== null
                                                                        && sortConfig?.SortColumn === finalHeadersValue[headIndex]) ?
                                                                        (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && PageName !== 'UserLogReport' && <>
                                                                                <i className="fa fa-arrow-up ms-2" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'DESC' ? "#FFFFFF" : 'red', cursor: "pointer" }}></i>
                                                                                <i className="fa fa-arrow-down" onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} style={{ color: sortConfig?.SortOrder !== 'ASC' ? '#FFFFFF' : 'red', cursor: "pointer" }}  ></i>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            PageName !== 'Dashboard' && PageName !== 'AWBPhoneKyc' && <>
                                                                                <i className="fa fa-arrow-up ms-2" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])}></i>
                                                                                <i className="fa fa-arrow-down" style={{ cursor: "pointer" }} onClick={() =>
                                                                                    handleSorting && handleSorting(finalHeadersValue[headIndex])} ></i>
                                                                            </>
                                                                        )
                                                                }
                                                            </th> || <></>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MainData?.map((item, bodyIndex) => (
                                                        <tr
                                                            className="TableTrhover"
                                                            key={`row${bodyIndex}`}
                                                            onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                            style={{
                                                                background:
                                                                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                        ? '#fee77f'
                                                                        : ''
                                                            }}
                                                        >


                                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'UserLogReport' && PageName !== 'CODReconciliation' && PageName !== 'VendorPayout' && PageName !== 'CODReconsiliationReport' && PageName !== '' && (
                                                                <td

                                                                    key={`tdAction${bodyIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background:
                                                                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                ? '#fee77f'
                                                                                : '',

                                                                    }}
                                                                >
                                                                    {tableData.Actions?.map((btn, index) => (
                                                                        <span key={index}>
                                                                            {btn === 'SendMail' && <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} />}
                                                                            {btn === 'Edit' && <i className="bi bi-pencil-square info me-2" title='Edit' style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} />}
                                                                            {btn === 'Del' && <i className=" bi bi-trash3 danger me-2" title='Delete' style={{ cursor: "pointer", color: "#C42B1C" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} />}
                                                                            {btn === 'Print' && <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}print${index}`} />}
                                                                            {btn === 'View' && <i className="fa fa-eye text-primary me-2" title='Details View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.View, "", tabType)} />}
                                                                            {btn === 'History' && <i className="fa-solid fa-history me-2" title='History View' style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}History${index}`} onClick={() => handleHistory(item.History, "", tabType)} />}
                                                                            {btn === 'Update' &&

                                                                                <i className=" fa-solid fa-circle-info me-2" title='Update ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update)} />}
                                                                            {btn === 'UpdateDispatch' && <i className=" fa-solid fa-circle-info me-2" title='Update Dispatch ' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}UpdateDispatch${index}`} onClick={() => handleVerificationDispatch(item.UpdateDispatch)} />}
                                                                            {/* {btn === 'ProductPreview' && <i className=" fa-solid fa-boxes-packing" title='Product Preview' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}ProductPreview${index}`} onClick={() => ProductPreview(item.ProductPreview)} />}*/}
                                                                            {btn === 'ProductPreview' && item.ProductPreview.trim() !== '' && (
                                                                                <i
                                                                                    className="fa-solid fa-boxes-packing"
                                                                                    title='Product Preview'
                                                                                    style={{ cursor: "pointer", color: "#21A892" }}
                                                                                    key={`row${bodyIndex}ProductPreview${index}`}
                                                                                    onClick={() => ProductPreview(item.ProductPreview)}
                                                                                />
                                                                            )}

                                                                            {btn === 'Switch' &&
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Switch}Switch`}
                                                                                    checked={item.Switch === 'true' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                            }
                                                                            {btn === 'ReviewView' && <i className="fa-solid fa-comment me-2" title='Review View' onClick={() => handleReviewData(item.ReviewView)} style={{ cursor: "pointer", color: "#21A892" }}></i>}
                                                                            {btn === 'ComplainView' && <i className="fa-solid danger fa-boxes-packing me-2" title='Complaint View' onClick={() => handleComplainView(item.ComplainView)} style={{ cursor: "pointer", color: "#C42B1C" }}></i>}
                                                                            {btn === 'OrderView' && <i className="fa-solid fa-folder-open" title='Order View' onClick={() => handleOrderViewData(item.OrderView)} style={{ cursor: "pointer" }}></i>}
                                                                            {btn === 'RERY' &&
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="checkbox"
                                                                                    checked={item.checkStatus}
                                                                                    id={item.Checkbox}
                                                                                    onChange={(e) => {
                                                                                        handleCheck(e.target.checked, item.Checkbox, bodyIndex)
                                                                                    }}
                                                                                />}
                                                                            {/*{item.img ? `data:image/png;base64,${item.img}`: ""}*/}
                                                                            {btn === 'Accept' &&
                                                                                <button
                                                                                    className="button btn mt-0"
                                                                                    id={item.Accept}

                                                                                    key={`row${bodyIndex}Accept${index}`}
                                                                                    style={{ width: "auto", marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                    onClick={() => handleAccept(item.Accept, 'Accepted', "")}

                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>Accept</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Reject' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Reject}
                                                                                    key={`row${bodyIndex}Reject${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Reject, 'Rejected', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px", }}> Reject</div>
                                                                                </button>
                                                                            }
                                                                            {btn === 'Remove' &&
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Remove}
                                                                                    key={`row${bodyIndex}Remove${index}`}
                                                                                    style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Remove, 'Removed', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>  Remove</div>
                                                                                </button>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            )}

                                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                                <td
                                                                    key={`row${tdIndex}`}
                                                                    onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView)}
                                                                    style={{
                                                                        background: (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow) && PageName !== 'AWBPhoneKyc' ? '#fee77f' : ''
                                                                    }}
                                                                >
                                                                    {/* Render content based on the type of data */}
                                                                    {typeof item[key] === 'object' ? (
                                                                        Array.isArray(item[key]) ? (
                                                                            item[key].length > 0 ? (
                                                                                <i className="fa fa-eye" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ cursor: 'pointer', color: '#5a8dee' }} onClick={() => ShowEyeRecords(item[key], key)} />
                                                                            ) : null
                                                                        ) : (
                                                                            item[key]?.FileType === 'pdf' ? (
                                                                                <span onClick={() => { handleShowFile(item[key]) }}>
                                                                                    {/* Render PDF icon or content */}
                                                                                </span>
                                                                            ) : (
                                                                                item[key]?.FileType === '' ? null : (
                                                                                    <i className="fa fa-image me-2" title='show Image' onClick={() => { handleShowFile(item[key]) }} style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }} />
                                                                                )
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        // Render normal cell content
                                                                        key === 'Checkbox' ? (
                                                                            <input type="checkbox" checked={item.checkStatus} onChange={(e) => handleCheck(e.target.checked, item.Checkbox, item.Amount, bodyIndex)} />
                                                                        ) : item[key]
                                                                    )}
                                                                </td>
                                                            ))}


                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {countNo > 1 &&
                                            < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
export const ModalTable = ({ Token, PageName,
    tableData, handleCheck, currentPageNo,
    handleChange1, handleSorting, ProductPreview,
    handleAcceptModal, filterData, tabType,
    setFilterData, handleFilter,
    handleEdit, handleDeleteData, handleDetails,
    handleVerification, handleCheckbox }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const [AdvanceModelCleared, setAdvanceModelCleared] = useState(true);
    const [serchingData, setSerchingData] = useState([]);
    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );
    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );
    const ShowEyeRecords = (data, key) => {

    };

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };


    const getSearchingColumn = async () => {
        const LoginUserCode = cookies.get('loginUserCode').toString();
        let data = {
            Token, LoginUserCode: LoginUserCode, PageName: PageName
        }
        post(`${API_SERVER}/api/Dashboard/BindSearchingColumnList`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                let list = res.getSearchingList
                setFilterData({ ...filterData, SearchFieldValue: list[0].searchingValue })
                setSerchingData(res.getSearchingList)
            }
            else if (res.message === "EXPIRED") {
                Navigate('/Login')
            }
            else if (res.message !== "") {
                //AlertBox(res.status, res.message, "");
            }
        })
    };

    useEffect(() => {
        if (PageName !== "Manage Customer" && PageName !== "Product Multimedia") {
            getSearchingColumn();
        }

    }, []);

    const handleShowFile = async (value) => {
        const LoginUserCode = cookies.get('loginUserCode').toString();
        let data = {
            Token, LoginUserCode: LoginUserCode, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));

    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData, serchingData]);

    useEffect(() => {
        if (PageName !== 'ReportQuery') handleFilter();
    }, [AdvanceModelCleared]);
    return (

        <>  {
            tableData ? (
                <section className="recoard-table ">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-box">
                                    <div className="heading-boxModal d-block d-sm-flex">
                                        <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                            {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>

                                    </div>
                                </div>

                                <div className="recoard-bata service-content-area1">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped mb-1">
                                            <thead>
                                                <tr className='light-bg'>
                                                    {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                        <th>Action</th> :
                                                        <></>
                                                    }

                                                    {finalHeaderKeys?.map((th, headIndex) => (
                                                        <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                handleSorting ?
                                                                    handleSorting(finalHeadersValue[headIndex]) : ''}
                                                        >
                                                            {th}

                                                        </th> || <></>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {MainData?.map((item, bodyIndex) => (
                                                    <tr className="TableTrhover" key={`row${bodyIndex}`}
                                                        // onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView || item.OrderNO || item.OrderStatusID || item.Accept)}
                                                        style={{
                                                            background:
                                                                (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow || item.OrderNO === ColoredRow || item.OrderStatusID === ColoredRow || item.Accept === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                    ? '#fee77f'
                                                                    : ''
                                                        }}
                                                    >

                                                        {tableData?.Actions?.length > 0 && PageName !== 'Dashboard' && PageName !== 'UserLogReport' ? (
                                                            <td key={`tdAction${bodyIndex}`}
                                                                //  onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView || item.OrderNO || item.OrderStatusID || item.Accept)}
                                                                style={{
                                                                    background:
                                                                        (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow || item.OrderNO === ColoredRow || item.OrderStatusID === ColoredRow || item.Accept === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                            ? '#fee77f'
                                                                            : ''
                                                                }}
                                                            >
                                                                {tableData.Actions?.map((btn, index) => (
                                                                    <span key={index}>
                                                                        {btn === 'SendMail' ? <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} /> : (<></>)}
                                                                        {btn === 'Edit' ? <i className="bi bi-pencil-square info me-2" style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} /> : (<></>)}
                                                                        {btn === 'Del' ? <i className=" bi bi-trash3 danger me-2" style={{ cursor: "pointer", color: "#C42B1C" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} /> : (<></>)}
                                                                        {btn === 'Print' ? <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}print${index}`} /> : (<></>)}
                                                                        {btn === 'View' ? <i className="fa fa-eye text-primary me-2" style={{ cursor: "pointer", color: "#434BC2" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.View, "", tabType)} /> : (<></>)}
                                                                        {btn === 'Update' ? <i className="fa-regular fa-pen-to-square me-2" style={{ cursor: "pointer", color: "#639E19" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update)} /> : (<></>)}
                                                                        {btn === 'ProductPreview' ? <i className=" fa-solid fa-boxes-packing  me-2" title='Product Preview' style={{ cursor: "pointer", color: "#21A892" }} key={`row${bodyIndex}ProductPreview${index}`} onClick={() => ProductPreview(item.ProductPreview)} /> : (<></>)}
                                                                        {btn === 'Switch' ?
                                                                            <Switch
                                                                                divclassname='d-inline mx-1'
                                                                                extraclassname='d-inline form-switch pb-0'
                                                                                customstyle={{ cursor: 'pointer' }}
                                                                                id={`row${item.Switch}Switch`}
                                                                                checked={item.Switch === 'true' ? true : false}
                                                                                onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                            />
                                                                            : (<></>)}
                                                                        {btn === 'Checkbox' ?
                                                                            <input
                                                                                className="form-check-input mt-0"
                                                                                type="checkbox"
                                                                                checked={item.checkStatus}
                                                                                id={item.Checkbox}
                                                                                onChange={(e) => handleCheck(e.target.checked, item.Checkbox, bodyIndex)}
                                                                            />
                                                                            : (<></>)}
                                                                        {btn === 'Accept' ?
                                                                            <button
                                                                                className="button btn  mt-0"
                                                                                id={item.Accept}

                                                                                key={`row${bodyIndex}Accept${index}`}
                                                                                style={{ width: 'auto', marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                onClick={() => handleAcceptModal(item.Accept, 'Accepted', "")}

                                                                            >
                                                                                Accept
                                                                            </button>
                                                                            : (<></>)}
                                                                        {btn === 'Reject' ?
                                                                            <button
                                                                                className="button btn btn-danger mt-0"
                                                                                id={item.Reject}
                                                                                key={`row${bodyIndex}Reject${index}`}
                                                                                style={{ width: 'auto', borderRadius: "5px" }}
                                                                                onClick={() => handleAcceptModal(item.Reject, 'Rejected', "")}
                                                                            >
                                                                                Reject
                                                                            </button>
                                                                            : (<></>)}
                                                                        {btn === 'Remove' ?
                                                                            <button
                                                                                className="button btn btn-danger mt-0"
                                                                                id={item.Remove}
                                                                                key={`row${bodyIndex}Remove${index}`}
                                                                                style={{ marginRight: "2px", width: 'auto', borderRadius: "5px" }}
                                                                                onClick={() => handleAcceptModal(item.Remove, 'Removed', "")}
                                                                            >
                                                                                Remove
                                                                            </button>
                                                                            : (<></>)}
                                                                    </span>
                                                                ))}
                                                            </td>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {
                                                            tableData?.Data && tableData?.Data[0]?.hasOwnProperty("Checkbox") && tableData?.Actions.includes('Checkbox') === false ?
                                                                <td className='text-center'>
                                                                    <input
                                                                        className="form-check-input mt-0"
                                                                        type="checkbox"
                                                                        checked={item.checkStatus}
                                                                        id={item.Checkbox}
                                                                        onChange={(e) => handleCheck(e.target.checked, item.Checkbox, bodyIndex)}
                                                                    />
                                                                </td> : <></>
                                                        }
                                                        {finalHeadersValue?.map((key, tdIndex) => (
                                                            (typeof (item[key]) === 'object') ? (
                                                                Array.isArray(item[key]) ?
                                                                    (item[key].length > 0 ?
                                                                        <td className='text-center' key={`key${tdIndex}`}
                                                                            // onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView || item.OrderNO || item.OrderStatusID || item.Accept)}
                                                                            style={{
                                                                                background:
                                                                                    (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow || item.OrderNO === ColoredRow || item.OrderStatusID === ColoredRow || item.Accept === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                        ? '#fee77f'
                                                                                        : ''
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-eye"
                                                                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                                style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                                onClick={() => ShowEyeRecords(item[key], key)}

                                                                            />
                                                                        </td> : <td key={`key${tdIndex}`}></td>) : (
                                                                        typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?
                                                                            <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                                {/* <FaFilePdf style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />*/}
                                                                            </td> : typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`}></td>) :
                                                                                (<td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                                    <i className="fa fa-image"

                                                                                        style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}

                                                                                    />
                                                                                </td>))
                                                            )
                                                                :
                                                                <td key={`key${tdIndex}`}
                                                                    //   onClick={() => handleHighlight(item.Edit || item.View || item.Checkbox || item.ReviewView || item.OrderNO || item.OrderStatusID || item.Accept)}
                                                                    style={{
                                                                        background:
                                                                            (item.Edit === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.ReviewView === ColoredRow || item.OrderNO === ColoredRow || item.OrderStatusID === ColoredRow || item.Accept === ColoredRow) && PageName !== 'AWBPhoneKyc'
                                                                                ? '#fee77f'
                                                                                : ''
                                                                    }}
                                                                >{item[key]}</td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {countNo > 1 ?
                                        < Pagination className='pt-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            ) : (<> </>)
        }
        </>
    )
}
export const ReportTable = ({ Token, PageName, handleTabClick, tableData, handleCheck, formData,
    showCheckBox, currentPageNo, handleChange1, handleSorting,
    handleAccept, sortConfig, tabType,
    handleFilter, handleEdit,
    handleDetails, handleVerification, handleCheckbox }) => {
    const cookies = new Cookies();
    const [MainData, setMainData] = useState(tableData?.Data ? tableData?.Data : []);
    const handleDeleteData = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Do you want to Delete this Record ?',
            confirmButtonColor: '#238914',
            allowOutsideClick: true,
            width: 500,
            showCancelButton: true,
            isConfirmed: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteData(id)
            }
        });
    }
    const [AdvanceModelCleared, setAdvanceModelCleared] = useState(true);
    const [serchingData, setSerchingData] = useState([]);
    const finalHeaderKeys = tableData?.HeadersKey?.length > 0
        ? tableData?.HeadersKey?.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData?.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );
    const finalHeadersValue = (tableData?.HeadersKey?.length > 0 && tableData?.HeadersValue?.length > 0)
        ? tableData.HeadersValue.filter((key) => !tableData?.HideColumns.includes(key))
        : Array.from(
            new Set(
                tableData.Data?.flatMap((data) => Object.keys(data))
                    .filter((columnName) => !tableData?.HideColumns.includes(columnName))
            )
        );
    const ShowEyeRecords = (data, key) => {
    };

    const [ColoredRow, setColoredRow] = useState('');
    const handleHighlight = (id) => {
        setColoredRow(id)
    };

    const handleShowFile = async (value) => {
        const LoginUserCode = cookies.get('loginUserCode').toString();
        let data = {
            Token, LoginUserCode: LoginUserCode, PageName
        }
        let imgresult = await post(`${API_SERVER}/api/Image/GetImage`, { ...data, FormName: 'Order', ID: value.ID, CallType: value.CallType });
        let result = imgresult.data;
        if (result.fileType === 'pdf') {
            window.open(`/PDFViewer/#${result.fileBase64}`, '_blank');
        }
        else if (result.fileType === 'down') {
            const link = document.createElement('a');
            link.href = `data:image/jpeg;base64,${result.fileBase64}`;
            link.download = 'image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            window.open(`/imageView/#${result.fileBase64}`, '_blank');
        }
    }
    const countNo = Math.ceil((parseInt(tableData?.TotalRecord) / parseInt(tableData?.PageSize)));


    useEffect(() => {
        setMainData(tableData?.Data)
    }, [tableData, serchingData]);

    useEffect(() => {
        if (PageName !== 'ReportQuery') handleFilter();
    }, [AdvanceModelCleared]);


    return (

        <>  {
            tableData && (
                <section className="recoard-table mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-box">
                                    <div className="heading-box d-block d-sm-flex">

                                        <div className="heading d-flex gap-2">

                                            <span> <h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary">
                                                {`${tableData?.TotalRecord !== undefined ? 'Total Records : ' + tableData?.TotalRecord : 'No Record Found'}`}</h6></span>
                                            <span>{showCheckBox && tableData?.Data?.filter(d => d.checkStatus).length > 0 ? `Selected: ${tableData?.Data?.filter(d => d.checkStatus).length}` : ''}</span>

                                        </div>
                                        {tableData && tableData.TabTypes && (
                                            <div>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    {tableData.TabTypes.map((type, index) => (
                                                        <li className="nav-item" role="presentation" key={index}>
                                                            <button className={`nav-link ${index === 0 ? 'active' : ''}`}
                                                                id={`${type.toLowerCase()}-tab`}
                                                                data-bs-toggle="tab"
                                                                data-bs-target={`#${type.toLowerCase()}`}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={type.toLowerCase()}
                                                                aria-selected={index === 0 ? "true" : "false"}
                                                                onClick={() => {
                                                                    handleTabClick(type)
                                                                }}
                                                            >
                                                                {type}
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        )}
                                    </div>
                                    <div className="recoard-bata service-content-area">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped mb-1">
                                                <thead>
                                                    <tr className='light-bg'>
                                                        {tableData?.Data && tableData?.Actions && tableData?.Actions.length > 0 && tableData?.Actions.includes('Checkbox') === false ?
                                                            <th>Action</th> :
                                                            <></>
                                                        }
                                                        {tableData?.Data && tableData?.Data[0]?.hasOwnProperty("Checkbox") && PageName !== 'LoadingSheetTally'
                                                            ?
                                                            <th style={{ width: showCheckBox ? '20px' : '10px', marginRight: "3px" }} key="thActions">
                                                                Select
                                                            </th> : <></>
                                                        }
                                                        {finalHeaderKeys?.map((th, headIndex) => (
                                                            <th key={`th${headIndex || 0}`} style={{ cursor: 'pointer' }}
                                                                onClick={() =>
                                                                    handleSorting ?
                                                                        handleSorting(finalHeadersValue[headIndex]) : ''}
                                                            >
                                                                {th}
                                                            </th> || <></>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MainData?.map((item, bodyIndex) => (
                                                        <tr className="TableTrhover" key={`row${bodyIndex}`}
                                                        >
                                                            {tableData?.Actions && PageName !== 'Dashboard' && PageName !== 'VendorPayoutReport' && PageName !== 'PayoutProcessReport' && PageName !== 'CancelShipmentReport' && PageName !== 'UserLogReport' && PageName !== 'PayoutProcessReport' && PageName !== 'PaidOrderPaymentDetails' && PageName !== 'CODOrderPaymentDetails' && PageName !== 'VendorPayout' && PageName !== 'SmsReport' ? (
                                                                <td key={`tdAction${bodyIndex}`}

                                                                //style={{ background: (item.Edit === ColoredRow || item.Del === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.Accept || item.SrNo === ColoredRow) ? '#fee77f' : '' }}
                                                                >
                                                                    {tableData.Actions?.map((btn, index) => (
                                                                        <span key={index}>
                                                                            {btn === 'SendMail' ? <i className="fa-regular fa-envelope text-success me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} /> : (<></>)}
                                                                            {btn === 'Edit' ? <i className="bi bi-pencil-square info me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}edit${index}`} onClick={() => handleEdit(item.Edit)} /> : (<></>)}
                                                                            {btn === 'Del' ? <i className=" bi bi-trash3 danger me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}del${index}`} onClick={() => handleDeleteData(item.Del)} /> : (<></>)}
                                                                            {btn === 'Print' ? <i className="fa fa-download text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}print${index}`} /> : (<></>)}
                                                                            {btn === 'View' ? <i className="fa fa-eye text-primary me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}View${index}`} onClick={() => handleDetails(item.View, "", tabType)} /> : (<></>)}
                                                                            {btn === 'Update' ? <i className="fa-regular fa-pen-to-square me-2" style={{ cursor: "pointer" }} key={`row${bodyIndex}Update${index}`} onClick={() => handleVerification(item.Update, tabType)} /> : (<></>)}
                                                                            {btn === 'Switch' ?
                                                                                <Switch
                                                                                    divclassname='d-inline mx-1'
                                                                                    extraclassname='d-inline form-switch pb-0'
                                                                                    customstyle={{ cursor: 'pointer' }}
                                                                                    id={`row${item.Switch}Switch`}
                                                                                    checked={item.Switch === 'true' ? true : false}
                                                                                    onChange={(e) => handleCheckbox(item.Switch, e.target.checked, item, bodyIndex)}
                                                                                />
                                                                                : (<></>)}
                                                                            {btn === 'Checkbox' && formData.PaymentDetailsID === '' ?
                                                                                <input
                                                                                    className="form-check-input mt-0"
                                                                                    type="checkbox"
                                                                                    checked={item.checkStatus}
                                                                                    id={item.Checkbox}
                                                                                    onChange={(e) => handleCheck(e.target.checked, item.Checkbox, bodyIndex)}
                                                                                />
                                                                                : (formData.PaymentDetailsID === item.Checkbox ?
                                                                                    <input
                                                                                        className="form-check-input mt-0"
                                                                                        type="checkbox"
                                                                                        checked={item.checkStatus}
                                                                                        id={item.Checkbox}
                                                                                        onChange={(e) => handleCheck(e.target.checked, item.Checkbox, bodyIndex)}
                                                                                    /> : <input
                                                                                        className="form-check-input mt-0"
                                                                                        type="checkbox"
                                                                                        checked={item.checkStatus}
                                                                                        id={item.Checkbox}
                                                                                        disabled={true}
                                                                                        onChange={(e) => handleCheck(e.target.checked, item.Checkbox, bodyIndex)}
                                                                                    />)}
                                                                            {btn === 'Accept' ?
                                                                                <button
                                                                                    className="button btn mt-0"
                                                                                    id={item.Accept}

                                                                                    key={`row${bodyIndex}Accept${index}`}
                                                                                    style={{ width: '35%', marginRight: "2px", borderRadius: "5px", backgroundColor: "#689D15" }}
                                                                                    onClick={() => handleAccept(item.Accept, 'Accepted', "")}

                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>Accept</div>
                                                                                </button>
                                                                                : (<></>)}
                                                                            {btn === 'Reject' ?
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Reject}
                                                                                    key={`row${bodyIndex}Reject${index}`}
                                                                                    style={{ marginRight: "2px", width: '35%', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Reject, 'Rejected', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}> Reject</div>
                                                                                </button>
                                                                                : (<></>)}
                                                                            {btn === 'Remove' ?
                                                                                <button
                                                                                    className="button btn btn-danger mt-0"
                                                                                    id={item.Remove}
                                                                                    key={`row${bodyIndex}Remove${index}`}
                                                                                    style={{ marginRight: "2px", width: '55%', borderRadius: "5px" }}
                                                                                    onClick={() => handleAccept(item.Remove, 'Removed', "")}
                                                                                >
                                                                                    <div style={{ fontSize: "14px" }}>  Remove</div>
                                                                                </button>
                                                                                : (<></>)}
                                                                        </span>
                                                                    ))}
                                                                </td>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            {finalHeadersValue?.map((key, tdIndex) => (
                                                                (typeof (item[key]) === 'object') ? (
                                                                    Array.isArray(item[key]) ?
                                                                        (item[key].length > 0 ?
                                                                            <td className='text-center' key={`key${tdIndex}`}>
                                                                                <i className="fa fa-eye"
                                                                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                                    style={{ cursor: 'pointer', color: '#5a8dee' }}
                                                                                    onClick={() => ShowEyeRecords(item[key], key)}

                                                                                />
                                                                            </td> : <td key={`key${tdIndex}`}></td>) : (
                                                                            typeof (item[key]) === 'object' && item[key]?.FileType === 'pdf' ?
                                                                                <td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                                    {/* <FaFilePdf style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '16px' }} />*/}
                                                                                </td> : typeof (item[key]) === 'object' && item[key]?.FileType === '' ? (<td key={`key${tdIndex}`}></td>) :
                                                                                    (<td key={`key${tdIndex}`} className='text-center' onClick={() => { handleShowFile(item[key]) }}>
                                                                                        <i className="fa fa-image"

                                                                                            style={{ cursor: 'pointer', color: '#5a8dee', fontSize: '14px' }}

                                                                                        />
                                                                                    </td>))
                                                                )
                                                                    :
                                                                    <td key={`key${tdIndex}`}
                                                                        style={{ background: (item.Edit === ColoredRow || item.Del === ColoredRow || item.View === ColoredRow || item.Checkbox === ColoredRow || item.Accept || item.SrNo === ColoredRow) ? '#fee77f' : '' }}>{item[key]}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {countNo > 1 &&
                                        < Pagination className='p-2' count={countNo} variant="outlined" page={currentPageNo} onChange={handleChange1} shape="rounded" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

        </>
    )
}


