
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { post } from '../Common/service';
import { FileUploadChild, ProductHeader, setFocus,} from '../Common/Common';
import Loader from '../Layout/Loader';
import { API_SERVER } from '../../EnvConfig/env_config';
import { AlertBox, handleFileUploader, InputBox } from '../Common/Input';
import { useNavigate } from "react-router-dom";
import {  ModalTable, TableBrand } from "../Common/Table";
import {  handleDownloadExcel } from "../Common/CommanServices";
import { FileUploader } from "react-drag-drop-files";
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import DragableModal from "../Common/DragableModal";

const BrandApplication = ({ open, setOpen }) => {
    const cookies = new Cookies();
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [tableData, setTableData] = useState([])
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [sortConfig, setSortConfig] = useState({ SortColumn: null, SortOrder: null });
    const [userCode, setUserCode] = useState({ value: 'System Generated' })
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [showgrid, setShowGrid] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBrandDetailsModalOpen, setIsBrandDetailsModalOpen] = useState(false);
    const [VendorCodePre, setVendorCodePre] = useState('')
    const VendorName = cookies.get('loginType') === 'Vendor' ? cookies.get('userName') : "";
    const VendorCode = cookies.get('loginType') === 'Vendor' ? cookies.get('loginUserCode') : ""
    const [modalImg, setModalImg] = useState('');
    const [modalData, setModalData] = useState([])
    const navigate = useNavigate();
    const fileType = 'jpg,png'
    const [filterData, setFilterData] = useState({
        SearchText: "",
        StartValue: "1",
        SearchFieldValue: "",
        PageIndex: "1",
        "AdvanceFilter": [],
        TabType:""
    })
    const [brandId, setBrandId] = useState("")
    const [isExpanded, setIsExpanded] = useState(false);
    const [globaltabtype, setGlobaltabtype] = useState('ALL')
    const [OrderId, SetOrderId] = useState({
        BrandId:""
    })
    const [formData, setFormData] = useState({
        BrandId: "",
        BrandName: '',
        Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
        SequenceNo: "",
        Description: "",
        BrandImage: "",
        BrandImageName: "",
        BrandImageExt: "",
        BrandBannerImage: "",
        BrandBannerImageExt: "",
        Active: true,
    });
    const Token = cookies.get('token')? cookies.get('token'):""
    const LoginUserCode = cookies.get('loginUserCode');
    const Source = 'web';
    const data = {
        Token: cookies.get('token'),
        LoginUserCode: String(LoginUserCode)
    }
    useEffect(() => {
        document.title = 'Brand Master'
        setVendorCodePre(VendorCode ? VendorCode + ' : ' + VendorName : "",);
        if (showForm) {
            setFocus("txtBrandName")
        }
        
    }, [showForm, setFocus])
    const getBrandData = async (CurrentPage, tabType) => {
        if (Token!=="") {
            setGlobaltabtype(tabType)
            let PageIndex = 1
            if (CurrentPage !== undefined && typeof (CurrentPage) !== "object") {
                PageIndex = CurrentPage;
                setCurrentPageNo(CurrentPage);
            }
            else {
                setCurrentPageNo(1);
            }
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
            const data = {
                ...filterData,
                ...sortConfig,
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                Type: 'S',
                PageIndex: `${PageIndex}`,
                TabType: tabType
            }
            setIsLoading(true)
            post(`${API_SERVER}/api/Master/ShowBrandMaster`, { ...data }, (res) => {
                if (res.Status === "SUCCESS") {
                    setTableData({ ...res })
                    setIsLoading(false)
                }
                else if (res.Message === "EXPIRED") {
                    navigate('/Login')
                }
                else if (res.Status === "ERROR") {
                    AlertBox(res.status, res.message, res.focus);
                    setTableData({ ...res })

                }
                setIsLoading(false)
            });
        }
        else {
            navigate("/Login")
        }

    }
   
    const handleTabClick = (s) => {
        setGlobaltabtype(s)
        getBrandData(currentPageNo,s)
    } 
    const toggleModal = (type) => {
        if (type === 'add') {
            setShowForm(true);
            setShowGrid(false);
        } else {
            setShowForm(false);
            setShowGrid(true);
        }
    };
    const handleClear = () => {
        setFormData({
            BrandId: "",
            BrandName: '',
            SequenceNo: "",
            Vendor: cookies.get('loginType') === 'Vendor' ? VendorCode + ' : ' + VendorName : "",
            Description: "",
            BrandImage: "",
            BrandImageName: "",
            BrandImageExt: "",
            BrandBannerImage: "",
            BrandBannerImageExt: "",
            Active: true,
        });
        filterData.SearchText = '';
        setUserCode({ ...userCode, value: 'System Generated' });
        setEdit(false)
        setSortConfig({ SortColumn: null, SortOrder: null })
    }

    const handleChange1 = (event, value) => {
        getBrandData(value);
    };



    const handleSubmit = async () => {
        setIsLoading(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        let data = {
            ...formData, Token, LoginUserCode: loginCodeString, Source,
        }
        post(`${API_SERVER}/api/Master/InsertUpdateBrandMaster`, { ...data }, (res) => {
            if (res.status === 'SUCCESS') {
                AlertBox(res.status, res.message, "");
                setIsLoading(false);
                handleClear();
                setEdit(false);
                setShowForm(false)
                setShowGrid(true)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            setIsLoading(false);
        })
    };

    const handleEditData = async (id,status) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            BrandId: id,
            BrandStatus: status
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/EditBrandMaster`, { ...data }, (res) => {
            setEdit(true)
            if (res.status === 'SUCCESS') {
                setFormData({
                    ...formData,
                    BrandId: res.brandID,
                    BrandName: res.brandName,
                    Vendor:res.vendor,
                    SequenceNo: res.sequenceNo,
                    Description: res.description,
                    BrandImage: res.brandImage,
                    BrandImageName: res.brandImageName,
                    BrandImageExt: res.brandImageExt,
                    BrandBannerImage: res.brandBannerImage,
                    BrandBannerImageExt:res.brandBannerImageExt,
                    Active: res.active
                });
                setShowForm(true)
                setIsLoading(false)
                SetOrderId(

                    ...OrderId,
                    
                )
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.message !== "") {
                AlertBox(res.status, res.message, res.focus);
            }
            
        });
        setIsLoading(false)
    }
    
        let ExportRequest = {
            ...filterData,
            ...data,
            Type: 'E',
            PageIndex: '1',
            Tabtype: globaltabtype 
        }
    const handleDelete = async (id) => {
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            BrandId: id
        }
        setIsLoading(true)
        post(`${API_SERVER}/api/Master/DeleteBrandMaster`, { ...data }, (res) => {
            if (res.status === "SUCCESS") {
                AlertBox(res.status, res.message, res.focus);
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else {
                AlertBox(res.status, res.message, res.focus);
            }
            handleClear();
            setIsLoading(false)
        })
    }
    const ImageView = (ProductUrl) => {
        window.open(ProductUrl, '_blank')
    }
    const handleAccept = async (Id, status) => {
        const confirmAcceptance = async (reason) => {
            const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";
            const requestData = {
                Token: cookies.get('token'),
                LoginUserCode: loginCodeString,
                Source: 'web',
                BrandId: Id,
                BrandStatus: status,
                TabType: globaltabtype
            }
            setIsLoading(true);
            await post(`${API_SERVER}/api/Master/UpdateBrandStatus`, { ...requestData, reason }, (res) => {
                if (res.status === "SUCCESS") {
                    AlertBox(res.status, res.message, res.focus);
                    getBrandData(currentPageNo, globaltabtype);
                    //getOrdersTabCounts();
                } else if (res.message === "EXPIRED") {
                    navigate('/Login');
                } else {
                    AlertBox(res.status, res.message, res.focus);
                }
                setIsLoading(false);
            });
        };

        let alertMessage = "";
        let title = "";
        let confirmButtonText = "";

        switch (status) {
            case "Accepted":
                alertMessage = "Do you want to accept?";
                title = "Accepted";
                confirmButtonText = "Yes Accept It!"
                break;
            case "Rejected":
                alertMessage = "Do you want to Reject?";
                title = "Rejected";
                confirmButtonText = "Yes Reject It!";
                break;
            default:
                alertMessage = "Do you want to proceed?";
                break;
        }
        if (status === "Accepted") {
            await Swal.fire({
                text: alertMessage,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmAcceptance('');
                }
            });
        }
        if (status === "Rejected") {
            const { value: text, dismiss } = await Swal.fire({
                text: alertMessage,
                icon: "warning",
                input: "textarea",
                inputPlaceholder: "Type your Reason here...",
                inputAttributes: {
                    "aria-label": "Type your Reason here"
                },
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: true,
                cancelButtonColor: "#d33",
                confirmButtonText: confirmButtonText,
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage("Please enter a reason.");
                    }
                    return value;
                }
            });

            if (text && !dismiss) {
                confirmAcceptance(text);
            }
        }
    };
    const handleDetails = async (BrandId) => {
        setBrandId(BrandId)
        setIsBrandDetailsModalOpen(true)
        const loginCodeString = LoginUserCode ? String(LoginUserCode) : "";

        const data = {
            Token: await cookies.get('token'),
            LoginUserCode: loginCodeString,
            Source: 'web',
            BrandId: BrandId,
        }
       // setIsLoading(true)
        post(`${API_SERVER}`, { ...data }, (res) => {
            if (res.Status === "SUCCESS") {
                setModalData({ ...res })
            //    setIsLoading(false)
            }
            else if (res.message === "EXPIRED") {
                navigate('/Login')
            }
            else if (res.Status === "ERROR") {
                AlertBox(res.Status, res.message, res.focus);
                setModalData({ ...res })
            }
         //   setIsLoading(false)
        });
    };


    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };
    //let status = VendorCode.toString().includes('V')
    const handleShowImageInModal = (Image) => {
        setIsModalOpen(true)
        setModalImg(Image)
    }

    useEffect(() => {
        getBrandData(currentPageNo, globaltabtype)
    }, [sortConfig, refreshGrid])
    return (
        <>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-box">
                                <ProductHeader
                                    onMainButtonClick={() => {
                                        toggleModal(showForm ? 'Back' : 'add');
                                        if (!showForm) {
                                            setFocus("txtBrandName");
                                        }
                                        handleClear()
                                    }}
                                    onFilterButtonClick={() => {
                                        setIsExpanded(!isExpanded)
                                    }}
                                    isExpanded={isExpanded}
                                    onExportButtonClick={() => {
                                        handleDownloadExcel(API_SERVER + '/api/Master/ShowBrandMaster', ExportRequest, 'BrandMaster')
                                    }}
                                    showForm={showForm}
                                    buttonText='Add New'
                                    heading='Brand Master'
                                />
                            

                                {showForm &&
                                    <div className="form-box service-content-area">
                                        <div className="row ">
                                                    <InputBox
                                                        label="Brand Name"
                                                        placeholder="Brand Name"
                                                        id="txtBrandName"
                                                        type="text"
                                                        maxLength='30'
                                                        value={formData.BrandName}
                                                        onChange={(e) => setFormData({ ...formData, BrandName: e.target.value })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtSequenceNo") };
                                                        }}
                                                        required
                                                    />
                                           
                                                    <InputBox
                                                        label="Sequence No"
                                                        placeholder="Sequence No"
                                                        id="txtSequenceNo"
                                                        type="text"
                                                        maxLength='30'
                                                        value={formData.SequenceNo}
                                                        onChange={(e) => setFormData({ ...formData, SequenceNo: e.target.value })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtDescription") };
                                                        }}
                                                        required
                                            />
                                           
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="fields">
                                                    <label className="form-label">Description</label>
                                                    <textarea
                                                        id="txtDescription"
                                                        className="form-control"
                                                        placeholder='Enter Description'
                                                        cols={500}
                                                        rows="1"
                                                        value={formData.Description}
                                                        onChange={(e) => {
                                                            setFormData({ ...formData, Description: e.target.value })
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("activeCheckbox") };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6"></div>
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtBrandImage">
                                                <div className="fields">
                                                    <label className="form-label ">Brand Image<span className="Required">*</span></label>
                                                    <FileUploader
                                                        classes='file-uploader emp-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'BrandImage', 'BrandImageExt', 'BrandImageName')}
                                                        children={<FileUploadChild label='Brand Image' message={formData.BrandImageName + '.' + formData.BrandImageExt} types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note:For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (180-200) Pixels.</div>
                                                                <div>* Height should be between (130-150) Pixels.</div>
                                                                <div>* Maximum Image Size 100 kb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            {formData.BrandImage ?
                                                <div className='col-md-4 col-lg-3 p-3' style={{  position: "relative" }}>
                                                    <img
                                                        onClick={() => handleShowImageInModal(`data:image/${formData.brandImageExt};base64,${formData.BrandImage}`)}
                                                        className='border border-success img-thumbnail '
                                                        src={`data:image/png;base64,${formData.BrandImage}`}
                                                        alt='logo'
                                                        style={{ width: '180px', height: "81px", position: "relative", left: "5%" }}
                                                    />
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6"
                                                id="txtBrandBannerImage">
                                                <div className="fields">
                                                    <label className="form-label ">Brand Banner Image<p className="d-inline text-danger">* </p>
                                                    </label>
                                                    <FileUploader
                                                        classes='file-uploader Brand-photo'
                                                        types={fileType.split(",")}
                                                        handleChange={(file) => handleFileUploader(file, fileType.split(","), setFormData, formData, 'BrandBannerImage', 'BrandBannerImageExt', 'BrandBannerImageName')}
                                                        children={<FileUploadChild label='Brand Banner Image'  types={fileType.split(",")} />}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <div className="my-2">
                                                        <div className="bg-secondary bg-gradient rounded-bottom text-white ps-2">
                                                            <div style={{ fontSize: '13px' }}>Note:For Best View</div>
                                                            <div className="notefont">
                                                                <div>* Width should be between (1500-1530)Pixels.</div>
                                                                <div>* Height should be between (275-300)Pixels.</div>
                                                                <div>* Maximum Image Size 2 mb.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.BrandBannerImage ?
                                                <div className='col-md-4 col-lg-3' style={{ position: "relative" }}>
                                                    <div className="m-4">
                                                        <img
                                                            onClick={() => handleShowImageInModal(`data:image/${formData.BrandBannerImageExt};base64,${formData.BrandBannerImage}`)}
                                                            className='border border-success img-thumbnail '
                                                            src={`data:image/png;base64,${formData.BrandBannerImage}`}
                                                            alt='logo'
                                                            style={{ width: '180px', height: "81px", position: "relative" }}
                                                        />
                                                    </div>
                                                </div>
                                                : <></>
                                            }
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-check form-switch pt-4">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="activeCheckbox"
                                                    >
                                                        Active
                                                    </label>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="activeCheckbox"
                                                        name='Active'
                                                        checked={formData.Active}
                                                        onChange={(e) => setFormData({ ...formData, Active: e.target.checked })}

                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleSubmit();
                                                            };
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mt-4">
                                                <div className="lms-new-button text-center">
                                                    {edit ? (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Update
                                                    </button>) : (isLoading ? <button className="button save-btn btn-disable" disabled={isLoading} >
                                                        Please wait...
                                                    </button> : <button className="button save-btn" onClick={handleSubmit}>
                                                        <i className="bi bi-check-lg" /> Save
                                                    </button>)}
                                                    {/*{edit ? (*/}
                                                    {/*    <button className="button save-btn" onClick={handleAccept}>*/}
                                                    {/*        <i className="bi bi-check-lg" /> Accept*/}
                                                    {/*    </button>*/}

                                                    {/*):(<></>) }*/}
                                                    <button className="button reset-btn" onClick={handleClear}>
                                                        <i className="bi bi-arrow-clockwise" /> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {showgrid && !showForm && (
                    <TableBrand
                        Token={Token}
                        PageName='BrandMaster'
                        handleEdit={handleEditData}
                        handleDelete={handleDelete}
                        handleTabClick={handleTabClick }
                        handleFilter={() => {
                            getBrandData(currentPageNo, globaltabtype);
                            setFilterData({
                                ...filterData,
                            });
                        }}
                        ImageView={ImageView}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        handleAccept={handleAccept}
                        handleDetails={handleDetails }
                        tableData={tableData}
                        filterData={filterData}
                        refreshGrid={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        setFilterData={setFilterData}
                        currentPageNo={currentPageNo}
                        handleChange1={handleChange1}
                        handleSorting={handleSorting}
                        sortConfig={sortConfig}
                    />
                )}
                {isLoading ? <Loader text='Please wait' size='100px' img_path='./assets/img/giphy.gif' /> : <></>}


            </main>
            <Modal closeButton size='lg' show={isModalOpen} onHide={() => {
                setIsModalOpen(false)
                setModalImg('')
            }} >
                <Modal.Header closeButton>
                    <Modal.Title>Brand Image</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className='text-center' style={{ overflow: 'auto' }}>
                        <img src={modalImg} alt='BrandImageinModal' />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={isBrandDetailsModalOpen} style={{ cursor: 'pointer', paddingTop: "31px" }} backdrop="static" dialogAs={DragableModal} onHide={() => setIsBrandDetailsModalOpen(false)} >
                <Modal.Header closeButton style={{ background: "mintcream" }} >
                    <Modal.Title><h6 className="m-0 mb-2 mb-sm-0 font-weight-bold text-primary me-4">Brand Details</h6></Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ background: "mintcream" }}>
                    <div className="modal-body p-0" style={{ padding: '0px' }}>
                        {modalData && modalData ?
                            <>
                                <div className="form-box p-0  me-3 " >
                                    <div className="row  details-tableAwb" >
                                        <ModalTable
                                            Token={Token}
                                            PageName='Brand'
                                            handleFilter={() => {
                                                setFilterData({
                                                    ...filterData,
                                                });
                                            }}
                                            Filter={false}
                                            handleDetails={handleDetails}
                                            tableData={modalData}
                                            setFilterData={setFilterData}
                                            currentPageNo={currentPageNo}
                                            handleChange1={handleChange1}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}
export default BrandApplication
